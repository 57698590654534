import { Button } from '@mui/material';
import { TableBody } from '@mui/material';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Document } from 'commons/interfaces/documents';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';


interface Props {
    documents: Document[]
}


export class DocumentsTable extends React.Component<Props, null> {

    renderItem = (item: Document & ComponentListItem) => {
        const onDownload = () => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = item.links[0].href;
            link.click();
        };

        return (
            <TableRow key={item._key}>
                <TableCell>{item._itemNumber}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.notes}</TableCell>
                <TableCell><Button onClick={onDownload} variant="contained" color="primary">Visualizza</Button></TableCell>
            </TableRow>
        )
    }

    render() {
        if (this.props.documents) {
            const tableItems = renderComponentsList(this.props.documents, this.renderItem)
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Nome documento</TableCell>
                                <TableCell>Note</TableCell>
                                <TableCell>Link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableItems}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

}