import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { GlobalStateInterface } from "redux/store"
import { GridSortItem } from "@mui/x-data-grid"
import { PAGES } from "utils/rest-routes"
import { ComponentListItem, ReactLabelComponent, appendQueryParams, logThis, renderComponentsList } from "@insoft/lib-react-universal"
import { BoxedContent, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel, composeUriPath, defaultReactDataRenderMUITable, executeRequest } from "@insoft/lib-react-web"
import { Link } from "react-router-dom"
import { Box } from "@mui/material"

interface Props {
    templateList: string
    enrollmentTemplate: string,
    dataChangeTemplate: string,
    frequencyChangesTemplate: string,
    additionalServiceTemplate: string,
    enrollmentChangeTemplate: string,
    changeIseeTemplate: string

}

interface TableRow {
    name: string;
    newEnrollment?: ReactLabelComponent;
    dataChange?: ReactLabelComponent;
    frequencyChange?: ReactLabelComponent;
    additionalService?: ReactLabelComponent;
    enrollmentChanges?: ReactLabelComponent;
    changeIsee?: ReactLabelComponent;
}

interface Template {
    name: string;
    yearId: string
}

interface AllProps extends Props//, React.Props<any> 
{ }

function TemplateTableComponent(props: AllProps) {

    const pageSizes = [5, 10, 25];

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[1]);
    const [sortModel, setSortModel] = useState<TableSortModel>();
    const [resources, setResources] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [])

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    const fetchData = () => {
        setLoading(true)
        var sortingArray: string[] = [];
        var requestUrl = appendQueryParams(props.templateList, {
            page: pageNumber.toString(),
            size: pageSize.toString(),
            sort: sortingArray
        });
        executeRequest<any>({ url: requestUrl, method: "GET" })
            .then((data: any) => {
                setResources(data)
                setLoading(false)
            }).catch((error: any) => {
                logThis("Cannot fetch the values", error)
                setLoading(false)
            })
    }

    function getTableColumns() {
        let tableColumns: TableColumnDefinition[] = [];
        tableColumns.push({
            headerName: 'Nome',
            field: 'name'
        });
        if (props.enrollmentTemplate) {
            tableColumns.push({
                headerName: 'Nuova Iscrizione',
                field: 'newEnrollment',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        if (props.dataChangeTemplate) {
            tableColumns.push({
                headerName: 'Cambio Dati',
                field: 'dataChange',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        if (props.frequencyChangesTemplate) {
            tableColumns.push({
                headerName: 'Modifica Frequenza',
                field: 'frequencyChange',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        if (props.additionalServiceTemplate) {
            tableColumns.push({
                headerName: 'Servizi Aggiuntivi',
                field: 'additionalService',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        if (props.enrollmentChangeTemplate) {
            tableColumns.push({
                headerName: 'Modifica inizio/fine frequenza',
                field: 'enrollmentChange',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        if (props.changeIseeTemplate) {
            tableColumns.push({
                headerName: 'Cambio Fascia',
                field: 'changeIsee',
                renderCell: defaultReactDataRenderMUITable
            });
        }
        return tableColumns;
    }

    const renderRow = (
        element: Template & ComponentListItem
    ) => {
        var newEnrollmentHref = composeUriPath(PAGES.templateNuovaIscrizioneFrequenza, [element.yearId]);
        var dataChangeHref = composeUriPath(PAGES.templateCambioDati, [element.yearId]);
        var frequencyChangeHref = composeUriPath(PAGES.templateModificaInizioFineFrequenza, [element.yearId]);
        var additionalServiceHref = composeUriPath(PAGES.templateServiziAggiuntivi, [element.yearId]);
        var enrollmentChangeHref = composeUriPath(PAGES.templateModificaIscrizione, [element.yearId]);
        var iseeChangeHref = composeUriPath(PAGES.templateCambioIsee, [element.yearId]);

        var newEnrollmentLink = undefined
        var dataChangeLink = undefined
        var frequencyChangeLink = undefined
        var additionalServiceLink = undefined
        var enrollmentChangeLink = undefined
        var iseeChangeLink = undefined

        if (props.enrollmentTemplate) {
            newEnrollmentLink = <Link to={newEnrollmentHref}>Visualizza</Link>
        }
        if (props.dataChangeTemplate) {
            dataChangeLink = <Link to={dataChangeHref}>Visualizza</Link>
        }
        if (props.frequencyChangesTemplate) {
            frequencyChangeLink = <Link to={frequencyChangeHref}>Visualizza</Link>
        }
        if (props.additionalServiceTemplate) {
            additionalServiceLink = <Link to={additionalServiceHref}>Visualizza</Link>
        }
        if (props.enrollmentChangeTemplate) {
            enrollmentChangeLink = <Link to={enrollmentChangeHref}>Visualizza</Link>
        }
        if (props.changeIseeTemplate) {
            iseeChangeLink = <Link to={iseeChangeHref}>Visualizza</Link>
        }

        const obj = {
            name: element.name,
            newEnrollment: { label: "", component: newEnrollmentLink },
            dataChange: { label: "", component: dataChangeLink },
            frequencyChange: { label: "", component: frequencyChangeLink },
            additionalService: { label: "", component: additionalServiceLink },
            enrollmentChange: { label: "", component: enrollmentChangeLink },
            changeIsee: { label: "", component: iseeChangeLink },
        }
        return obj;
    };

    return (
        <div>
            <BoxedContent xl={12}>
                <Box p={2}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <ResponsiveMaterialUITableWithPagination
                                    dense={true}
                                    data={resources && resources._embedded && renderComponentsList(resources._embedded.yearTemplateSummaries, renderRow)}
                                    columns={getTableColumns()}
                                    sortingMode="server"
                                    sortModel={sortModel}
                                    onSortModelChange={(sortModel: TableSortModel) => handleModule(sortModel)}
                                    pagination={true}
                                    paginationMode="server"
                                    rowsPerPage={pageSize}
                                    page={pageNumber}
                                    rowCount={resources != undefined ?
                                        (resources.page != undefined ? resources.page.totalElements : 0)
                                        : 0}
                                    onPageChange={setNumberOfPage}
                                    onPageSizeChange={setNumberElementsOnPage}
                                    rowsPerPageOptions={pageSizes}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </BoxedContent>
        </div>
    )

}
const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        templateList: state.global.navigationLinks._links.listYearTemplates.href,
        enrollmentTemplate: state.global.navigationLinks._links.enrollmentTemplate ? state.global.navigationLinks._links.enrollmentTemplate.href : undefined,
        dataChangeTemplate: state.global.navigationLinks._links.dataChangeTemplate ? state.global.navigationLinks._links.dataChangeTemplate.href : undefined,
        frequencyChangesTemplate: state.global.navigationLinks._links.frequencyChangeTemplate ? state.global.navigationLinks._links.frequencyChangeTemplate.href : undefined,
        additionalServiceTemplate: state.global.navigationLinks._links.additionalServiceTemplate ? state.global.navigationLinks._links.additionalServiceTemplate.href : undefined,
        enrollmentChangeTemplate: state.global.navigationLinks._links.enrollmentChangeTemplate ? state.global.navigationLinks._links.enrollmentChangeTemplate.href : undefined,
        changeIseeTemplate: state.global.navigationLinks._links.changeIseeTemplate ? state.global.navigationLinks._links.changeIseeTemplate.href : undefined
    }
}

export default connect(mapStateToProps)(TemplateTableComponent)