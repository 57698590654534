import { commonFormStyleRules, getCommonFields, getCommonWidgets } from "commons/styles/common-form-style-rules";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { startLoading, stopLoading } from "redux/actions/global-actions";
import { GlobalStateInterface } from "redux/store";
import { getLoadingComponent, renderAlertComponent, renderModal } from "utils";
import { ModalResponse } from "commons/interfaces/utils";
import { PAGES } from "utils/rest-routes";
import { useParams } from "react-router";
import { AlertObject, composeUriPath, FormPage, getFormData, getRedirectComponent, parseServerErrorResponse, sendForm, updateFormDataState } from "@insoft/lib-react-web";
import { expandUri, logThis } from "@insoft/lib-react-universal/dist";
import { Box } from "@mui/material";

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading?: boolean
    url?: string
    structureId?: string
    nextPage: string
}

interface AllProps
    extends ActionCreatorsProps, Props { }

function NewStaffRia(props: AllProps) {

    const [ria, setRia] = useState<string>(undefined)
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [resources, setResources] = useState<any>(null);
    const [serverResponse, setServerResponse] = useState<AlertObject>(undefined);
    const [modalObject, setModalObject] = useState<ModalResponse>(undefined);

    const params = useParams()
    const ana = params.ana


    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        props.startLoading()
        const expandedUri = expandUri(props.url, { ana: ana });
        getFormData({ url: expandedUri })
            .then((data) => {
                // Da rivedere
                if (data._links && data._links.getRoles && data._links.getRoles.href && data.data && data.data.form
                    && data.data.form.formSchema && data.data.form.formSchema.properties
                    && data.data.form.formSchema.properties.staffProfile.properties.profile
                    && data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete
                    && data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete.href) {
                    data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete.href = data._links.getRoles.href
                }
                setResources(data)
                props.stopLoading()
            }).catch((data) => {
                logThis(data)
                props.stopLoading()
            })
    }

    const onFormSubmit = (formObject: { formData: { [key: string]: any } }) => {
        let formData = formObject.formData
        formData.cst = props.structureId
        setResources(updateFormDataState(resources, formData))
        const expandedUri = expandUri(props.url, { ana: ana });
        props.startLoading()
        sendForm({ method: 'POST', url: expandedUri, data: formData })
            .then((message) => {
                setRia(message)
                setServerResponse(undefined)
                let modalResponse: ModalResponse = {
                    status: 'OK',
                    message: 'Dati aggiornati con successo. La pagina si ricaricherà automaticamente.'
                }
                setModalObject(modalResponse)
                props.stopLoading()
            }).catch((error) => {
                setServerResponse(parseServerErrorResponse(error))
                props.stopLoading()
            })
    }

    const closeModal = () => {
        setModalObject(undefined)
        setFormSubmitted(true)
    }

    let serverResponseComponent = renderAlertComponent(serverResponse)
    let modalObjectComponent = renderModal(modalObject, closeModal)

    if (formSubmitted) {
        return getRedirectComponent(composeUriPath(props.nextPage, [ria]))
    }
    if (props.loading) {
        return getLoadingComponent(true)
    }

    return (
        <Box>
            {modalObjectComponent}
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={onFormSubmit}
                resources={resources}
                buttonText="Aggiorna"
                showRequiredFieldsLegend
                serverResponseComponent={serverResponseComponent}
            />
        </Box >
    )

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        // urlGetRia: state.global.navigationLinks._links.getRia.href,
        url: state.global.navigationLinks._links.getAnaForRia.href,
        structureId: state.global.structureId,
        nextPage: PAGES.modifyStaff
    }
};

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStaffRia)