import { FormPage } from '@insoft/lib-react-web';
import { Structure } from 'commons/interfaces/user-info';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { StaffFilters, saveStaffFilters, startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { getSearchStaffFormObject } from './search-staff-form-objects';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    saveStaffFilters: (staffFilters: StaffFilters) => any
}

export interface Props {
    authorizedStructures?: Structure[] | string
    loading?: boolean
    staffFilters: StaffFilters
}

interface AllProps extends ActionCreatorsProps, Props {
    title?: string
    searchCallBack?: () => void
}

class StaffSearchForm extends React.Component<AllProps, null> {

    onFormSubmit = (formObject: { formData: StaffFilters }) => {
        let formData = formObject.formData
        this.props.saveStaffFilters(formData)
        if (this.props.searchCallBack) {
            this.props.searchCallBack()
        }
    }

    render() {
        if (this.props.loading) {
            return getLoadingComponent(true)
        }
        const formObject = getSearchStaffFormObject(this.props)
        return (
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={this.onFormSubmit}
                resources={formObject}
                title={"Ricerca"}
                buttonText="Cerca"
                showRequiredFieldsLegend
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        authorizedStructures: state.global.userInfo.structures != undefined ? state.global.userInfo.structures : state.global.navigationLinks._links.authorizedStructures.href,
        staffFilters: state.global.staffFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        saveStaffFilters: bindActionCreators(saveStaffFilters, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffSearchForm)