import { isProductionVersion } from '@insoft/lib-react-universal/dist/utils';
import { applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

let middleware = [thunk]

if (!isProductionVersion()) {
    const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default()
    middleware = [...middleware, reduxImmutableStateInvariant, logger]
} else {
    middleware = [...middleware]
}

export default function configureStore(initialState: any) {
    return createStore(
        enableBatching(rootReducer),
        initialState,
        applyMiddleware(...middleware)
    )
}