import { FORMAT_ISO8601, expandUri, formatDateObject } from "@insoft/lib-react-universal"
import { CollectionModel, executeRequest } from "@insoft/lib-react-web"
import { Navigation } from "../interfaces/navigation"
import { Operator, OrganizationData, Section } from "../interfaces/organization-data"

export const fetchSections = async (props: {
    date?: Date
}, navigation: Navigation
): Promise<Section[]> => {
    const uri = navigation._links?.sections?.href
    if (!uri) {
        throw new Error("No link found for sections fetch")
    }
    const expandedUri = expandUri(uri, {
        'date': props.date ? formatDateObject(props.date, FORMAT_ISO8601) : undefined
    });
    return executeRequest<CollectionModel<Section>>({ url: expandedUri }).then(resources => {
        return resources._embedded["sectionDatas"] as Section[];
    })
}

export const fetchEducators = async (props: {
    date?: Date,
    onlyName?: boolean
}, navigation: Navigation
): Promise<Operator[]> => {
    const uri = navigation._links?.educators?.href
    if (!uri) {
        throw new Error("No link found for educators fetch")
    }
    const expandedUri = expandUri(uri, {
        'date': props.date ? formatDateObject(props.date, FORMAT_ISO8601) : undefined,
        'onlyName': props.onlyName ? 'true' : undefined,
    });
    return executeRequest<CollectionModel<Operator>>({ url: expandedUri }).then(resources => {
        return resources._embedded["staffEntities"] as Operator[];
    })
}

export const fecthOrganizations = async (navigation: Navigation): Promise<OrganizationData[]> => {
    const uri = navigation._links?.listOrganizations?.href
    if (!uri) {
        throw new Error("No link found for listOrganizations fetch")
    }
    return executeRequest<CollectionModel<OrganizationData>>({ url: uri }).then(resources => {
        return resources._embedded["structureDatas"] as OrganizationData[];
    })
}