import { Enrollments } from 'commons/interfaces//enrollments';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import EnrollmentsComponent from 'commons/components/enrollments-component';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl: string
    excelUrl: string
    showChangeOrganization: boolean
}

interface AllProps extends ActionCreatorsProps, Props { }

class OrganizationFillingList extends React.Component<AllProps> {
    constructor(props: AllProps) {
        super(props)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.startLoading()
        executeRequest<Enrollments>({ url: this.props.requestUrl, method: "GET" })
            .then((data) => {
                this.props.stopLoading()
                this.setState({
                    resources: data
                })
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    render() {
        if (this.props.loading) {
            return getLoadingComponent(true)
        }
        if (!this.props.requestUrl) {
            return null
        }
        return (
            <EnrollmentsComponent
                requestUrl={this.props.requestUrl}
                excelUrl={this.props.excelUrl}
                printEmpty
                operator={true}
                showChangeOrganization={this.props.showChangeOrganization}
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationInFilling) {
        var requestUrl = state.global.navigationLinks._links.organizationInFilling.href
        var showChangeOrganization = state.global.navigationLinks._links.changeOrganization != undefined ? true : false
    }
    if (state.global.navigationLinks._links.inFillingExcelTable) {
        var excelUrl = state.global.navigationLinks._links.inFillingExcelTable.href
    }
    return {
        loading: state.global.isLoading,
        requestUrl,
        excelUrl,
        showChangeOrganization
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationFillingList)