import React, { useEffect, useState } from 'react';
import { GlobalStateInterface } from "redux/store";
import { addMessage, removeMessage, startLoading, stopLoading } from "@insoft/lib-react-universal/dist/redux/base-actions";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { HistoryFilters } from "redux/actions/global-actions";
import { MessageInterface } from "@insoft/lib-react-universal/dist/interfaces/message-interface";
import { expandUri, generateUUID, isValidURL, logThis, renderComponentsList } from "@insoft/lib-react-universal/dist/utils";
import { PARENT_APP_CONSTANTS, STRINGS } from "utils/constants";
import { PAGES } from "utils/rest-routes";
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { NewsItem } from "commons/interfaces/news";
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces/component-list-item";
import { createDangerousInnerHtml } from "@insoft/lib-react-web/dist/utils/utils";
import { DocumentsTable } from "commons/components/documents-table";
import { Document } from "commons/interfaces/documents";
import { getLoadingComponent, isTabSelectedNum } from "utils";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { AppBar, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Tab, Tabs, Typography } from "@mui/material";
import DiaryTab from "./diary-tab";
import SliderImages from "./slider";
import { Invoice } from "commons/interfaces/invoices";
import { InvoicesTable } from "commons/components/invoices-table";


interface ParamsObj {
    children: Child[]
}
interface Child {
    ana: number,
    firstName: string,
    lastName: string,
    image: {
        source?: string
    }
    fullDescr?: string
}
interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    addMessage: (message: MessageInterface) => any
    removeMessage: (message: MessageInterface) => any
}

export interface Props {
    loading?: boolean
    parentParamsUrl: string
    childDiaryUrl: string
    childrenImagesUrl: string
    sliderImagesUrl: string
    parentDocumentsUrl: string
    parentInvoicesUrl: string
    parentNewsUrl: string
    cstHasInvoiceUrl: string
    historyFilters: HistoryFilters
}


interface AllProps extends ActionCreatorsProps, Props {
    parentID: string
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ParentsAppSimulatorContainer(props: AllProps) {

    const [childSelected, setChildSelected] = useState<Child>(undefined)
    const [day, setDayState] = useState<Date>(moment().startOf('day').toDate())
    const [childs, setChilds] = useState<Child[]>([])
    const [news, setNews] = useState<NewsItem[]>(undefined)
    const [documents, setDocuments] = useState<Document[]>(undefined)
    const [invoices, setInvoices] = useState<Invoice[]>(undefined)
    const [hasInvoiceTab, setHasInvoiceTab] = useState<boolean>(false)
    const [tabSelected, setTabSelected] = useState<number>(PARENT_APP_CONSTANTS.DIARY_TAB)

    useEffect(() => {
        if (!props.parentParamsUrl || !props.childDiaryUrl || !props.childrenImagesUrl || !props.parentDocumentsUrl || !props.parentNewsUrl) {
            props.addMessage({ id: generateUUID(), message: STRINGS.ERROR_TOTEM_USER_NOT_AUTHORIZED, target: PAGES.appSimulator })
        }
        props.startLoading()
        executeRequest<ParamsObj>({ url: expandUri(props.parentParamsUrl, { userId: props.parentID }), method: "GET" })
            .then((data) => {
                data.children.forEach((child: Child) => {
                    child.fullDescr = child.firstName + ' ' + child.lastName
                })
                props.stopLoading()
                setChilds(data.children)
                setChildSelected(data.children && data.children.length > 0 ? data.children[0] : undefined)

            }).catch((data) => {
                logThis(data);
            });
        executeRequest<NewsItem[]>({ url: expandUri(props.parentNewsUrl, { userId: props.parentID }), method: "GET" })
            .then((data) => {
                setNews(data)

            }).catch((data) => {
                logThis(data);
            });
        executeRequest<Document[]>({ url: expandUri(props.parentDocumentsUrl, { userId: props.parentID }), method: "GET" })
            .then((data) => {
                setDocuments(data)
            }).catch((data) => {
                logThis(data);
            });
        executeRequest<Invoice[]>({ url: expandUri(props.parentInvoicesUrl, { userId: props.parentID }), method: "GET" })
            .then((data) => {
                setInvoices(data)
            }).catch((data) => {
                logThis(data);
            })
    }, [])



    const setChildId = (event: DropDownListChangeEvent) => {
        if (childSelected === event.target.value) {
            return
        }
        setChildSelected(event.target.value)
    }

    const setDay = (event: DatePickerChangeEvent) => {
        let newDay: Date = event.target.value
        if (newDay.getTime() === day.getTime()) {
            return
        }
        setDayState(event.target.value)
    }

    const renderNews = (item: ComponentListItem & NewsItem) => {
        if (item.link && item.link.url && isValidURL(item.link.url) && item.link.text) {
            var linkButton = (
                <CardActions>
                    <Link href={item.link.url}>
                        <Button size="small" color="primary">
                            {item.link.text}
                        </Button>
                    </Link>
                </CardActions>
            )
        }
        if (item.image && item.image.source) {
            var imgView = (<CardMedia
                component="img"
                height="140"
                image={item.image.source}
            />
            )
        }
        return (
            <Grid item lg={3} md={6} sm={12}>
                <Card key={generateUUID()}>
                    <CardActionArea>
                        {imgView}
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {item.title}
                            </Typography>
                            <Box dangerouslySetInnerHTML={createDangerousInnerHtml(item.description)} />
                        </CardContent>
                    </CardActionArea>
                    {linkButton}
                </Card>
            </Grid>
        )
    }

    const renderNewsSection = () => {
        if (news && news.length > 0) {
            let newsContent = renderComponentsList(news, renderNews)
            return (
                <Grid container spacing={3}>
                    {newsContent}
                </Grid>
            )
        } else if (childSelected) {
            return renderNoData();
        }
    }

    const renderDocumentsSection = () => {
        if (documents && documents.length > 0) {
            return (
                <DocumentsTable documents={documents} />
            )
        } else if (childSelected) {
            return renderNoData();
        }
    }

    const renderInvoicesSection = () => {
        if (invoices && invoices.length > 0) {
            return (
                <InvoicesTable invoices={invoices} />
            )
        } else if (childSelected) {
            return renderNoData();
        }
    }

    const renderNoData = () => {
        return (
            <Grid container spacing={3}>
                <Grid item lg={12}><Typography gutterBottom component="h2" align="center">Nessuna dato da visualizzare</Typography></Grid>
            </Grid>

        )
    }

    const onTabSelected = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabSelected(newValue)
    }

    //TODO aggiungere reogla css in material per first-of-type con margin: 0
    const renderTab = (tabId: number, label: string) => {
        return (
            <Tab label={label} {...a11yProps(tabId)} />
        )
    }

    const hasInvoices = () => {
        executeRequest<boolean>({ url: props.cstHasInvoiceUrl, method: 'GET' })
            .then((data) => {
                setHasInvoiceTab(data);
            }).catch((data) => {
                logThis(data);
            });
    }

    const renderTabMenu = () => {
        const selectedTab = tabSelected
        hasInvoices()
        let diaryTab = renderTab(PARENT_APP_CONSTANTS.DIARY_TAB, 'DIARIO')
        let mediaTab = renderTab(PARENT_APP_CONSTANTS.MEDIA_TAB, 'MEDIA')
        let newsTab = renderTab(PARENT_APP_CONSTANTS.NEWS_TAB, 'NEWS')

        let documentsTab = renderTab(PARENT_APP_CONSTANTS.DOCUMENTS_TAB, 'DOCUMENTI')
        let invoicesTab = hasInvoiceTab ? renderTab(PARENT_APP_CONSTANTS.INVOICES_TAB, 'FATTURE') : undefined
        return (
            <AppBar position="static" color="default">
                <Tabs
                    value={selectedTab}
                    onChange={onTabSelected}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {diaryTab}
                    {mediaTab}
                    {newsTab}
                    {documentsTab}
                    {invoicesTab}
                </Tabs>
            </AppBar>
        )
    }

    const renderChildrenAndDateChoice = () => {
        return (
            <Grid container spacing={3} key={"child-choice#" + props.parentID}>
                <Grid item xs={12} sm={6} lg={6} className="form-group field field-string">
                    <label>Bambino</label>
                    <DropDownList data={childs} dataItemKey="ana" textField="fullDescr" className="k-widget k-dropdown k-header form-control" onChange={setChildId} value={childSelected} />
                </Grid>
                <Grid item xs={12} sm={6} lg={6} className="form-group field field-string">
                    <label>Giorno</label>
                    <DatePicker value={day} max={moment().startOf('day').toDate()} format={"dd-MM-yyyy"} onChange={setDay} className="k-widget k-datepicker k-header form-control" />
                </Grid>
            </Grid>

        )
    }

    const renderChildrenChoice = () => {
        return (
            <Grid container spacing={3} key={"child-choice#" + props.parentID}>
                <Grid item xs={12} sm={6} lg={6} className="form-group field field-string">
                    <label>Bambino</label>
                    <DropDownList data={childs} dataItemKey="ana" textField="fullDescr" className="k-widget k-dropdown k-header form-control" onChange={setChildId} value={childSelected} />
                </Grid>
            </Grid>

        )
    }




    let tabMenu = renderTabMenu()


    if (props.loading) {
        var loading = getLoadingComponent(false)
    } else if (childSelected && isTabSelectedNum(PARENT_APP_CONSTANTS.DIARY_TAB, tabSelected)) {
        var diarytab = (<DiaryTab childId={childSelected.ana} day={day} renderNoData={renderNoData} childrenAndDateChoice={renderChildrenAndDateChoice()} childDiaryUrl={props.childDiaryUrl} addMessage={props.addMessage} removeMessage={props.removeMessage} />)
    } else if (childSelected && isTabSelectedNum(PARENT_APP_CONSTANTS.MEDIA_TAB, tabSelected)) {
        var mediaTab = (<SliderImages childId={childSelected.ana} renderNoData={renderNoData} childrenChoice={renderChildrenChoice()} childrenImagesUrl={props.sliderImagesUrl} addMessage={props.addMessage} removeMessage={props.removeMessage} />)
    } else if (isTabSelectedNum(PARENT_APP_CONSTANTS.NEWS_TAB, tabSelected)) {
        var newstab = renderNewsSection()
    } else if (isTabSelectedNum(PARENT_APP_CONSTANTS.DOCUMENTS_TAB, tabSelected)) {
        var documentsTab = renderDocumentsSection()
    } else if (isTabSelectedNum(PARENT_APP_CONSTANTS.INVOICES_TAB, tabSelected)) {
        var invoicesTab = renderInvoicesSection()
    }

    return (
        <div>
            {tabMenu}
            <TabPanel value={tabSelected} index={PARENT_APP_CONSTANTS.DIARY_TAB}>
                {loading}
                {diarytab}
            </TabPanel>
            <TabPanel value={tabSelected} index={PARENT_APP_CONSTANTS.MEDIA_TAB}>
                {loading}
                {mediaTab}
            </TabPanel>
            <TabPanel value={tabSelected} index={PARENT_APP_CONSTANTS.NEWS_TAB}>
                {loading}
                {newstab}
            </TabPanel>
            <TabPanel value={tabSelected} index={PARENT_APP_CONSTANTS.DOCUMENTS_TAB}>
                {loading}
                {documentsTab}
            </TabPanel>
            <TabPanel value={tabSelected} index={PARENT_APP_CONSTANTS.INVOICES_TAB}>
                {loading}
                {invoicesTab}
            </TabPanel>
        </div>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        parentParamsUrl: state.global.navigationLinks._links.parentParams.href,
        childDiaryUrl: state.global.navigationLinks._links.parentDiary.href,
        childrenImagesUrl: state.global.navigationLinks._links.parentImages.href,
        sliderImagesUrl: state.global.navigationLinks._links.sliderImages.href,
        parentDocumentsUrl: state.global.navigationLinks._links.parentDocuments.href,
        parentNewsUrl: state.global.navigationLinks._links.parentNews.href,
        parentInvoicesUrl: state.global.navigationLinks._links.parentInvoices.href,
        cstHasInvoiceUrl: state.global.navigationLinks._links.cstHasInvoice.href,
        historyFilters: state.global.historyFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        addMessage: bindActionCreators(addMessage, dispatch),
        removeMessage: bindActionCreators(removeMessage, dispatch),
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentsAppSimulatorContainer)