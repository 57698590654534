import { Navigation } from 'commons/interfaces/navigation';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GlobalStateInterface } from 'redux/store';
import { getTitleViewForPage, getFunctionName } from 'utils';
import { PAGES } from 'utils/rest-routes';
import FormWithYearSelection from './index';
import { REGISTRATION_TYPE } from 'utils/constants';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { Alert } from '@mui/material';
import { savePageHeaderInfo } from '../../redux/actions/global-actions';

interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
}

function DataChange(props: Props) {
    const { id, yearId } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId ? PAGES.templateCambioDati : PAGES.cambioDati, structureInfo: props.structureInfo, id: yearId ? yearId : id });
        dispatch(savePageHeaderInfo(pageInfo))
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    const component = (props.structureInfo.helpSectionsDuringFilling ? (
        <Alert severity="warning" variant="filled" icon={false}>
            <p dangerouslySetInnerHTML={{ __html: "<h4>Come procedere</h4><p>Compilare la seguente pagina con i dati richiesti. Una volta terminato cliccare il pulsante <i>\"Avanti\"</i> a fondo pagina.</p><p>Se si lascia o si ricarica la pagina le modifiche verranno perse.</p>" }}></p>
        </Alert>
    ) : null)
    return (
        <FormWithYearSelection
            id={id}
            yearId={yearId}
            nextPage={yearId == null ? PAGES.cambioDatiDichiarazioni : PAGES.templateCambioDatiDichiarazioni}
            defaultFormTitle={getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, props.structureInfo)}
            yearSelectionUri={props.navigationLinks._links.newDataChangeForm ? props.navigationLinks._links.newDataChangeForm.href : undefined}
            resourceUri={yearId == null ? props.navigationLinks._links.dataChangeForm.href : props.navigationLinks._links.dataChangeTemplate.href}
            changeDataType={true}
            helpSection={component}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(DataChange)