import { StructureInfo } from 'commons/interfaces/structure-info';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { FooterData, Icon, Partner } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import React from 'react';

interface Props {
    structureInfo: StructureInfo
}
export default class Footer extends React.Component<Props, null> {




    renderValue(value: string, label: string) {
        if (value) {
            return (
                <span>{label}{value}</span>
            )
        }
        return undefined;
    }

    renderLogo(logo: Icon) {
        if (!logo || !logo.imgPath)
            return null
        if (logo && logo.link) {
            return (
                <a className='separate-to-text' href={logo.link} target='_blank'>
                    <img className='separate-to-text' src={logo.imgPath} width='70' />
                </a>
            )
        } else if (logo) {
            return (
                <img className='separate-to-text' src={logo.imgPath} width='70' />
            )
        }
        return undefined
    }

    renderFooterData = (footerData: FooterData, key?: any) => {
        if (footerData) {
            let name = footerData.name
            if (footerData.contacts) {
                var address = this.renderValue(footerData.contacts.address, ' - ')
                var phone = this.renderValue(footerData.contacts.phone, ' - Tel. ')
                var mail = this.renderValue(footerData.contacts.mail, ' - Email ')

            }
            let logo = this.renderLogo(footerData.logo)
            return (
                <div className='text-center' key={key}>
                    <span>{name}</span>{address}{phone}{mail}{logo}
                </div>
            )
        }
    }

    renderPartner = (partner: Partner & ComponentListItem) => {
        if (partner && partner.showDataOnFooter && partner.name) {
            return this.renderFooterData(partner, partner._key)
        }
        return undefined
    }

    renderPartners() {
        if (this.props.structureInfo && this.props.structureInfo.partners) {
            let partners = renderComponentsList(this.props.structureInfo.partners, this.renderPartner)
            return (
                <div>
                    {partners}
                </div>
            )
        }
        return undefined
    }


    renderStructurePart() {
        if (this.props.structureInfo) {
            let structureFooter = this.renderFooterData(this.props.structureInfo)
            let partnersFooter = this.renderPartners()
            return (
                <div>
                    {structureFooter}
                    {partnersFooter}
                </div>
            )
        }
        return undefined;
    }


    render() {
        let structurePart = this.renderStructurePart()
        let year = new Date().getFullYear();
        return (
            <footer className='main-footer'>
                <div className="row footer-row">
                    {structurePart}
                </div>

                <div className="row footer-row">
                    <div className='text-center hidden-xs'>
                        <span>&copy; {year} </span>
                        <strong>Created by </strong>
                        <a className='separate-to-text' href='https://www.insoft.it' target='_blank'>
                            <img className='separate-to-text' src='https://www.insoft.it/wp-content/themes/health-center-lite/images/logo.png' width='70' />
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
}