import { Box, Button, Grid, GridSize, Snackbar, SnackbarOrigin, Alert, AlertTitle } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import DraftsIcon from '@mui/icons-material/Drafts';
import EmailIcon from '@mui/icons-material/Email';
import GetAppIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';
import SummaryAdapter from 'commons/adapters/summary-adapter';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { executeRequest, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { MISC } from '@insoft/lib-react-web/dist/utils/constants';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { savePageHeaderInfo, startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import STYLE from 'src/commons/styles/style';
import { getFormHeaderComponent, getFunctionName, getLoadingComponent, getTitleViewForPage, parsePositiveResponse, parseServerErrorResponse } from 'utils';
import { ENROLLMENT_STATUS, REGISTRATION_TYPE } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import SummaryComponent from './summary-component';
import { useParams } from 'react-router-dom';
import { Summary } from 'commons/interfaces/summary';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { composeUriPath, refreshPage, updateFormDataState } from '@insoft/lib-react-web';
import { ModalResponse } from 'commons/interfaces/utils';
import { Link } from '@insoft/lib-react-web/dist/interfaces/hateoas';
import { ComponentListItem, expandUri, generateUUID, isObjectEmpty, logThis, renderComponentsList } from '@insoft/lib-react-universal/dist';


interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    navigationLinks?: Navigation
    structureInfo: StructureInfo
}

interface AllProps extends ActionCreatorsProps, Props { }

function SummaryPage(props: AllProps) {

    let { id } = useParams();
    let { pathname } = useLocation()

    const [resources, setResources] = useState<Summary>(null)
    const [serverResponse, setServerResponse] = useState<AlertObject>(null)
    const [modalObject, setModalObject] = useState<ModalResponse>(undefined)

    const page = id ? pathname.replace('/' + id, '') : pathname

    const dispatch = useDispatch()

    const classesButton = ButtonsStyles();

    const getPageInformation = () => {
        switch (page) {
            case PAGES.nuovaIscrizioneRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.ENROLLMENT,
                    requestUri: props.navigationLinks._links.enrollmentSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, props.structureInfo),
                    modifyTargetPage: PAGES.nuovaIscrizioneFrequenza
                }
            case PAGES.cambioDatiRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.DATA_CHANGE,
                    requestUri: props.navigationLinks._links.dataChangeSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, props.structureInfo),
                    modifyTargetPage: PAGES.cambioDati
                }
            case PAGES.modificaIscrizioneRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.MODIFICA,
                    requestUri: props.navigationLinks._links.frequencyChangeSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.MODIFICA, props.structureInfo),
                    modifyTargetPage: PAGES.modificaIscrizione
                }
            case PAGES.serviziAggiuntiviRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI,
                    requestUri: props.navigationLinks._links.additionalServiceSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, props.structureInfo),
                    modifyTargetPage: PAGES.serviziAggiuntivi
                }
            case PAGES.modificaInizioFineFrequenzaRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.ENROLLMENT_CHANGE,
                    requestUri: props.navigationLinks._links.enrollmentChangeSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, props.structureInfo),
                    modifyTargetPage: PAGES.modificaInizioFineFrequenza
                }
            case PAGES.cambioIseeRiepilogo:
                return {
                    summaryType: REGISTRATION_TYPE.ISEE_CHANGE,
                    requestUri: props.navigationLinks._links.changeIseeSummary.href,
                    pageTitle: (resources && resources.title) || getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, props.structureInfo),
                    modifyTargetPage: PAGES.cambioIsee
                }
        }
    }

    useEffect(() => {
        props.startLoading()
        const expandedUri = expandUri(getPageInformation().requestUri, { id: id })
        executeRequest<Summary>({ url: expandedUri, Adapter: SummaryAdapter })
            .then((data) => {
                setResources(data)
                const isInFilling = isInFillingStatus(data)

                dispatch(savePageHeaderInfo(getTitleViewForPage({ page: page, structureInfo: props.structureInfo, id: isInFilling ? id : null })))
                props.stopLoading()
                return null
            }).catch((data) => {
                logThis(data)
            })
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    const isInFillingStatus = (resources: Summary) => {
        return resources?.status === ENROLLMENT_STATUS.FILLING
    }

    const renderButton = (p: { callback: any, content: any }) => {
        let { callback, content } = p
        return (
            <Box display="flex" flexGrow={1} justifyContent="flex-end" p={1}>
                <Button color="primary" onClick={callback} variant="contained">
                    {content}
                </Button>
            </Box>
        )
    }

    const renderModifyButton = (): JSX.Element => {
        if (isInFillingStatus(resources)) {
            const targetPage = getPageInformation().modifyTargetPage
            if (targetPage) {
                const composedTargetPage = composeUriPath(targetPage, [id])
                return (
                    <Box p={1}>
                        <NavLink to={composedTargetPage} >
                            <Button className={classesButton.warningStyle} type="button" variant="contained" startIcon={<CreateIcon />}>Modifica la domanda</Button>
                        </NavLink>
                    </Box>
                )
            }
        }
    }

    const formSummarySubmit = (formObject: { formData: object }, i: number) => {
        let index = i;
        let formData = formObject.formData
        let summary = resources;
        let forms = summary.forms
        let actionToUpdate = forms[index]
        forms[index] = updateFormDataState(actionToUpdate, formData)
        summary.forms = forms
        setResources(summary)
        props.startLoading()
        sendForm({
            method: resources.forms[index].method, url: resources.forms[index].url, data: formData, retryPolicy: {
                retries: 1, // massimo 2 chiamate
                factor: 2,
                maxTimeout: 3000
            }
        }).then((message) => {
            props.stopLoading()
            refreshPage()
            return null
        }).catch((data) => {
            props.stopLoading()
            setServerResponse(parseServerErrorResponse(data))
        })
    }

    const renderForm = (form: FormObject & ComponentListItem, gridSize: GridSize) => {
        if (!isObjectEmpty(form.data.form.formSchema)) {
            return (
                <Grid item md={gridSize} key={form._key}>
                    <FormPage
                        formStyleRules={commonFormStyleRules}
                        fields={getCommonFields()}
                        widgets={getCommonWidgets()}
                        buttonText={form.data.form.submitButtonLabel}
                        onFormSubmit={(e) => { formSummarySubmit(e, form._itemIndex) }}
                        resources={form}
                        title={form.data.form.title}
                        boxStyle="grey400"
                        versionManagement={true}
                    />
                </Grid>
            )
        } else {
            const button = renderButton({ callback: (e: any) => formSummarySubmit(e, form._itemIndex), content: form.data.form.submitButtonLabel })
            return (
                <Grid item md={gridSize} key={form._key}>
                    <FormPage
                        formStyleRules={commonFormStyleRules}
                        fields={getCommonFields()}
                        widgets={getCommonWidgets()}
                        buttonText={form.data.form.submitButtonLabel}
                        onFormSubmit={(e) => { formSummarySubmit(e, form._itemIndex) }}
                        title={form.data.form.title}
                        bodyContent={button}
                        boxStyle="grey200"
                        versionManagement={true}
                    />
                </Grid>
            )
        }
    }

    const renderFormsFooter = (forms: FormObject[]) => {
        const className = forms.length > 2 ? 4 : 6
        const renderedForms = renderComponentsList(forms, (item: any) => { return renderForm(item, className) })
        return (
            <Box p={3}>
                <Grid container spacing={3}>
                    {renderedForms}
                </Grid>
            </Box>
        )
    }



    const renderSingleFormButton = (form: FormObject) => {
        // form con button e basta
        const button = renderButton({ callback: ((e: any) => formSummarySubmit(e, 0)), content: form.data.form.submitButtonLabel })
        if (isInFillingStatus(resources)) {
            const modifyButton = renderModifyButton()
            return (
                <Box display="flex">
                    {modifyButton}
                    {button}
                </Box>
            )
        } else {
            return button
        }
    }

    const renderSingleOldLetter = (link: Link & ComponentListItem) => {
        return (
            <li key={link._key}><a href={link.href} target={MISC.A_TARGET_NEW_PAGE} >lettera {link._itemIndex + 1}</a></li>
        )
    }

    const renderOtherLetters = (links: Link[]) => {
        var attachments = renderComponentsList(links, renderSingleOldLetter)
        return (
            <div>
                <h3>Precedenti lettere di conferma della domanda: </h3>
                <ul>
                    {attachments}
                </ul>
            </div>
        )
    }

    const renderFooterArea = () => {
        if (resources && resources._links && resources._links.old_confirm_letters) {
            var otherLetters = renderOtherLetters(resources._links.old_confirm_letters);
        }
        if (resources && resources.forms && (resources.forms.length === 1 && !resources.forms[0].data.form.formSchema)) {
            var footerArea = renderSingleFormButton(resources.forms[0])
        } else if (resources && resources.forms) {
            footerArea = renderFormsFooter(resources.forms)
        }
        return (
            <div>
                {otherLetters}
                {footerArea}
            </div>
        )
    }

    const getSubtitleView = () => {
        let summaryLabel = 'Riepilogo'
        if (resources && resources.paymentStatus) {
            let paymentStatus = resources.paymentStatus
            let paymentStatusLabel = STYLE.paymentStatus[paymentStatus] ? STYLE.paymentStatus[paymentStatus].label : 'N.D.'
            let paymentStatusClassName = STYLE.paymentStatus[paymentStatus] ? STYLE.paymentStatus[paymentStatus].class : 'N.D.'
            var paymentStatusView = (
                <span className={paymentStatusClassName}>{paymentStatusLabel}</span>
            )
        }
        if (resources && resources.status) {
            let status = resources.status
            if (status === ENROLLMENT_STATUS.FILLING) {
                var fillingLabel = (
                    <span><b> dati da inviare </b></span>
                )
            }
            let statusLabel = STYLE.tags[status] ? STYLE.tags[status].label : 'N.D.'
            let statusClassName = STYLE.tags[status] ? STYLE.tags[status].class : 'N.D.'
            var statusView = (
                <span className={statusClassName}>{statusLabel}</span>
            )
        }
        return (
            <span>{summaryLabel} {fillingLabel} {statusView} {paymentStatusView}</span>
        )
    }

    const canProtocol = () => {
        if (resources && resources._links.protocol) {
            return true
        }
    }

    const canDownloadLetter = () => {
        if (resources && resources._links.confirmLetter) {
            return true
        }
    }

    const renderDownloadButton = () => {
        if (resources && resources._links.download) {
            const refreshPage = () => {
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }
            return (
                <a href={resources._links.download.href} target={MISC.A_TARGET_NEW_PAGE} key={generateUUID()}>
                    <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' startIcon={<PrintIcon />} onClick={refreshPage}>
                        Genera lettera conferma
                    </Button>
                </a>
            )
        }
    }

    const renderDownloadButtonDocx = () => {
        if (resources && resources._links.download_docx) {
            return (
                <a href={resources._links.download_docx.href} target={MISC.A_TARGET_NEW_PAGE} key={generateUUID()}>
                    <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' startIcon={<GetAppIcon />}>
                        Scarica lettera conferma
                    </Button>
                </a>
            )
        }
    }

    const renderComfirmLetterButton = () => {
        if (canDownloadLetter()) {
            return (
                <a href={resources._links.confirmLetter.href} target={MISC.A_TARGET_NEW_PAGE} key={generateUUID()}>
                    <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' startIcon={<DraftsIcon />}>
                        Visualizza lettera conferma
                    </Button>
                </a>
            )
        }
    }

    const renderLetterButton = () => {
        if (resources && resources._links.letter) {
            return (
                <a href={resources._links.letter.href} target={MISC.A_TARGET_NEW_PAGE} key={generateUUID()}>
                    <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' startIcon={<PrintIcon />}>
                        Stampa lettera
                    </Button>
                </a>
            )
        }
    }

    const renderProtocolButton = () => {
        if (canProtocol()) {
            return (
                <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' onClick={() => { executeSubmit(resources._links.protocol.href) }} key={generateUUID()} startIcon={<EmailIcon />}>
                    Protocolla Domanda
                </Button>
            )
        }
    }

    const executeSubmit = (url: string) => {
        props.startLoading()
        executeRequest({ url: url, method: 'POST' })
            .then(() => {
                let opt = {
                    autoclose: false,
                    closable: true
                }
                refreshPage()
                props.stopLoading()
                setServerResponse(parsePositiveResponse(opt))
            })
            .catch((error) => {
                props.stopLoading()
                setServerResponse(parseServerErrorResponse(error))
            })
    }

    const executeSubmit2 = (url: string) => {
        props.startLoading();
        executeRequest({ url: url, method: 'POST' })
            .then(() => {
                props.stopLoading();
                let modalResponse: ModalResponse = {
                    status: 'OK',
                    message: 'Operazione andata a buon fine. La pagina si ricaricherà automaticamente.'
                }
                setModalObject(modalResponse)
            })
            .catch((error) => {
                props.stopLoading()

                let modalResponse: ModalResponse = {
                    status: 'ERROR',
                    message: 'C\'è stato un errore, si consiglia di riprovare.'
                }
                setModalObject(modalResponse)
            })
    }

    const renderSendToOrganizationButton = () => {
        if (resources && resources._links.send_to_organization) {
            return (
                <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' onClick={() => { executeSubmit2(resources._links.send_to_organization.href) }} key={generateUUID()} startIcon={<EmailIcon />}>
                    Notifica il comune
                </Button>
            )
        }
    }

    const renderTODO = () => {
        if (resources && resources._links.finish_internal) {
            return (
                <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" type='button' onClick={() => { executeSubmit2(resources._links.finish_internal.href) }} key={generateUUID()} startIcon={<CheckIcon />}>
                    Processo completato
                </Button>
            )
        }
    }

    const closeModal = () => {
        setModalObject(undefined)
        refreshPage()
    }

    const renderModal = (result: "OK" | "ERROR") => {
        const severty = result === "OK" ? "success" : "error"
        const autoHideDuration = result === "OK" ? 6000 : null
        const anchor: SnackbarOrigin = {
            horizontal: 'center',
            vertical: 'top'
        }
        return (
            <Snackbar open={modalObject != undefined} autoHideDuration={autoHideDuration} onClose={closeModal} anchorOrigin={anchor} >
                <Alert onClose={closeModal} severity={severty} variant="filled" elevation={6}>
                    {modalObject.message}
                </Alert>
            </Snackbar>

        )
    }

    const renderHelpSection = () => {
        if (isInFillingStatus(resources) && props.structureInfo.helpSectionsDuringFilling) {
            const message: string = "<p>Procedendo con l'invio non sarà più possibile modificare i dati.</p><p>Una volta inviata la domanda riceverai una copia della domanda e della comunicazione di avvio procedimento nella tua casella e-mail e la stessa domanda sarà automaticamente protocollata.</p>"
            return (
                <Box p={2}>
                    <Alert severity="warning" variant="filled" icon={false}>
                        <AlertTitle dangerouslySetInnerHTML={{ __html: "<h4>Invio domanda</h4>" }} />
                        <p dangerouslySetInnerHTML={{ __html: message }}></p>
                    </Alert>
                </Box>
            )
        }
    }

    if (props.loading) {
        return getLoadingComponent(true)
    }
    const headerComponent = getFormHeaderComponent()
    const { pageTitle } = getPageInformation()
    const footerArea = renderFooterArea()

    const otherButtons: JSX.Element[] = [];
    otherButtons.push(renderLetterButton());
    otherButtons.push(renderDownloadButton());
    otherButtons.push(renderDownloadButtonDocx());
    otherButtons.push(renderProtocolButton());
    otherButtons.push(renderSendToOrganizationButton())
    otherButtons.push(renderComfirmLetterButton());
    otherButtons.push(renderTODO());

    const helpSection = renderHelpSection();

    if (modalObject) {
        var modal = renderModal(modalObject.status);
    }

    return (
        <Box>
            {modal}
            <SummaryComponent
                footerArea={footerArea}
                headerComponent={headerComponent}
                resources={resources}
                serverResponse={serverResponse}
                subtitleView={getSubtitleView()}
                otherButtons={otherButtons}
                title={pageTitle}
                showPrintButton={true}
                helpSection={helpSection}
            />
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage)