import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React, { useEffect, useState } from 'react';

interface Props {
    formData: string
    schema: {
        enumNames?: string[], enum?: string[], imgs?: string[], title: string, oneOf?: {
            title: string,
            const: string,
            img: string
        }[], anyOf?: {
            title: string,
            const: string,
            img: string
        }[]
    }
    onChange: (value: string) => any
    required: boolean
}

interface State {
    _selectedItemValue: string
}

export const ICON_RADIO_FIELD_NAME = 'custom-icon-radio-field'

export default function IconRadioField(props: Props) {
    const [_selectedItemValue, setSelectedItemValue] = useState<string>(props.formData || undefined)

    useEffect(() => {
        props.onChange(_selectedItemValue)
    }, [_selectedItemValue])

    const onChange = (event: { target: { value: string } }) => {
        let value = event.target.value
        setSelectedItemValue(value)
    }

    const renderIcon = (img: string) => {
        if (img) {
            return (<img src={img} width='25' />)
        }
    }

    const renderSingleRadio = (item: { label: string, value: string, img: string, _key: string }) => {
        let { label, value, img } = item

        let image = renderIcon(img);

        return (
            <div key={item._key}>
                <label>
                    <input
                        checked={value === _selectedItemValue}
                        onChange={onChange}
                        type='radio'
                        value={value}
                    />
                    <span className='separate-to-text'>{label}</span>
                    {image}
                </label>
            </div>
        )
    }
    let schema = props.schema
    let enumNames = schema.enumNames
    let enums = schema.enum
    let images = schema.imgs
    let objectsArray = []

    if (schema.oneOf || schema.anyOf) {
        objectsArray = (schema.oneOf || schema.anyOf).map(oneOfItem => {
            return {
                label: oneOfItem.title,
                value: oneOfItem.const,
                img: oneOfItem.img
            }
        });
    } else {

        for (var i = 0; i < enumNames.length; i++) {
            let image = images && images.length > i ? images[i] : undefined
            objectsArray.push({ label: enumNames[i], value: enums[i], img: image })
        }
    }

    let itemsFirstColumn = renderComponentsList(objectsArray.splice(0, Math.ceil(objectsArray.length / 2)), (item: any) => {
        return renderSingleRadio(item)
    })
    let itemsSecondColumn = renderComponentsList(objectsArray, (item: any) => {
        return renderSingleRadio(item)
    })
    let title = schema.title
        ? schema.title
        : ''
    let required = props.required
    return (
        <div>
            <label>{title}{required
                ? '*'
                : null}</label>
            <div className='radio'>
                <div className='col-xs-12 col-sm-6'>
                    {itemsFirstColumn}
                </div>
                <div className='col-xs-12 col-sm-6'>
                    {itemsSecondColumn}
                </div>
            </div>
        </div>
    )
}