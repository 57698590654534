import { itIT } from '@mui/material/locale';
import { createTheme } from '@mui/material';

const NovaePurple = "#605ca8"
const NovaePurpleDark = "#303F9F"
const NovaePurpleContrast = "#FFFFFF"
const NovaePurpleLight = "#7986CB"


export const NOVAE_PALETTE = createTheme({
    palette: {
        primary: {
            main: `${NovaePurple}`,
            dark: `${NovaePurpleDark}`,
            light: `${NovaePurpleLight}`,
            contrastText: `${NovaePurpleContrast}`
        },
        secondary: {
            main: "#222d32",
            dark: "#171f23",
            light: "#4e575b",
            contrastText: "#FFFFFF"
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '1rem'
                },
                sizeSmall: {
                    padding: '6px 12px 6px 8px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    '&:hover': {
                        color: `${NovaePurpleContrast}`,
                        textDecoration: "none"
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    colorPrimary: {
                        '&:hover': {
                            color: `${NovaePurpleDark}`
                        },
                        '&:active': {
                            color: `${NovaePurpleDark}`
                        }
                    }
                },
                h1: {
                    fontSize: '4rem',
                    '@media (min-width:800px)': {
                        fontSize: '5.5rem',
                    },
                    '@media (min-width:1000px)': {
                        fontSize: '7.5rem',
                    },
                },
                h2: {
                    fontSize: '2.5rem',
                    '@media (min-width:800px)': {
                        fontSize: '4.5rem',
                    },
                    '@media (min-width:1000px)': {
                        fontSize: '5.5rem',
                    },
                },
            }
        },
        MuiSelect: {
            // defaultProps: {
            //     fullWidth: true
            // }
        },
        MuiInput: {
            defaultProps: {
                fullWidth: true,
                size: 'small'
            }
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: 'outlined',
                size: 'small'
            }
        }
    }
}, itIT)