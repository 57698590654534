import { FORMAT_ISO8601, expandUri, formatDateObject, getDateFromString } from "@insoft/lib-react-universal"
import { PagedModel, executeRequest } from "@insoft/lib-react-web"
import { Child, ChildResponse, ChildrenSearch } from "../interfaces/entities"
import { Navigation } from "../interfaces/navigation"



export const fetchChildren = async (props: ChildrenSearch, navigation: Navigation): Promise<Child[]> => {
    const uri = navigation._links?.enrollments?.href
    if (!uri) {
        throw new Error("No link found for enrollments fetch")
    }
    const expandedUri = expandUri(uri, {
        'year': props?.year?.toString(),
        'from': props.from ? formatDateObject(props.from, FORMAT_ISO8601) : undefined,
        'to': props.to ? formatDateObject(props.to, FORMAT_ISO8601) : undefined,
        'unpaged': props.fetchAll ? 'true' : undefined,
        'fetchChildData': props.decodeChildData ? 'true' : undefined,
        'fetchAllYear':props.fetchEntireYear ? 'true' : undefined
    });
    return executeRequest<PagedModel<ChildResponse>>({ url: expandedUri }).then(resources => {
        const entities: ChildResponse[] = resources._embedded["childEntityDatas"] as ChildResponse[];
        const result = entities.map(response => {
            const item: Child = {
                ...response,
                birthDate: getDateFromString(response.birthDate, FORMAT_ISO8601),
                from: getDateFromString(response.from, FORMAT_ISO8601),
                to: getDateFromString(response.to, FORMAT_ISO8601),
            }
            return item
        })
        return result;
    })

}