import { Enrollments } from 'commons/interfaces//enrollments';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import EnrollmentsComponent from 'commons/components/enrollments-component';

interface Props {
    requestUrl: string
    excelUrl: string
}

class OrganizationRequestsPreaccepted extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (this.props.requestUrl) {
            executeRequest<Enrollments>({ url: this.props.requestUrl, method: 'GET' })
                .then((data) => {
                    this.setState({
                        resources: data
                    })
                    return null
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    render() {
        if (!this.props.requestUrl) {
            return null
        }
        let title = 'Richieste pre-accettate dal Nido'
        return (
            <EnrollmentsComponent
                requestUrl={this.props.requestUrl}
                excelUrl={this.props.excelUrl}
                title={title}
                operator={true}
                emptyNotShow={true}
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationPreAcceptedRequests) {
        var requestUrl = state.global.navigationLinks._links.organizationPreAcceptedRequests.href
    }
    if (state.global.navigationLinks._links.preAcceptedExcelTable) {
        var excelUrl = state.global.navigationLinks._links.preAcceptedExcelTable.href
    }
    return {
        requestUrl,
        excelUrl
    }
}

export default connect(mapStateToProps)(OrganizationRequestsPreaccepted)