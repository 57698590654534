import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SvgIcon from '@mui/material/SvgIcon';
import Description from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material//Delete';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryIcon from '@mui/icons-material/History';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Receipt from '@mui/icons-material/Receipt';
import ViewListIcon from '@mui/icons-material/ViewList';
import clsx from 'clsx';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { NOVAE_PALETTE } from 'commons/styles/material-ui-style';
import { generateUUID, logThis } from '@insoft/lib-react-universal/dist/utils';
import { NavStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { NavLink, useLocation } from 'react-router-dom';
import { getFunctionName } from 'utils';
import { REGISTRATION_TYPE } from 'utils/constants';
import { MODULE_ROOTS, MULTI_STRUCTURE_MODULE_PAGES, PAGES } from 'utils/rest-routes';


export interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
    open: Boolean
}


export default function LinksArea(props: Props) {
    // TODO usare quello del package material
    const classes = NavStyles();
    const location = useLocation()

    const renderItem = (item: { active: boolean, href: string, title: string, leftIcon?: typeof SvgIcon }) => {
        let { active, href, title, leftIcon } = item

        if (leftIcon) {
            const Icon = leftIcon
            var icon = (<ListItemIcon>
                <Icon htmlColor={NOVAE_PALETTE.palette.secondary.contrastText}></Icon>
            </ListItemIcon>)
        }
        return (
            <ListItem className={clsx(active ? classes.ListItemActive : '', classes.ListItem)} key={generateUUID()} disableGutters={true} >
                <NavLink to={href} className={classes.ItemLabelText} title={title}>
                    <Box display="flex" p={1}>
                        <Box alignItems="center" p={0.4}>
                            {icon}
                        </Box>
                        <Box>
                            <ListItemText primary={title} />
                        </Box>
                    </Box>
                </NavLink>
            </ListItem>

        )
    }

    const isHomeLinkActive = (arrayOfLinks: string[]) => {
        let isActive = false
        try {
            arrayOfLinks.forEach((item) => {
                let locationPath = location.pathname
                if (locationPath === item) {
                    isActive = true
                }
            })
        } catch (TypeError) {
            logThis('WARNING in isActiveLink: the param is not an array!')
        }
        return isActive
    }

    const isActiveLink = (arrayOfLinks: string[]) => {
        let isActive = false
        try {
            arrayOfLinks.forEach((item) => {
                let locationPath = location.pathname
                if (locationPath.startsWith(item)) {
                    isActive = true
                }
            })
        } catch (TypeError) {
            logThis('WARNING in isActiveLink: the param is not an array!')
        }
        return isActive
    }

    const isModuleActive = (bool: boolean) => {
        return bool;
    }

    //TODO: decidere se usare le icone di material-ui
    const evaluateEnrollmentModuleLinks = () => {
        let { navigationLinks } = props
        let links = navigationLinks._links
        let arr = []
        if (links.dashboard || links.elencoIscrizioni) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.dashboard]),
                href: PAGES.dashboard,
                title: 'I miei dati',
                leftIcon: DashboardIcon
            }))
        }

        if (links.organizationWaitingList) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.listaAttesa]),
                href: PAGES.listaAttesa,
                title: 'Lista di attesa',
                leftIcon: ViewListIcon
            }))
        }

        if (links.organizationRequestsHistory) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.storicoRichieste]),
                href: PAGES.storicoRichieste,
                title: 'Storico Richieste',
                leftIcon: HistoryIcon
            }))
        }

        if (links.listYearTemplates) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.yearTemplate]),
                href: PAGES.yearTemplate,
                title: 'Preview moduli',
                leftIcon: CodeIcon
            }))
        }

        if (links.newEnrollmentForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.nuovaIscrizioneFrequenza, PAGES.nuovaIscrizioneDichiarazioni, PAGES.nuovaIscrizioneRiepilogo]),
                href: PAGES.nuovaIscrizioneFrequenza,
                title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, props.structureInfo),
                leftIcon: AddIcon
            }))
        }

        if (links.newDataChangeForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.cambioDati, PAGES.cambioDatiDichiarazioni, PAGES.cambioDatiRiepilogo]),
                href: PAGES.cambioDati,
                title: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, props.structureInfo),
                leftIcon: CreateIcon
            }))
        }

        if (links.newFrequencyChangeForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.modificaIscrizione]),
                href: PAGES.modificaIscrizione,
                title: getFunctionName(REGISTRATION_TYPE.MODIFICA, props.structureInfo),
                leftIcon: AddIcon
            }))
        }

        if (links.newAdditionalServiceForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.serviziAggiuntivi]),
                href: PAGES.serviziAggiuntivi,
                title: getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, props.structureInfo),
                leftIcon: AddIcon
            }))
        }

        if (links.newEnrollmentChangeForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.modificaInizioFineFrequenza]),
                href: PAGES.modificaInizioFineFrequenza,
                title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, props.structureInfo),
                leftIcon: AddIcon
            }))
        }

        if (links.newChangeIseeForm) {
            arr.push(renderItem({
                active: isActiveLink([PAGES.cambioIsee]),
                href: PAGES.cambioIsee,
                title: getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, props.structureInfo),
                leftIcon: AddIcon
            }))
        }

        if (links.changeNews) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.tableNews]),
                href: PAGES.tableNews,
                title: 'Modifica news',
                leftIcon: AddIcon
            }))
        }

        if (links.changeOrganizationDetails) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.contentManagerDescriptions]),
                href: PAGES.contentManagerDescriptions,
                title: 'Modifica descrizioni',
                leftIcon: AddIcon
            }))
        }

        if (links.changeParameters) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.changeParameters]),
                href: PAGES.changeParameters,
                title: 'Configura lista attesa',
                leftIcon: SettingsIcon
            }))
        }

        if (links.organizationInFilling) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.richiesteInCompilazione]),
                href: PAGES.richiesteInCompilazione,
                title: 'Richieste in compilazione',
                leftIcon: CreateIcon
            }))
        }

        if (links.requestBin) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.requestBin]),
                href: PAGES.requestBin,
                title: 'Richieste eliminate',
                leftIcon: DeleteIcon
            }))
        }

        if (links.adminOrganizations) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.admin]),
                href: PAGES.admin,
                title: 'Admin dashboard',
                leftIcon: SettingsIcon
            }))
        }

        if (links.newsReadUsers) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.readNews]),
                href: PAGES.readNews,
                title: 'News Lette',
                leftIcon: SettingsIcon
            }))
        }

        if (links.getStaff) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.getStaff]),
                href: PAGES.getStaff,
                title: 'Personale',
                leftIcon: GroupIcon
            }))
        }

        // TODO: verificare se usare un'altro
        if (links.parentYears) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.appSimulator]),
                href: PAGES.appSimulator,
                title: 'AppGenitori',
                leftIcon: SmartphoneIcon
            }))
        }

        if (links.invoicesList) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.listInvoices]),
                href: PAGES.listInvoices,
                title: 'Fatture',
                leftIcon: Receipt
            }))
        }

        if (links.documentsList) {
            arr.push(renderItem({
                active: isHomeLinkActive([PAGES.listDocuments]),
                href: PAGES.listDocuments,
                title: 'Documenti',
                leftIcon: Description
            }))
        }

        return arr;
    }



    const { navigationLinks } = props
    if (!navigationLinks) {
        return null
    }
    const links = navigationLinks._links
    let categoriesArray = []
    let locationPath = location.pathname

    let navigationLiks = []
    let linkModule = []


    let moduleName;
    if (locationPath.startsWith(MODULE_ROOTS.multiStructureModule)) {
        moduleName = 'Dashboard Multistruttura'

        if (links.getStaff) {
            navigationLiks.push(renderItem({
                active: isHomeLinkActive([MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences]),
                href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
                title: 'Report presenze',
                leftIcon: DashboardIcon
            }))
        }

        if (links.coberturaReport) {
            navigationLiks.push(renderItem({
                active: isHomeLinkActive([MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences]),
                href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
                title: 'Report presenze',
                leftIcon: DashboardIcon
            }))
        }

        if (props.open) {
            linkModule.push(renderItem({
                active: isModuleActive(false),
                href: PAGES.dashboard,
                title: 'Modulo Iscrizioni'
            }))
        }


    } else {
        // modulo iscrizioni
        navigationLiks.push(evaluateEnrollmentModuleLinks());
        moduleName = 'Iscrizioni'

        const multiStructureModule = links.coberturaReport != undefined || links.authorizedStructures != undefined || links.presencesReport != undefined

        if (multiStructureModule && props.open) {
            linkModule.push(renderItem({
                active: isModuleActive(false),
                href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
                title: 'Gestione strutture'
            })
            )
        }
    }

    if (links.changePassword) {
        categoriesArray.push(renderItem({
            active: isHomeLinkActive([PAGES.changePassword]),
            href: PAGES.changePassword,
            title: 'Cambia Password',
            leftIcon: LockIcon
        }))
        categoriesArray.push((<Divider key={generateUUID()} />))
    }



    if (linkModule.length > 0 && props.open) {
        categoriesArray.push(
            <ListSubheader disableSticky={true} id="entrollements-list-subheader" key={generateUUID()} className={classes.ItemLabelText}>
                {moduleName.toUpperCase()}
            </ListSubheader>
        )
    }

    categoriesArray.push(navigationLiks);

    if (linkModule.length > 0 && props.open) {
        categoriesArray.push(<Divider key={generateUUID()} />)
        categoriesArray.push(
            <ListSubheader id="others-list-subheader" key={generateUUID()} className={classes.ItemLabelText} sx={{ backgroundColor: 'transparent' }} >
                ALTRI MODULI
            </ListSubheader>
        )
        categoriesArray.push(linkModule)
    }

    return (
        <List aria-labelledby="nested-list-subheader"
            className={classes.ListRoot}
        >
            {categoriesArray}
        </List>
    )
}

