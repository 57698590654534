import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { expandUri, logThis, generateUUID } from '@insoft/lib-react-universal/dist/utils';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { getFormData, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { BoxStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { updateFormDataState } from '@insoft/lib-react-web/dist/utils/utils';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, parseServerErrorResponse, renderAlertComponent } from 'utils';
import Box from '@mui/material/Box'
import { useParams } from 'react-router';


interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl?: string
}

interface AllProps extends ActionCreatorsProps, Props { }


function ParametersPanel(props: AllProps) {

    const { id } = useParams()

    //BoxStyles non è stato incorporato con un hook
    const classesBox = BoxStyles();

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [resources, setResources] = useState<FormObject>(null)
    const [serverResponse, setServerResponse] = useState<AlertObject>(null)
    const [formKey, setFormKey] = useState<string>(generateUUID())


    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        const expandedUri = expandUri(props.requestUrl, { id: id })
        getFormData({ url: expandedUri })
            .then((data) => {
                setResources(
                    data
                )
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const onFormSubmit = (formObject: { formData: object }) => {
        let formData = formObject.formData
        setResources(
            updateFormDataState(resources, formData)
        ), () => {
            props.startLoading()
            sendForm({ method: resources.method, url: resources.url, data: formData })
                .then((message) => {
                    props.stopLoading()
                    setFormSubmitted(
                        true
                    )
                }).catch((error) => {
                    props.stopLoading()
                    setServerResponse(parseServerErrorResponse(error))
                    setFormKey(generateUUID())
                })
        }
    }


    if (resources) {
        var serverResponseComponent = renderAlertComponent(serverResponse)
    }
    return (
        <Box>
            {props.loading ? <Box className={classesBox.box}>
                {getLoadingComponent(true)}
            </Box> : undefined}
            <FormPage
                formKey={formKey}
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={onFormSubmit}
                resources={resources}
                serverResponseComponent={serverResponseComponent}
            />
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.changeParameters.href
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParametersPanel)