import EnrollmentsComponent from 'commons/components/enrollments-component';
import React from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from "redux/store";

interface Props {
    requestUrl: string
}

function UserEnrollments(props: Props) {
    if (!props.requestUrl) {
        return null
    }
    return (<EnrollmentsComponent
        isParentUser={true}
        operator={false}
        requestUrl={props.requestUrl}
        printEmpty={true}
        notifyOpenEnrollments={true}
    />)
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.registrations) {
        var requestUrl = state.global.navigationLinks._links.registrations.href
    }
    return {
        requestUrl: requestUrl
    }
}

export default connect(mapStateToProps)(UserEnrollments)
// for test purpose
export { UserEnrollments as UserEnrollmentsWithoutRedux };

