import { useSelector } from "react-redux";
import { GlobalStateInterface } from "../../../redux/store";
import { Navigation } from "../../interfaces/navigation";
import { AUTOCOMPLETE_WIDGET_ID, ExcelIcon, SpringRestTableAndSearch } from "@insoft/lib-react-mui-web";
import { useEffect, useState } from "react";
import { CollectionModel, EntityModel, JsonSchema, LoadingComponent, PagedModel, ResponseError, SpringErrorResponse, TableColumnDefinition, deepEquals, defaultReactDataRenderMUITable, executeRequest } from "@insoft/lib-react-web";
import { Alert, AlertTitle, Box, Button, Snackbar } from "@mui/material";
import { FORMAT_ISO8601, expandUri, formatUNIEN28601Date, formatUNIEN28601Datetime, generateUUID, logThis, formatDateObject } from "@insoft/lib-react-universal";
import { Link } from "react-router-dom";
import { PAGES } from "../../../utils/rest-routes";
import LockIcon from '@mui/icons-material/Lock';
import UndoIcon from '@mui/icons-material/Undo';
import TableViewIcon from '@mui/icons-material/TableView';
import CachedIcon from '@mui/icons-material/Cached';
import { URITemplateInput } from "@insoft/lib-react-universal/dist";
import Tooltip from "@mui/material/Tooltip";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { OrganizationData } from "../../interfaces/organization-data";
import { fecthOrganizations } from "../../services/organization-data-service";
import moment from "moment";
interface Props {
    singleOrganization?: boolean
}

export interface ReportEntity extends EntityModel {
    id: number,
    month: string,
    generationTime: string,
    generationUser: string,
    locked?: boolean,
    lastUpdatedTime: string,
    organizationId: number
    dirty?: boolean
}

interface SearchData {
    from: string,
    to: string,
    organizations?: number[]
}

export default function OrganizationPresenceReport(props: Props) {
    const navigationLinks: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks);

    const tableUrl = navigationLinks._links.organizationPresencesReportTable?.href
    // const dataUrl = props.singleOrganization ? navigationLinks._links.organizationPresencesReport?.href
    //     : navigationLinks._links.presencesReport?.href
    // const presencesReportOrganization: string = useSelector((state: GlobalStateInterface) => state.global.navigationLinks?._links.presencesReportOrganization?.href);
    // const [columns, setColumns] = useState<TableColumnDefinition[]>([])
    // const [organizations, setOrganizations] = useState<{ label: string, value: string }[]>(undefined)
    const [loading, setLoading] = useState<Set<String>>(new Set())
    const [tableKey, setTableKey] = useState<string>(generateUUID())
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);
    const [errorTitle, setErrorTitle] = useState<string>(undefined);
    const [searchData, setSearchData] = useState<SearchData>(undefined);
    const [organizations, setOrganizations] = useState<OrganizationData[]>(undefined);
    const [selectedItems, setSelectedItems] = useState<string[]>(undefined);
    // let dispath = useDispatch()

    if (!tableUrl) {
        return undefined
    }

    useEffect(() => {
        if (!searchData) {
            const fromDate = new Date();
            fromDate.setDate(1)
            if (props.singleOrganization) {
                fromDate.setFullYear(fromDate.getFullYear() - 1);
            } else {
                fromDate.setMonth(fromDate.getMonth() - 1);
            }

            const toDate = new Date();
            toDate.setDate(1)
            setSearchData({
                from: formatDateObject(fromDate, FORMAT_ISO8601),
                to: formatDateObject(toDate, FORMAT_ISO8601),
                organizations: undefined
            })
        }
        if (!organizations && !props.singleOrganization) {
            fecthOrganizations(navigationLinks)
                .then(org => setOrganizations(org))
                .catch(e => {
                    logThis(e)
                    setOrganizations([])
                })

        }
    },
        [props.singleOrganization])

    if (!searchData) {
        return null//<LoadingComponent />
    }

    if (!props.singleOrganization && !organizations) {
        return <LoadingComponent fullPage />
    }

    if (error) {
        var snackbar =
            (<Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={error}
                autoHideDuration={6000}
                onClose={() => { setError(false) }}>
                <Alert onClose={() => { setError(false) }} severity="error" sx={{ width: '100%' }} variant="filled">
                    <AlertTitle>{errorTitle}</AlertTitle>
                    {errorMessage}
                </Alert></Snackbar>)
    }


    const notifyLoading = (uuid: string) => {
        const newLoading = new Set(loading);
        newLoading.add(uuid)
        setLoading(newLoading)
    }

    const removeLoading = (uuid: string) => {
        const newLoading = new Set(loading);
        newLoading.delete(uuid)
        setLoading(newLoading)
    }

    const prepareColumns: () => TableColumnDefinition[] = () => {
        const columns: TableColumnDefinition[] = [{
            field: 'month',
            headerName: 'Mese'
        }, {
            field: 'lastUpdatedTime',
            headerName: 'aggiornato il'
        }, {
            field: 'dirty',
            renderCell: defaultReactDataRenderMUITable
        }]
        if (!props.singleOrganization) {
            columns.push({
                field: 'organization',
                headerName: 'struttura'
            })
        }

        columns.push({
            field: 'updateButton',
            renderCell: defaultReactDataRenderMUITable
        });
        columns.push({
            field: 'lockedButton',
            renderCell: defaultReactDataRenderMUITable
        });
        columns.push({
            field: 'showReportButton',
            renderCell: defaultReactDataRenderMUITable
        });
        return columns;
    }



    const executePut = (url: string) => (event: any) => {
        const expanded = expandUri(url, { version: undefined })
        const uuid = generateUUID()
        notifyLoading(uuid)
        executeRequest({ url: expanded, method: 'PUT', retryPolicy: { retries: 1 } })
            .then(() => {
                setTableKey(generateUUID())
                removeLoading(uuid)
            })
            .catch((data: ResponseError) => {
                const errorResponse: SpringErrorResponse = data.response
                logThis(data);
                setError(true)
                setErrorTitle(errorTitle)
                setErrorMessage(errorResponse.message)
                removeLoading(uuid)
            });
    }

    const getOrganization = (organizationId: number, organizations: OrganizationData[]): string => {
        return organizations.find(data => data.id == organizationId)?.name || 'ND'
    }

    const prepareRow = (entity: EntityModel & ReportEntity) => {
        let lockedButton;
        if (entity._links?.unlock) {
            lockedButton = (<Button variant="contained" size="small" onClick={executePut(entity._links?.unlock?.href)} endIcon={<UndoIcon />}>Sblocca</Button >)
        } else if (entity._links?.lock) {
            lockedButton = (<Button variant="contained" size="small" color="warning" onClick={executePut(entity._links?.lock?.href)} endIcon={<LockIcon />}>Valida</Button >)
        }
        if (entity._links?.simpleReport) {
            var showReportButton = <Link to={PAGES.reportPreview.replace(":id", entity.id.toString())} state={{
                uri: entity._links?.simpleReport?.href
            }}><Button variant="contained" size="small" endIcon={<TableViewIcon />}>Visualizza</Button></Link>
        }
        if (entity._links?.update) {
            var updateButton = <Button variant="contained" size="small" onClick={executePut(entity._links?.update?.href)} endIcon={<CachedIcon />}>Aggiorna dati</Button >
        }
        if (entity.dirty) {
            var dirtyComponent = <Tooltip title="Alcuni dati sono stati aggiornati dopo la generazione del report"><ReportProblemIcon /></Tooltip>
        }
        const result = {
            ...entity,
            lockedButton: {
                component: lockedButton,
            },
            showReportButton: {
                component: showReportButton,
            }, updateButton: {
                component: updateButton,
            },
            // month: formatUNIEN28601Date(entity.month, FORMAT_ISO8601),
            month: moment(entity.month).locale('it').format('MMMM yyyy'),
            lastUpdatedTime: formatUNIEN28601Datetime(entity.lastUpdatedTime),
            organization: props.singleOrganization ? undefined : getOrganization(entity.organizationId, organizations),
            dirty: {
                component: dirtyComponent
            },
            selected: selectedItems.lastIndexOf(entity.id.toString()) > -1
        }
        return result
    }

    const renderSelectionButtons = (resources: PagedModel<ReportEntity>) => {
        const buttons: React.ReactElement[] = []
        if (resources?._links?.multiplePreview?.href) {
            buttons.push(<Box key="preview-box" mr={2}><Link to={PAGES.reportPreview.replace(":id", 'many')} state={{
                uri: expandUri(resources?._links?.multiplePreview?.href, { ids: selectedItems })
            }}><Button key="preview" variant="contained" size="small" title="Visualizza selezionati" endIcon={<TableViewIcon />}>Visualizza</Button>
            </Link></Box>)
        }
        if (resources?._links?.multipleExcel?.href) {
            const href = expandUri(resources?._links?.multipleExcel?.href, { ids: selectedItems });
            buttons.push(<Box key="download-box" mr={2}><Button key="download" variant="contained" size="small" title="Scarica selezionati" href={href} target="_blank" endIcon={<ExcelIcon />}>Scarica </Button></Box>)
        }

        return buttons
    }

    const renderButtonArea = (resources: PagedModel<ReportEntity>) => {
        // search-download
        const href = resources?._links?.['search-download-excel']?.href;
        if (href && resources._embedded) {
            return (<Box key="download-box" p={2}>
                <Button key="download" variant="contained" size="small" title="Scarica tutti" href={href} target="_blank" endIcon={<ExcelIcon />}>Scarica tutti</Button>
            </Box>)
        }
        return undefined;
    }

    const jsonSchemaSearch: JsonSchema = {
        type: 'object',
        properties: {
            from: {
                type: 'string',
                format: 'month',
                title: 'dal mese',
                sm: 6,
                lg: 4
            },
            to: {
                type: 'string',
                format: 'month',
                title: 'al mese',
                sm: 6,
                lg: 4
            }
        },
        required: ['from', 'to']
    }
    if (!props.singleOrganization) {
        jsonSchemaSearch.properties['organizations'] = {
            type: 'array',
            title: 'strutture',
            uniqueItems: true,
            xs: 12,
            lg: 12,
            items: {
                "$ref": "#/definitions/organizations"
            },
            "ui:widget": AUTOCOMPLETE_WIDGET_ID
        }
        jsonSchemaSearch['definitions'] = {
            "organizations": {
                // enumNames: organizations.map(i => i.name),
                // enum: organizations
                type: 'number',
                anyOf: organizations.map(i => {
                    return {
                        const: i.id,
                        title: i.name
                    }
                })
            }
        }
    }


    if (loading.size > 0) {
        return <LoadingComponent fullPage />
    }

    const createSchema: JsonSchema = {
        type: 'object',
        properties: {
            month: {
                type: 'string',
                format: 'month',
                title: 'Mese del report da generare',
                xs: 12
            }
        },
        required: ['month']
    };
    if (!props.singleOrganization) {
        createSchema.properties['organizationId'] = {
            type: "number",
            title: 'struttura',
            xs: 12,
            anyOf: organizations.map(i => {
                return {
                    const: i.id,
                    title: i.name
                }
            })
        }
        createSchema.required = ['month', 'organizationId']
    }
    return (<Box>
        {snackbar}
        <SpringRestTableAndSearch
            key={tableKey}
            pageSizes={props.singleOrganization ? undefined : [30, 60, 90]}
            jsonSchemaSearch={jsonSchemaSearch}
            searchData={searchData}
            searchDataToParams={(data: SearchData): URITemplateInput => {
                // setSearchData(data)
                return {
                    from: data.from,
                    to: data.to,
                    organizations: data.organizations ? data.organizations.map(i => i.toString()) : undefined
                }
            }}
            requestUrl={tableUrl}
            columnDefinitions={prepareColumns()}
            embeddedKey={"presencesReportDatas"}
            createSchemaDialog={createSchema}
            deleteColumn
            renderRow={prepareRow}
            selectableRows={!props.singleOrganization}
            showLoading
            onSelectionChange={(items: any[]) => {
                const idSelected = items?.map((item: any) => {
                    const entity: ReportEntity = item as ReportEntity;
                    return entity.id.toString()
                })
                if (!deepEquals(idSelected, selectedItems)) {
                    setSelectedItems(idSelected)
                }
            }}
            leftToolbarArea={renderButtonArea}
            onSelectionActions={renderSelectionButtons}

        />
    </Box>)
}