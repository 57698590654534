import { getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { getFormData, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { FORM_INPUT_KEYS } from 'utils/constants';
import { getLoadingComponent, parsePositiveResponse, parseServerErrorResponse, renderAlertComponent } from 'utils';
import { styleRules } from './style/change-password-form-style';
import Box from '@mui/material/Box'



interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl?: string
}

interface AllProps extends ActionCreatorsProps, Props {
    width: string
}


function NovaeChangePwd(props: AllProps) {

    const [resources, setResources] = useState<any>(null);
    const [serverResponse, setServerResponse] = useState<AlertObject>(null);


    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        getFormData({ url: props.requestUrl })
            .then((data) => {
                setResources(
                    data
                )
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const onFormSubmit = (formObject: { formData: object }) => {
        let formData = formObject.formData
        let method = resources.method
        let url = resources.url
        props.startLoading()
        sendForm({ method, url, data: formData })
            .then(() => {
                let opt = {
                    autoclose: false,
                    closable: true,
                    title: 'La password è stata cambiata con successo'
                }
                setServerResponse(parsePositiveResponse(opt))
                props.stopLoading()
            }).catch((error) => {
                setServerResponse(parseServerErrorResponse(error))
                props.stopLoading()
            })
    }

    const validate = (formData: any, errors: any) => {
        if (formData[FORM_INPUT_KEYS.NEW_PASSWORD] !== formData[FORM_INPUT_KEYS.RETYPE_NEW_PASSWORD]) {
            errors[FORM_INPUT_KEYS.RETYPE_NEW_PASSWORD].addError('Le password non corrispondono')
        }
        return errors
    }


    let serverResponseComponent = renderAlertComponent(serverResponse)
    let loadingView = (
        <div >
            {getLoadingComponent(true)}
        </div>
    )
    if (props.loading) {
        return loadingView
    } else {
        return (
            <Box>
                {/*TODO <Grid container justify="center">*/}
                <FormPage
                    buttonText='Cambia'
                    formStyleRules={styleRules}
                    fields={getCommonFields()}
                    widgets={getCommonWidgets()}
                    onFormSubmit={onFormSubmit}
                    resources={resources}
                    serverResponseComponent={serverResponseComponent}
                    title='Cambia password'
                    boxStyle='warning'
                    validate={validate}
                    md={6}
                />
                {/*</Grid>*/}
            </Box >
        )
    }
}

NovaeChangePwd.defaultProps = {
    width: 'col-sm-3'
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.changePassword.href
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NovaeChangePwd)