import { expandUri, generateUUID } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import EnrollmentsComponent from 'commons/components/enrollments-component';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl: string
}

interface AllProps extends ActionCreatorsProps, Props {
    yearFilter: string
}

class OrganizationWaitingList extends React.Component<AllProps> {
    constructor(props: AllProps) {
        super(props)
    }

    // componentDidMount() {
    //     this.fetchData()
    // }

    // componentDidUpdate(oldProps: AllProps) {
    //     if (this.props.yearFilter !== oldProps.yearFilter) {
    //         this.fetchData();
    //     }
    // }

    // fetchData = () => {
    //     this.props.startLoading()
    //     executeRequest<Enrollments>({ url: expandUri(this.props.requestUrl, { yearId: this.props.yearFilter }), method: 'GET' })
    //         .then((data) => {
    //             this.props.stopLoading()
    //             this.setState({
    //                 resources: data
    //             })
    //             return null
    //         }).catch((data) => {
    //             this.props.stopLoading()
    //             logThis(data)
    //         })
    // }

    render() {

        if (this.props.loading) {
            return getLoadingComponent()
        }

        if (!this.props.requestUrl) {
            return null
        }
        return (<EnrollmentsComponent
            requestUrl={expandUri(this.props.requestUrl, { yearId: this.props.yearFilter })}
            printEmpty
            search={false}
            hideNumber={true}
            hideStartDate={true}
            key={generateUUID()}
            defaultOrder={[[2, "asc"]]}
            operator={true}
        />)
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationWaitingList) {
        var requestUrl = state.global.navigationLinks._links.organizationWaitingList.href
    }
    return {
        loading: state.global.isLoading,
        requestUrl
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationWaitingList)