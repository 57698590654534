import { Grid } from '@mui/material';
import { IChangeEvent } from '@rjsf/core';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import { FormObject, ServerFormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from '@insoft/lib-react-web/dist/utils/schema-utils';
import { useState } from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import { SEARCH_FILEDS } from '../multi-structure-presences-report/search-multi-structure-form-object';
import OrganizationWaitingList from './organization-waiting-list';


interface Props {
    yearsRequestUrl: string
}

function WaitingListPanel(props: Props) {

    const [year, setYear] = useState(undefined);




    const onChange = (event: IChangeEvent<any>) => {
        let formData = event.formData
        console.log(formData)
        let year = undefined
        if (formData) {
            year = formData[SEARCH_FILEDS.YEAR]
        }
        setYear(year)
    }

    const getFormObject = (): FormObject => {
        let serverFormObject: ServerFormObject
        let properties: { [key: string]: any } = {

        }


        properties[SEARCH_FILEDS.YEAR] = {
            type: "string",
            title: "Anno",
            represents: [
                FORM_INPUT_DEFAULT_WIDGETS.LIST,
                FORM_LIST_TYPE.DROPDOWN_LIST
            ],
            autocomplete: {
                href: props.yearsRequestUrl
            }
        }
        let formData: { [key: string]: any } = {}
        formData[SEARCH_FILEDS.YEAR] = year

        serverFormObject = {
            PUT: {
                form: {
                    formSchema: {
                        type: "object",
                        properties: properties
                    },
                    formData: formData
                }
            },
            _links: {
                self: {
                    href: ''
                }
            }
        }
        return new FormObjectAdapter(serverFormObject)
    }

    return (
        <Grid container spacing={3}>
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onChange={onChange}
                resources={getFormObject()}
                title={"Filtro di ricerca"}
                showButton={false}
                showRequiredFieldsLegend
            />
            <ErrorBoundary>
                <OrganizationWaitingList yearFilter={year} />
            </ErrorBoundary>
        </Grid>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationWaitingListYears) {
        var requestUrl = state.global.navigationLinks._links.organizationWaitingListYears.href
    }
    return {
        yearsRequestUrl: requestUrl
    }
}

export default connect(mapStateToProps)(WaitingListPanel)