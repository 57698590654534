import { Box, Link } from "@mui/material";
import { Navigation } from "commons/interfaces/navigation";
import { StructureInfo } from "commons/interfaces/structure-info";
import STYLE from "commons/styles/style";
import BoxedContent from "@insoft/lib-react-web/dist/components/misc/boxed-content";
import React from "react";
import { connect } from "react-redux";
import { GlobalStateInterface } from "redux/store";
import { getFunctionName } from "utils";
import { ENROLLMENT_STATUS, REGISTRATION_TYPE } from "utils/constants";
import { APP_STORE_URLS } from "utils/rest-routes";

interface Props {
    structureInfo: StructureInfo,
    navigation: Navigation
}

function ParentHelp(props: Props) {

    if (!props.navigation.parentsApplicationHelp)
        return null

    const functionName = getFunctionName(REGISTRATION_TYPE.ENROLLMENT, props.structureInfo)
    const structureName = props.structureInfo.name
    let otherRequests = undefined
    return (
        <BoxedContent title="Benvenuto in Novae Web" type="info" xs={12} md={12} lg={9}>
            <Box p={3}>
                <p>Qui potrai effettuare una <b>{functionName}</b> ed inserire i dati di tuo figlio.</p>
                <p>Dal menù laterale è possibile procedere con una nuova compilazione. Integra i dati richiesti ed arriva fino al "Riepilogo" da qui invia la domanda a <b>{structureName}</b>. Solo confermando i dati avrai completato il processo e la domanda sarà in <span className={STYLE.tags[ENROLLMENT_STATUS.IN_PROGRESS].class}>{STYLE.tags[ENROLLMENT_STATUS.IN_PROGRESS].label}</span>.
                    In questa pagina sarà sempre possibile vedere l'elenco delle richieste effettuate e il loro stato.</p>
                <p>Finchè la domanda è in <span className={STYLE.tags[ENROLLMENT_STATUS.FILLING].class}>{STYLE.tags[ENROLLMENT_STATUS.FILLING].label}</span> i dati saranno modificabili in qualsiasi momento.</p>
                {otherRequests}
                <p>
                    Quando la tua richiesta appare nello stato <span className={STYLE.tags[ENROLLMENT_STATUS.ACCEPTED].class}>{STYLE.tags[ENROLLMENT_STATUS.ACCEPTED].label}</span> e ad anno educativo avviato sarà possibile utilizzare l'applicazione <b>Novae Genitori</b>.
                    Scarica la versione per il tuo dispositivo dai link qui sotto ed utilizza le credenziali in tuo possesso per accedere.
                </p>
                <Link href={APP_STORE_URLS.APPLE}><img alt='Logo to AppleStore' src='/dist/img/app-store.webp' /></Link>
                <Link href={APP_STORE_URLS.GOOGLE}><img alt='Logo to PlayStore' src='/dist/img/play-store.webp' /></Link>
            </Box>
        </BoxedContent>
    )

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        structureInfo: state.global.structureInfo,
        navigation: state.global.navigationLinks
    }
}

export default connect(mapStateToProps)(ParentHelp);