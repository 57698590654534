import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import ContentManagerComponent from './content-manager-component';

interface Props {
    requestUrl?: string
}

function DescriptionManager(props: Props) {

    const requestUrl = props.requestUrl

    return (
        <ContentManagerComponent
            requestUrl={requestUrl}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        requestUrl: state.global.navigationLinks._links.changeOrganizationDetails.href
    }
}

export default connect<{}, {}, Props>(mapStateToProps)(DescriptionManager)