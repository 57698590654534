import React from 'react'

interface Props {
    message?: string
}
export default class Error404 extends React.Component<Props, null> {
    render() {
        return (
            <div className='error-page-parent'>
                <div className='error-page'>
                    <img className='img-responsive center-block' src='/dist/img/novae/404_error_page.png' />
                    <p className='error-page-message'>{this.props.message}</p>
                </div>
            </div>
        )
    }
}