// TODO attenzione form-style è da deprecare in favore di un file più generico
import { ENROLLMENT_STATUS, REGISTRATION_TYPE, PAYMENT_STATUS } from 'utils/constants'

export const ICONS = {
    head: "glyphicon glyphicon-user form-control-feedback",
    email: "glyphicon glyphicon-envelope form-control-feedback",
    lock: "glyphicon glyphicon-lock form-control-feedback"
}

let STYLE: any = {
    formGroupClass: "form-group has-feedback",
    formInputClass: "form-control",

    widthCheckbox: "col-xs-8",
    checkboxStyle: "checkbox icheck icheckbox_square-blue ",
    widthEnterButton: "col-xs-4",
    widthRegisterButton: "col-xs-6",
    enterButtonStyle: "btn btn-primary btn-block btn-flat",
    rowClass: "row",

    defaultWidth: "col-md-4",

    widths: {
        'firstName': "col-md-4",
        'lastName': "col-md-4"
    },

    icons: {
        'username': ICONS.head,
        'name': ICONS.head,
        'firstNameParent': ICONS.head,
        'lastName': ICONS.head,
        'email': ICONS.email,
        'retype-email': ICONS.email,
        'password': ICONS.lock,
        'retype-password': ICONS.lock,
        'positive': "fa fa-check-circle-o text-green",
        'negative': "fa fa-times-circle-o text-red"
    },
    tags: {},
    types: {},
    paymentStatus: {}
}

STYLE.tags[ENROLLMENT_STATUS.ACCEPTED] = {
    "label": "Approvato",
    "class": "label label-success",
    "icon": "fa fa-check-circle-o text-green"
}
STYLE.tags[ENROLLMENT_STATUS.PRE_ACCEPTED] = {
    "label": "Pre accettato",
    "class": "label label-success",
    "icon": "fa fa-check-circle-o text-green"
}
STYLE.tags[ENROLLMENT_STATUS.IN_PROGRESS] = {
    "label": "In lavorazione",
    "class": "label label-warning",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.tags[ENROLLMENT_STATUS.WAITING_LIST] = {
    "label": "In lista di attesa",
    "class": "label bg-maroon",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.tags[ENROLLMENT_STATUS.RETIRED] = {
    "label": "Ritirata",
    "class": "label bg-gray-active",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.tags[ENROLLMENT_STATUS.REJECTED] = {
    "label": "Non approvato",
    "class": "label label-danger",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.tags[ENROLLMENT_STATUS.EXPIRED] = {
    "label": "Scaduta",
    "class": "label bg-gray-active",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.tags[ENROLLMENT_STATUS.FILLING] = {
    "label": "In compilazione",
    "class": "label label-info",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.ENROLLMENT] = {
    "label": "Nuova iscrizione",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.MODIFICA] = {
    "label": "Modifica frequenza",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI] = {
    "label": "Servizi aggiuntivi",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.ENROLLMENT_CHANGE] = {
    "label": "Modifica inizio/fine frequenza",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.ISEE_CHANGE] = {
    "label": "Cambio Fascia",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.types[REGISTRATION_TYPE.DATA_CHANGE] = {
    "label": "Cambio dati",
    "class": "",
    "icon": "fa fa-times-circle-o text-red"
}
STYLE.paymentStatus[PAYMENT_STATUS.PAID] = {
    "label": "Pagato",
    "class": "label label-success",
    "icon": "fa fa-check-circle-o text-green"
}
STYLE.paymentStatus[PAYMENT_STATUS.UNPAID] = {
    "label": "Non pagato",
    "class": "label label-danger",
    "icon": "fa fa-times-circle-o text-red"
}

export default STYLE