
import { bindActionCreators, Dispatch } from "redux";
import { startLoading, stopLoading } from "redux/actions/global-actions";
import { GlobalStateInterface } from "redux/store";
import { getLoadingComponent, parseServerErrorResponse, renderAlertComponent, renderModal } from "utils";
import { ModalResponse } from "commons/interfaces/utils";
import { Method } from "axios";
import { PAGES } from "utils/rest-routes";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import { AlertObject, FormPage, getFormData, sendForm, updateFormDataState } from "@insoft/lib-react-web";
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from "commons/styles/common-form-style-rules";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { expandUri, logThis } from "@insoft/lib-react-universal/dist";

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading?: boolean
    urlGetRia?: string
    urlGetAnaForRia?: string
    structureId?: string
    nextPage: string
}

interface AllProps
    extends ActionCreatorsProps, Props { }

function ModifyStaff(props: AllProps) {

    // const [ana, setAna] = useState<string>(props.match.params.ana)
    // const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [resources, setResources] = useState<any>(null);
    const [serverResponse, setServerResponse] = useState<AlertObject>(undefined);
    const [modalObject, setModalObject] = useState<ModalResponse>(undefined);

    const routeParams = useParams()
    const ria = routeParams.ria


    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        props.startLoading()
        let expandedUri
        // if (ria === undefined) {
        //     expandedUri = expandUri(props.urlGetAnaForRia, {ana: ana});
        // } else {
        expandedUri = expandUri(props.urlGetRia, { ria: ria });
        // }
        getFormData({ url: expandedUri })
            .then((data) => {
                // Da rivedere
                if (data._links && data._links.getRoles && data._links.getRoles.href && data.data && data.data.form
                    && data.data.form.formSchema && data.data.form.formSchema.properties
                    && data.data.form.formSchema.properties.staffProfile.properties.profile
                    && data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete
                    && data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete.href) {
                    data.data.form.formSchema.properties.staffProfile.properties.profile.autocomplete.href = data._links.getRoles.href
                }
                setResources(data)
                props.stopLoading()
            }).catch((data) => {
                logThis(data)
                props.stopLoading()
            })
    }

    const onFormSubmit = (formObject: { formData: { [key: string]: any } }) => {
        let formData = formObject.formData
        setResources(updateFormDataState(resources, formData))
        let expandedUri
        let method: Method
        let postNew: boolean = false
        // if (ria === undefined) {
        //     method = 'POST'
        //     expandedUri = expandUri(props.urlGetAnaForRia + "/update", {ana: ana});
        //     postNew = true
        // } else {
        method = 'PUT'
        expandedUri = expandUri(props.urlGetRia, { ria: ria });
        // }
        formData.cst = props.structureId
        props.startLoading()
        sendForm({ method: method, url: expandedUri, data: formData })
            .then((message) => {
                // if(postNew) {
                //     setRia(message)
                //     setAna(undefined)
                //     setFormSubmitted(true)
                // } else {
                //     setServerResponse(undefined)
                let modalResponse: ModalResponse = {
                    status: 'OK',
                    message: 'Dati aggiornati con successo.'
                }
                setModalObject(modalResponse)
                // }                
                props.stopLoading()
            }).catch((error) => {
                setServerResponse(parseServerErrorResponse(error))
                props.stopLoading()
            })
    }

    const closeModal = () => {
        setModalObject(undefined)
    }

    let serverResponseComponent = renderAlertComponent(serverResponse)
    let modalObjectComponent = renderModal(modalObject, closeModal)

    // if (formSubmitted) {
    //     redirectToWithPathToAttachFromOrigin(composeUriPath(props.nextPage, [ria]))
    // return getRedirectComponent(composeUriPath(props.nextPage, [ria]))
    // }
    if (props.loading) {
        return getLoadingComponent(true)
    }

    return (
        <Box>
            {modalObjectComponent}
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={onFormSubmit}
                resources={resources}
                buttonText="Aggiorna"
                showRequiredFieldsLegend
                serverResponseComponent={serverResponseComponent}
            />
        </Box >
    )

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        urlGetRia: state.global.navigationLinks._links.getRia.href,
        urlGetAnaForRia: state.global.navigationLinks._links.getAnaForRia.href,
        structureId: state.global.structureId,
        nextPage: PAGES.modifyStaff
    }
};

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifyStaff)