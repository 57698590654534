import { Link } from "@insoft/lib-react-universal";
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import LinkAdapter from '@insoft/lib-react-web/dist/adapters/link-adapter';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { CheckedData, NotesForParentsData, StatusChange, Summary, SummaryCategoryItem } from "commons/interfaces/summary";

class CategoryItemAdapter implements SummaryCategoryItem {
    title: string
    items: string[]
    isNewData?: boolean
    constructor(object: SummaryCategoryItem) {
        this.title = object && object.title ? object.title : ''
        this.items = object && object.items ? object.items : []
        if (object && object.isNewData != undefined) {
            this.isNewData = object.isNewData
        }
    }
}

// class CustomizationOptionsAdapter implements CustomizationOptions {
//     positiveButtonLabel: string
//     negativeButtonLabel: string
//     confirmTitle: string
//     title: string
//     constructor(object: CustomizationOptions) {
//         this.positiveButtonLabel = object && object.positiveButtonLabel ? object.positiveButtonLabel : "Conferma"
//         this.negativeButtonLabel = object && object.negativeButtonLabel ? object.negativeButtonLabel : "Rifiuta"
//         this.confirmTitle = object && object.confirmTitle ? object.confirmTitle : "Conferma Domanda"
//         this.title = object && object.title ? object.title : undefined
//     }
// }

function isNestedObject(object: any) {
    return object && object.label && object.value
}

function isArray(object: any) {
    return Array.isArray(object)
}

function getOneLevelArray(arrayToParse: any[]) {
    let tempArray: any[] = []
    for (const a in arrayToParse) {
        if (isArray(arrayToParse[a].value)) {
            tempArray = tempArray.concat(getOneLevelArray(arrayToParse[a].value))
        } else {
            const newObj = arrayToParse[a]
            if (isNestedObject(newObj.value)) {
                newObj.value = newObj.value.label
            }
            tempArray.push(newObj)
        }
    }
    return tempArray
}

export default class SummaryAdapter implements Summary {
    _links: {
        self: Link
        confirm: Link
        reject: Link
        download: Link
        protocol: Link
        download_docx: Link
        confirmLetter: Link
        send_to_organization: Link
        letter: Link
        finish_internal: Link
        old_confirm_letters: Link[]
    }
    effectiveStartDate: string
    submissionDate: string
    status: string
    paymentStatus: string
    economicBand: number
    categories: SummaryCategoryItem[]
    title: string
    forms: FormObject[]
    checkedData?: CheckedData
    notesForParentsData?: NotesForParentsData
    statusChanges?: StatusChange[]
    // customizationOptions: CustomizationOptions

    constructor(object: any) {

        this._links = {
            self: object && object._links && object._links.self ? new LinkAdapter(object._links.self) : undefined,
            confirm: object && object._links && object._links.confirm ? new LinkAdapter(object._links.confirm) : undefined,
            reject: object && object._links && object._links.reject ? new LinkAdapter(object._links.reject) : undefined,
            download: object && object._links && object._links.download ? new LinkAdapter(object._links.download) : undefined,
            protocol: object && object._links && object._links.protocol ? new LinkAdapter(object._links.protocol) : undefined,
            download_docx: object && object._links && object._links.download_docx ? new LinkAdapter(object._links.download_docx) : undefined,
            confirmLetter: object && object._links && object._links.confirm_letter ? new LinkAdapter(object._links.confirm_letter) : undefined,
            letter: object && object._links && object._links.letter ? new LinkAdapter(object._links.letter) : undefined,
            send_to_organization: object && object._links && object._links.send_to_organization ? new LinkAdapter(object._links.send_to_organization) : undefined,
            finish_internal: object && object._links && object._links.finish_internal ? new LinkAdapter(object._links.finish_internal) : undefined,
            old_confirm_letters: undefined
        }
        this.effectiveStartDate = object && object.effectiveStartDate ? object.effectiveStartDate : undefined
        this.submissionDate = object && object.submissionDate ? object.submissionDate : undefined
        this.status = object && object.status ? object.status : undefined
        this.economicBand = object && object.economicBand != undefined ? object.economicBand : undefined
        this.paymentStatus = object && object.paymentStatus ? object.paymentStatus : undefined
        this.title = object && object.title ? object.title : undefined
        this.forms = object && object.forms ? object.forms.map((x: any) => { return new FormObjectAdapter(x) }) : undefined
        // this.customizationOptions = new CustomizationOptionsAdapter(object ? object.customizationOptions : undefined)
        this.checkedData = object && object.organizationCheckedData ? object.organizationCheckedData : undefined
        this.notesForParentsData = (object && object.requestConfirmData ? object.requestConfirmData : (object && object.requestRejectData ? object.requestRejectData : undefined))
        this.statusChanges = object && object.statusChanges ? object.statusChanges : undefined
        this.categories = []
        try {
            const content = object.content
            for (const category in content) {
                if (content.hasOwnProperty(category)) {
                    let isNewData
                    if (category.search("new") == 0) {
                        isNewData = true
                    } else if (category.search("old") == 0) {
                        isNewData = false
                    } else {
                        isNewData = undefined
                    }
                    this.categories.push(new CategoryItemAdapter({
                        title: content[category].title,
                        items: getOneLevelArray(content[category].items),
                        isNewData: isNewData
                    }))
                }
            }
        } catch (error) {
            logThis("Errore nell'Adapter Summary", error)
        }
        try {
            if (object._links?.old_confirm_letters) {
                var oldConfirmLetters: Link[] = []
                if (object._links.old_confirm_letters instanceof Array) {
                    object._links.old_confirm_letters.forEach(function (element: any) {
                        oldConfirmLetters.push(new LinkAdapter(element))
                    });
                } else {
                    oldConfirmLetters.push(new LinkAdapter(object._links.old_confirm_letters))
                }
                this._links.old_confirm_letters = oldConfirmLetters
            }

        } catch (error) {
            logThis("Errore nell'Adapter Summary", error)
        }

    }
}