import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';

interface Props {
    resources: string[]
}

export default class TosArea extends React.Component<Props, {}> {

    renderTosItem = (item: {
        label: string,
        _key: string
    }) => {
        return (
            <li key={item._key}>{item.label}</li>
        )
    }

    render() {
        if (!this.props.resources) {
            return null
        }
        let title = 'Dichiara'
        let resources = this.props.resources
        let arrayOfObjects = resources.map((item) => { return { label: item } })
        let tosItems = renderComponentsList(arrayOfObjects, this.renderTosItem)
        return (
            <div className='row'>
                <div className='col-md-12'>
                    <p>
                        <strong>{title}</strong>
                    </p>
                    <ul>
                        {tosItems}
                    </ul>
                </div>
            </div>
        )
    }
}