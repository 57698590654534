import Box from '@mui/material/Box';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { Icon } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';

interface StructureProps {
    structureInfo: StructureInfo
}

interface Props {
    helpSection?: any;
}

interface AllProps extends StructureProps, Props { }

export function FormHeaderComponent(props: AllProps) {

    const renderTitle = (item: { title: string, _key: string }) => {
        return (
            <span key={item._key}>{item.title}<br /></span>
        )
    }

    const renderIcon = (iconUri: Icon) => {
        return (
            <Box height="5em">
                <img style={{ height: "100%" }} src={iconUri.imgPath} />
            </Box>
        )
    }

    if (!props.structureInfo) {
        return null
    }
    const { contactsInfo, partnersLogos, name } = props.structureInfo
    try {
        var icon = partnersLogos[1]
    } catch (e) {
        // Icon not found
    }

    if (contactsInfo) {
        let itemsArray = contactsInfo.map((s: any) => { return ({ title: s }) })
        if (itemsArray.length == 0) {
            itemsArray.push({ title: name })
        }
        var infoView = renderComponentsList(itemsArray, renderTitle)
    } else {
        var infoView = renderComponentsList([{ title: name }], renderTitle)
    }
    if (icon) {
        var iconView = renderIcon(icon)
    }
    if (props.helpSection) {
        return (
            <Box>
                <Box className='col-xs-12' p={1} flexGrow={12}>
                    {props.helpSection}
                </Box>
                <Box display="flex" p={1}>
                    <Box p={1} flexGrow={1}>
                        <b>
                            {infoView}
                        </b>
                    </Box>
                    <Box p={1}>
                        {iconView}
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box display="flex" p={1}>
                <Box p={1} flexGrow={1}>
                    <b>
                        {infoView}
                    </b>
                </Box>
                <Box p={1}>
                    {iconView}
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): StructureProps => {
    return {
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(FormHeaderComponent)