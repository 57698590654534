import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GlobalStateInterface } from 'redux/store';
import { getTitleViewForPage } from 'utils';
import { PAGES } from 'utils/rest-routes';
import FormWithYearSelection from './index';
import { savePageHeaderInfo } from '../../redux/actions/global-actions';

interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
}


export function ServiziAggiuntivi(props: Props) {
    const { id, yearId } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId ? PAGES.templateServiziAggiuntivi : PAGES.serviziAggiuntivi, structureInfo: props.structureInfo, id: yearId ? yearId : id })
        dispatch(savePageHeaderInfo(pageInfo))
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])


    return (
        <FormWithYearSelection
            id={id}
            yearId={yearId}
            nextPage={yearId == null ? PAGES.serviziAggiuntiviRiepilogo : undefined}
            defaultFormTitle={"Richiesta servizi aggiuntivi"}
            yearSelectionUri={props.navigationLinks._links.newAdditionalServiceForm ? props.navigationLinks._links.newAdditionalServiceForm.href : undefined}
            resourceUri={yearId == null ? props.navigationLinks._links.additionalServiceForm.href : props.navigationLinks._links.additionalServiceTemplate.href}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(ServiziAggiuntivi)