import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import { baseInitApp } from "@insoft/lib-react-web/dist/config/base-app-initializer";

export function initApp() {
    // DefaultMaterialTheme.setApplicationTheme(NOVAE_PALETTE);

    initRum()
    baseInitApp()


    // Kendo
    // require('@progress/kendo-theme-default/dist/all.css')
    require('@progress/kendo-theme-core/dist/all.css')
    // require('@progress/kendo-theme-material/dist/all.css')
    require('@progress/kendo-theme-bootstrap/dist/all.css')

    // @progress/kendo-ui dependency is not required here, because it causes the vendors.js file to become very large.
    // But, if localization file must be added, probably this dependency becomes mandatory
    // https://docs.telerik.com/kendo-ui/globalization/localization
    // require('@progress/kendo-ui')
    // require('@progress/kendo-ui/js/messages/kendo.messages.it-IT.js')
    // require('@progress/kendo-ui/js/cultures/kendo.culture.it-IT.js')
    // kendo.culture("it-IT") // Warning: kendo is not resolved as namespace

    // Bootstrap Toggle
    // require('node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css')

    // Insoft
    require('public/dist/css/insoft.less')
}


function initRum() {
    try {
        const config: AwsRumConfig = {
            sessionSampleRate: 1,
            endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
            telemetries: ["errors", "http"],
            allowCookies: true,
            enableXRay: true
        };

        const APPLICATION_ID: string = '5c2241f8-d288-4477-bee6-e23f116d2296';
        const APPLICATION_VERSION: string = '1.0.0';
        const APPLICATION_REGION: string = 'eu-central-1';

        const awsRum: AwsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
        );
        awsRum.recordError(new Error('prova'))
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
        console.warn('cannot init RUM ' + error)
    }
}