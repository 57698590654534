import { Navigation } from 'commons/interfaces/navigation';
import { MessageInterface, Messages } from '@insoft/lib-react-universal/dist/interfaces/message-interface';
import { getMessageByParam } from '@insoft/lib-react-universal/dist/redux/redux-utils';
import { generateUUID, logThis } from '@insoft/lib-react-universal/dist/utils';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import Modal, { ModalType } from '@insoft/lib-react-web/dist/components/misc/modal';
import { checkSession, executePerpetualRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { addMessage, removeMessage, startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, getTotemContentHeight, isTabSelected } from 'utils';
import { STRINGS, TOTEM_CONSTANTS } from 'utils/constants';
import { PAGES, REST_ENDPOINTS } from 'utils/rest-routes';
import NewsComponent from '../landing-page/news';
import DailyReport from './daily-report';
import DocumentsList from './documents-list';
import TabsContainer from './tabs-component';

declare var android: {
    disconnect: () => any
    sendFirebaseToken: () => any
}

interface ActionCreatorsProps {
    addMessage: (message: MessageInterface) => any
    removeMessage: (message: MessageInterface) => any
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    navigationLinks: Navigation
    messages: Messages
    loading: boolean
    selectTotemTab: string
}

interface AllProps extends ActionCreatorsProps, Props { }

class Totem extends React.Component<AllProps, null> {

    componentDidMount() {
        if (!this.props.navigationLinks._links.children) {
            this.props.addMessage({ id: generateUUID(), message: STRINGS.ERROR_TOTEM_USER_NOT_AUTHORIZED, target: PAGES.totem })
        }
        this.sendFirebaseToken()
        this.startKeepAliveSession()
    }

    sendFirebaseToken = () => {
        // Richiama la sync del token firebase nell'App Android
        try {
            android.sendFirebaseToken()
        } catch (e) {
            logThis(e)
        }
    }

    startKeepAliveSession = () => {
        function keepAliveFunction() {
            checkSession(REST_ENDPOINTS.CHECK_AUTHENTICATION)
        }
        const perpetualInterval = 1000 * 60 * 5  // 5 minutes
        executePerpetualRequest(keepAliveFunction, perpetualInterval)
    }

    onWrongUserDismiss = (message: MessageInterface) => {
        this.props.removeMessage(message)

        // Richiama la disconnessione dell'utente nell'App Android
        try {
            android.disconnect()
        } catch (e) {
            logThis(e)
        }
    }

    renderMessageView = (message: MessageInterface) => {
        return (
            <div className="news-wrapper">
                <Modal
                    title="Errore"
                    message={message.message}
                    onDismiss={() => { this.onWrongUserDismiss(message) }}
                    modalType={ModalType.ERROR}
                />
            </div>
        )
    }

    render() {
        const message = getMessageByParam(this.props.messages, "target", PAGES.totem)
        if (message) {
            return this.renderMessageView(message)
        }

        if (this.props.loading) {
            var loadingComponent = getLoadingComponent(true)
        }

        if (isTabSelected(TOTEM_CONSTANTS.NEWS_TAB, this.props.selectTotemTab)) {
            var content = (
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-10 col-xs-offset-1 news-container" style={{ height: getTotemContentHeight() }}>
                            <NewsComponent
                                autoplay
                            />
                        </div>
                    </div>
                </div>
            )
        } else if (isTabSelected(TOTEM_CONSTANTS.DIARIES_TAB, this.props.selectTotemTab)) {
            content = (
                <DailyReport />
            )
        } else if (isTabSelected(TOTEM_CONSTANTS.DOCUMENTS_TAB, this.props.selectTotemTab)) {
            content = (
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-10 col-xs-offset-1 news-container" style={{ height: getTotemContentHeight() }}>
                            <DocumentsList />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div id="totem-container">
                {loadingComponent}
                <TabsContainer />
                <ErrorBoundary>
                    {content}
                </ErrorBoundary>
            </div>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        messages: state.global.messages,
        loading: state.global.isLoading,
        selectTotemTab: state.global.selectedTotemTab,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        addMessage: bindActionCreators(addMessage, dispatch),
        removeMessage: bindActionCreators(removeMessage, dispatch),
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Totem)