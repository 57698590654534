import { BreadCrumb, MenuLink } from "@insoft/lib-react-mui-web";
import DeleteIcon from '@mui/icons-material//Delete';
import AddIcon from '@mui/icons-material/Add';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CodeIcon from '@mui/icons-material/Code';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CreateIcon from '@mui/icons-material/Create';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import GroupIcon from '@mui/icons-material/Group';
import HistoryIcon from '@mui/icons-material/History';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewListIcon from '@mui/icons-material/ViewList';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { NavigateFunction } from "react-router";
import { getFunctionName } from "utils";
import { REGISTRATION_TYPE } from "utils/constants";
import { MULTI_STRUCTURE_MODULE_PAGES, PAGES } from "utils/rest-routes";
import { Navigation } from "./interfaces/navigation";
import { StructureInfo } from "./interfaces/structure-info";


export function getPageTitleInfo(currentPage: string, menuLinks?: MenuLink[], navigate?: NavigateFunction): {
    title: string,
    breadcrumbs?: BreadCrumb[]
} {
    if (currentPage.startsWith(PAGES.organizationChange)) {
        return {
            title: 'Cambia struttura',
            breadcrumbs: [{
                title: 'Pagina precedente',
                action: () => {
                    navigate(-1)
                },
                icon: ListIcon
            }, {
                title: "Cambia struttura"
            }]
        }
    } else if (currentPage.startsWith(PAGES.newsDetails)) {
        return {
            title: 'Dettaglio News',
            breadcrumbs: [{
                title: "News",
                url: PAGES.tableNews,
                icon: ListIcon
            }, {
                title: "Dettaglio News"
            }]
        }
    } else if (currentPage.startsWith(PAGES.newsModify)) {
        return {
            title: 'Modifica News',
            breadcrumbs: [{
                title: "News",
                url: PAGES.tableNews,
                icon: ListIcon
            }, {
                title: "Modifica News"
            }]
        }
    }
    return {
        title: evaluatePageTitle(currentPage, menuLinks)
    }
}

function evaluatePageTitle(currentPage: string, menuLinks?: MenuLink[]): string {
    const title = getTitle(currentPage, menuLinks);
    switch (currentPage) {
        case MULTI_STRUCTURE_MODULE_PAGES.cobertura:
            return 'Dahsboard multistruttura'
        case PAGES.tableNews: 
            return 'News'
        case PAGES.appSimulator:
            return 'Simulazione applicazione dei genitori'
        case PAGES.newStaff:
            return 'Nuovo personale'
        case PAGES.dashboard:
            return 'Home'
    }
    if (currentPage ==  PAGES.nuovaIscrizioneFrequenza) {
        return title + " (1/2)"
    }
    if (currentPage ==  PAGES.nuovaIscrizioneDichiarazioni) {
        return title + " (2/2)"
    }
    if (currentPage.startsWith(PAGES.modifyStaff) || currentPage.startsWith(PAGES.newStaff)) {
        return "Scheda personale"
    }
    if (title) {
        return title;
    }
        
    return undefined;
}

export function getTitle(currentPage: string, menuLinks?: MenuLink[]): string | undefined {
    if (menuLinks == undefined) {
        return undefined
    }
    const link = menuLinks.find(i => i.href == currentPage);
    if (link) {
        return link.name
    }
    return menuLinks.map(i => getTitle(currentPage, i.subItems)).find(i => i != undefined)
}

export function evaluateLinks(navigation: Navigation, structureInfo: StructureInfo): MenuLink[] {
    let menuLinks: MenuLink[] = []
    const links = navigation?._links
    if (navigation?._links == undefined || Object.keys(navigation._links).length == 0) {
        return undefined
    }

    if (navigation.isOperator) {
        // operatore
        const requestsLink = operatorRequestsLinks(navigation);
        if (requestsLink) {
            menuLinks.push(requestsLink)
        }
        
        const newsLinks = operatorNewsLinks(navigation);
        if (newsLinks) {
            menuLinks.push(newsLinks)
        }

        const calendarLinks: MenuLink = operatorCalendarLinks(navigation);
        if (calendarLinks) {
            menuLinks.push(calendarLinks)
        }

        const reportLinks: MenuLink = operatorReportLinks(navigation);
        if (reportLinks) {
            menuLinks.push(reportLinks)
        }

        if (navigation._links?.accountHolders)
        menuLinks.push({
            id: 'account-holder',
            name: 'Intestatari Fatture',
            href: PAGES.accountHolders,
            icon: ContactPageIcon
        })


        
        // TODO: verificare se usare un'altro
     if (links.parentYears) {
        menuLinks.push({
            id: 'parent-app',
            name: 'Simulatore AppGenitori',
            href: PAGES.appSimulator,
            icon: SmartphoneIcon
        })
     }

        if (links.getStaff) {
            menuLinks.push({
                id: 'staff',
                name: 'Personale',
                href: PAGES.getStaff,
                icon: GroupIcon
            })
        }
        const settings = operatorSettingsLinks(navigation);
        if (settings) {
            menuLinks.push(settings)
        }
             
    } else {
        dashboardLink(navigation, menuLinks);
        userRequestsLinks(navigation, menuLinks, structureInfo);
    }
   
    return menuLinks.length == 1 && menuLinks[0].subItems ? menuLinks[0].subItems : menuLinks;
}



function dashboardLink(navigation: Navigation, menuLinks: MenuLink[]) {
    const links = navigation._links
    if (links.dashboard || links.elencoIscrizioni) {
        menuLinks.push({
            id: 'dashboard',
            name: navigation.isOperator ? 'Richieste in lavorazione' : 'I miei dati',
            href: PAGES.dashboard,
            pathToCheck: (current: string) => current == PAGES.dashboard,
            icon: DashboardIcon
        });
    }
}

function userRequestsLinks(navigation: Navigation, menuLinks: MenuLink[], structureInfo: StructureInfo) {
    const links = navigation._links
    if (links.newEnrollmentForm) {
        menuLinks.push({
            id: 'new-enrollment',
            name: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, structureInfo),
            href: PAGES.nuovaIscrizioneFrequenza,
            pathToCheck: PAGES.nuovaIscrizione,
            icon: AddIcon
        });
    }
    if (links.newDataChangeForm) {
        menuLinks.push({
            id: 'data-change',
            href: PAGES.cambioDati,
            name: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, structureInfo),
            icon: CreateIcon
        });
    }

    if (links.newFrequencyChangeForm) {
        menuLinks.push({
            id: 'frequency-change',
            href: PAGES.modificaIscrizione,
            name: getFunctionName(REGISTRATION_TYPE.MODIFICA, structureInfo),
            icon: AddIcon
        });
    }

    if (links.newAdditionalServiceForm) {
        menuLinks.push({
            id: 'addtional-service',
            href: PAGES.serviziAggiuntivi,
            name: getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, structureInfo),
            icon: AddIcon
        });
    }

    if (links.newEnrollmentChangeForm) {
        menuLinks.push({
            id: 'enrollment-change',
            href: PAGES.modificaInizioFineFrequenza,
            name: getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, structureInfo),
            icon: AddIcon
        });
    }

    if (links.newChangeIseeForm) {
        menuLinks.push({
            id: 'isee-change',
            href: PAGES.cambioIsee,
            name: getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, structureInfo),
            icon: AddIcon
        });
    }

    if (links.invoicesList) {
        menuLinks.push({
            id: 'invoices',
            href: PAGES.listInvoices,
            name: 'Fatture',
            icon: ReceiptIcon
        });
    }

    if (links.documentsList) {
        menuLinks.push({
            id: 'documents',
            href: PAGES.listDocuments,
            name: 'Documenti',
            icon: DescriptionIcon
        });
    }
}

function operatorRequestsLinks(navigation: Navigation): MenuLink {
    const links = navigation._links;
    const subItems: MenuLink[] = []
    dashboardLink(navigation, subItems);
    if (links.organizationWaitingList) {
        subItems.push({
            id: 'waiting-list',
            name: 'Lista di attesa',
            href: PAGES.listaAttesa,
            pathToCheck: PAGES.listaAttesa,
            icon: ViewListIcon
        })
    }
    if (links.organizationRequestsHistory) {
        subItems.push({
            id: 'history',
            name: 'Storico Richieste',
            href: PAGES.storicoRichieste,
            pathToCheck: PAGES.storicoRichieste,
            icon: HistoryIcon
        })
    }

         if (links.organizationInFilling) {
            subItems.push({
                id: 'in-filling',
                name: 'Richieste in compilazione',
                href: PAGES.richiesteInCompilazione,
                pathToCheck: PAGES.richiesteInCompilazione,
                icon: ModeEditIcon
            })
    }

     if (links.requestBin) {
        subItems.push({
            id: 'bin',
            name: 'Richieste eliminate',
                href: PAGES.requestBin,
                pathToCheck: PAGES.requestBin,
                icon: DeleteIcon
        })
     }
     if (links.listYearTemplates) {
        subItems.push({
            id: 'previw-modules',
            name: 'Preview moduli',
                href: PAGES.yearTemplate,
                pathToCheck: PAGES.yearTemplate,
                icon: CodeIcon
        });
    }
     if (subItems.length > 0) {
        return {
            
                id: 'requests',
                name: 'Portale Iscrizioni',
                    pathToCheck: (currentPath: string) => (currentPath == '/') || currentPath.startsWith(PAGES.richieste),
                    icon: LibraryBooksIcon,
                    subItems: subItems
        }
     }
}

function operatorNewsLinks(navigation: Navigation) {
    const links = navigation._links;
    const subItems: MenuLink[] = []
    if (links.changeNews) {
        subItems.push({
            id: 'news#edit',
            name: 'Modifica news',
            href: PAGES.tableNews,
            pathToCheck: PAGES.tableNews,
            icon: RateReviewIcon
        })
    }
         if (links.newsReadUsers) {
            subItems.push({
                id: 'news#readed',
                name: 'News Lette',
                href: PAGES.readNews,
                pathToCheck: PAGES.readNews,
                icon: MarkChatReadIcon
            })
     }
    
    if (subItems.length > 0) {
        return {
            
                id: 'news',
                name: 'News',
                    pathToCheck: PAGES.news,
                    icon: ChatBubbleIcon,
                    subItems: subItems
        }
     }

}


function operatorCalendarLinks(navigation: Navigation): MenuLink  {
    const links = navigation._links;
    if (links.calendar) {
        return {
            id: 'calendar',
            name: 'Calendario colloqui',
            href: PAGES.calendar,
            pathToCheck: PAGES.calendar,
            icon: EventAvailableIcon
        }
    }
    return undefined

}

function operatorReportLinks(navigation: Navigation): MenuLink  {
    const links = navigation._links;
    if (links.organizationPresencesReport) {
        return {
            id: 'report',
            name: 'Report Presenze',
            href: PAGES.reportPresenze,
            pathToCheck: PAGES.reportPresenze,
            icon: TableViewIcon
        }
    }
    return undefined

}


function operatorSettingsLinks(navigation: Navigation) {
    const links = navigation._links;
    const subItems: MenuLink[] = []
        if (links.changeOrganizationDetails) {
            subItems.push({
                id: 'settings#edit',
                name: 'Modifica descrizioni portale',
                href: PAGES.contentManagerDescriptions,
                icon: WebAssetIcon
            })
        if (links.changeParameters) {
            subItems.push({
                id: 'settings#waiting-list',
                name: 'Configura lista attesa',
                href: PAGES.changeParameters,
                icon: ListAltIcon
            })
         }
             if (links.adminOrganizations) {
                subItems.push({
                    id: 'settings#admin-orgs',
                    name: 'Admin dashboard',
                    href: PAGES.admin,
                    icon: AdminPanelSettingsIcon
                })
     }
     }
    
    if (subItems.length > 0) {
        const toCheckFunction = (current: string) => subItems.findIndex(item => item.href == current) != -1
        return {
            
                id: 'settings',
                name: 'Configurazione',
                    pathToCheck: toCheckFunction,
                    icon: SettingsIcon,
                    subItems: subItems
        }
     }

}







// const { navigationLinks } = props
// if (!navigationLinks) {
//     return null
// }
// const links = navigationLinks._links
// let categoriesArray = []
// let locationPath = location.pathname

// let navigationLiks = []
// let linkModule = []


// let moduleName;
// if (locationPath.startsWith(MODULE_ROOTS.multiStructureModule)) {
//     moduleName = 'Dashboard Multistruttura'

//     if (links.getStaff) {
//         navigationLiks.push(renderItem({
//             active: isHomeLinkActive([MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences]),
//             href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
//             title: 'Report presenze',
//             leftIcon: DashboardIcon
//         }))
//     }

//     if (links.dashboardPresences) {
//         navigationLiks.push(renderItem({
//             active: isHomeLinkActive([MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences]),
//             href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
//             title: 'Report presenze',
//             leftIcon: DashboardIcon
//         }))
//     }
    
//     if (props.open) {
//         linkModule.push(renderItem({
//             active: isModuleActive(false),
//             href: PAGES.dashboard,
//             title: 'Modulo Iscrizioni'
//         }))
//     }


// } else {
//     // modulo iscrizioni
//     navigationLiks.push(evaluateEnrollmentModuleLinks());
//     moduleName = 'Iscrizioni'

//     const multiStructureModule = links.dashboardPresences != undefined || links.authorizedStructures != undefined || links.presencesReport != undefined

//     if (multiStructureModule && props.open) {
//         linkModule.push(renderItem({
//             active: isModuleActive(false),
//             href: MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences,
//             title: 'Gestione strutture'
//         })
//         )
//     }
// }

// if (links.changePassword) {
//     categoriesArray.push(renderItem({
//         active: isHomeLinkActive([PAGES.changePassword]),
//         href: PAGES.changePassword,
//         title: 'Cambia Password',
//         leftIcon: LockIcon
//     }))
//     categoriesArray.push((<Divider key={generateUUID()} />))
// }



// if (linkModule.length > 0 && props.open) {
//     categoriesArray.push(
//         <ListSubheader disableSticky={true} id="entrollements-list-subheader" key={generateUUID()} className={classes.ItemLabelText}>
//             {moduleName.toUpperCase()}
//         </ListSubheader>
//     )
// }

// categoriesArray.push(navigationLiks);

// if (linkModule.length > 0 && props.open) {
//     categoriesArray.push(<Divider key={generateUUID()} />)
//     categoriesArray.push(
//         <ListSubheader id="others-list-subheader" key={generateUUID()} className={classes.ItemLabelText} sx={{ backgroundColor: 'transparent' }} >
//             ALTRI MODULI
//         </ListSubheader>
//     )
//     categoriesArray.push(linkModule)
// }
