import { generateUUID } from "@insoft/lib-react-universal";

export const children = [{
    id: generateUUID(),
    child: 'Luna Bianca',
    parent: 'Luna Mario'
}, {
    id: generateUUID(),
    child: 'Rossi Valeria',
    parent: 'Bianchi Marina'
}, {
    id: generateUUID(),
    child: 'Verdi Luca',
    parent: 'Rosso Lucilla'
}, {
    id: generateUUID(),
    child: 'Blu Stella',
    parent: 'Neri Mara'
},
{
    id: generateUUID(),
    child: 'Neri Sara',
    parent: 'Neri Stefano'
},
{
    id: generateUUID(),
    child: 'Verdi Carla',
    parent: 'Bianchi Martina'
}
]

export interface Children {
    child: string,
    parent: string
}

export interface CalendarChildItem {
    id: string,
    start: Date,
    end: Date,
    title: string,
    child: Children
}

const randomElement = (items: any[]): any => {
    return items[Math.floor(Math.random() * items.length)];
}

export function generateRandomCalendarItems(dates: string[]): CalendarChildItem[] {
    return dates.map((startDateString) => {
        const startDate = new Date(startDateString);
        const child = randomElement(children)
        return {
            id: generateUUID(),
            start: startDate,
            end: new Date(startDate.getTime() + (30 * 60000)),
            title: child.child,
            child: child
        }
    })
}