import IconRadioField, { ICON_RADIO_FIELD_NAME } from 'commons/components/forms/icon-radio-field';
import { FieldProps, UiSchema } from '@insoft/lib-react-web';
import { StyleRulesObject } from '@insoft/lib-react-web';
import { COMMONS_REPRESENTATION_KEYS, FORM_INPUT_DEFAULT_WIDGETS, FORM_INPUT_FORMAT, FORM_LIST_TYPE, JSON_SCHEMA_TYPES, represents } from '@insoft/lib-react-web';
import { createDangerousInnerHtml } from '@insoft/lib-react-web';
import React from 'react';
import { REPRESENTS } from 'utils/constants';
import CustomNumericWidget from 'commons/components/forms/step-numeric-widget';
import { baseFormStyleRules, getBaseFields, getBaseWidgets } from '@insoft/lib-react-web';
import { FileUrlField, ComboBoxField, AutocompleteListField, ArrayExternalPickerField, DropdownListField, SwitchBooleanField } from '@insoft/lib-react-web';
import { SWITCH_BOOLEAN_FIELD_NAME } from '@insoft/lib-react-web';

export function commonFormStyleRules(key: string, object: any): StyleRulesObject {
    let uiObject: UiSchema = baseFormStyleRules(key, object).value

    if (object.type !== JSON_SCHEMA_TYPES.OBJECT) {
        uiObject['ui:classNames'] = 'col-xs-12 col-sm-6 col-lg-4'
    }

    if (object.type === JSON_SCHEMA_TYPES.NUMBER) {
        uiObject['ui:widget'] = CustomNumericWidget.FIELD_NAME
        uiObject['ui:placeholder'] = '0.00'
        uiObject['ui:help'] = 'Inserisci il numero con il solo punto come separatore dei decimali'
    }

    if (object.type === JSON_SCHEMA_TYPES.ARRAY) {
        uiObject['ui:widget'] = FORM_INPUT_DEFAULT_WIDGETS.CHECKBOXES
        uiObject['ui:options'] = { inline: true }

    }
    if (object.type === JSON_SCHEMA_TYPES.BOOLEAN) {
        if (object.represents === REPRESENTS.DISABLE_SECTION) {
            uiObject['ui:classNames'] = ''
            uiObject['ui:field'] = SWITCH_BOOLEAN_FIELD_NAME
        } else {
            uiObject['ui:widget'] = FORM_INPUT_DEFAULT_WIDGETS.RADIO
            uiObject['ui:classNames'] = 'radio-area-height col-xs-12 col-sm-6'
        }
    }
    if (represents(object.format, FORM_INPUT_FORMAT.FILE)) {
        uiObject['ui:classNames'] = 'col-xs-12'
    }

    if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.FILE_URL)) {
        uiObject['ui:classNames'] = FileUrlField.FIELD_NAME
    }

    if (represents(object.represents, FORM_INPUT_FORMAT.FILE)) {
        uiObject['ui:classNames'] = 'col-xs-12'
    }

    if (represents(object.represents, REPRESENTS.PARENT_LIST)) {
        /// object.items['ui:classNames'] = (object.items['ui:classNames'] || '') + ' no-title'
        object.items.noTitle = true
        delete uiObject['ui:widget'];
        uiObject['ui:classNames'] = 'no-title'
        uiObject['ui:options'] = {
            orderable: false,
            removable: true,
            addable: true
        }
    }

    if (represents(object.represents, REPRESENTS.PRIVACY)) {
        uiObject['ui:field'] = (props) => {
            let required = props.required ? '*' : undefined
            return (
                <label>
                    <input
                        checked={props.formData}
                        type="checkbox"
                        required={props.required}
                        onChange={
                            (event) => {
                                props.onChange(event.target.checked)
                            }}
                    />
                    <span dangerouslySetInnerHTML={createDangerousInnerHtml(props.schema.title)} />
                    {required}
                </label>
            )
        }
    }

    if (represents(object.represents, COMMONS_REPRESENTATION_KEYS.PHONE)) {
        // TODO: creare widget per phone https://text-mask.github.io/text-mask/
        uiObject['ui:options'] = { inputType: 'tel' }
    }
    if (represents(object.format, FORM_INPUT_FORMAT.TEXTAREA)) {
        uiObject['ui:classNames'] = 'col-xs-12'
    }
    if (represents(object.represents, 'turns')) {
        uiObject['ui:classNames'] = 'col-xs-12'
        uiObject['ui:field'] = ICON_RADIO_FIELD_NAME
    }
    if (represents(object.represents, 'textarea')) {
        uiObject['ui:classNames'] = 'col-xs-12'
        uiObject['ui:widget'] = 'textarea'
    }
    // if (object.autocomplete) {
    //     uiObject['ui:field'] = ComboBoxField.FIELD_NAME
    //     uiObject.customUrl = object.autocomplete.href
    //     uiObject.customAllowCustomValues = object.autocomplete.otherValues
    // }
    // if (object.represents === FORM_INPUT_DEFAULT_WIDGETS.LIST) {
    //     uiObject['ui:field'] = AutocompleteListField.FIELD_NAME
    // }


    // List fields
    if (represents(object.represents, FORM_INPUT_DEFAULT_WIDGETS.LIST)) {
        uiObject['ui:field'] = AutocompleteListField.FIELD_NAME
    }
    if (represents(object.represents, FORM_LIST_TYPE.COMBO_BOX)) {
        uiObject.customListComponentType = ComboBoxField.FIELD_NAME
    }
    if (represents(object.represents, FORM_LIST_TYPE.DROPDOWN_LIST)) {
        uiObject.customListComponentType = DropdownListField.FIELD_NAME
        uiObject['ui:placeholder'] = object.placeholder
    }
    if (object.autocomplete) {
        uiObject.customUrl = object.autocomplete.href
        uiObject.customAllowCustomValues = object.autocomplete.otherValues
        uiObject.customFilterKey = object.autocomplete.filterKey
    }

    if (object.classNames) {
        uiObject['ui:classNames'] = object.classNames
    }

    if (object['ui:classNames']) {
        uiObject['ui:classNames'] = object['ui:classNames']
    }

    if (object.uiorder) {
        uiObject["ui:order"] = object.uiorder;
    }

    if (object.customFilterable) {
        uiObject["customFilterable"] = object.customFilterable
    }

    if (object.represents === 'password') {
        uiObject['ui:widget'] = 'password'
    }

    return { key: key, value: uiObject }
}

export function getCommonFields() {
    let outputObject: any = getBaseFields()
    outputObject[ICON_RADIO_FIELD_NAME] = IconRadioField
    outputObject[SWITCH_BOOLEAN_FIELD_NAME] = SwitchBooleanField
    outputObject[ComboBoxField.FIELD_NAME] = ComboBoxField
    outputObject[AutocompleteListField.FIELD_NAME] = AutocompleteListField
    outputObject[ArrayExternalPickerField.FIELD_NAME] = ArrayExternalPickerField
    return outputObject
}

export function getCommonWidgets() {
    let outputObject = getBaseWidgets()
    outputObject[CustomNumericWidget.FIELD_NAME] = CustomNumericWidget
    return outputObject;
}
