import { process } from '@progress/kendo-data-query';
import { State } from '@progress/kendo-data-query/dist/npm/state';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridToolbar, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { IntlProvider, IntlService, load, LocalizationProvider } from '@progress/kendo-react-intl';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import caGregorian from 'cldr-dates-full/main/it/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/it/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/it/timeZoneNames.json';
import currencies from 'cldr-numbers-full/main/it/currencies.json';
import numbers from 'cldr-numbers-full/main/it/numbers.json';
import { ComboBoxData } from '@insoft/lib-react-web/dist/components/form-components/combo-box-field';
import React, { useState } from 'react';
import { HistoryFilters } from 'redux/actions/global-actions';
import itMessages from './it-grid.json';
import { BoxStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme'
import { loadItalianMessagesForKendo } from '@insoft/lib-react-web'
import Box from '@mui/material/Box'
import clsx from 'clsx'


loadItalianMessagesForKendo()

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);

interface Locale {
    language: string,
    locale: string
}

interface Props {
    requestUrlDashBoard?: string
    requestUrlYear?: string
    requestUrlState?: string
    requestUrlType?: string
    loading?: boolean
    historyFilters?: HistoryFilters
    data: any,
    title?: string
    structureData?: ComboBoxData[]
    searchCallBack?: () => void
    linkStructure?: string

}

const DATE_FORMAT = 'MM-yyyy';
const intl = new IntlService("it");

export default function MultiStructureGrid(props: Props) {

    const classesBox = BoxStyles();

    const locales: Locale[] = [
        {
            language: 'it-ita',
            locale: 'it'
        }
    ]

    var dataGrid = props.data

    dataGrid.forEach((o: any) => {
        o.month = intl.parseDate(o.month, DATE_FORMAT);
    });

    const dataStateDefault: State = {
        skip: 0,
        take: 25,
        sort: [
            { field: 'month', dir: 'asc' as const }
        ],
        group: [
            { field: 'nursery' }
        ]
    };

    let excelExportCopy = JSON.parse(JSON.stringify(dataStateDefault))
    excelExportCopy.take = 1000


    const [state, setState] = useState({
        dataResult: process(dataGrid, dataStateDefault),
        dataState: dataStateDefault,
        currentLocale: locales[0],
        excelExportState: excelExportCopy
    })

    const dataStateChange = (event: GridDataStateChangeEvent) => {
        //TODO Trovare un modo per fare la deep copy di event senza l'errore di struttura circolare con JSON stringify
        var excelState: State = {};

        excelState.take = 1000;
        excelState.filter = event.dataState.filter
        excelState.group = event.dataState.group
        excelState.skip = event.dataState.skip
        excelState.sort = event.dataState.sort

        setState({
            ...state,
            dataResult: process(props.data, event.dataState),
            dataState: event.dataState,
            excelExportState: excelState
        })
    }

    const expandChange = (event: { dataItem: { expanded: boolean; aggregates: any; }; }) => {
        const isExpanded =
            event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;

        setState({ ...state })
    }

    var _pdfExport: GridPDFExport;
    const exportExcel = () => {
        _export.save();
    }

    var _export: ExcelExport;
    const exportPDF = () => {
        _pdfExport.save(props.data);
    }

    return (

        <div className="col-sm-12">
            <Box className={clsx(classesBox.box, classesBox.box_primary)}>
                <Box className={clsx(classesBox.box_body, classesBox.table_responsive, classesBox.no_padding)}>
                    <LocalizationProvider language={state.currentLocale.language}>
                        <IntlProvider locale={state.currentLocale.locale} >
                            <div>
                                {/*<ExcelExport
                                    data={process(props.data, state.excelExportState).data}
                                    ref={exporter => (_export = exporter)}
                                    group={state.dataState.group}
                                >
                                    <Grid
                                        style={{ height: '100%', width: "100%" }}
                                        sortable
                                        filterable
                                        groupable
                                        reorderable
                                        pageable={{ buttonCount: 4, pageSizes: [25, 50, 100] }}
                                        data={process(props.data, state.dataState)}
                                        {...state.dataState}
                                        onDataStateChange={dataStateChange}
                                        expandField="expanded"
                                        onExpandChange={expandChange}
                                    >
                                        <GridToolbar>
                                            <button
                                                title="Export to Excel"
                                                className="k-button k-primary"
                                                onClick={exportExcel}
                                            >
                                                Esporta file Excel
                                            </button>&nbsp;

                                            <button className="k-button k-primary"
                                                onClick={exportPDF}
                                            >
                                                Esporta file PDF
                                            </button>
                                        </GridToolbar>
                                        <GridColumn field="month" title="Anno" width="300px" format="{0: MM-yyyy}" filter="date" />
                                        <GridColumn field="nursery" title="Nido" format="{0:D}" width="200px" />
                                        <GridColumn field="days" title="Giorni" filter="numeric" width="200px" />
                                        <GridColumn field="presenceAverage" title="Media presenze" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="presenceRate" title="Tasso di presenza" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="absenceRate" title="Tasso di assenza" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="coverage" title="" width="220px" />
                                        <GridColumn field="presences" title="Presenze" width="220px" />

                                    </Grid>
    </ExcelExport>*/}
                                {/*<GridPDFExport
                                    ref={pdfExport => _pdfExport = pdfExport}
                                    margin="1cm"
                                >
                                    {<Grid style={{ width: "100%" }} data={process(props.data, { skip: state.dataState.skip, take: state.dataState.take })} >
                                        <GridColumn field="month" title="Anno" width="300px" format="{0: MM-yyyy}" filter="date" />
                                        <GridColumn field="nursery" title="Nido" format="{0:D}" width="200px" />
                                        <GridColumn field="days" title="Giorni" filter="numeric" width="200px" />
                                        <GridColumn field="presenceAverage" title="Media presenze" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="presenceRate" title="Tasso di presenza" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="absenceRate" title="Tasso di assenza" format="{0:p2}" filter="numeric" width="220px" />
                                        <GridColumn field="coverage" title="" width="220px" />
                                        <GridColumn field="presences" title="Presenze" width="220px" />
                                    </Grid>}
    </GridPDFExport>*/}
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                </Box>
            </Box>
        </div>
    );
}

