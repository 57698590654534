import classNames from 'classnames';
import { SliderData } from 'commons/interfaces//slider-data';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces//component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';
import { BoxStyles, CarouselStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles, useTheme } from '@mui/material/styles';



interface Props {
    sliderData: SliderData[]
}

export default function Slider(props: Props) {

    const theme = useTheme();
    const isScreenXL = useMediaQuery(theme.breakpoints.up('xl'));
    const isScreenLG = useMediaQuery(theme.breakpoints.up('lg'));
    const isScreenMD = useMediaQuery(theme.breakpoints.up('md'));
    const isScreenSM = useMediaQuery(theme.breakpoints.up('sm'));
    const isScreenXS = useMediaQuery(theme.breakpoints.up('xs'));

    if (isScreenXL) {
        var heightScreenResponsive = "500px !important"
    } else if (isScreenLG) {
        heightScreenResponsive = "400px !important"
    } else if (isScreenMD) {
        heightScreenResponsive = "350px !important"
    } else if (isScreenSM) {
        heightScreenResponsive = "300px !important"
    } else if (isScreenXS) {
        heightScreenResponsive = "250px !important"
    } else {
        heightScreenResponsive = "100%"
    }



    const classesBox = BoxStyles();
    const classesCarousel = CarouselStyles();


    const renderIndicators = (item: SliderData & ComponentListItem) => {
        if (item._itemIndex === 0) {
            var active = 'active'
        }
        return (
            <li data-target='#carousel-example-generic' data-slide-to={item._itemIndex} className={active} key={item._key} />
        )
    }

    const renderImg = (item: SliderData & ComponentListItem) => {
        let cssClass = 'item'
        if (item._itemIndex === 0) {
            cssClass = classNames(cssClass, 'active')
        }
        let imgElement = (
            <img src={item.imgPath} alt='image of the slider' style={{
                height: heightScreenResponsive,
                display: "block",
                marginRight: "auto",
                marginLeft: "auto"
            }} />
        )
        let imgView = item.link ? (<a href={item.link}> {imgElement} </a>) : imgElement
        return (
            <Box className={cssClass} key={item._key}>
                {imgView}
                <div className='carousel-caption'>{item.caption}</div>
            </Box>
        )
    }

    const { sliderData } = props
    if (sliderData) {
        const sliderDataArray = sliderData
        const sliderDataViews = renderComponentsList(sliderDataArray, renderImg)
        const indicatorsViews = renderComponentsList(sliderDataArray, renderIndicators)



        var viewToRender = (
            <Box className={classesBox.box_body}>
                <div id='carousel-example-generic' className='carousel slide' data-ride='carousel'>
                    <ol className='carousel-indicators'>
                        {indicatorsViews}
                    </ol>
                    <Box className='carousel-inner'>
                        {sliderDataViews}
                    </Box>
                    <a className={clsx(classesCarousel.carouselControl_left, classesCarousel.carousel_control_right_left, classesCarousel.carousel_control)} href='#carousel-example-generic' data-slide='prev'>
                        <span className={clsx(classesCarousel.carousel_control_fa, 'fa fa-angle-left')} />
                    </a>
                    <a className={clsx(classesCarousel.carousel_control_right, classesCarousel.carousel_control_right_left, classesCarousel.carousel_control)} href='#carousel-example-generic' data-slide='next'>
                        <span className={clsx(classesCarousel.carousel_control_fa, 'fa fa-angle-right')} />
                    </a>
                </div>
            </Box>
        )
    }
    return (
        <div>
            {/* TODO: I commented this part because we haven't a title now and I don't want a gray line in UI. But I'll want this line when we'll have a title.
                <div className='box-header with-border'>
                    <h3 className='box-title'></h3>
                </div> */}
            {viewToRender}
        </div>
    )
}