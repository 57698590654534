//import Footer from 'commons-components/footer';
import Footer from '@insoft/lib-react-web/dist/themes/material-ui/footer'
import GenericNovaeHeader from 'commons/components/navigation/generic-novae-header';
import { Navigation } from 'commons/interfaces/navigation';
import { SectionItem } from '@insoft/lib-react-web/dist/interfaces/sections';
import { SliderData } from 'commons/interfaces/slider-data';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces//component-list-item';
import { Messages } from '@insoft/lib-react-universal/dist/interfaces/message-interface';
import { getMessageByParam } from '@insoft/lib-react-universal/dist/redux/redux-utils';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import { createDangerousInnerHtml, getContentFromMetaWithName, getQueryParamsFromWindowSearch } from '@insoft/lib-react-web/dist/utils/utils';
import React, { useEffect } from 'react';
import { connect, Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { retrievePublicInitializationInfo, saveStructureId } from 'redux/actions/global-actions';
import { GlobalStateInterface, INITIAL_STATE } from 'redux/store';
import configureStore from 'redux/store/configureStore';
import { getLoadingComponent } from 'utils';
import { initApp } from 'utils/app-initializer';
import { SINGLETON_ERRORS, STRINGS } from 'utils/constants';
import GenericError from '../error-pages/generic-error';
import NewsComponent from './news';
import Organization from './organization';
import Error404 from '../error-pages/404';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { PagesStyles, BoxStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import Grid from '@mui/material/Grid'
import clsx from 'clsx'
import { NOVAE_PALETTE } from 'commons/styles/material-ui-style';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Initialize the App
initApp()

// Initialize the Redux store
const store = configureStore(INITIAL_STATE)

interface ActionCreatorsProps {
    retrievePublicInitializationInfo: (structureId: string) => any
    saveStructureId: (structureId: string) => any
}

interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
    sliderData: SliderData[]
    messages: Messages
    structureId: string
}

interface AllProps extends ActionCreatorsProps, Props { }

function LandingPage(props: AllProps) {
    const classesBox = BoxStyles();
    const classesPages = PagesStyles();

    useEffect(() => {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            props.saveStructureId(queryParams.structureId)
        }
        props.retrievePublicInitializationInfo(queryParams.structureId)
    }, []);

    const renderSubSectionItem = (item: SectionItem & ComponentListItem) => {
        let { title, text } = item
        return (
            <Grid item lg={4} md={6} xs={12} key={item._itemIndex}>
                <Box key={item._key}>
                    <Box className={clsx(classesBox.box, classesBox.box_danger)} >
                        <Box className={clsx(classesBox.box_header, classesBox.box_header_with_border)}>
                            <Typography variant="h3">{title}</Typography>
                        </Box>
                        <Box className={classesBox.box_body} dangerouslySetInnerHTML={createDangerousInnerHtml(text)} />
                    </Box>
                </Box>
            </Grid>
        )
    }

    const renderNewsView = () => {
        return (
            <ErrorBoundary>
                <NewsComponent />
            </ErrorBoundary>
        )
    }

    const renderStructureView = () => {
        if (props.structureInfo) {
            console.log("StructureInfo: " + props.structureInfo)
            const { subSections, mainDescription, secondDescription } = props.structureInfo
            const subSectionsViews = renderComponentsList(subSections, renderSubSectionItem)
            return (
                <ErrorBoundary>
                    <Grid item lg={8} xs={12} md={8}>
                        <Organization
                            structureInfo={props.structureInfo}
                            sliderData={props.sliderData}
                            structureId={props.structureId}
                        />
                    </Grid>
                    <Grid container spacing={3} >
                        {subSectionsViews}
                    </Grid>
                </ErrorBoundary>
            )
        }
        return getLoadingComponent(false)
    }

    // FIXME gestione degli errori simile a quella di app.tsx
    const navigationNotFoundError = getMessageByParam(props.messages, "id", SINGLETON_ERRORS.NAVIGATION_404)
    if (navigationNotFoundError) {
        return (
            <Error404 message={STRINGS.ERROR_NAVIGATION_NOT_FOUND} />
        )
    }

    const genericError = getMessageByParam(props.messages, "id", SINGLETON_ERRORS.INIT_ERROR)
    if (genericError) {
        return (
            <GenericError />
        )
    }

    if (!props.navigationLinks) {
        return getLoadingComponent(true)
    }

    const newsView = renderNewsView()
    const structureView = renderStructureView()

    return (
        <HashRouter>

            <Box flexDirection="column" style={{ background: NOVAE_PALETTE.palette.primary.light }} >
                <GenericNovaeHeader />
                <Box className={classesPages.content_wrapper} style={{ paddingTop: "74px" }}>
                    <Grid container spacing={3} >
                        <Grid item lg={4} xs={12} md={4}>
                            {newsView}
                        </Grid>
                        {structureView}
                    </Grid>
                </Box>
            </Box>
            <Footer structureInfo={props.structureInfo} />
        </HashRouter >
    )

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo,
        sliderData: state.global.sliderData,
        messages: state.global.messages,
        structureId: state.global.structureId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrievePublicInitializationInfo: bindActionCreators(retrievePublicInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

const AppHoc = connect(mapStateToProps, mapDispatchToProps)(LandingPage)

const container = document.getElementById('react-root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const nonce = getContentFromMetaWithName('csp-nonce');

const cache = createCache({
    key: 'mui',
    nonce: nonce, // Passa la nonce
});
root.render(<Provider store={store}>
    <CacheProvider value={cache}>
        <ThemeProvider theme={NOVAE_PALETTE}>
            <AppHoc />
        </ThemeProvider>
    </CacheProvider>
</Provider>)