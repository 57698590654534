
import { ArrayExternalPickerField, FileWidget, PhotoWidget, UiSchema, baseFormStyleRules } from '@insoft/lib-react-web';
import { REPRESENTS } from 'utils/constants';

export function styleRules(key: string, object: any) {
    let uiObject: UiSchema = baseFormStyleRules(key, object).value

    if (object.type === 'array') {
        uiObject['ui:options'] = { removable: true }

        if (object.represents === REPRESENTS.RECIPIENTS_PICKER) {
            uiObject['ui:field'] = ArrayExternalPickerField.FIELD_NAME
        }
    } else {
        if (object.type === 'number') {
            uiObject['ui:widget'] = 'updown'
        }
        if (object.readOnly) {
            uiObject['ui:disabled'] = true
        }
        if (object.represents === 'text-long') {
            uiObject['ui:widget'] = 'textarea'
            uiObject['ui:options'] = { rows: 5 }
        }
        if (object.format === 'data-url') {
            uiObject['ui:classNames'] = 'col-xs-12'
            uiObject['ui:widget'] = FileWidget.FIELD_NAME
        }
        if (object.represents === 'photo-uploader') {
            uiObject['ui:classNames'] = 'col-xs-12'
            uiObject['ui:widget'] = PhotoWidget.FIELD_NAME
        }
    }
    return {
        key: key,
        value: uiObject
    }
}