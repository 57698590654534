import { BreadCrumb } from "@insoft/lib-react-mui-web";
import { Messages, generateUUID } from "@insoft/lib-react-universal/dist";
import { Navigation } from "commons/interfaces/navigation";
import { News, NewsItem } from "commons/interfaces/news";
import { SliderData } from "commons/interfaces/slider-data";
import { StructureInfo } from "commons/interfaces/structure-info";
import { UserInfo } from "commons/interfaces/user-info";
import { AdminFilters, HistoryFilters, StaffFilters } from "redux/actions/global-actions";
import { TOTEM_CONSTANTS } from "utils/constants";
import { getStructureIdFromCookie } from "utils/novae-cookies";
import { Child } from "../../commons/interfaces/child";


export interface KendoItem {
    id: string,
    start: Date,
    end: Date,
    title?: string
}
export interface GlobalStateInterface {
    global: {
        _isReduxConfigured: boolean,
        isLoading: boolean,
        navigationLinks: Navigation,
        userInfo: UserInfo,
        structureInfo: StructureInfo,
        structureId: string,
        news: News,
        newsRecipients: Child[],
        sliderData: SliderData[],
        messages: Messages,
        childrenList: Child[],
        selectedChild: Child,
        selectedTotemTab: string,
        selectedNews: NewsItem,
        historyFilters: HistoryFilters,
        adminFilters: AdminFilters,
        staffFilters: StaffFilters,
        cookieStructureId: string,
        report?: {
            uri?: string,
            id?: string | string[]
        }
        pageInfo?: {
            title?: string
            breadcrumbs?: BreadCrumb[]
        }
    }
}

export const INITIAL_STATE: GlobalStateInterface = {
    global: {
        _isReduxConfigured: true,
        isLoading: false,
        navigationLinks: null,
        userInfo: null,
        structureInfo: null,
        structureId: null,
        news: null,
        newsRecipients: null,
        sliderData: null,
        messages: {},
        childrenList: [],
        selectedChild: null,
        selectedTotemTab: TOTEM_CONSTANTS.DIARIES_TAB,
        selectedNews: null,
        historyFilters: {},
        adminFilters: {},
        staffFilters: { structureId: [getStructureIdFromCookie()] },
        cookieStructureId: getStructureIdFromCookie()
    }
}



function getCalendarDefinition(): KendoItem[] {
    const items: KendoItem[] = []
    const minutes = ['00', '30']
    for (let i = 1; i <= 5; i++) {
        for (let j = 9; j <= 10; j++) {
            addItmesForHour(minutes, i, j, items);
        }
        for (let j = 15; j <= 17; j++) {
            addItmesForHour(minutes, i, j, items);
        }
    }
    return items;
}
function addItmesForHour(minutes: string[], i: number, j: number, items: KendoItem[]) {
    minutes.forEach(min => {
        const startString = '1990-01-0' + i + ' ' + (j == 9 ? '09' : j) + ':' + min + '.000Z';
        const startDate = new Date(startString);
        const endDate = new Date(startDate.getTime() + (30 * 60000));
        const item: KendoItem = {
            id: generateUUID(),
            start: startDate,
            end: endDate
        };

        items.push(item);
    });
}

