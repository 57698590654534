import Box from '@mui/material/Box';
import YearSelectionAdapter from 'commons/adapters/year-selection-adapter';
import { Year, YearSelection } from 'commons/interfaces/year-selection';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { clone, expandUri, generateUUID, logThis } from '@insoft/lib-react-universal/dist/utils';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { executeRequest, getFormData, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { composeUriPath, getRedirectComponent, updateFormDataState } from '@insoft/lib-react-web/dist/utils/utils';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import YearSelectionComponent from 'src/commons/components/year-selection';
import { getFormHeaderComponent, getIdFromError, getLoadingComponent, getTosComponent, getVersionFromError, parseEnrollmentIdFromResponse, parseServerErrorResponse, renderAlertComponent } from 'utils';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { Button } from '@mui/material';
import { FORMAT_ISO8601, formatDateObject } from '@insoft/lib-react-universal';
import AlertDialog from '@insoft/lib-react-web/dist/components/misc/alert-dialog';
import { PreventUnloadPageJS } from '@insoft/lib-react-mui-web';
import { IChangeEvent } from '@rjsf/core'
import { deepEquals } from '@insoft/lib-react-web';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean,
    structureInfo: StructureInfo
}

interface AllProps extends ActionCreatorsProps, Props {
    id: string
    yearId: string
    nextPage: string
    defaultFormTitle: string
    yearSelectionUri: string
    resourceUri: string
    checkEnrollmentsUri?: string
    changeDataType?: boolean
    helpSection?: any
}

function FormWithSelection(props: AllProps) {
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [yearSelectionResources, setYearSelectionResources] = useState<YearSelection>(null)
    const [resources, setResources] = useState<FormObject>(null)
    const [childsFromOtherStructures, setChildsFromOtherStructures] = useState<any[]>(undefined)
    const [serverResponse, setServerResponse] = useState<AlertObject>(null)
    const [newId, setNewId] = useState<string>(null)
    const [formKey, setFormKey] = useState<string>(generateUUID())
    const [dataChanged, setDataChanged] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(true)

    useEffect(() => {
        getData()
    }, []);

    const getData = () => {
        if (props.id || props.yearId) {
            fetchData(undefined)
        } else {
            fetchYearSelectionData()
        }
    }

    const fetchYearSelectionData = () => {
        props.startLoading()
        executeRequest<YearSelection>({ url: props.yearSelectionUri, Adapter: YearSelectionAdapter })
            .then((data) => {
                props.stopLoading()
                if (data.buttons.length === 1) {
                    onYearSelected(data.buttons[0])
                }
                setYearSelectionResources(data)
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const fetchData = (uri: string) => {
        props.startLoading()
        const finalUri = uri ? uri : (props.yearId == null ? expandUri(props.resourceUri, { id: props.id }) : expandUri(props.resourceUri, { yearId: props.yearId }))
        getFormData({ url: finalUri })
            .then((data) => {
                props.stopLoading()
                setResources(data)
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const parseErrorResponse = (error: any): AlertObject => {
        let reponse = error.response || error
        let newFormResources: FormObject = clone(resources);
        let newFormData = newFormResources.data.form.formData;

        let newId = getIdFromError(error)
        if (newId != undefined) {
            // la richiesta è stata inserita recupero l'ID e lo inietto nel formData
            newFormData = {
                ...newFormData,
                id: newId
            }
        }

        let newVersion = getVersionFromError(error)
        if (newVersion != undefined) {
            newFormData = {
                ...newFormData,
                version: newVersion
            }
        }

        if (newFormData != newFormResources.data.form.formData) {
            newFormResources.data.form.formData = newFormData;
            setResources(newFormResources)
            setFormKey(generateUUID())
        }

        return parseServerErrorResponse(error);
    }

    const onFormSubmit = (formObject: { formData: object }) => {
        if (!props.yearId) {
            let formData = formObject.formData
            setResources(updateFormDataState(resources, formData))
            props.startLoading()
            sendForm({ method: resources.method, url: resources.url, data: formData })
                .then((message: { id: string }) => {
                    props.stopLoading()
                    setDataChanged
                    setNewId(parseEnrollmentIdFromResponse(message)),
                        setFormSubmitted(true)
                }).catch((error) => {
                    props.stopLoading()
                    setServerResponse(parseErrorResponse(error)),
                        setFormKey(generateUUID())
                })
        } else {
            setFormSubmitted(true)
        }
    }

    const onYearSelected = (yearObject: Year) => {
        fetchData(yearObject.uri)
        if (props.checkEnrollmentsUri) {
            const searchString = "yearId="
            const yearId = yearObject.uri.substring(yearObject.uri.search(searchString) + searchString.length)
            const url = expandUri(props.checkEnrollmentsUri, { yearId: yearId })
            executeRequest<any[]>({ url: url })
                .then((data) => {
                    setChildsFromOtherStructures(data)
                })
        }
    }

    const loadChildAndSecondParentData = (enrollment: any) => {
        let newResources = resources
        if (enrollment['child'] != undefined) {
            newResources.data.form.formData['childData'] = {
                'firstName': enrollment['child']['firstName'],
                'lastName': enrollment['child']['lastName'],
                'birthDate': formatDateObject(enrollment['child']['birthDate'], FORMAT_ISO8601),
                'birthPlace': {
                    'state': enrollment['child']['birthState'],
                    'town': enrollment['child']['birthTown']
                },
                'fiscalCode': enrollment['child']['fiscalCode'],
                'residenceTown': {
                    'state': enrollment['child']['residenceState'],
                    'town': enrollment['child']['residenceTown']
                },
                'housePhone': enrollment['child']['housePhoneNumber'],
                'emergencyContact': enrollment['child']['emergencyContact'],
                'pediatrician': enrollment['child']['pediatrician']
            }
        }
        newResources.data.form.formData['secondParent'] = { 'singleParentFamily': enrollment['singleParentFamily'] }
        if (enrollment['secondParent'] != undefined) {
            newResources.data.form.formData['secondParent'] = {
                'personalData': {
                    'firstName': enrollment['secondParent']['firstName'],
                    'lastName': enrollment['secondParent']['lastName'],
                    'birthDate': enrollment['secondParent']['birthDate'],
                    'citizenship': enrollment['secondParent']['citizenship'],
                    'birthPlace': {
                        'town': enrollment['secondParent']['birthTown'],
                        'state': enrollment['secondParent']['birthState'],
                    },
                    'fiscalCode': enrollment['secondParent']['fiscalCode'],
                    'email': enrollment['secondParent']['email'],
                    'firstPhoneNumber': enrollment['secondParent']['firstPhoneNumber'],
                    'secondPhoneNumber': enrollment['secondParent']['secondPhoneNumber'],
                    'identityNumberCard': enrollment['secondParent']['identityCardNumber'],
                    'number': enrollment['secondParent']['residence']['number'],
                    'road': enrollment['secondParent']['residence']['road'],
                    'cap': enrollment['secondParent']['residence']['cap'],
                    'town': {
                        'town': enrollment['secondParent']['residence']['town'],
                        'state': enrollment['secondParent']['residence']['state'],
                    }
                }
            }
        }
        setResources(newResources)
        setFormKey(generateUUID())
        setOpenModal(false)
    }

    var modal
    if (formSubmitted && props.yearId == null) {
        return getRedirectComponent(composeUriPath(props.nextPage, [newId]))
    } else if (formSubmitted && props.yearId) {
        return getRedirectComponent(composeUriPath(props.nextPage, [props.yearId]))
    }
    if (props.loading) {
        return getLoadingComponent(true)
    }
    const headerComponent = getFormHeaderComponent(resources && resources.data && resources.data.form && resources.data.form.formData ? props.helpSection : undefined)

    if (yearSelectionResources) {
        var yearSelectionView = (
            <YearSelectionComponent
                buttons={yearSelectionResources.buttons}
                onYearSelected={onYearSelected}
                changeDataType={props.changeDataType}
            />
        )
    }
    if (serverResponse) {
        var serverResponseComponent = renderAlertComponent(serverResponse)
    }
    if (!serverResponseComponent && childsFromOtherStructures && childsFromOtherStructures.length > 0) {
        var elements: any[] = []
        childsFromOtherStructures.forEach((enrollment: any) => {
            const element = (<Button style={{ marginBottom: "5px" }} color="primary" variant="contained" onClick={() => loadChildAndSecondParentData(enrollment)}>{enrollment['child']['lastName'] + " " + enrollment['child']['firstName']}</Button>)
            elements.push({ element })
        })
        modal = (
            <AlertDialog
                title='Preselezione anagrafica'
                message='Sono state compilate alcune iscrizioni per le seguenti anagrafiche. Sceglierne una se si desidera iscrivere qualcuno già iscritto in precedenza altrimenti premere su "Annulla":'
                open={openModal}
                acceptButtonText="Annulla"
                onlyOneButton={true}
                elements={elements}
            ></AlertDialog>
        )
    }
    const formTitle = resources ? (resources.data.form.title || props.defaultFormTitle) : props.defaultFormTitle
    const mySubTitle = resources ? (resources.data.form.subTitle || undefined) : undefined
    const onUpdateData = (data: IChangeEvent<any, any, any>, id?: string) => {
        const newData = data?.formData
        if (!dataChanged && !deepEquals(newData, resources.data?.form?.formData)) {
            setDataChanged(true)
        }
    }

    return (
        <Box>
            {modal}
            <PreventUnloadPageJS hasUnsavedChanges={dataChanged} />
            <FormPage
                showButton={props.nextPage ? true : false}
                formKey={formKey}
                tosComponent={getTosComponent(resources)}
                bodyContent={yearSelectionView}
                formStyleRules={commonFormStyleRules}
                buttonText='Avanti'
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                headerComponent={headerComponent}
                onFormSubmit={onFormSubmit}
                resources={resources}
                serverResponseComponent={serverResponseComponent}
                title={formTitle}
                subTitle={mySubTitle}
                showRequiredFieldsLegend
                versionManagement={true}
                onChange={onUpdateData}
            />
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        structureInfo: state.global.structureInfo
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormWithSelection)