import { Provider, connect } from 'react-redux';
import { HashRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { GlobalStateInterface, INITIAL_STATE } from 'redux/store';
import configureStore from 'redux/store/configureStore';
import { initApp } from 'utils/app-initializer';
import { createRoot } from 'react-dom/client';
import { MULTI_STRUCTURE_MODULE_PAGES, NovaeModule, PAGES } from '../utils/rest-routes';
import MultiStructurePresencesReport from '../pages/multi-structure-presences-report';
import { evaluateLinks } from './navigation/menu-links';
import { Navigation } from '../commons/interfaces/navigation';
import { ContextChoiceSection } from '@insoft/lib-react-mui-web'
import PageWrapper from '../commons/components/page/page-wrapper';
import { UserInfo } from '../commons/interfaces/user-info';
import { getLoadingComponent } from '../utils';
import { useEffect } from 'react';
import { retrieveInitializationInfo, saveStructureId } from '../redux/actions/global-actions';
import { Dispatch, bindActionCreators } from 'redux';
import { getContentFromMetaWithName, getQueryParamsFromWindowSearch } from '@insoft/lib-react-web';
import OrganizationPresenceReport from '../commons/components/organization-presence-report/metadata-table';
import ReportDataPreview from '../commons/components/organization-presence-report/preview';
import AccountHoldersTable from '../commons/components/account-holders/account-holders-table';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';


// Initialize the App
initApp()


const nonce = getContentFromMetaWithName('csp-nonce');

const cache = createCache({
    key: 'mui',
    nonce: nonce, // Passa la nonce
});

// Initialize the Redux store
const store = configureStore(INITIAL_STATE)

interface Props {
    navigation: Navigation,
    userInfo: UserInfo,
    structureId: string
}
interface ActionCreatorsProps {
    retrieveInitializationInfo: (structureId: string, module?: string) => any
    saveStructureId: (structureId: string) => any
}

function App(props: Props & ActionCreatorsProps) {

    const location = useLocation()
    // const navigation: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks)
    // const userInfo: UserInfo = useSelector((state: GlobalStateInterface) => state.global.userInfo)
    // const structureId: string = useSelector((state: GlobalStateInterface) => state.global.structureId)
    // const dispatch = useDispatch()

    useEffect(() => {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            props.saveStructureId(queryParams.structureId)
        }
        props.retrieveInitializationInfo(queryParams.structureId, NovaeModule.MULTIPLE_STRUCTURE_MANAGMENT)
    }, []);


    const evaluateLinksFromState = (state: GlobalStateInterface) => {
        return evaluateLinks(state.global.navigationLinks)
    }

    if (props.navigation?.toChoiceOrganization) {
        return <PageWrapper evaluateLinksFromState={evaluateLinksFromState}>
            <ContextChoiceSection options={props.navigation.organizations} templatePath="/dashboard{?structureId}"
                showInstallationId={props.navigation.isSuperUser} />
        </PageWrapper>
    }

    if (!props.navigation && !props.userInfo) {
        return <PageWrapper evaluateLinksFromState={evaluateLinksFromState} navigationBars={false}>{getLoadingComponent(true)}</PageWrapper>
    }

    if (location.pathname == '/') {
        return <Navigate to={MULTI_STRUCTURE_MODULE_PAGES.presences} />
    }

    return (<PageWrapper evaluateLinksFromState={evaluateLinksFromState}>
        <Routes>
            <Route path={MULTI_STRUCTURE_MODULE_PAGES.presences} element={<OrganizationPresenceReport />} />
            <Route element={<ReportDataPreview />} path={PAGES.reportPreview} />
            <Route element={<AccountHoldersTable isMultiStructure />} path={PAGES.accountHolders} />
            <Route path={MULTI_STRUCTURE_MODULE_PAGES.cobertura} element={<MultiStructurePresencesReport />} />
        </Routes>
    </PageWrapper>)
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigation: state.global.navigationLinks,
        userInfo: state.global.userInfo,
        structureId: state.global.structureId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveInitializationInfo: bindActionCreators(retrieveInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

export const AppHoc = connect(mapStateToProps, mapDispatchToProps)(App)

const container = document.getElementById('react-root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
    <CacheProvider value={cache}>
        <HashRouter>
            <AppHoc />
        </HashRouter>
    </CacheProvider>
</Provider>)