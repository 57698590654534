import ChildDiaryText from "commons/components/child-diary-text";
import { ChildDiary } from "commons/interfaces/child";
import { MessageInterface } from "@insoft/lib-react-universal/dist/interfaces/message-interface";
import { expandUri, generateUUID, logThis } from "@insoft/lib-react-universal/dist/utils";
import { formatDateObject, FORMAT_ISO8601 } from "@insoft/lib-react-universal/dist/utils/date-utils";
import LoadingComponent from "@insoft/lib-react-web/dist/components/misc/loading-componentMaterial";
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services";
import React, { useEffect, useState } from "react";
import { PAGES, REST_ENDPOINTS } from "utils/rest-routes";


interface Props {
    day: Date
    childId: number
    renderNoData: () => JSX.Element
    childrenAndDateChoice: JSX.Element
    childDiaryUrl: string
    addMessage: (message: MessageInterface) => any
    removeMessage: (message: MessageInterface) => any
}

function DiaryTab(props: Props) {
    const [childDiary, setChildDiary] = useState<ChildDiary>(undefined)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        console.log('diary use effect')
        console.log(props)
        fetchDiary()
    }, [props.childId, props.day])

    const fetchDiary = () => {
        setLoading(true)
        const expandedUri = expandUri(props.childDiaryUrl, { childId: props.childId.toString(), date: formatDateObject(props.day, FORMAT_ISO8601) })
        executeRequest<ChildDiary>({ url: expandedUri, sessionCheckUri: REST_ENDPOINTS.CHECK_AUTHENTICATION })
            .then((data) => {
                setLoading(false)
                setChildDiary(data)
                return null
            }).catch((data) => {
                setLoading(false)
                props.addMessage({ id: generateUUID(), message: "Impossibile recuperare i dati del diario", target: PAGES.appSimulator })
                setChildDiary({ childId: props.childId.toString() })
                logThis(data)
            })
    }

    if (loading) {
        var content = (<LoadingComponent fullPage={false} />)
    } else if (childDiary && childDiary.diaryId) {
        content = (
            <ChildDiaryText diaryDate={props.day} childDiary={childDiary} />
        )
    } else {
        content = props.renderNoData();
    }
    return (
        <div>
            {props.childrenAndDateChoice}
            {content}
        </div>
    )

}

export default DiaryTab