import { ChildDiary, DiaryItem, DiarySection } from 'commons/interfaces/child';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { formatDateObject, FORMAT_UNI_EN28601 } from '@insoft/lib-react-universal/dist/utils/date-utils';
import React from 'react';
import { getDiaryIcon } from 'utils';
import Typography from '@mui/material/Typography'
import { Box, createStyles, Grid } from '@mui/material';
import clsx from 'clsx';


interface Props {
    childName?: string
    childDiary: ChildDiary
    diaryDate: Date
}

export default function ChildDiaryText(props: Props) {

    const renderDiaryItem = (item: DiaryItem & ComponentListItem) => {
        return (
            <li key={item._key}>{item.label}: {item.value}</li>
        )
    }

    const renderDiarySection = (item: DiarySection & ComponentListItem) => {
        const itemsViews = renderComponentsList(item.items, renderDiaryItem)
        const iconUri = getDiaryIcon(item.icon)
        if (iconUri) {
            var iconView = <img src={iconUri} width="30" />
        }
        return (
            <Grid item xs={12} md={6} key={item._key} style={{ fontSize: "1.5em" }}>
                <Box paddingBottom={1}>{iconView}<Typography variant="h3" display="inline" style={{ paddingLeft: "0.5em" }}>{item.title}</Typography></Box>
                <ul>
                    {itemsViews}
                </ul>
            </Grid>
        )
    }

    const renderDiarySections = () => {
        const childDiary = props.childDiary
        const sections = renderComponentsList(childDiary.sections, renderDiarySection)
        return (
            <Grid container spacing={4}>
                {sections}
            </Grid>
        )
    }

    const renderDayResultSection = () => {
        const description = props.childDiary.dayResultDescription
        const icon = props.childDiary.dayResultImage
        if (description || icon) {
            const iconSrc = getDiaryIcon(icon)
            return (
                <Box p={3}>
                    <Typography variant="h3" >La mia giornata</Typography>
                    <img src={iconSrc} />
                    <Typography component="p" style={{ fontSize: "1.5em" }}>{description}</Typography>
                </Box>
            )
        }
    }

    const renderDaySection = () => {
        const currentDate = formatDateObject(props.diaryDate, FORMAT_UNI_EN28601)
        return (
            <h3>Data: {currentDate}</h3>
        )
    }

    const renderHeader = () => {
        if (props.childName) {
            return (
                <Typography variant="h2" gutterBottom>
                    La giornata di {props.childName}
                </Typography>
            )
        }
    }

    const diarySections = renderDiarySections()
    const dayResultSection = renderDayResultSection()
    const daySection = renderDaySection()
    const header = renderHeader()

    return (
        <Box>
            {header}
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    {dayResultSection}
                </Grid>
                <Grid item xs={12} md={6}>
                    {daySection}
                </Grid>
            </Grid>
            {diarySections}
        </Box>
    )
}