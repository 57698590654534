import { UserInfo } from 'commons/interfaces//user-info';
import { Navigation } from 'commons/interfaces/navigation';
import { useEffect } from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Divider, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { PAGES } from 'utils/rest-routes';
import clsx from 'clsx'
import { generateUUID } from '@insoft/lib-react-universal/dist/utils';
import Box from '@mui/material/Box';
import LogoutButton from '@insoft/lib-react-web/dist/themes/adminlte/components/logout-button/logout-button';
import LinksArea from './links-area';
import { NavStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import UserArea from './user-area';
import { Breakpoint, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StructureInfo } from 'commons/interfaces/structure-info';



interface Props {
    navigationLinks: Navigation
    userInfo: UserInfo
    structureInfo: StructureInfo
    structureId: string
    needNavbarArea?: boolean
    loginRedirectUrl?: string
}
type BreakpointOrNull = Breakpoint | null;


const NovaeHeaderIcon = '/dist/img/novae_logo.png'
const NovaeHeaderIconSmall = '/dist/img/ic_launcher.png'

// TODO Questa potrebbe essere generica senza riferimenti a novae
export default function NovaeNavigation(props: Props) {

    const classes = NavStyles();
    const [openDesktop, setOpenDesktop] = useState<boolean>(true);
    const [openMobile, setOpenMobile] = useState<boolean>(false)

    const path = useLocation();

    useEffect(() => {
        setOpenMobile(false);
    }, [path])

    const handleDrawerState = () => {
        setOpenDesktop(!openDesktop)
    }

    const handleMobileState = () => {
        setOpenMobile(!openMobile)
    }

    // let openMenu = openSelection || (openSelection === undefined && (props.width == 'xl' || props.width == 'lg' || props.width == 'md'))

    const theme = useTheme();
    const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));

    if (!openDesktop || isScreenXS) {
        var logo =
            <img alt='Novae Web' src={NovaeHeaderIconSmall} key={generateUUID()} />
    } else {

        var title = 'WEB'
        var logo = <Box display="flex" alignItems="center">
            <img alt='Novae Web' src={NovaeHeaderIcon} key={generateUUID()} />
            <Typography style={{ color: "white" }}>{title}</Typography>
        </Box>

    }

    if (props.userInfo) {
        var { firstName, lastName } = props.userInfo
    }

    //TODO migliorabile la gestione degli hidden
    console.log("LogoutRedirectUrl in navigation-menu: " + props.structureInfo?.logoutRedirectUrl)
    return (
        <div>
            <Hidden smDown implementation="css">
                <AppBar position="relative" color="primary" className={classes.appBar} >
                    <Toolbar disableGutters={true} >
                        <Box alignItems="center" justifyContent="center" display="flex" className={clsx({
                            [classes.logoBoxClose]: !openDesktop,
                            [classes.logoBoxOpen]: openDesktop
                        })}>
                            <Link to={PAGES.dashboard}  >
                                <div >
                                    {logo}
                                </div>
                            </Link>
                        </Box>
                        <Box className={classes.content}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerState}
                                edge="start"
                                className={classes.menuButton}  >
                                <MenuIcon className={classes.largeIcon} />
                                <span className='sr-only' />
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                            </IconButton>
                        </Box>
                        <Box>
                            <LogoutButton logoutRedirectUrl={props.loginRedirectUrl} structureId={props.structureId} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: openDesktop,
                        [classes.drawerClose]: !openDesktop,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: openDesktop,
                            [classes.drawerClose]: !openDesktop,
                        }),
                    }}

                >
                    <div className={classes.toolbar} />
                    <Divider />
                    <UserArea open={openDesktop} firstName={firstName} lastName={lastName} />
                    <Divider />
                    <LinksArea open={openDesktop} navigationLinks={props.navigationLinks} structureInfo={props.structureInfo} />
                </Drawer>
            </Hidden>

            <Hidden smUp implementation="css">
                <AppBar position="relative" color="primary" className={classes.appBar} >
                    <Toolbar disableGutters={true} >
                        <Box alignItems="center" justifyContent="center" display="flex" className={classes.logoBoxClose}>
                            <Link to={PAGES.dashboard}  >
                                <div >
                                    {logo}
                                </div>
                            </Link>
                        </Box>
                        <Box className={classes.content}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleMobileState}
                                edge="start"
                                className={classes.menuButton}  >
                                <MenuIcon className={classes.largeIcon} />
                                <span className='sr-only' />
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                                <span className='icon-bar' />
                            </IconButton>
                        </Box>
                        <Box>
                            <LogoutButton logoutRedirectUrl={props.loginRedirectUrl} structureId={props.structureId} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="temporary"
                    open={openMobile}
                    onClose={handleMobileState}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: openMobile,
                        [classes.drawerClose]: !openMobile,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: openMobile,
                            [classes.drawerClose]: !openMobile,
                        }),
                    }}
                >
                    <div className={classes.toolbar} />
                    <Divider />
                    <UserArea open={openMobile} firstName={firstName} lastName={lastName} />
                    <Divider />
                    <LinksArea open={openMobile} navigationLinks={props.navigationLinks} structureInfo={props.structureInfo} />
                </Drawer>
            </Hidden>
        </div>
    )
}