import FormObjectAdapter from "@insoft/lib-react-web/dist/adapters/form-object-adapter"
import { AutocompleteListField } from "@insoft/lib-react-web/dist/components"
import { FormObject, ServerFormObject } from "@insoft/lib-react-web/dist/interfaces/form-object"
import { COMMONS_REPRESENTATION_KEYS, FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from "@insoft/lib-react-web/dist/utils/schema-utils"
import { Props } from "./parent-app-search-form"

export const SEARCH_FILEDS = {
    YEAR: 'yearId',
    PARENT: 'parent'
}


export function getApplicationSimulatorFormObject(props: Props): FormObject {
    let serverFormObject: ServerFormObject
    let properties: { [key: string]: any } = {

    }

    properties[SEARCH_FILEDS.YEAR] = {
        type: "string",
        title: "Anno Educativo",
        represents: [FORM_INPUT_DEFAULT_WIDGETS.LIST, FORM_LIST_TYPE.DROPDOWN_LIST],
        classNames: "col-xs-12 col-sm-6 col-lg-6",
        autocomplete: {
            href: props.yearsLink
        }
    }

    properties[SEARCH_FILEDS.PARENT] = {
        type: "string",
        title: "Genitore",
        represents: [FORM_INPUT_DEFAULT_WIDGETS.LIST, FORM_LIST_TYPE.DROPDOWN_LIST],
        customFilterable: true,
        classNames: "col-xs-12 col-sm-6 col-lg-6",
        autocomplete: {
            href: props.parentsByYear
        }
    }


    serverFormObject = {
        PUT: {
            form: {
                formSchema: {
                    type: "object",
                    properties: properties,
                    represents: FORM_INPUT_DEFAULT_WIDGETS.LIST
                },
                formData: props.historyFilters
            }
        },
        _links: {
            self: {
                href: ''
            }
        }
    }

    // state:
    //     classNames: "col-xs-12 col-sm-6 col-lg-4"
    //     customAllowCustomValues: false
    //     customFilterKey: undefined
    //     customUrl: "/resource/socialis-territory/public/territory/states{?state}"
    // town:
    //     classNames: "col-xs-12 col-sm-6 col-lg-4"
    //     customAllowCustomValues: true
    //     customFilterKey: undefined
    //     customUrl: "/resource/socialis-territory/public/territory/towns{?town,state}"
    // ui:field: "custom-autocomplete-list-field"
    let toReturn = new FormObjectAdapter(serverFormObject)
    // return new FormObjectAdapter(serverFormObject)
    return toReturn
}