import { logThis, expandUri, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getTotemContentHeight } from 'utils';
import { Document } from 'commons/interfaces/documents';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { DocumentsTable } from '../../commons/components/documents-table';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl: string
}

interface AllProps extends ActionCreatorsProps, Props { }

interface State {
    resources: Document[]
}

class DocumentsList extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props)
        this.state = {
            resources: null
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.startLoading()
        executeRequest<Document[]>({ url: this.props.requestUrl, method: 'GET' })
            .then((data) => {
                this.props.stopLoading()
                this.setState({
                    resources: data
                })
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    renderItem = (item: Document & ComponentListItem) => {
        return (
            <tr key={item._key}>
                <td>{item._itemNumber}</td>
                <td>{item.name}</td>
                <td>{item.notes}</td>
                <td><a href={item.links[0].href} className="btn btn-primary" role="button" aria-disabled="true" target="_blank">Visualizza</a></td>
            </tr>
        )
    }

    render() {
        if (this.state.resources) {
            const tableItems = renderComponentsList(this.state.resources, this.renderItem)
            return (
                <div className="box">
                    <div className="box-header">
                        <h3 className="box-title">Documenti</h3>
                    </div>
                    <div className="box-body no-padding">
                        <DocumentsTable documents={this.state.resources} />
                    </div>
                </div>
            )
        }
        return (
            <section className='content' style={{ height: getTotemContentHeight() }}>
                <h2 style={{ marginTop: getTotemContentHeight() * 1 / 3 }}>Nessun documento disponibile</h2>
            </section>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationDocuments) {
        var requestUrl = state.global.navigationLinks._links.organizationDocuments.href
        requestUrl = expandUri(requestUrl, { structureId: state.global.structureId })
    }
    return {
        loading: state.global.isLoading,
        requestUrl
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList)