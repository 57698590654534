import { Box, Button, Dialog, DialogActions, AlertTitle, Alert as AlertMaterial, DialogContent, DialogContentText, Typography, DialogTitle, IconButton, Grid, Select, useMediaQuery, useTheme, withStyles } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces//component-list-item";
import {
  composeUriPath,
  refreshPage
} from "@insoft/lib-react-web/dist/utils/utils";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { appendQueryParams, logThis, ReactLabelComponent, renderComponentsList } from "@insoft/lib-react-universal";
import { BoxedContent, ButtonWithAlert, defaultReactDataRenderMUITable, executeRequest, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel } from "@insoft/lib-react-web";
import { Icon } from "@insoft/lib-react-web/dist/interfaces/structure-info";
import AlertDialog from "@insoft/lib-react-web/dist/components/misc/alert-dialog";
// import { SelectChangeEvent } from '@mui/material/Select';
import GetAppIcon from '@mui/icons-material/GetApp';
import { StructureInfo } from "../interfaces/structure-info";
import { Enrollment, Requests } from "../interfaces/enrollments";
import STYLE from "../styles/style";
import { formatDate, formatStringDate, getFunctionName, isEnrollmentInFillingStatus } from "../../utils";
import { ENROLLMENT_STATUS, REGISTRATION_TYPE } from "../../utils/constants";
import { PAGES } from "../../utils/rest-routes";
import { saveStructureIdForCookie } from "../../redux/actions/global-actions";
import { GlobalStateInterface } from "../../redux/store";

interface ActionCreatorsProps {
  saveStructureIdForCookie: (structureId: string) => any
}

interface GlobalProps {
  structureInfo: StructureInfo;
  structureCountUrl: string;
  structureId: string;
  cookieStructureId: string;
}

interface Props {
  requestUrl: string;
  excelUrl?: string;
  operator: boolean;
  title?: string;
  width?: string;
  hideType?: boolean;
  hideOrder?: boolean;
  printEmpty?: boolean;
  search?: boolean;
  hideNumber?: boolean;
  hideStartDate?: boolean;
  showChangeOrganization?: boolean;
  defaultOrder?: any[];
  sortModel?: GridSortModel;
  isParentUser?: boolean;
  notifyOpenEnrollments?: boolean;
  emptyNotShow?: boolean;
}
interface TableRow {
  collapsibleArrow?: ReactLabelComponent;
  number?: number;
  id: number;
  childData?: string;
  birthDate?: string;
  fillingStartDate: string;
  yearId: string;
  for?: string;
  effectiveStartDate: string;
  residenceTown?: string;
  submissionDate?: string;
  totalOrder?: number | string;
  type?: string;
  status: ReactLabelComponent;
  action: ReactLabelComponent;
  changeOrganization?: ReactLabelComponent;
  delete?: ReactLabelComponent;
}

interface AllProps
  extends Props,
  GlobalProps,
  ActionCreatorsProps { }

// const StyledDataGrid = withStyles({
//   root: {
//     fontSize: 14,
//     "& .MuiDataGrid-columnHeaderTitle": {
//       whiteSpace: "normal",
//       lineHeight: "1rem"
//     },
//     "& .MuiDataGrid-cell": {
//       whiteSpace: "normal",
//     }
//   }
// })(DataGrid);

function getTableColumns(props: Props, hasDeleteColumn: boolean, isScreenXS?: boolean) {
  let tableColumns: TableColumnDefinition[] = [];
  if (isScreenXS) {
    tableColumns.push({
      headerName: "",
      field: "collapsibleArrow",
      sortable: false,
      showMobile: true,
      mobileCollapser: true,
      renderCell: defaultReactDataRenderMUITable
    });
  }
  tableColumns.push({ headerName: "Nome", field: "childData", showMobile: true });
  tableColumns.push({ headerName: "Data di nascita", field: "birthDate" })
  tableColumns.push({
    headerName: "Inizio compilazione",
    field: "fillingStartDate"
  });
  tableColumns.push({
    headerName: "Data sottomissione",
    field: "submissionDate"
  });
  tableColumns.push({ headerName: "A.E.", field: "yearId" });
  tableColumns.push({ headerName: "Data di inizio", field: "effectiveStartDate" });
  if (!props.isParentUser) { tableColumns.push({ headerName: "Residenza", field: "residenceTown" }); }
  tableColumns.push({ headerName: "Ord.", field: "totalOrder" });
  tableColumns.push({ headerName: "Tipo", field: "type" });
  tableColumns.push({
    headerName: "Stato",
    field: "status",
    showMobile: true,
    renderCell: defaultReactDataRenderMUITable
  });
  tableColumns.push({
    headerName: "",
    field: "action",
    sortable: false,
    renderCell: defaultReactDataRenderMUITable
  });
  if (props.showChangeOrganization) {
    tableColumns.push({
      headerName: "",
      field: "changeOrganization",
      sortable: false,
      renderCell: defaultReactDataRenderMUITable
    });
  }
  if (hasDeleteColumn) {
    tableColumns.push({
      headerName: "",
      field: "delete",
      sortable: false,
      renderCell: defaultReactDataRenderMUITable,
    });
  }
  return tableColumns;
}

EnrollmentsComponent.defaultProps = {
  width: "col-sm-12",
  search: true,
  operator: true,
};

function EnrollmentsComponent(props: AllProps) {
  const pageSizes = [10, 20, 50];

  const [openStructureDialog, setOpenStructureDialog] = React.useState(true);
  const [openRequestsNotificationDialog, setOpenRequestsNotificationDialog] = React.useState(true);
  const [sortModel, setSortModel] = useState<TableSortModel>({ field: "childData", sort: "asc" });
  const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [resources, setResources] = useState<Requests>(null);
  const [structureCount, setStructureCount] = useState<number>(null)
  // const [structureList, setStructureList] = useState<StructureInfo[]>(null)
  // const [structure, setStructure] = useState<string>(props.structureId)
  const [loading, setLoading] = useState<boolean>(false)
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasOpenRequests, setHasOpenRequests] = useState<boolean>(false)
  const [collapsedElements, setCollapsedElements] = useState<number[]>([])

  useEffect(() => {
    fetchData();
    fetchStructureCount();
  }, [props.requestUrl, props.structureCountUrl, pageNumber, pageSize, sortModel, openStructureDialog, hasOpenRequests]);

  const createParam = (paramName: string, sortItem: TableSortModel) =>
    paramName + (sortItem ? "," + sortItem.sort : "");
  const fetchData = () => {
    if (props.requestUrl) {
      setLoading(true)
      if (sortModel) {
        var sortingArray: string[] = [];
        if (sortModel.field === "childData") {
          sortingArray.push(createParam("child.lastName", sortModel));
        } else {
          sortingArray.push(createParam(sortModel.field, sortModel));
        }
      }


      var requestUrl = appendQueryParams(props.requestUrl, {
        page: pageNumber.toString(),
        size: pageSize.toString(),
        sort: sortingArray,
      });
      executeRequest<Requests>({ url: requestUrl, method: "GET" })
        .then((data) => {
          setResources(data);
          setLoading(false)
          return null;
        })
        .catch((data) => {
          setLoading(false)
          logThis(data);
        });
    }
  };

  const manageCollapsedElements = (element: number) => {
    let index = collapsedElements.indexOf(element, 0)
    let newCollapsedElements: number[] = []
    collapsedElements.forEach((element) => {
      newCollapsedElements.push(element)
    })
    if (index != -1) {
      newCollapsedElements.splice(index, 1)
    } else {
      newCollapsedElements.push(element)
    }
    setCollapsedElements(newCollapsedElements)
  }

  const fetchStructureCount = () => {
    if (props.structureCountUrl) {
      var toReturn;
      executeRequest<number>({ url: props.structureCountUrl, method: "GET" })
        .then((data) => {
          setStructureCount(data)
          return null;
        })
        .catch((data) => {
          logThis(data);
        });
      return toReturn
    }
  }

  const renderEnrollmentRow = (
    enrollment: Enrollment & ComponentListItem
  ) => {
    const ND = "N.D.";
    let enrollmentId = enrollment.id;
    let child = enrollment.child;
    let fillingStartDate =
      enrollment.fillingStartDate !== undefined
        ? new Date(enrollment.fillingStartDate)
        : undefined;
    let status = enrollment.status;
    let statusClassName = STYLE.tags[status] ? STYLE.tags[status].class : ND;
    let statusLabel = STYLE.tags[status] ? STYLE.tags[status].label : ND;
    let type = enrollment.type;
    let typeLabel = getFunctionName(type, props.structureInfo) || ND;
    let yearLabel = enrollment.year ? enrollment.year : "";
    let yearId = enrollment.yearId;
    let startDate = enrollment.effectiveStartDate
      ? enrollment.effectiveStartDate
      : ND;
    let residenceTown = enrollment.residenceTown
      ? enrollment.residenceTown
      : ND;
    let lastPage = enrollment.lastPage;
    let order = enrollment.order !== undefined ? enrollment.order : ND;
    let submissionDate =
      enrollment.submissionDateTime !== undefined
        ? new Date(enrollment.submissionDateTime)
        : undefined;

    if (props.notifyOpenEnrollments && !hasOpenRequests && isEnrollmentInFillingStatus(status)) {
      setHasOpenRequests(true)
    }

    switch (type) {
      case REGISTRATION_TYPE.ENROLLMENT:
        var targetPage = isEnrollmentInFillingStatus(status)
          ? (lastPage == 1 ? PAGES.nuovaIscrizioneDichiarazioni : PAGES.nuovaIscrizioneFrequenza)
          : PAGES.nuovaIscrizioneRiepilogo;
        break;
      case REGISTRATION_TYPE.DATA_CHANGE:
        targetPage = isEnrollmentInFillingStatus(status)
          ? (lastPage == 1 ? PAGES.cambioDatiDichiarazioni : PAGES.cambioDati)
          : PAGES.cambioDatiRiepilogo;
        break;
      case REGISTRATION_TYPE.MODIFICA:
        targetPage = isEnrollmentInFillingStatus(status)
          ? PAGES.modificaIscrizione
          : PAGES.modificaIscrizioneRiepilogo;
        break;
      case REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI:
        targetPage = isEnrollmentInFillingStatus(status)
          ? PAGES.serviziAggiuntivi
          : PAGES.serviziAggiuntiviRiepilogo;
        break;
      case REGISTRATION_TYPE.ENROLLMENT_CHANGE:
        targetPage = isEnrollmentInFillingStatus(status)
          ? PAGES.modificaInizioFineFrequenza
          : PAGES.modificaInizioFineFrequenzaRiepilogo;
        break;
      case REGISTRATION_TYPE.ISEE_CHANGE:
        targetPage = isEnrollmentInFillingStatus(status)
          ? PAGES.cambioIsee
          : PAGES.cambioIseeRiepilogo;
        break;
    }


    var linkChangeStructure = composeUriPath(PAGES.organizationChange, [yearId, enrollmentId])
    if (props.operator && enrollment.checked) {
      var checked = <span className={statusClassName}>Controllato</span>;
    }

    const stateComponent = (
      <span>
        <span className={statusClassName}>{statusLabel}</span> {checked}
      </span>
    );
    const changeOrganization = <Link to={linkChangeStructure}>Cambia struttura</Link>

    let changeOrganizationComponet: any = null
    if (props.showChangeOrganization) {
      changeOrganizationComponet = { label: 'Cambia struttura', component: changeOrganization }
    }
    var actionComponent
    if (targetPage) {
      var linkUrl = composeUriPath(targetPage, [enrollmentId]);
      var linkLabel = isEnrollmentInFillingStatus(status)
        ? "Continua compilazione"
        : "Consulta";
      actionComponent = <Link to={linkUrl}>{linkLabel}</Link>
    } else {
      actionComponent = <span></span>
    }

    let deleteButton: any
    if (enrollment && enrollment.deleteLink && enrollment.deleteLink.href) {
      deleteButton = <ButtonWithAlert size="small" message={"L'operazione di eliminazione sarà irreversibile. Continuare?"} action={() => deleteRequest(enrollment.deleteLink.href)} icon={<DeleteIcon />}></ButtonWithAlert>
    } else {
      deleteButton = <p></p>
    }
    let collapsibleArrow: any
    if (collapsedElements.indexOf(enrollment._itemIndex + 1, 0) != -1) {
      collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(enrollment._itemIndex + 1)}><ExpandLessIcon /></IconButton>
    } else {
      collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(enrollment._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
    }

    let enrollmentObject: TableRow = {
      collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
      id: enrollment._itemIndex + 1,
      childData: child.lastName + " " + child.firstName,
      birthDate: formatStringDate(child.birthDate),
      fillingStartDate: formatDate(fillingStartDate),
      yearId: yearLabel,
      effectiveStartDate: startDate,
      residenceTown: residenceTown,
      submissionDate: formatDate(submissionDate),
      type: typeLabel,
      status: { label: statusLabel, component: stateComponent },
      action: { label: linkLabel, component: actionComponent },
      changeOrganization: changeOrganizationComponet,
      delete: { component: deleteButton },
    };
    if (!props.hideOrder) {
      enrollmentObject.totalOrder = order;
    }
    if (!props.hideType) {
      enrollmentObject.type = typeLabel;
    }
    return enrollmentObject;
  };

  const deleteRequest = (deleteLink: string) => {
    executeRequest({ url: deleteLink, method: "DELETE" })
      .then((data) => {
        console.log(data)
        refreshPage()
      })
      .catch((data) => {
        logThis(data);
        setLoading(false);
      });
  }

  const renderLogo = (logo: Icon) => {
    if (!logo || !logo.imgPath) {
      return null
    } else {
      return "<img style='display: block; margin-left: auto; margin-right: auto;' className='separate-to-text' src='" + logo.imgPath + "' width='120' />"
    }
  }

  if (!props || !props.requestUrl) {
    return null;
  }
  if (openStructureDialog && props.isParentUser && (props.cookieStructureId == undefined || props.cookieStructureId != props.structureId) && structureCount != undefined && structureCount > 1) {
    return (
      <AlertDialog
        title={"Benvenuto!"}
        message={"Hai eseguito l'accesso sulla struttura: <b>" + props.structureInfo.name + " (" + props.structureInfo.contacts.address + ")" +
          "</b>.<br><br>Sei sicuro di aver scelto la struttura giusta? <b>Se la struttura non è quella desiderata contattare la" +
          " struttura corretta e seguire le indicazioni fornite da loro." +
          (props.structureInfo.logo != undefined && props.structureInfo.logo != null ? "</b><br><br><br>" + renderLogo(props.structureInfo.logo) : "")}
        open={true}
        onAcceptCallback={() => setOpenStructureDialog(false)}
        onlyOneButton={true}
      // elements={elements}
      />
    );
  }

  if (openRequestsNotificationDialog && props.notifyOpenEnrollments && hasOpenRequests) {
    const element = (<span className={STYLE.tags[ENROLLMENT_STATUS.FILLING].class}>{STYLE.tags[ENROLLMENT_STATUS.FILLING].label}</span>)
    return (
      <AlertDialog
        title={"Bentornato"}
        message={"Hai delle richieste ancora nello stato <span class='" + STYLE.tags[ENROLLMENT_STATUS.FILLING].class + "'>" + STYLE.tags[ENROLLMENT_STATUS.FILLING].label + "</span></br>" +
          "Ti consigliamo di completarle ed inviarle alla struttura.</br> Se invece non sono più necessarie è possibile cancellarle attraverso l'apposito bottone."}
        open={true}
        onAcceptCallback={() => setOpenRequestsNotificationDialog(false)}
        onlyOneButton={true}
      />
    )
  }

  const setNumberOfPage = (page: number) => {
    setPageNumber(page);
  };

  const setNumberElementsOnPage = (size: number) => {
    setPageSize(size);
  };

  const handleModule = (model: TableSortModel) => {
    setSortModel(model)
  };

  let hasDeleteColumn = resources && resources._embedded && resources._embedded.requestSummaries.find(e => e.deleteLink != undefined) != undefined
  const getTable = () => {
    return (<ResponsiveMaterialUITableWithPagination
      dense={true}
      collapsedElements={collapsedElements}
      data={resources && resources._embedded && renderComponentsList(resources._embedded.requestSummaries, renderEnrollmentRow)}
      columns={getTableColumns(props, hasDeleteColumn, isScreenXS)}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={(sortModel) => handleModule(sortModel)}
      pagination={true}
      paginationMode="server"
      rowsPerPage={pageSize}
      page={pageNumber}
      rowCount={resources != undefined ?
        (resources.page != undefined ? resources.page.totalElements : 0)
        : 0}
      onPageChange={setNumberOfPage}
      onPageSizeChange={setNumberElementsOnPage}
      rowsPerPageOptions={[10, 20, 50]}
      loading={loading}
      isScreenXS={isScreenXS}
    />)
  }

  if (props.operator == true) {
    if ((!resources || !resources._embedded || !resources._embedded.requestSummaries || !resources._embedded.requestSummaries.length) && props.emptyNotShow) {
      return null;
    } else if (props.excelUrl) {
      return (
        <BoxedContent title={props.title} xl={12}>
          <Box p={2}>
            <div style={{ width: "100%" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<GetAppIcon />}
                    href={props.excelUrl}>Scarico richieste</Button>
                </Grid>
              </Grid>
            </div>
          </Box>
          <Box p={2}>
            {getTable()}
          </Box>
        </BoxedContent>
      );
    } else {
      return (
        <BoxedContent title={props.title} xl={12}>
          <Box p={2}>
            {getTable()}
          </Box>
        </BoxedContent>
      );
    }
  } else {
    //utente
    if (resources && resources._embedded && resources._embedded.requestSummaries && resources._embedded.requestSummaries.length) {
      let title = "Storico azioni";
      return (
        <BoxedContent type="primary" title={title} gridItem>
          <Box p={2}>
            {getTable()}
          </Box>
        </BoxedContent>
      );
    } else {
      let innerMessage = isScreenXS ? (<Typography>
        <b>Attenzione:</b> per continuare clicca sull'icona{" "}
        <b>menù</b> situata in alto a sinistra (
        <span className="glyphicon glyphicon-menu-hamburger" />) e da
        li procedi al menù{" "}
        <b>
          {"+ " +
            getFunctionName(
              REGISTRATION_TYPE.ENROLLMENT,
              props.structureInfo
            )}
        </b>
        .
      </Typography>) : (<Typography >
        <b>Attenzione: </b>per continuare clicca su{" "}
        <b>
          {"+ " +
            getFunctionName(
              REGISTRATION_TYPE.ENROLLMENT,
              props.structureInfo
            )}
        </b>{" "}
        situato nel menù laterale.
      </Typography>)
      return (<Box p={2}><AlertMaterial severity="warning" variant="filled">
        <AlertTitle>Come procedere</AlertTitle>
        {innerMessage}
      </AlertMaterial></Box>);
    }
  }
}

const mapStateToProps = (state: GlobalStateInterface): GlobalProps => {
  return {
    structureInfo: state.global.structureInfo,
    structureCountUrl: state.global.navigationLinks._links.structuresCount != undefined ? state.global.navigationLinks._links.structuresCount.href : undefined,
    structureId: state.global.structureId,
    cookieStructureId: state.global.cookieStructureId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
  return {
    saveStructureIdForCookie: bindActionCreators(saveStructureIdForCookie, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentsComponent);

// for test purpose
export { EnrollmentsComponent as EnrollmentsComponentWithoutRedux };

