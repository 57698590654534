import { ReadUsersNews } from 'commons/interfaces/read-users-news';
import { isEmptyArray, logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import { composeUriPath } from '@insoft/lib-react-web/dist/utils/utils';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GlobalStateInterface } from 'redux/store';
import { PAGES } from 'utils/rest-routes';
import { getCustomNoRowsOverlayForDataGrid, getLoadingComponent } from 'utils';
import Box from '@mui/material/Box'
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { defaultReactDataRenderMUI } from '@insoft/lib-react-web';

interface Props {
    requestUrl: string
}

const TABLE_COLUMNS: GridColDef[] = [
    {
        headerName: 'Titolo',
        field: 'title',
        flex: 1
    },
    {
        headerName: 'Utenti che hanno letto la news',
        field: 'readUsersCount',
        flex: 1
    },
    {
        headerName: 'Dettaglio',
        field: 'newsDetail',
        renderCell: defaultReactDataRenderMUI,
        sortable: false,
        flex: 1
    }
]

function TopTableReadNews(props: Props) {

    const pageSizes = [10, 20, 50];

    const [resources, setResources] = useState<ReadUsersNews[]>(null)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "title", sort: 'asc' }]);

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (props.requestUrl) {
            executeRequest<ReadUsersNews[]>({ url: props.requestUrl, method: "GET" })
                .then((data) => {
                    setResources(data)
                }).catch((error) => {
                    logThis("Cannot fetch the values", error)
                })
        }
    }

    const renderDataTable = () => {
        const data = newsItemsToDataTable()
        return (
            <DataGrid
                autoHeight={true}
                style={{ fontSize: 15 }}
                rows={data}
                columns={TABLE_COLUMNS}
                disableRowSelectionOnClick
                disableColumnMenu
                components={{
                    NoRowsOverlay: getCustomNoRowsOverlayForDataGrid,
                }}
                pagination
                paginationMode="server"
                paginationModel={{
                    page: pageNumber,
                    pageSize: pageSize
                }}
                // rowCount={resources? (resources.page? Number(resources.page.totalElements) : 0) : 0}
                pageSizeOptions={pageSizes}
                onPaginationModelChange={(model) => {
                    setPageNumber(model.page)
                    setPageSize(model.pageSize)
                }}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(model) => handleModule(model)}
            />
        )
    }

    const newsItemsToDataTable = () => {
        const data: any = []
        if (resources && !isEmptyArray(resources)) {
            const linkLabel = 'Dettaglio News'
            let counter: number = 1
            resources.forEach((item: any) => {
                const linkUrl = composeUriPath(PAGES.newsDetails, [item.news.id])
                const actionComponent =
                    (<Link to={linkUrl}>
                        {linkLabel}
                    </Link>)
                const title = item.news.title ? item.news.title : "Titolo Mancante"
                const readUsersCount = item.readUsers ? item.readUsers.length : 0
                const obj = {
                    id: counter,
                    title: title,
                    readUsersCount: readUsersCount,
                    newsDetail: { component: actionComponent }
                }
                counter = counter + 1
                data.push(obj)
            })
        }
        return data
    }

    const handleModule = (model: GridSortModel) => {
        if (model && model.length == 1) {
            if (sortModel.length == 0) {
                setSortModel(model);
            } else if (model[0].field != sortModel[0].field || model[0].sort != sortModel[0].sort) {
                setSortModel(model);
            }
        } else if (sortModel.length != 0) {
            setSortModel(model)
        }
    };

    if (resources) {
        const dataTable = renderDataTable()
        return (
            <BoxedContent>
                <Box p={2}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                {dataTable}
                            </div>
                        </div>
                    </div>
                </Box>
            </BoxedContent>
        )
    } else {
        return getLoadingComponent()
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.newsReadUsers) {
        var requestUrl = state.global.navigationLinks._links.newsReadUsers.href
    }
    return {
        requestUrl: requestUrl
    }
}

export default connect(mapStateToProps)(TopTableReadNews)
