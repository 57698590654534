import LinkAdapter from '@insoft/lib-react-web/dist/adapters/link-adapter';
import { Link } from "@insoft/lib-react-web/dist/interfaces/hateoas";
import { SectionItem } from '@insoft/lib-react-web/dist/interfaces/sections';
import { SocialNetwork } from '@insoft/lib-react-web/dist/interfaces/social-network';
import { Contacts, Icon, Partner } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import { StructureInfo } from 'commons/interfaces/structure-info';

class IconAdapter implements Icon {
    link: string
    imgPath: string
    showInHome?: boolean
    constructor(object: { link?: string, imgPath?: string, showInHome?: boolean }) {
        this.link = object && object.link ? object.link : ''
        this.imgPath = object && object.imgPath ? object.imgPath : ''
        this.showInHome = object && object.showInHome ? object.showInHome : undefined
    }
}

class SectionItemAdapter implements SectionItem {
    title: string
    text: string
    constructor(object: SectionItem) {
        this.title = object && object.title ? object.title : ''
        this.text = object && object.text ? object.text : ''
    }
}

class SocialItemAdapter implements SocialNetwork {
    name: string
    link: string
    constructor(object: SocialNetwork) {
        this.name = object && object.name ? object.name : ''
        this.link = object && object.link ? object.link : ''
    }
}

class PartnerItemAdapter implements Partner {
    name?: string
    contacts?: Contacts
    showDataOnFooter?: boolean
    logo?: Icon

    constructor(object: Partner) {
        this.name = object && object.name ? object.name : undefined
        this.contacts = object && object.contacts ? object.contacts : undefined
        this.showDataOnFooter = object && object.showDataOnFooter ? object.showDataOnFooter : false
        this.logo = object && object.logo ? new IconAdapter(object.logo) : undefined
    }
}

export default class StructureInfoAdapter implements StructureInfo {
    name: string
    contacts?: Contacts
    logo?: Icon
    partnersLogos?: Icon[]
    partners?: Partner[]
    contactsInfo: string[]
    mainDescription?: SectionItem
    secondDescription?: SectionItem
    subSections?: SectionItem[]
    socialNetworks?: SocialNetwork[]
    _links: {
        self: Link
        news: Link
    }
    registrationLabels?: {
        [name: string]: string
    }
    todayLabel: string
    logoutRedirectUrl?: string
    helpSectionsDuringFilling?: boolean

    constructor(object: StructureInfo) {
        this.name = object && object.name ? object.name : ''
        this.contacts = object && object.contacts ? object.contacts : undefined
        this.logo = object && object.logo ? new IconAdapter(object.logo) : undefined
        let tempAray: Icon[] = []
        if (object && object.partners) {
            object.partners.forEach((element) => {
                if (element.logo) {
                    tempAray.push(new IconAdapter(element.logo))
                }
            });
        }
        if (this.logo) {
            if (tempAray.length > 0) {
                tempAray.splice(1, 0, this.logo)
            } else {
                tempAray.push(this.logo);
            }
        }
        this.partnersLogos = tempAray
        this.contactsInfo = object && object.contactsInfo ? object.contactsInfo : []
        this.mainDescription = new SectionItemAdapter(object ? object.mainDescription : undefined)
        this.secondDescription = new SectionItemAdapter(object ? object.secondDescription : undefined)
        let descriptionTempArray: SectionItem[] = []
        if (object && object.subSections) {
            object.subSections.forEach((element) => {
                descriptionTempArray.push(new SectionItemAdapter(element))
            });
        }
        this.subSections = descriptionTempArray
        this._links = {
            self: new LinkAdapter(object && object._links ? object._links.self : undefined),
            news: new LinkAdapter(object && object._links ? object._links.news : undefined)
        }

        let socialNetworkArray: SocialNetwork[] = []
        if (object && object.socialNetworks) {
            object.socialNetworks.forEach((element) => {
                socialNetworkArray.push(new SocialItemAdapter(element))
            });
        }
        this.socialNetworks = socialNetworkArray

        let partnersArray: Partner[] = []
        if (object && object.partners) {
            object.partners.forEach((element) => {
                partnersArray.push(new PartnerItemAdapter(element))
            });
        }
        this.partners = partnersArray

        this.registrationLabels = object && object.registrationLabels
        this.todayLabel = object && object.todayLabel ? object.todayLabel : 'OGGI AL NIDO'
        this.logoutRedirectUrl = object && object.logoutRedirectUrl
        this.helpSectionsDuringFilling = object && object.helpSectionsDuringFilling
    }
}