import React from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import { executeAjaxRequest } from '@insoft/lib-react-universal/dist/utils'
import { EnabledOrganization } from '@insoft/lib-react-universal/dist/interfaces/message-interface'
import ButtonWithAlert from '@insoft/lib-react-web/dist/components/misc/button/buttonWithAlert'
import { redirectToHostNameWithStructureId, redirectWithPathToAttachFromOriginChangeStructureId } from '@insoft/lib-react-web/dist/utils/utils'

interface Props {
    message?: string,
    enabledOrganizations?: EnabledOrganization[]
}
export default class GenericError extends React.Component<Props, null> {

    handleChange = (event: any) => {
        redirectToHostNameWithStructureId(event.target.value[0], event.target.value[1])
    }

    render() {
        if (this.props.enabledOrganizations) {
            const element = (<Select
                color='primary'
                value={"0"}
                onChange={this.handleChange}
                displayEmpty={true}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                <MenuItem disabled value="0">
                    <em>Seleziona struttura</em>
                </MenuItem>
                {this.props.enabledOrganizations.map(element => <MenuItem value={[element.hostName, element.structureId]}>{element.name}</MenuItem>)}
            </Select>);
            var elements: any[] = [{
                element
            }]
            return (
                <Box className='error-page-parent'>
                    <Box paddingTop={"64px"} className='error-page'>
                        <img className='img-responsive center-block' src='/dist/img/novae/generic_error.png' />
                        {/* <h2 className='error-page-message' dangerouslySetInnerHTML={{
                                __html: this.props.message ? this.props.message : "Si è verificato un problema, si prega di riprovare.",
                            }}></h2> */}
                        <p className='error-page-message' dangerouslySetInnerHTML={{
                            __html: this.props.message ? this.props.message : "Si è verificato un problema, si prega di riprovare.",
                        }}></p>
                        <Box className='error-page-select'>
                            {/* <Select
                                    style={{color: 'white', fontSize: 20}}
                                    color='primary'
                                    value={"0"}
                                    onChange={this.handleChange}
                                    displayEmpty={true}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem disabled value="0">
                                        <em>Seleziona struttura</em>
                                    </MenuItem>
                                    {this.props.enabledOrganizations.map(element => <MenuItem value={[element.hostName, element.structureId]}>{element.name}</MenuItem>)}
                                </Select>  */}
                            <ButtonWithAlert
                                message="Fai logout e login con l'utente corretto oppure seleziona una delle strutture su cui sei abilitato nel campo qua sotto."
                                type='primary'
                                elements={elements}
                                onlyOneButton={true}
                                buttonText='Cambia struttura'
                            ></ButtonWithAlert>
                        </Box>
                    </Box>
                </Box>
            )
        }
        else {
            return (
                <Box className='error-page-parent'>
                    <Box paddingTop={"64px"} className='error-page'>
                        <img className='img-responsive center-block' src='/dist/img/novae/generic_error.png' />
                        <h2 className='error-page-message'>{this.props.message ? this.props.message : "Si è verificato un problema, si prega di riprovare."}</h2>
                    </Box>
                </Box>
            )
        }
    }

}