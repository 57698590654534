import { Box } from '@mui/material';
import { Child } from 'commons/interfaces/child';
import { NewsItem } from 'commons/interfaces/news';
import { isEmptyArray } from '@insoft/lib-react-universal/dist/utils';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveNewsRecipients } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { MuiTableResponsive } from '@insoft/lib-react-mui-web';
import { TableColumnDefinition } from '@insoft/lib-react-web';

interface Props {
    requestUrl: string
    newsRecipients: Child[]
}

interface ActionCreatorsProps {
    retrieveNewsRecipients: (uri: string) => any
}

interface AllProps extends Props, ActionCreatorsProps {
    selectedNews: NewsItem
}

interface TableRow {
    firstName: string
    lastName: string
    section: string
}

function getTableColumns() {
    let tableColumns: TableColumnDefinition[] = []
    tableColumns.push({ headerName: 'Nome', field: 'firstName', sortable: true })
    tableColumns.push({ headerName: 'Cognome', field: 'lastName', sortable: true })
    tableColumns.push({ headerName: 'Sezione', field: 'section', sortable: true })
    return tableColumns
}

export class NewsDetailsRecipients extends React.Component<AllProps, null> {

    componentDidMount() {
        if (!this.props.newsRecipients || isEmptyArray(this.props.newsRecipients)) {
            this.props.retrieveNewsRecipients(this.props.requestUrl)
        }
    }

    getDatatablesData = (): TableRow[] => {
        const selectedNewsRecipients = this.props.selectedNews.recipients
        const allNewsRecipients = this.props.newsRecipients
        const tableRows: TableRow[] = []
        if (selectedNewsRecipients && !isEmptyArray(selectedNewsRecipients) && allNewsRecipients && !isEmptyArray(allNewsRecipients)) {
            selectedNewsRecipients.forEach((selectedNewsRecipient) => {
                let row = {
                    firstName: "",
                    lastName: "",
                    section: "",
                }
                allNewsRecipients.forEach((allNewsRecipient) => {
                    if (allNewsRecipient.id === selectedNewsRecipient) {
                        row = {
                            firstName: allNewsRecipient.firstName,
                            lastName: allNewsRecipient.lastName,
                            section: allNewsRecipient.section ? allNewsRecipient.section : "",
                        }
                    }
                })
                tableRows.push(row)
            })
        }
        return tableRows
    }

    renderNoRecipientsView = () => {
        return (
            <div className="box-body">
                <p>Nessun destinatario impostato per questa news.</p>
                <p>Questa news è visibile a chiunque.</p>
            </div>
        )
    }

    render() {
        if (!this.props.newsRecipients) {
            var content = (
                <div>
                    <br />
                    {getLoadingComponent(false)}
                </div>
            )
        } else {
            const datatablesData = this.getDatatablesData()
            if (isEmptyArray(datatablesData)) {
                content = this.renderNoRecipientsView()
            } else {
                content = (
                    <MuiTableResponsive
                        data={datatablesData}
                        columns={getTableColumns()}
                        sortingMode='client'
                        sorting
                    />
                )
            }
        }
        return (
            <BoxedContent title="Destinatari della news">
                <Box p={2}>
                    {content}
                </Box>
            </BoxedContent>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        requestUrl: state.global.navigationLinks._links.newsChildRecipients.href,
        newsRecipients: state.global.newsRecipients
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveNewsRecipients: bindActionCreators(retrieveNewsRecipients, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsDetailsRecipients)