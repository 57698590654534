import { WidgetProps } from '@insoft/lib-react-web';
import React from 'react';



interface State {
    value: string
}


export default class CustomNumericWidget extends React.Component<WidgetProps, State> {
    static FIELD_NAME = 'custom-numeric-widget'

    constructor(props: WidgetProps) {
        super(props)
        this.state = {
            value: props.value
        }
    }

    onChange = (event: { target: { value: string } }) => {
        let newValue = event.target.value
        this.setState({ value: newValue }, () => this.props.onChange(newValue))
    }

    render() {
        const otherProps = {
            label: this.props.label
        }
        return (
            <input
                id={this.props.id}
                className="form-control"
                type="number"
                step={this.props.schema.multipleOf || 'any'}
                min={this.props.schema.minimum}
                max={this.props.schema.maximum}
                disabled={this.props.readonly || this.props.disabled}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                value={this.state.value}
                {...otherProps}
            />
        );
    }
}