import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import { Icon } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import InvoicesArea from './invoices-area';
import LogosSection from './logos-section';
import OrganizationEnrollments from './organization-enrollments';
import OrganizationEnrollmentsTodo from './organization-enrollments-todo';
import OrganizationRequestsPreAcceptd from './organization-requests-preacceped';
import OrganizationTODOs from './organization-todos';
import ParentHelp from './parent-help';
import UserEnrollments from './user-enrollments';

interface Props {
    partnerLogos: Icon[]
    structureName: string
}

function NovaeDashboard(props: Props) {

    return (
        <Box>
            <ErrorBoundary>
                <LogosSection partnerLogos={props.partnerLogos} name={props.structureName} />
            </ErrorBoundary>
            <Box>
                <Grid container spacing={3}>
                    <ParentHelp />
                    <ErrorBoundary>
                        <InvoicesArea />
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <UserEnrollments />
                    </ErrorBoundary>
                </Grid>
                <ErrorBoundary>
                    <Box paddingTop={2} paddingBottom={2}>
                        <OrganizationTODOs />
                    </Box>
                </ErrorBoundary>
                <ErrorBoundary>
                    <Box paddingTop={2} paddingBottom={2}>
                        <OrganizationEnrollments />
                    </Box>
                </ErrorBoundary>
                <ErrorBoundary>
                    <Box paddingTop={2} paddingBottom={2}>
                        <OrganizationEnrollmentsTodo />
                    </Box>
                </ErrorBoundary>
                <ErrorBoundary>
                    <Box paddingTop={2} paddingBottom={2}>
                        <OrganizationRequestsPreAcceptd />
                    </Box>
                </ErrorBoundary>

            </Box>
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        partnerLogos: state.global.structureInfo.partnersLogos,
        structureName: state.global.structureInfo.name
    }
}

export default connect(mapStateToProps)(NovaeDashboard)
// for test purpose
export { NovaeDashboard as NovaeDashboardWithoutRedux };

