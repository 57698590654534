import { Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import { GridSortItem } from '@mui/x-data-grid';
import { PageNews } from 'commons/interfaces/page-news';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import ButtonWithAlert from '@insoft/lib-react-web/dist/components/misc/button/buttonWithAlert';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import { ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { composeUriPath, refreshPage } from '@insoft/lib-react-web/dist/utils/utils';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { PAGES } from 'utils/rest-routes';
import { defaultReactDataRenderMUITable, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel, _Links } from '@insoft/lib-react-web';
import { appendQueryParams, ComponentListItem, logThis, ReactLabelComponent, renderComponentsList } from '@insoft/lib-react-universal/dist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    optionsUrl: string,
    requestUrl: string
}

interface News {
    id: string
    order: number
    title: string
    _links?: _Links
}

interface TableRow {
    collapsibleArrow?: ReactLabelComponent
    id: number
    order: number
    title: string
    newsDetail: ReactLabelComponent,
    newsModify: ReactLabelComponent,
    newsDelete: ReactLabelComponent
}

function getTableColumns(isScreenXS?: boolean) {
    let tableColumns: TableColumnDefinition[] = []
    if (isScreenXS) {
        tableColumns.push({
            headerName: "",
            field: "collapsibleArrow",
            sortable: false,
            showMobile: true,
            mobileCollapser: true,
            renderCell: defaultReactDataRenderMUITable
        });
    }
    tableColumns.push({
        headerName: 'Ordine',
        field: 'order'
    })
    tableColumns.push({
        headerName: 'Titolo',
        field: 'title',
        showMobile: true
    })
    tableColumns.push({
        headerName: 'Dettaglio',
        field: 'newsDetail',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    tableColumns.push({
        headerName: 'Modifica',
        field: 'newsModify',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    tableColumns.push({
        headerName: 'Elimina',
        field: 'newsDelete',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    return tableColumns
}


function NewsTableComponent(props: Props) {

    const pageSizes = [10, 20, 50];

    const [resources, setResources] = useState<PageNews>(null)
    const [allowedMethods, setAllowedMethods] = useState<String[]>(null)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
    const [sortModel, setSortModel] = useState<TableSortModel>({ field: "order", sort: 'asc' });
    const classesButton = ButtonsStyles();
    const theme = useTheme();
    const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
    const [collapsedElements, setCollapsedElements] = useState<number[]>([])

    useEffect(() => {
        fetchData()
        getOptions()
    }, [sortModel, pageSize, pageNumber])

    const deleteNews = (linkUrlDelete: string) => {
        console.log(linkUrlDelete)
        executeRequest({
            url: linkUrlDelete, method: "DELETE"
        }).catch((error) => {
            logThis("Cannot delete news", error)
        })
        refreshPage()
    }

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const fetchData = () => {
        if (props.requestUrl) {
            var sortingArray: string[] = [];
            if (sortModel) {
                sortingArray.push(createParam(sortModel.field, sortModel));
            }
        }
        var requestUrl = appendQueryParams(props.requestUrl, {
            page: pageNumber.toString(),
            size: pageSize.toString(),
            sort: sortingArray,
        });
        executeRequest<PageNews>({ url: requestUrl, method: "GET" })
            .then((data) => {
                setResources(data)
            }).catch((error) => {
                logThis("Cannot fetch the values", error)
            })
    }

    const manageCollapsedElements = (element: number) => {
        let index = collapsedElements.indexOf(element, 0)
        let newCollapsedElements: number[] = []
        collapsedElements.forEach((element) => {
            newCollapsedElements.push(element)
        })
        if (index != -1) {
            newCollapsedElements.splice(index, 1)
        } else {
            newCollapsedElements.push(element)
        }
        setCollapsedElements(newCollapsedElements)
    }

    const getOptions = () => {
        fetch(props.optionsUrl, {
            method: 'OPTIONS'
        }).then((response) => {
            const stringa = response.headers.get("Allow")
            const headers = stringa.split(",").map((element) => { return element.replace(" ", "") })
            setAllowedMethods(headers)
        })
    }

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    const renderDataTable = () => {
        return (
            <ResponsiveMaterialUITableWithPagination
                dense={true}
                collapsedElements={collapsedElements}
                data={resources && resources._embedded && renderComponentsList(resources._embedded.newsDatas, renderRow)}
                columns={getTableColumns(isScreenXS)}
                pagination
                paginationMode="server"
                page={pageNumber}
                rowsPerPage={pageSize}
                rowCount={resources ? (resources.page ? Number(resources.page.totalElements) : 0) : 0}
                rowsPerPageOptions={pageSizes}
                onPageChange={setPageNumber}
                onPageSizeChange={setPageSize}
                sortingMode="server"
                sortModel={sortModel}
                onSortModelChange={(model) => handleModule(model)}
                isScreenXS={isScreenXS}
            />
        )
    }

    const renderRow = (
        news: News & ComponentListItem
    ) => {
        let buttonDelete: any = null
        let actionComponent: any = null
        const linkLabelDetails = 'Dettaglio News'
        const linkLabelModify = 'Modifica News'
        const linkActionNotAvailable = "Azione non disponibile"
        const linkUrlDetails = composeUriPath(PAGES.newsDetails, [news.id])
        const title = news.title ? news.title : "Titolo Mancante"
        let order: any = "N.D."
        if (news.order >= 0) {
            order = news.order + 1
        }
        const detailsComponent =
            (<Link to={linkUrlDetails}>
                {linkLabelDetails}
            </Link>)
        if (allowedMethods != undefined && allowedMethods.includes("DELETE")) {
            const linkUrlDelete = news._links.self.href
            buttonDelete = (<ButtonWithAlert message={"L'operazione di eliminazione sarà irreversibile. Continuare?"} action={() => deleteNews(linkUrlDelete)} icon={<DeleteIcon />}></ButtonWithAlert>)
        } else {
            buttonDelete = (<Button className={classesButton.errorStyle} type="button" variant="contained" disabled={true}>{linkActionNotAvailable}</Button>)
        }
        if (allowedMethods != undefined && allowedMethods.includes("PUT")) {
            const linkUrlModify = composeUriPath(PAGES.newsModify, [news.id])
            actionComponent =
                (<Link to={linkUrlModify}>
                    {linkLabelModify}
                </Link>)
        } else {
            actionComponent =
                (<Link to={"#"} aria-disabled={true}>
                    {linkActionNotAvailable}
                </Link>)
        }

        let collapsibleArrow: any
        if (collapsedElements.indexOf(news._itemIndex + 1, 0) != -1) {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(news._itemIndex + 1)}><ExpandLessIcon /></IconButton>
        } else {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(news._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
        }

        const obj: TableRow = {
            collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
            id: news._itemIndex + 1,
            order: order,
            title: title,
            newsDetail: { component: detailsComponent },
            newsModify: { component: actionComponent },
            newsDelete: { component: buttonDelete }
        }
        return obj;
    }

    if (resources && allowedMethods != undefined) {
        const dataTable = renderDataTable()
        return (
            <BoxedContent xl={12}>
                <Box p={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CreateIcon />}
                        href={"#" + PAGES.newsModify} >
                        Crea nuova News
                    </Button>
                </Box>
                <Box p={2}>
                    {dataTable}
                </Box>
            </BoxedContent>
        )
    } else {
        return getLoadingComponent()
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.changeNews) {
        var requestUrl = state.global.navigationLinks._links.changeNews.href
    }
    if (state.global.navigationLinks._links.optionsNews) {
        var optionsUrl = state.global.navigationLinks._links.optionsNews.href
    }
    return {
        optionsUrl: optionsUrl,
        requestUrl: requestUrl
    }
}

export default connect(mapStateToProps)(NewsTableComponent)