import { Method } from 'axios';
import { News } from 'commons/interfaces/news';
import { getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { expandUri, generateUUID, logThis } from '@insoft/lib-react-universal';
import { FormPage } from '@insoft/lib-react-web';
import { ArrayExternalPickerFieldContextInterface } from '@insoft/lib-react-web/dist/components/form-components/array-external-picker-field';
import Modal from '@insoft/lib-react-web/dist/components/misc/modal';
import { FormDataObject, FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { executeRequest, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { updateFormDataState } from '@insoft/lib-react-web/dist/utils/utils';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, parseServerErrorResponse } from 'utils';
import { NEWS_FROM } from 'utils/form';
import { styleRules } from '../content-manager/style/content-manager-styles';
import NewsRecipientsComponent from './news-recipients-component';
import { useParams } from 'react-router';
import { Grid } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean,
    postUrl: string,
    getAndPutUrl: string,
    width?: string
}

interface AllProps extends ActionCreatorsProps, Props { }

function SingleNewsComponent(props: AllProps) {
    const params = useParams()
    const [id, setId] = useState<string>(params.id)
    const [resources, setResources] = useState<FormObject>(null)
    const [formKey, setFormKey] = useState<string>(generateUUID())
    const [recipientsModal, setRecipientsModal] = useState<boolean>(false)
    const [onRecipientsPickedFormCallback, setOnRecipientsPickedFormCallback] = useState(null)
    const [alreadyPickedRecipientsIds, setAlreadyPickedRecipientsIds] = useState<string[]>(null)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()


    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (props.getAndPutUrl) {
            let formObject: FormObject
            if (id != undefined) {
                const expandedUri = expandUri(props.getAndPutUrl, { newsId: id })
                executeRequest<News>({ url: expandedUri, method: "GET" })
                    .then((data) => {
                        let formDataObject: FormDataObject = {
                            form: {
                                formSchema: NEWS_FROM,
                                formData: data,
                                title: "Modifica news",
                            }
                        }
                        formObject = {
                            data: formDataObject,
                            method: null,
                            url: null
                        }
                        setResources(formObject)
                    }).catch((error) => {
                        logThis("Cannot fetch the values", error)
                    })
            } else {
                let formDataObject: FormDataObject = {
                    form: {
                        formSchema: NEWS_FROM,
                        formData: undefined,
                        title: "Aggiunta nuova news",
                    }
                }
                formObject = {
                    data: formDataObject,
                    method: null,
                    url: null
                }
                setResources(formObject)
            }
        }
    }

    const onFormSubmit = (formObject: { formData: object }) => {
        const formData = formObject.formData
        setResources(updateFormDataState(resources, formData))
        var method: Method
        var url: string
        if (id) {
            method = 'PUT'
            url = expandUri(props.getAndPutUrl, { newsId: id })
        } else {
            method = 'POST'
            url = props.postUrl
        }
        props.startLoading()
        sendForm({ method: method, url: url, data: formData })
            .then((response) => {
                if (response.id) {
                    setId(response.id)
                }
                const action = (snackbarId: SnackbarKey) => (
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => closeSnackbar(snackbarId)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                );
                enqueueSnackbar('News aggiornata', {
                    key: generateUUID(),
                    variant: 'success',
                    title: 'Richiesta eseguita con successo',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    action: action,
                    autoHideDuration: 10000
                })
                setFormKey(generateUUID())
                props.stopLoading()

            }).catch((error) => {
                props.stopLoading()
                const parsed = parseServerErrorResponse(error)
                const action = (snackbarId: SnackbarKey) => (
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => closeSnackbar(snackbarId)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                );
                enqueueSnackbar(parsed.message, {
                    key: generateUUID(),
                    variant: 'error',
                    title: 'Errore durante la richiesta',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                    action: action,
                    autoHideDuration: null
                })
                setFormKey(generateUUID())
            });
    }

    const showRecipientsModal = () => {
        setRecipientsModal(true)
    }

    const dismissRecipientsModal = () => {
        setRecipientsModal(false)
    }

    const onRecipientsStartPick = (onRecipientsPickedFormCallback: (items: string[]) => any, alreadyPickedRecipientsIds: string[]) => {
        setOnRecipientsPickedFormCallback(() => onRecipientsPickedFormCallback)
        setAlreadyPickedRecipientsIds(alreadyPickedRecipientsIds)
        showRecipientsModal()
    }

    const onRecipientsEndPick = () => {
        setOnRecipientsPickedFormCallback(null)
        setAlreadyPickedRecipientsIds(null)
        dismissRecipientsModal()
    }

    const onRecipientsPicked = (recipients: string[]) => {
        if (onRecipientsPickedFormCallback) {
            onRecipientsPickedFormCallback(recipients)
        }
    }

    const renderRecipientsModal = () => {
        return (
            <NewsRecipientsComponent
                alreadyPickedRecipientsIds={alreadyPickedRecipientsIds}
                onRecipientsPicked={onRecipientsPicked}
            />
        )
    }

    const formContext: ArrayExternalPickerFieldContextInterface & {
        "CKEditor": any,
        "CKEditorType": any
    } = {
        pickItems: onRecipientsStartPick,
        labelPickItems: "Scegli destinatari",
        labelQuantityZero: "Nessun destinatario scelto",
        labelQuantityOne: "Un destinatario scelto",
        labelQuantityMulti: "destinatari scelti",
        "CKEditor": CKEditor,
        CKEditorType: ClassicEditor
    }
    if (props.loading) {
        return getLoadingComponent(true)
    }
    if (resources) {
        if (recipientsModal) {
            const modalBody = renderRecipientsModal()
            var recipientsModalView = (
                <Modal
                    title={"Destinatari"}
                    body={modalBody}
                    onDismiss={onRecipientsEndPick}
                    resizeBodyHeight
                />
            )
        }

        return (
            <Grid >
                <Grid item lg={12} md={10}>
                    {recipientsModalView}
                    <FormPage
                        formKey={formKey}
                        formContext={formContext}
                        formStyleRules={styleRules}
                        fields={getCommonFields()}
                        widgets={getCommonWidgets()}
                        onFormSubmit={onFormSubmit}
                        resources={resources}
                        boxStyle='warning'
                        onChange={(data) => {
                            console.log(data)
                        }}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return getLoadingComponent()
    }

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        postUrl: state.global.navigationLinks._links.newNews.href,
        getAndPutUrl: state.global.navigationLinks._links.modifyNews.href
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleNewsComponent)