import { FieldProps } from "@rjsf/utils";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';

export function EducationYearField(props: FieldProps) {

    const [value, setValue] = useState<string>(props.value)
    const [textValue, setTextValue] = useState<string>(props.value)

    const [disabled, setDisabled] = useState<boolean>(props.readonly)

    useEffect(() => {
        props.onChange(value)
        if (value?.match(/20\d\d/)) {
            const year = Number.parseInt(value)
            setTextValue(year + '/' + (year + 1))
            setDisabled(true)
        } else {
            setTextValue(value)
        }
    }, [value])






    return <TextField
        type="text"
        value={textValue === undefined ? '' : textValue}
        required={props.required}
        label={props.schema?.title}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
        }}
        InputProps={{
            readOnly: disabled,
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={_e => {
                        setValue(undefined)
                        setDisabled(props.readonly)
                    }} edge="end">
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
}