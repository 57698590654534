import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GlobalStateInterface } from 'redux/store';
import { getFunctionName, getTitleViewForPage } from 'utils';
import { REGISTRATION_TYPE } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import FormWithYearSelection from './index';
import { savePageHeaderInfo } from '../../redux/actions/global-actions';

interface Props {
    navigationLinks: Navigation,
    structureInfo: StructureInfo
}

function Modifica(props: Props) {
    const { id, yearId } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId ? PAGES.templateModificaIscrizione : PAGES.modificaIscrizione, structureInfo: props.structureInfo, id: yearId ? yearId : id })
        dispatch(savePageHeaderInfo(pageInfo))
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    return (
        <FormWithYearSelection
            id={id}
            yearId={yearId}
            nextPage={yearId == null ? PAGES.modificaIscrizioneRiepilogo : undefined}
            defaultFormTitle={getFunctionName(REGISTRATION_TYPE.MODIFICA, props.structureInfo)}
            yearSelectionUri={props.navigationLinks._links.newFrequencyChangeForm ? props.navigationLinks._links.newFrequencyChangeForm.href : undefined}
            resourceUri={yearId == null ? props.navigationLinks._links.frequencyChangeForm.href : props.navigationLinks._links.frequencyChangeTemplate.href}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(Modifica)