import { Box, Button, IconButton, TableRow, useMediaQuery, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from '@mui/icons-material/Restore';
import { GridSortModel } from "@mui/x-data-grid";
import { DeletedEnrollment, Requests } from "commons/interfaces/enrollments";
import { StructureInfo } from "commons/interfaces/structure-info";
import STYLE from "commons/styles/style";
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces/component-list-item";
import { appendQueryParams, logThis, renderComponentsList } from "@insoft/lib-react-universal/dist/utils";
import ResponsiveMaterialUITableWithPagination, { TableColumnDefinition, TableSortModel } from "@insoft/lib-react-web/dist/components/datatable-mui/table-mui-with-pagination";
import BoxedContent from "@insoft/lib-react-web/dist/components/misc/boxed-content";
import ButtonWithAlert from "@insoft/lib-react-web/dist/components/misc/button/buttonWithAlert";
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services";
import { refreshPage } from "@insoft/lib-react-web/dist/utils/utils";
import { useEffect, useState } from "react";
import { formatDate, getFunctionName } from "utils";
import { GlobalStateInterface } from "redux/store";
import { connect } from "react-redux";
import { defaultReactDataRenderMUITable } from "@insoft/lib-react-web/dist/components";
import { ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { ReactLabelComponent } from "@insoft/lib-react-universal";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface GlobalProps {
  structureInfo: StructureInfo;
  requestUrl: string;
}

interface Props {
  operator: boolean;
  title?: string;
  width?: string;
  hideType?: boolean;
  hideOrder?: boolean;
  printEmpty?: boolean;
  search?: boolean;
  hideNumber?: boolean;
  hideStartDate?: boolean;
  showChangeOrganization?: boolean;
  defaultOrder?: any[];
  sortModel?: GridSortModel;
  isParentUser?: boolean;
}

interface TableRow {
  collapsibleArrow?: ReactLabelComponent;
  number?: number;
  id: number;
  childData?: string;
  deletionDate: string;
  yearId: string;
  type?: string;
  status: ReactLabelComponent;
  delete?: ReactLabelComponent;
  restore?: ReactLabelComponent;
}

interface AllProps
  extends Props,
  GlobalProps { }

// const StyledDataGrid = withStyles({
//     root: {
//         fontSize: 14,
//         "& .MuiDataGrid-columnHeaderTitle": {
//         whiteSpace: "normal",
//         lineHeight: "1rem"
//         },
//         "& .MuiDataGrid-cell": {
//         whiteSpace: "normal",
//         }
//     }
// })(DataGrid);

function getTableColumns(props: Props, hasDeleteColumn: boolean, hasRestoreColumn: boolean, isScreenXS?: boolean) {
  let tableColumns: TableColumnDefinition[] = [];
  if (isScreenXS) {
    tableColumns.push({
      headerName: "",
      field: "collapsibleArrow",
      sortable: false,
      showMobile: true,
      mobileCollapser: true,
      renderCell: defaultReactDataRenderMUITable
    });
  }
  tableColumns.push({ headerName: "Nome", field: "childData", showMobile: true });
  tableColumns.push({
    headerName: "Data cancellazione",
    field: "deletionDate",
    showMobile: true
  });
  tableColumns.push({ headerName: "A.E.", field: "yearId" });
  if (!props.hideType) {
    tableColumns.push({ headerName: "Tipo", field: "type" });
  }
  tableColumns.push({
    headerName: "Stato",
    field: "status",
    renderCell: defaultReactDataRenderMUITable
  });
  if (hasDeleteColumn) {
    tableColumns.push({
      headerName: "",
      field: "delete",
      sortable: false,
      renderCell: defaultReactDataRenderMUITable
    });
  }
  if (hasRestoreColumn) {
    tableColumns.push({
      headerName: "",
      field: "restore",
      sortable: false,
      renderCell: defaultReactDataRenderMUITable
    });
  }
  return tableColumns;
}

RequestBin.defaultProps = {
  width: "col-sm-12",
  search: true,
  operator: true,
};

function RequestBin(props: AllProps) {
  const classesButton = ButtonsStyles();
  const pageSizes = [10, 20, 50];

  const [sortModel, setSortModel] = useState<TableSortModel>({ field: "childData", sort: "desc" });
  const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [resources, setResources] = useState<Requests>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [collapsedElements, setCollapsedElements] = useState<number[]>([]);
  const theme = useTheme();
  const isScreenXS = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    fetchData();
  }, [props.requestUrl, pageNumber, pageSize, sortModel]);

  const createParam = (paramName: string, sortItem: TableSortModel) =>
    paramName + (sortItem ? "," + sortItem.sort : "");
  const fetchData = () => {
    if (props.requestUrl) {
      setLoading(true)
      if (sortModel) {
        var sortingArray: string[] = [];
        if (sortModel.field === "childData") {
          sortingArray.push(createParam("deletedRequest.child.firstName", sortModel));
        } else {
          sortingArray.push(createParam(sortModel.field, sortModel));
        }
      }


      var requestUrl = appendQueryParams(props.requestUrl, {
        page: pageNumber.toString(),
        size: pageSize.toString(),
        sort: sortingArray,
      });
      executeRequest<Requests>({ url: requestUrl, method: "GET" })
        .then((data) => {
          setResources(data);
          setLoading(false)
          return null;
        })
        .catch((data) => {
          setLoading(false)
          logThis(data);
        });
    }
  };

  const renderEnrollmentRow = (
    deletedEnrollment: DeletedEnrollment & ComponentListItem
  ) => {
    const ND = "N.D.";
    let enrollmentId = deletedEnrollment.id;
    let child = deletedEnrollment.child;
    let deletionDate = deletedEnrollment.deletionDate !== undefined
      ? new Date(deletedEnrollment.deletionDate)
      : undefined;
    let status = deletedEnrollment.status;
    let statusClassName = STYLE.tags[status] ? STYLE.tags[status].class : ND;
    let statusLabel = STYLE.tags[status] ? STYLE.tags[status].label : ND;
    let type = deletedEnrollment.type;
    let typeLabel = getFunctionName(type, props.structureInfo) || ND;
    let yearLabel = deletedEnrollment.year ? deletedEnrollment.year : "";

    if (deletedEnrollment.checked) {
      var checked = <span className={statusClassName}>Controllato</span>;
    }

    const stateComponent = (
      <span>
        <span className={statusClassName}>{statusLabel}</span> {checked}
      </span>
    );

    let deleteButton: any
    if (deletedEnrollment && deletedEnrollment.deleteLink && deletedEnrollment.deleteLink.href) {
      deleteButton = <ButtonWithAlert size="small" message={"L'operazione di eliminazione sarà irreversibile. Continuare?"} action={() => deleteRequest(deletedEnrollment.deleteLink.href)} icon={<DeleteIcon />}></ButtonWithAlert>
    } else {
      deleteButton = <p></p>
    }

    let restoreButton: any
    if (deletedEnrollment && deletedEnrollment.deleteLink && deletedEnrollment.deleteLink.href) {
      restoreButton = <Button className={classesButton.buttonWithMargin} type="button" size="small" variant="contained" onClick={() => restoreRequest(deletedEnrollment.restoreLink.href)} startIcon={<RestoreIcon />}>{"Ripristina"}</Button>
    } else {
      restoreButton = <p></p>
    }

    let collapsibleArrow: any
    if (collapsedElements.indexOf(deletedEnrollment._itemIndex + 1, 0) != -1) {
      collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(deletedEnrollment._itemIndex + 1)}><ExpandLessIcon /></IconButton>
    } else {
      collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(deletedEnrollment._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
    }

    let enrollmentObject: TableRow = {
      collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
      id: deletedEnrollment._itemIndex + 1,
      childData: child.firstName + " " + child.lastName,
      deletionDate: formatDate(deletionDate),
      yearId: yearLabel,
      type: typeLabel,
      status: { label: statusLabel, component: stateComponent },
      delete: { component: deleteButton },
      restore: { component: restoreButton }
    };
    // if (!props.hideOrder) {
    //   enrollmentObject.totalOrder = order;
    // }
    if (!props.hideType) {
      enrollmentObject.type = typeLabel;
    }
    return enrollmentObject;
  };

  const manageCollapsedElements = (element: number) => {
    let index = collapsedElements.indexOf(element, 0)
    let newCollapsedElements: number[] = []
    collapsedElements.forEach((element) => {
      newCollapsedElements.push(element)
    })
    if (index != -1) {
      newCollapsedElements.splice(index, 1)
    } else {
      newCollapsedElements.push(element)
    }
    setCollapsedElements(newCollapsedElements)
  }

  const deleteRequest = (deleteLink: string) => {
    executeRequest({ url: deleteLink, method: "DELETE" })
      .then((data) => {
        console.log(data)
        refreshPage()
      })
      .catch((data) => {
        logThis(data);
        setLoading(false);
      });
  }

  const restoreRequest = (restoreLink: string) => {
    executeRequest({ url: restoreLink, method: "PUT" })
      .then((data) => {
        console.log(data)
        refreshPage()
      })
      .catch((data) => {
        logThis(data);
        setLoading(false);
      })
  }

  if (!props || !props.requestUrl) {
    return null;
  }

  const setNumberOfPage = (page: number) => {
    setPageNumber(page);
  };

  const setNumberElementsOnPage = (size: number) => {
    setPageSize(size);
  };

  const handleModule = (model: TableSortModel) => {
    setSortModel(model)
  };

  let hasDeleteColumn = resources && resources._embedded && resources._embedded.deletedRequestSummaries.find(e => e.deleteLink != undefined) != undefined
  let hasRestoreColumn = resources && resources._embedded && resources._embedded.deletedRequestSummaries.find(e => e.restoreLink != undefined) != undefined
  const getTable = () => {
    return (<ResponsiveMaterialUITableWithPagination
      dense={true}
      collapsedElements={collapsedElements}
      data={resources && resources._embedded && renderComponentsList(resources._embedded.deletedRequestSummaries, renderEnrollmentRow)}
      columns={getTableColumns(props, hasDeleteColumn, hasRestoreColumn, isScreenXS)}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={(sortModel) => handleModule(sortModel)}
      pagination={true}
      paginationMode="server"
      rowsPerPage={pageSize}
      page={pageNumber}
      rowCount={resources != undefined ?
        (resources.page != undefined ? resources.page.totalElements : 0)
        : 0}
      onPageChange={setNumberOfPage}
      onPageSizeChange={setNumberElementsOnPage}
      rowsPerPageOptions={[10, 20, 50]}
      loading={loading}
      isScreenXS={isScreenXS}
    />)
  }

  return (
    <BoxedContent type="primary" gridItem>
      <Box p={2}>
        {getTable()}
      </Box>
    </BoxedContent>
  );

}

const mapStateToProps = (state: GlobalStateInterface): GlobalProps => {
  return {
    structureInfo: state.global.structureInfo,
    requestUrl: state.global.navigationLinks._links.requestBin.href
  }
};

export default connect(mapStateToProps)(RequestBin)
