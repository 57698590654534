import { Box, Button, Grid, Typography } from '@mui/material';
import { ReadUsersNews } from 'commons/interfaces/read-users-news';
import { expandUri, isEmptyArray, logThis } from '@insoft/lib-react-universal/dist/utils';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, isValidURL } from 'utils';
import NewsDetailsRecipients from './news-details-recipients';
import { useParams } from 'react-router';
import { MuiTableResponsive } from '@insoft/lib-react-mui-web';
import { TableColumnDefinition } from '@insoft/lib-react-web';

const TABLE_COLUMNS: TableColumnDefinition[] = [
    {
        headerName: 'Nome',
        field: 'firstname',
    },
    {
        headerName: 'Cognome',
        field: 'lastname'
    },
    {
        headerName: 'Email',
        field: 'email'
    },
    {
        headerName: 'Orario Visualizzazione',
        field: 'timestamp'
    }
]


interface Props {
    requestUrl: string
}

function NewsDetails(props: Props) {
    const newsId = useParams().id;

    const [resources, setResources] = useState<ReadUsersNews>(null)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (props.requestUrl) {
            var url = expandUri(props.requestUrl, {
                newsId: newsId
            })
            executeRequest<ReadUsersNews>({ url: url, method: "GET" })
                .then((data) => {
                    setResources(data)
                }).catch((error) => {
                    logThis("Cannot fetch the values", error)
                })
        }
    }

    const renderReadNews = () => {
        if (resources) {
            const data = usersDataToDataTable()
            var content = (
                <MuiTableResponsive
                    data={data}
                    columns={TABLE_COLUMNS}
                    sortingMode='client'
                    sorting
                />
            )
        } else {
            content = (<p>Non ci sono dati da visualizzare</p>)
        }
        return (
            <BoxedContent title="Utenti che hanno letto la news">
                {content}
            </BoxedContent>
        )
    }

    const usersDataToDataTable = () => {
        const readUsers = resources.readUsers
        const data: any = []
        if (readUsers && !isEmptyArray(readUsers)) {
            readUsers.forEach((item) => {
                let { firstName, lastName, email, timestamp } = item
                const obj = {
                    firstname: firstName,
                    lastname: lastName,
                    email: email,
                    timestamp: timestamp
                }
                data.push(obj)
            })
        }
        return data
    }

    const newsRender = () => {
        if (resources) {
            const news = resources.news
            if (news.urlImg || news.base64Image) {
                const imageSrc = news.base64Image ? news.base64Image : news.urlImg
                var imgView = (
                    <Box flex="" justifyContent="flex-start" p={2}>
                        <img className='left margin' src={imageSrc} style={{ maxWidth: "100%" }} />
                    </Box>


                )
            }
            if (news.link && news.link.url && isValidURL(news.link.url) && news.link.text) {
                var linkButton = (
                    <Button href={news.link.url} variant="contained" color="primary">{news.link.text}</Button>
                )
            }
            return (
                <BoxedContent title={news.title} >
                    <Box p={2}>
                        <Typography variant="body1">{news.body}</Typography>
                        {linkButton}
                        {imgView}
                    </Box>
                </BoxedContent>
            )
        } else {
            return getLoadingComponent()
        }
    }

    const renderNewsRecipients = () => {
        if (resources) {
            const news = resources.news
            return (
                <NewsDetailsRecipients
                    selectedNews={news}
                />
            )
        }
    }


    const news = newsRender()
    const readNews = renderReadNews()
    const newsRecipients = renderNewsRecipients()
    return (
        <Grid container spacing={3}>
            {news}
            {newsRecipients}
            {readNews}
        </Grid>
    )
}


const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.singleNewsReadUsers) {
        var requestUrl = state.global.navigationLinks._links.singleNewsReadUsers.href
    }
    return {
        requestUrl: requestUrl
    }
}

export default connect(mapStateToProps)(NewsDetails)