import { useState } from 'react';
import ParentsAppSearchForm from './parent-app-search-form';
import ParentsAppSimulatorContainer from './parent-app-simulator-container';
import { executeAsync, generateUUID } from "@insoft/lib-react-universal/dist/utils";
import { getLoadingComponent } from "utils";
import { SEARCH_FILEDS } from "./parent-app-search-form-object";
import { Grid } from "@mui/material";


export default function ParentsAppSimulator() {

    const [elementKey, setElementKey] = useState<string>(generateUUID())
    const [PageRefresh, setPageRefresh] = useState<boolean>(false)
    const [parentID, setParentID] = useState<string>(undefined)


    const updateParentIdForReload = (data: { [searchKey: string]: string }) => {
        forcePageRefresh()
        setElementKey(generateUUID())
        setParentID(data[SEARCH_FILEDS.PARENT])
    }


    const forcePageRefresh = () => {
        setPageRefresh(true)
        executeAsync(() => {
            setPageRefresh(false)
        }, 500)
    }



    if (PageRefresh) {
        var parentContainer = getLoadingComponent(false)
    } else if (parentID) {
        parentContainer = (
            <ParentsAppSimulatorContainer parentID={parentID} key={elementKey} />
        )
    }

    return (
        <Grid container spacing={3} >
            <Grid item xs={12}>
                <ParentsAppSearchForm searchCallBack={updateParentIdForReload} />
            </Grid>
            <Grid item xs={12}>
                {parentContainer}
            </Grid>
        </Grid>
    )

}

