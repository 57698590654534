//import Footer from 'commons-components/footer';
//import NovaeHeader from 'commons-components/header/header';
//import NovaeNavigation from 'commons-components/navigation/navigation-menu';
import NovaeNavigation from 'commons/components/navigation/navigation-menu'
import { Navigation } from 'commons/interfaces/navigation';
import { UserInfo } from 'commons/interfaces/user-info';
import { Messages } from '@insoft/lib-react-universal/dist/interfaces/message-interface';
import { getMessageByParam } from '@insoft/lib-react-universal/dist/redux/redux-utils';
import { getQueryParamsFromWindowSearch } from '@insoft/lib-react-web/dist/utils/utils';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveInitializationInfo, saveStructureId } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { SINGLETON_ERRORS, STRINGS } from 'utils/constants';
import { MODULE_ROOTS, MULTI_STRUCTURE_MODULE_PAGES, PAGES } from 'utils/rest-routes';
import Error404 from 'src/pages/error-pages/404';
import GenericError from 'src/pages/error-pages/generic-error';
import MultiStructurePresencesReport from 'src/pages/multi-structure-presences-report'
import { createStyles, makeStyles, Theme as ThemeMaterial } from '@mui/material/styles';
import Footer from '@insoft/lib-react-web/dist/themes/material-ui/footer'
import Box from '@mui/material/Box'
import { NOVAE_PALETTE } from 'commons/styles/material-ui-style';
import { StructureInfo } from 'commons/interfaces/structure-info';

interface ActionCreatorsProps {
    retrieveInitializationInfo: (structureId: string) => any
    saveStructureId: (structureId: string) => any
}

interface Props {
    navigationLinks: Navigation
    userInfo: UserInfo
    structureInfo: StructureInfo // TODO togliere
    messages: Messages
    structureId: string// TODO togliere
}


interface AllProps extends ActionCreatorsProps, Props { }


class MultiStructureRouter extends React.Component<AllProps, null> {


    multiStructureFull = () => {
        return <Box sx={{
            display: "flex",
            width: "100%"
        }} >

            <NovaeNavigation
                needNavbarArea
                loginRedirectUrl={this.props.structureInfo.logoutRedirectUrl}
                structureId={this.props.structureId}
                navigationLinks={this.props.navigationLinks}
                userInfo={this.props.userInfo}
                structureInfo={this.props.structureInfo}
            />
            <Box display="flex" flexDirection="column" width="100vw" minHeight="100vh"  >
                <Box display="flex" flexGrow={1} justifyContent="center" style={{ background: NOVAE_PALETTE.palette.primary.light }}>
                    <MultiStructurePresencesReport />
                </Box>
                <Box alignContent="flex-end">
                    <Footer structureInfo={this.props.structureInfo} />
                </Box>
            </Box>
        </Box>
    }
    componentDidMount() {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            this.props.saveStructureId(queryParams.structureId)
        }
        this.props.retrieveInitializationInfo(queryParams.structureId)
    }

    notFoundRoute = () => (
        <Navigate to={PAGES.notFound} />
    )

    // TODO eventualmente aggiungere footer
    multistructureModuleRoutes = () => (
        <div>
            <Routes>
                <Route element={this.multiStructureFull()} path={MODULE_ROOTS.multiStructureModule + MULTI_STRUCTURE_MODULE_PAGES.presences} />
                <Route element={this.notFoundRoute()} />
            </Routes>
        </div>
    )

    isInitCompleted = () => {
        const { navigationLinks, userInfo } = this.props
        return navigationLinks && userInfo
    }

    render() {
        const navigationNotFoundError = getMessageByParam(this.props.messages, "id", SINGLETON_ERRORS.NAVIGATION_404)
        if (navigationNotFoundError) {
            return (
                <Error404 message={STRINGS.ERROR_NAVIGATION_NOT_FOUND} />
            )
        }

        const genericError = getMessageByParam(this.props.messages, "id", SINGLETON_ERRORS.INIT_ERROR)
        if (genericError) {
            return (
                <GenericError />
            )
        }
        if (!this.isInitCompleted()) {
            return getLoadingComponent(true)
        }
        return (
            <Routes>
                <Route element={<Error404 />} path={PAGES.notFound} />
                <Route element={this.multistructureModuleRoutes()} />
            </Routes>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        userInfo: state.global.userInfo,
        structureInfo: state.global.structureInfo,
        messages: state.global.messages,
        structureId: state.global.structureId
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveInitializationInfo: bindActionCreators(retrieveInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiStructureRouter)
