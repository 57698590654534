import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { INITIAL_STATE } from 'redux/store';
import configureStore from 'redux/store/configureStore';
import { AppHoc } from '../router';
import { initApp } from 'utils/app-initializer';
import { createRoot } from 'react-dom/client';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { getContentFromMetaWithName } from '@insoft/lib-react-web';
// Initialize the App
initApp()

const nonce = getContentFromMetaWithName('csp-nonce');

const cache = createCache({
    key: 'mui',
    nonce: nonce, // Passa la nonce
});


// Initialize the Redux store
const store = configureStore(INITIAL_STATE)

const container = document.getElementById('react-root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Provider store={store}>
    <CacheProvider value={cache}>
        <HashRouter>
            <AppHoc />
        </HashRouter>
    </CacheProvider>
</Provider>)
