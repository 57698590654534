import { Alert } from '@mui/material';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GlobalStateInterface } from 'redux/store';
import { getFunctionName, getTitleViewForPage } from 'utils';
import { REGISTRATION_TYPE } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import FormWithYearSelection from './index';
import { savePageHeaderInfo } from '../../redux/actions/global-actions';

interface Props {
    navigationLinks: Navigation
    structureInfo: StructureInfo
}

function NewEnrollment(props: Props) {

    const { id, yearId } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId == null ? PAGES.nuovaIscrizioneFrequenza : PAGES.templateNuovaIscrizioneFrequenza, structureInfo: props.structureInfo, id: yearId == null ? id : yearId })
        dispatch(savePageHeaderInfo(pageInfo))
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    const component = (props.structureInfo.helpSectionsDuringFilling ? (
        <Alert severity="warning" variant="filled" icon={false}>
            <p dangerouslySetInnerHTML={{ __html: "<h4>Come procedere</h4><p>Compilare la seguente pagina con i dati richiesti. Una volta terminato cliccare il pulsante <i>\"Avanti\"</i> a fondo pagina.</p><p>Se si lascia o si ricarica la pagina le modifiche verranno perse.</p>" }}></p>
        </Alert>
    ) : null)
    return (
        <FormWithYearSelection
            id={id}
            yearId={yearId}
            nextPage={yearId == null ? PAGES.nuovaIscrizioneDichiarazioni : PAGES.templateNuovaIscrizioneDichiarazioni}
            defaultFormTitle={getFunctionName(REGISTRATION_TYPE.ENROLLMENT, props.structureInfo)}
            yearSelectionUri={props.navigationLinks._links.newEnrollmentForm ? props.navigationLinks._links.newEnrollmentForm.href : undefined}
            resourceUri={yearId == null ? props.navigationLinks._links.enrollmentForm.href : props.navigationLinks._links.enrollmentTemplate.href}
            checkEnrollmentsUri={props.navigationLinks._links.checkOtherStructureEnrollment ? props.navigationLinks._links.checkOtherStructureEnrollment.href : undefined}
            helpSection={component}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(NewEnrollment)