import { FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from '@insoft/lib-react-web';
import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import { FormObject, ServerFormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { Structure } from 'commons/interfaces/user-info';
import { Props } from './search-staff';


export const SEARCH_FILEDS = {
    NAME: 'name',
    SURNAME: 'lastName',
    STRUCTUREID: "structureId"
}


export function getSearchStaffFormObject(props: Props): FormObject {
    let serverFormObject: ServerFormObject
    let array: object[] = []
    // let cstPks: string = "["
    if (props.authorizedStructures != undefined && props.authorizedStructures instanceof Array) {
        (props.authorizedStructures as Structure[]).map((element) => {
            array.push({
                type: "string",
                enum: [
                    element.structureId
                ],
                title: element.name
            })
            // cstPks = cstPks + "'" + element.structureId + "', "
        })
        // cstPks = cstPks.substring(0, cstPks.lastIndexOf(","))
    }
    // cstPks = cstPks + "]"

    let properties: { [key: string]: any } = {

    }

    properties[SEARCH_FILEDS.NAME] = {
        type: "string",
        title: "Nome"
    }
    properties[SEARCH_FILEDS.SURNAME] = {
        type: "string",
        title: "Cognome"
    }
    if (props.authorizedStructures != undefined && props.authorizedStructures instanceof Array) {
        properties[SEARCH_FILEDS.STRUCTUREID] = {
            type: "string",
            title: "Struttura",
            represents: FORM_LIST_TYPE.DROPDOWN_LIST,
            placeholder: "Tutte le strutture in lista",
            anyOf: array
        }
    } else {
        properties[SEARCH_FILEDS.STRUCTUREID] = {
            type: "array",
            title: "Struttura",
            represents: [
                FORM_LIST_TYPE.DROPDOWN_LIST,
                FORM_LIST_TYPE.MULTI_SELECT_LIST
            ],
            autocomplete: {
                href: props.authorizedStructures
            }
        }
    }
    serverFormObject = {
        PUT: {
            form: {
                formSchema: {
                    type: "object",
                    properties: properties,
                    required: [SEARCH_FILEDS.STRUCTUREID]
                },
                formData: props.staffFilters
            }
        },
        _links: {
            self: {
                href: ''
            }
        }
    }
    return new FormObjectAdapter(serverFormObject)
}