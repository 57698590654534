import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { Link } from "@insoft/lib-react-web/dist/interfaces/hateoas";
import { Year, YearSelection } from 'commons/interfaces//year-selection';

export class YearAdapter implements Year {
    enable: boolean
    label: string
    uri: string

    constructor(enable: boolean, label: string, uri: string) {
        this.enable = enable
        this.label = label || ''
        this.uri = uri || ''
    }
}

export default class YearSelectionAdapter implements YearSelection {
    buttons: Year[] = []

    constructor(array: YearSelection) {
        let btn: Year[] = []
        if (Array.isArray(array)) {
            try {
                array.forEach((item) => {
                    item.links.forEach((linkItem: Link) => {
                        if (linkItem.rel === 'self') {
                            btn.push(new YearAdapter(item.enable, item.label, linkItem.href))
                        }
                    })
                })
            } catch (e) {
                logThis(e)
            }
        }
        this.buttons = btn
    }
}