

import { Alert, Box, Snackbar } from "@mui/material";
import { BoxedContent, CollectionModel, EntityModel, JsonSchema, LoadingComponent, executeRequest } from "@insoft/lib-react-web";
import { MaterialUIForm } from "@insoft/lib-react-mui-web";
import { ReactNode, useEffect, useState } from "react";
import { IntlProvider, LocalizationProvider, load } from "@progress/kendo-react-intl";
import { MonthView, Scheduler, SchedulerDataChangeEvent, SchedulerDateChangeEvent, SchedulerFormProps, SchedulerHeader, SchedulerHeaderProps, SchedulerItem, SchedulerItemProps, SchedulerViewItem, SchedulerViewItemProps, SchedulerViewSlot } from "@progress/kendo-react-scheduler";
import { useSelector } from "react-redux";
import { GlobalStateInterface } from "../../redux/store";
import { Navigation } from "../../commons/interfaces/navigation";
import { FORMAT_ISO8601, clone, expandUri, formatDateObject, generateUUID, getDateFromString, logThis } from "@insoft/lib-react-universal";

import weekData from "cldr-core/supplemental/weekData.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";

import numbers from "cldr-numbers-full/main/it/numbers.json";
import dateFields from "cldr-dates-full/main/it/dateFields.json";
import currencies from "cldr-numbers-full/main/it/currencies.json";
import caGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import timeZoneNames from "cldr-dates-full/main/it/timeZoneNames.json";

import "@progress/kendo-date-math/tz/Europe/Rome";

import { Child } from "../../commons/interfaces/entities";
import { Operator, Section } from "../../commons/interfaces/organization-data";
import { CustomEventForm } from "./event-form";
import { fetchChildren } from "../../commons/services/child-data-service";
import { fetchEducators, fetchSections } from "../../commons/services/organization-data-service";
import { EditableProp } from "@progress/kendo-react-scheduler/dist/npm/models";
import { SchedulerCalendarEvent } from "../../commons/interfaces/calendar";
import { loadItalianMessagesForKendo } from '@insoft/lib-react-web'

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadItalianMessagesForKendo()

type StatusType = 'AVAIBLE' | 'BOOKED_UP' | 'VALIDATED';
interface SearchEventsData {
    section?: number,
    operator?: number,
    month: Date
}

interface EventResponseItem extends EntityModel {
    id: number,
    from: string, // dateTime
    to: string // dateTime
    section: number
    child?: number,
    educators?: number[],
    status?: StatusType
}

interface EventSchedulerItem extends EntityModel {
    id: number,
    day?: Date,
    start: Date, // dateTime
    end: Date // dateTime
    section: Section | number
    child: Child | number,
    operators: Operator[] | number[],
    title?: string,
    status?: StatusType
}





const toStartOfMonth = (date: Date) => {
    const trucate = new Date(date)
    trucate.setMilliseconds(0)
    trucate.setSeconds(0)
    trucate.setMinutes(0)
    trucate.setHours(0)
    trucate.setDate(1)
    return trucate;
}

const ISOLOCALDATETIME = 'YYYY-MM-DDTHH:mm:ss';
const toSchedulerItem = (i: EventResponseItem): EventSchedulerItem => {
    return {
        start: getDateFromString(i.from, ISOLOCALDATETIME),
        end: getDateFromString(i.to, ISOLOCALDATETIME),
        id: i.id,
        section: i.section,
        child: i.child,
        operators: i.educators,
        status: i.status,
        _links: i._links
    };
};
export default function CalendarPage() {
    const [searchDate, setSearchDate] = useState<Date>(toStartOfMonth(new Date()))
    const [loading, setLoading] = useState<Set<string>>(new Set())
    const [sections, setSections] = useState<Section[]>(undefined)
    const [section, setSection] = useState<number>(undefined)
    const [children, setChildren] = useState<Child[]>(undefined)
    const [educators, setEducators] = useState<Operator[]>(undefined)
    const [create, setCreate] = useState<string>(undefined)
    const [error, setError] = useState<string>(undefined)
    const [calendarItems, setCalendarItems] = useState<EventSchedulerItem[]>([])


    const navigation: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks)
    const eventsUri = navigation._links?.calendar?.href

    const notifyLoading = (uuid: string) => {
        console.log("Start load " + uuid)
        loading.add(uuid)
        setLoading(new Set(loading))
    }

    const removeLoading = (uuid: string) => {
        console.log("Stop load " + uuid)
        loading.delete(uuid)
        setLoading(new Set(loading))
    }

    useEffect(() => {
        const sectionUuid = generateUUID();
        notifyLoading(sectionUuid)
        fetchSections({ date: searchDate }, navigation)
            .then(data => {
                setSections(data)
                removeLoading(sectionUuid)
            }).catch(error => {
                logThis(error)
                removeLoading(sectionUuid)
            })
        const childUuid = generateUUID();
        notifyLoading(childUuid)
        fetchChildren({
            from: searchDate,
            fetchAll: true,
            decodeChildData: true,
            fetchEntireYear: true
        }, navigation).then(children => {
            setChildren(children);
            removeLoading(childUuid)
        }).catch(error => {
            logThis(error)
            removeLoading(childUuid)
        })
        const educatorsUuid = generateUUID();
        notifyLoading(educatorsUuid)
        fetchEducators({
            date: searchDate,
            onlyName: true
        }, navigation).then(educators => {
            setEducators(educators);
            removeLoading(educatorsUuid)
        }).catch(error => {
            logThis(error)
            removeLoading(educatorsUuid)
        })
    }, [searchDate])


    useEffect(() => {
        if (!eventsUri) {
            return
        }
        const uuid = generateUUID()
        notifyLoading(uuid)
        const uri = expandUri(eventsUri, {
            from: formatDateObject(searchDate, FORMAT_ISO8601),
            to: formatDateObject(new Date(searchDate.getFullYear(), searchDate.getMonth() + 1, 0), FORMAT_ISO8601),
            section: section?.toString()
        })
        executeRequest<CollectionModel<EventResponseItem>>({ url: uri })
            .then(data => {
                if (data._embedded) {
                    let items = data._embedded["calendarEventDatas"] as EventResponseItem[]
                    setCalendarItems(items.map(toSchedulerItem))
                }
                setCreate(data._links?.create?.href)
                removeLoading(uuid)
            }).catch(error => {
                removeLoading(uuid)
                logThis(error)
            })
    }, [section, searchDate])

    console.log(loading)
    if (loading.size > 0) {
        return <LoadingComponent fullPage />
    }

    if (sections) {
        var sectionOptions = sections.map((item: Section) => {
            return {
                'type': 'number',
                'enum': [
                    item.id
                ],
                'title': item.name
            }
        })
    }

    const popItem = (item: EventSchedulerItem) => {
        const newItems: EventSchedulerItem[] = []
        newItems.push(...calendarItems.filter(i => item.id == undefined || i.id != item.id))
        newItems.push(item)
        setCalendarItems(newItems)
    }

    const deleteItem = (deleted: EventSchedulerItem) => {
        const newItems: EventSchedulerItem[] = []
        newItems.push(...calendarItems.filter(i => i.id != deleted.id))
        setCalendarItems(newItems)
    }


    const jsonSchema: JsonSchema = {
        type: 'object',
        properties: {
            'section': {
                type: 'number',
                title: 'Sezione',
                sm: 6,
                xs: 12
            }
            // ,'operatore': {
            //     type: 'string',
            //     title: 'Educatore',
            //     sm: 6,
            //     xs: 12
            // }
        }
    }

    if (sectionOptions) {
        jsonSchema.properties['section'].anyOf = sectionOptions
    }


    const handleDateChange = (args: SchedulerDateChangeEvent) => {
        setSearchDate(toStartOfMonth(args.value))
    }

    const onSearchSubmit = (formData: {
        formData: {
            section?: number
        }
    }) => {
        setSection(formData.formData?.section)
    }

    const processError = ((action: string, error: any) => {
        if (error.response?.errorCode == "overlappingItem") {
            setError(`Impossibile ${action} l'evento. E' in sovrapposzione con un'altro!`)
        } else {
            logThis(error)
            setError(`Impossibile ${action} l'evento`)
        }
    })

    const onDataChange = (event: SchedulerDataChangeEvent) => {

        if (event.created && create) {
            event.created.forEach((addedItem: EventSchedulerItem) => {
                const uuid = generateUUID()
                notifyLoading(uuid)
                let start, end: Date;
                if (addedItem.day) {
                    start = new Date(addedItem.day)
                    start.setHours(addedItem.start.getHours())
                    start.setMinutes(addedItem.start.getMinutes())

                    end = new Date(addedItem.day)
                    end.setHours(addedItem.end.getHours())
                    end.setMinutes(addedItem.end.getMinutes())
                } else {
                    start = addedItem.start
                    end = addedItem.end
                }
                const itemToPost: EventResponseItem = {
                    id: null,
                    from: formatDateObject(start, ISOLOCALDATETIME),
                    to: formatDateObject(end, ISOLOCALDATETIME),
                    section: (addedItem.section as Section).id,
                    child: (addedItem.child as Child)?.anaID,
                    educators: addedItem.operators ? (addedItem.operators as Operator[]).map((i: Operator) => parseInt(i.pk)) : undefined
                }
                executeRequest<EventResponseItem>({
                    url: create, method: 'POST', data: itemToPost, retryPolicy: {
                        maxRetryTime: 0
                    }
                })
                    .then((item) => {
                        popItem(toSchedulerItem(item))
                        removeLoading(uuid)
                    })
                    .catch(error => {
                        processError('creare', error)
                        removeLoading(uuid)
                    })
            });
        }
        if (event.updated) {
            event.updated.forEach((updatedItem: EventSchedulerItem) => {
                // verifichiamo se effettivamente aggiornato
                const oldItem = calendarItems.find(i => i.id == updatedItem.id)
                if (oldItem.child == updatedItem.child && oldItem.operators == updatedItem.operators &&
                    oldItem.start?.getTime() == updatedItem.start?.getTime() && oldItem.end?.getTime() == updatedItem.end?.getTime()) {
                    return;
                }

                const updateLink = updatedItem._links?.edit?.href
                if (!updateLink) {
                    return
                }
                const uuid = generateUUID()
                notifyLoading(uuid)
                const itemToPost: EventResponseItem = {
                    id: updatedItem.id,
                    from: formatDateObject(updatedItem.start, ISOLOCALDATETIME),
                    to: formatDateObject(updatedItem.end, ISOLOCALDATETIME),
                    section: (updatedItem.section as Section).id,
                    child: (updatedItem.child as Child)?.anaID,
                    educators: updatedItem.operators ? (updatedItem.operators as Operator[]).map((i: Operator) => parseInt(i.pk)) : undefined
                }
                executeRequest<EventResponseItem>({
                    url: updateLink, method: 'PUT', data: itemToPost, retryPolicy: {
                        maxRetryTime: 0
                    }
                })
                    .then((item) => {
                        popItem(toSchedulerItem(item))
                        removeLoading(uuid)
                    })
                    .catch(error => {
                        processError('modificare', error)
                        removeLoading(uuid)
                    })
            })
        }
        if (event.deleted) {
            event.deleted.forEach((deletedItem: EventSchedulerItem) => {
                const deleteLink = deletedItem._links?.edit?.href
                if (!deleteLink) {
                    return
                }
                const uuid = generateUUID()
                notifyLoading(uuid)

                executeRequest<EventResponseItem>({
                    url: deleteLink, method: 'DELETE', retryPolicy: {
                        maxRetryTime: 0
                    }
                })
                    .then((item) => {
                        deleteItem(toSchedulerItem(item))
                        removeLoading(uuid)
                    })
                    .catch(error => {
                        logThis(error)
                        setError("Impossibile cancellare l'evento")
                        removeLoading(uuid)
                    })
            })
        }
    }

    if (error) {
        var alert = <Snackbar open={error != undefined} autoHideDuration={6000} onClose={() => setError(undefined)} anchorOrigin={{
            vertical: "top",
            horizontal: "center"
        }}>
            <Alert variant="filled" onClose={() => setError(undefined)} color="error">{error}</Alert>
        </Snackbar>
    }

    const ColoredItem = (props: SchedulerItemProps) => (
        <SchedulerItem
            {...props}
            style={{
                ...props.style,
                backgroundColor: `${(props.dataItem as EventSchedulerItem).status == 'AVAIBLE' ? "green" : "orange"}`,
            }}
        />
    );

    const currentView = "month"
    const dataBindedItems = calendarItems.map(i => {
        const currentSection: Section = typeof i.section === "number" ? sections?.find(s => s.id == i.section) : i.section as Section
        const currentChild: Child = typeof i.child === "number" ? children?.find(s => s.anaID == i.child) : i.child as Child
        const title = formatDateObject(i.start, 'HH:mm') + " " + formatDateObject(i.end, 'HH:mm') + " - " + (currentChild?.completeName || currentSection?.name)
        return {
            ...i,
            title: title,
            section: currentSection,
            child: currentChild,
            operators: i.operators?.map(ope => typeof ope === "number" ? educators.find(e => e.pk == ope.toString()) : ope)
        }
    })
    return (<Box>
        {alert}
        <BoxedContent >
            <Box p={2}>
                <MaterialUIForm
                    data={{
                        section: section
                    }}
                    formSchema={jsonSchema}
                    onFormSubmit={onSearchSubmit}
                    buttonText="Cerca" />
            </Box>
        </BoxedContent>
        <Box mt={3}>
            {loading.size > 0 ?
                <LoadingComponent /> :
                <LocalizationProvider language="it-IT">
                    <IntlProvider locale="it">
                        <Scheduler
                            data={dataBindedItems}
                            // onDataChange={handleDataChange}
                            view={currentView}
                            date={searchDate}
                            onDateChange={handleDateChange}
                            onDataChange={onDataChange}
                            viewItem={(itemProps: SchedulerViewItemProps) => {
                                const dataItem = itemProps.dataItem as SchedulerCalendarEvent
                                const editable = dataItem.start > new Date() && dataItem.child == undefined
                                const customEditable: EditableProp = {
                                    edit: editable && (itemProps.editable as EditableProp)?.edit,
                                    remove: editable && (itemProps.editable as EditableProp)?.remove,
                                    drag: editable && (itemProps.editable as EditableProp)?.drag,
                                    resize: editable && (itemProps.editable as EditableProp)?.resize,
                                    add: (itemProps.editable as EditableProp)?.add,
                                    select: editable && (itemProps.editable as EditableProp)?.select,
                                }
                                console.log(JSON.stringify(dataItem))
                                console.log(JSON.stringify(customEditable))
                                return <SchedulerViewItem  {...itemProps} editable={customEditable}
                                    onDrag={editable ? itemProps.onDrag : undefined} />
                            }}
                            viewSlot={(props) => {
                                return (
                                    <SchedulerViewSlot
                                        {...props}
                                        style={{
                                            ...props.style,
                                            minHeight: 120,
                                        }}
                                    />
                                );
                            }}
                            editable={{
                                add: create != undefined,
                                remove: true,
                                drag: true,
                                resize: true,
                                edit: true,
                                select: true
                            }}
                            timezone="Europe/Rome"
                            header={(props: SchedulerHeaderProps) => {
                                const newChildren: ReactNode[] = clone(props.children as ReactNode[])
                                newChildren.pop()
                                return <SchedulerHeader {...props} children={newChildren} />
                            }}
                            height="auto"
                            form={(schedulerFormProps: SchedulerFormProps) => {
                                console.log(JSON.stringify(schedulerFormProps.dataItem))
                                return <CustomEventForm {...schedulerFormProps} sections={sections} section={section} children={children} eventDefaultDurationMinutes={60}
                                    currentView={currentView} educators={educators} />
                            }}
                            item={ColoredItem}

                        >
                            <MonthView itemsPerSlot={10} />
                        </Scheduler>
                    </IntlProvider>
                </LocalizationProvider>
            }
        </Box>
    </Box >)
}

