import { getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { logThis, generateUUID } from '@insoft/lib-react-universal/dist/utils';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { FormContext } from '@insoft/lib-react-web/dist/interfaces/form-interfaces';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { getFormData, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { updateFormDataState } from '@insoft/lib-react-web/dist/utils/utils';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, parsePositiveResponse, parseServerErrorResponse, renderAlertComponent } from 'utils';
import { styleRules } from './style/content-manager-styles';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
}

interface AllProps extends ActionCreatorsProps, Props {
    width?: string
    requestUrl?: string
    title?: string
    eventCallback?: () => {}
    formContext?: FormContext
}

interface State {
    resources: FormObject
    serverResponse: AlertObject
    formKey: string
}

class ContentManagerComponent extends React.Component<AllProps, State> {
    static defaultProps = {
        width: 'col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1'
    }

    constructor(props: AllProps) {
        super(props)
        this.state = {
            resources: null,
            serverResponse: null,
            formKey: generateUUID()
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.props.startLoading()
        getFormData({ url: this.props.requestUrl })
            .then((data) => {
                this.props.stopLoading()
                this.setState({
                    resources: data
                })
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    onFormSubmit = (formObject: { formData: object }) => {
        const formData = formObject.formData
        const method = this.state.resources.method
        const url = this.state.resources.url
        this.setState({
            resources: updateFormDataState(this.state.resources, formData),
        }, () => {
            this.props.startLoading()
            sendForm({ method: method, url: url, data: formData })
                .then((message) => {
                    this.props.stopLoading()
                    this.setState({
                        serverResponse: parsePositiveResponse(),
                        formKey: generateUUID()
                    })
                }).catch((error) => {
                    this.props.stopLoading()
                    this.setState({
                        serverResponse: parseServerErrorResponse(error),
                        formKey: generateUUID()
                    })
                })
        })
    }

    render() {
        if (this.props.loading) {
            return getLoadingComponent(true)
        }
        if (this.state.serverResponse) {
            var serverResponseComponent = renderAlertComponent(this.state.serverResponse)
        }
        return (
            <div className={this.props.width}>
                <FormPage
                    formKey={this.state.formKey}
                    formContext={this.props.formContext}
                    buttonText='Aggiorna contenuti'
                    formStyleRules={styleRules}
                    fields={getCommonFields()}
                    widgets={getCommonWidgets()}
                    onFormSubmit={this.onFormSubmit}
                    resources={this.state.resources}
                    serverResponseComponent={serverResponseComponent}
                    title={this.props.title}
                    boxStyle='warning'
                />
            </div>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagerComponent)