import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { PAGES } from 'utils/rest-routes';
import { generateUUID } from '@insoft/lib-react-universal/dist/utils';
import { NavStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { Box } from '@mui/material'
import LogoutButton from '@insoft/lib-react-web/dist/themes/adminlte/components/logout-button/logout-button';


interface Props {
    logout?: boolean
    loginRedirectUrl?: string | null
    structureId?: string
}

const NovaeHeaderIcon = '/dist/img/novae_logo.png'

export default function GenericNovaeHeader(props: Props) {
    let title = 'WEB'
    const classes = NavStyles();

    if (props.logout) {
        var logout =
            <Box>
                <LogoutButton logoutRedirectUrl={props.loginRedirectUrl} structureId={props.structureId} />
            </Box>
    }

    return (
        <AppBar position="static" color="primary" className={classes.appBar}>
            <Toolbar disableGutters={true} className={classes.toolbar} >
                <Box flexGrow={1}>
                    <Link to={PAGES.dashboard}  >
                        <Box display="flex" alignItems="center">
                            <img alt='Novae Web' src={NovaeHeaderIcon} key={generateUUID()} />
                            <Typography style={{ color: "white" }}>{title}</Typography>
                        </Box>
                    </Link>
                </Box>
                {logout}
            </Toolbar>
        </AppBar>
    )
}
