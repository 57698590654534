import * as React from 'react';

import { FormElement, Field, FieldProps } from '@progress/kendo-react-form';
import { Label, Error } from '@progress/kendo-react-labels';
import { DateInput, DatePicker, DateTimePicker, TimePicker, TimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { SchedulerFormEditor, SchedulerFormEditorProps } from '@progress/kendo-react-scheduler';
import { LabelProps } from '@progress/kendo-react-progressbars';
import { InputChangeEvent, InputProps } from '@progress/kendo-react-inputs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { children } from './data';
import { CustomEditorProps } from './interfaces';
import { SectionEditor } from './calendar-form-editor';
import { useEffect, useState } from 'react';
import { BlurEvent } from '@progress/kendo-react-dropdowns/dist/npm/common/events';

export const ChildEditor = (props: InputProps & FieldProps) => {
    const values = children.map(i => i.child);
    return <DropDownList
        data={values}
        value={props.value}
        onChange={props.onChange}
    />
}

export const CustomFormEditor = (props: SchedulerFormEditorProps & CustomEditorProps) => {

    useEffect(() => {
        if (props.eventDefaultDurationMinutes) {
            const currentStart = props.valueGetter('start')
            if (currentStart && currentStart instanceof Date) {
                const newEnd = new Date(currentStart)
                newEnd.setMinutes(newEnd.getMinutes() + props.eventDefaultDurationMinutes)
                props.onChange('end', {
                    value: newEnd
                })
            }
        }
    }, [props.valueGetter('start'), props.eventDefaultDurationMinutes])

    let timeFields: React.ReactElement[] = []
    if (props.currentView == 'month') {
        timeFields.push(<div className="k-form-field" key="fieldday">
            <Label>Giorno</Label>
            <div className="k-form-field-wrap">
                <Field name={"day"} component={(datPickerProps: FieldProps) => <DateInput {...datPickerProps} readonly />} />
            </div>
        </div>)
        timeFields.push(<div className="k-form-field" key="fieldstart">
            <Label>dalle</Label>
            <div className="k-form-field-wrap">
                <Field
                    name={"start"}
                    component={TimePicker}
                    as={TimePicker}
                />
                {props.errors.start && <Error>{props.errors.start}</Error>}
            </div>

        </div>)

        timeFields.push(<div className="k-form-field" key="fieldend">
            <Label>alle</Label>
            <div className="k-form-field-wrap">
                <Field
                    name={"end"}
                    component={TimePicker}
                    as={TimePicker}
                />
                {props.errors.start && <Error>{props.errors.start}</Error>}
            </div>
        </div>)
    } else {
        // todo da fare
    }
    return (
        <FormElement>
            {timeFields}
            <div className="k-form-field" key="fieldsection">
                <Label>Sezione*</Label>
                <div className="k-form-field-wrap">
                    <Field name={'section'} component={
                        (editorProps: InputProps & FieldProps) =>
                            <DropDownList
                                data={props.sections}
                                textField='name'
                                dataItemKey="id"
                                value={editorProps.value}
                                onChange={editorProps.onChange}
                            />} />
                    {props.errors.section && <Error>{props.errors.section}</Error>}
                </div>
            </div>
            <div className="k-form-field" key="fieldchild">
                <Label>Bambino</Label>
                <div className="k-form-field-wrap">
                    <Field name={'child'} component={
                        (editorProps: InputProps & FieldProps) =>
                            <DropDownList
                                data={(props.children || []).filter(item => props.valueGetter('section') == undefined || props.valueGetter('section').id == item.section)}
                                textField='completeName'
                                dataItemKey="anaId"
                                value={editorProps.value}
                                onChange={editorProps.onChange}
                            />} />
                    {props.errors.child && <Error>{props.errors.child}</Error>}
                </div>
            </div>
            <div className="k-form-field" key="fieldeducator">
                <Label>Educatore</Label>
                <div className="k-form-field-wrap">
                    <Field name={'operators'} component={
                        (editorProps: InputProps & FieldProps) =>
                            <MultiSelect
                                data={props.educators}
                                textField='staffName'
                                dataItemKey="pk"
                                value={editorProps.value as string[]}
                                onChange={editorProps.onChange}
                            />} />
                    {props.errors.operator && <Error>{props.errors.operator}</Error>}
                </div>
            </div>

        </FormElement>
    );
};