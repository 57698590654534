import { Navigation } from 'commons/interfaces/navigation';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { GlobalStateInterface } from 'redux/store';
import { getTitleViewForPage, getFunctionName } from 'utils';
import { PAGES } from 'utils/rest-routes';
import FormWithYearSelection from './index';
import { REGISTRATION_TYPE } from 'utils/constants';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { savePageHeaderInfo } from '../../redux/actions/global-actions';

interface Props {
    navigationLinks: Navigation,
    structureInfo: StructureInfo
}

function EnrollmentChange(props: Props) {

    const { id, yearId } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId ? PAGES.templateModificaInizioFineFrequenza : PAGES.modificaInizioFineFrequenza, structureInfo: props.structureInfo, id: yearId ? yearId : id })
        dispatch(savePageHeaderInfo(pageInfo))
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    const getPageInfo = () => {
        return {
            nextPage: yearId == null ? PAGES.modificaInizioFineFrequenzaRiepilogo : undefined,
            formTitle: getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, props.structureInfo),
            yearSelectionUri: props.navigationLinks._links.newEnrollmentChangeForm ? props.navigationLinks._links.newEnrollmentChangeForm.href : undefined,
            resourceUri: yearId == null ? props.navigationLinks._links.enrollmentChangeForm.href : props.navigationLinks._links.enrollmentChangeTemplate.href
        }
    }

    const { nextPage, formTitle, yearSelectionUri, resourceUri } = getPageInfo()
    return (
        <FormWithYearSelection
            id={id}
            yearId={yearId}
            nextPage={yearId == null ? PAGES.modificaInizioFineFrequenzaRiepilogo : undefined}
            defaultFormTitle={getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, props.structureInfo)}
            yearSelectionUri={props.navigationLinks._links.newEnrollmentChangeForm ? props.navigationLinks._links.newEnrollmentChangeForm.href : undefined}
            resourceUri={yearId == null ? props.navigationLinks._links.enrollmentChangeForm.href : props.navigationLinks._links.enrollmentChangeTemplate.href}
        />
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        structureInfo: state.global.structureInfo
    }
}

export default connect(mapStateToProps)(EnrollmentChange)