import { getCookie, setCookie } from "@insoft/lib-react-web/dist/utils/cookies";

export const COOKIE_LAST_STRUCTUREID = "LAST_STRUCTUREID_COOKIE"
export const COOKIE_SELECTED_VIEW = "COOKIE_SELECTED_VIEW"

export function setStructureIdInCookie(value: string) {
    setCookie({ key: COOKIE_LAST_STRUCTUREID, value, expires: 1 })
}

export function getStructureIdFromCookie() {
    return getCookie({ key: COOKIE_LAST_STRUCTUREID, type: "string", defaultValue: undefined })
}