import { Child } from "commons/interfaces/child";
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { isEmptyArray, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveNewsRecipients } from "redux/actions/global-actions";
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from "utils";
import { Navigation } from "../../commons/interfaces/navigation";
import { MuiTableResponsive } from "@insoft/lib-react-mui-web";
import { TableColumnDefinition } from "@insoft/lib-react-web";



interface AllProps {
    onRecipientsPicked: (items: string[]) => any
    alreadyPickedRecipientsIds: string[]
}

interface TableRow {
    id: string
    number: number
    firstName: string
    lastName: string
    section: string
}

// function getTableColumns() {
//     let tableColumns: TableColumn[] = []
//     tableColumns.push({ title: 'Seleziona', data: 'number' })
//     tableColumns.push({ title: 'Nome', data: 'firstName', name: 'firstName' })
//     tableColumns.push({ title: 'Cognome', data: 'lastName', name: 'lastName' })
//     tableColumns.push({ title: 'Sezione', data: 'section', name: 'section' })
//     tableColumns.push({ title: 'INVISIBLE_COLUMN', data: 'id', name: 'id', visible: false })
//     return tableColumns
// }

function getTableColumns(): TableColumnDefinition[] {
    let tableColumns: TableColumnDefinition[] = []
    tableColumns.push({ headerName: 'Nome', field: 'firstName', sortable: true })
    tableColumns.push({ headerName: 'Cognome', field: 'lastName', sortable: true })
    tableColumns.push({ headerName: 'Sezione', field: 'section', sortable: true })
    return tableColumns
}

function getTableOrder() {
    return [[0, "asc"]]
}

const PAGE_SIZE = [2, 10, 25, 50, 100]

export function NewsRecipientsComponent(props: AllProps) {

    const newsRecipients: Child[] = useSelector((state: GlobalStateInterface) => state.global.newsRecipients)
    const navigationLinks: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks);

    const dispatch = useDispatch()

    useEffect(() => {
        if (!newsRecipients || isEmptyArray(newsRecipients)) {
            retrieveNewsRecipients(navigationLinks._links.newsChildRecipients.href)(dispatch)
        }
    }, [])


    const renderRow = (child: Child & ComponentListItem) => {
        return {
            id: child.id,
            number: child._itemNumber,
            firstName: child.firstName,
            lastName: child.lastName,
            section: child.section ? child.section : "",
            selected: isRecipientAlreadyPicked(child)
        }
    }

    const isRecipientAlreadyPicked = (recipient: Child) => {
        const { alreadyPickedRecipientsIds } = props
        let alreadyPicked = false
        if (alreadyPickedRecipientsIds && !isEmptyArray(alreadyPickedRecipientsIds)) {
            alreadyPickedRecipientsIds.forEach((item) => {
                if (item === recipient.id) {
                    alreadyPicked = true
                }
            })
        }
        return alreadyPicked
    }

    const recipientsIdsExtractor = (pickedRecipients: Child[]) => {
        const recipientsIds: string[] = []
        if (pickedRecipients && !isEmptyArray(pickedRecipients)) {
            pickedRecipients.forEach((item) => {
                recipientsIds.push(item.id)
            })
        }
        props.onRecipientsPicked(recipientsIds)
    }


    if (!newsRecipients) {
        var content = getLoadingComponent(false)
    } else {
        const datatablesData = renderComponentsList(newsRecipients, (e: Child & ComponentListItem) => renderRow(e))
        content = (
            <div className="box box-primary">
                <div className="box-body table-responsive no-padding">
                    {/* <Datatable
                        allInputs
                        columns={getTableColumns()}
                        data={datatablesData}
                        search
                        ordering
                        order={getTableOrder()}
                        select
                        buttonSelectAll
                        buttonSelectNone
                        onRowsSelected={recipientsIdsExtractor}
                    /> */}
                    <MuiTableResponsive data={datatablesData}
                        columns={getTableColumns()}
                        pagination
                        paginationMode="client"
                        rowsPerPageOptions={PAGE_SIZE}
                        checkbox
                        onSelectionChange={recipientsIdsExtractor}
                        sorting
                        sortingMode="client"
                        filterMode="client"
                        filterToolbar
                    />
                </div>
            </div>
        )
    }
    return (
        <div className="col-sm-12">
            {content}
        </div>
    )
}


export default NewsRecipientsComponent