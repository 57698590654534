import { Alert, Box } from '@mui/material';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { clone, expandUri, generateUUID, logThis } from '@insoft/lib-react-universal';
import { FormPage, AlertObject, deepEquals } from '@insoft/lib-react-web';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { getFormData, sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import { composeUriPath, getRedirectComponent, updateFormDataState } from '@insoft/lib-react-web';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { savePageHeaderInfo, startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getFormHeaderComponent, getFunctionName, getLoadingComponent, getTitleViewForPage, getTosComponent, getVersionFromError, parseServerErrorResponse, renderAlertComponent } from 'utils';
import { REGISTRATION_TYPE } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import { PreventUnloadPageJS } from '@insoft/lib-react-mui-web';
import { IChangeEvent } from '@rjsf/core'

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrl?: string,
    requestUrlForTemplate: string,
    structureInfo: StructureInfo
}

interface AllProps extends ActionCreatorsProps, Props { }

function Dichiarazioni(props: AllProps) {
    const { id, yearId } = useParams()

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [resources, setResources] = useState<FormObject>(null)
    const [serverResponse, setServerResponse] = useState<AlertObject>(null)
    const [formKey, setFormKey] = useState<string>(generateUUID())
    const [dataChanged, setDataChanged] = useState<boolean>(false)
    const dispatch = useDispatch()


    useEffect(() => {
        const pageInfo = getTitleViewForPage({ page: yearId == null ? PAGES.nuovaIscrizioneDichiarazioni : PAGES.templateNuovaIscrizioneDichiarazioni, structureInfo: props.structureInfo, id: yearId == null ? id : yearId })
        dispatch(savePageHeaderInfo(pageInfo))

        fetchData()
        return () => {
            dispatch(savePageHeaderInfo(undefined))
        }
    }, [])

    const fetchData = () => {
        props.startLoading()
        const expandedUri = !yearId ? expandUri(props.requestUrl, { id: id }) : expandUri(props.requestUrlForTemplate, { yearId: yearId, pageIndex: '1' })
        getFormData({ url: expandedUri })
            .then((data) => {
                props.stopLoading()
                setResources(data)
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const parseErrorResponse = (error: any): AlertObject => {
        let newFormResources: FormObject = clone(resources);
        let newFormData = newFormResources.data.form.formData;
        let newVersion = getVersionFromError(error)
        if (newVersion != undefined) {
            newFormData = {
                ...newFormData,
                version: newVersion
            }
        }

        if (newFormData != newFormResources.data.form.formData) {
            newFormResources.data.form.formData = newFormData;
            setResources(newFormResources)
            setFormKey(generateUUID())
        }

        return parseServerErrorResponse(error);
    }

    const onFormSubmit = (formObject: { formData: object }) => {
        // this.onChange(formObject)
        let formData = formObject.formData

        setResources(updateFormDataState(resources, formData))
        let method = resources.method
        let url = resources.url
        props.startLoading()
        sendForm({ method, url, data: formData })
            .then(() => {
                setDataChanged(false)
                props.stopLoading()
                setFormSubmitted(true)
            }).catch((error) => {
                props.stopLoading()
                //Vedere qua di aggiornare versione
                setServerResponse(parseErrorResponse(error))
                setFormKey(generateUUID())
            })
    }

    /* Check if the file size is under 5MB*/

    if (formSubmitted) {
        let redirPath = composeUriPath(PAGES.nuovaIscrizioneRiepilogo, [id])
        return getRedirectComponent(redirPath)
    }
    if (props.loading) {
        return getLoadingComponent(true)
    }
    const component = (props.structureInfo.helpSectionsDuringFilling ? (
        <Alert severity="warning" variant="filled" icon={false}>
            <p dangerouslySetInnerHTML={{ __html: "<h4>Come procedere</h4><p>Compilare la seguente pagina con i dati richiesti. Una volta terminato cliccare il pulsante <i>\"Avanti\"</i> a fondo pagina.</p><p>Se si lascia o si ricarica la pagina le modifiche verranno perse.</p>" }}></p>
        </Alert>
    ) : null)
    const headerComponent = getFormHeaderComponent(component)
    const formTitle = resources ? (resources.data.form.title || getFunctionName(REGISTRATION_TYPE.ENROLLMENT, props.structureInfo)) : getFunctionName(REGISTRATION_TYPE.ENROLLMENT, props.structureInfo)
    const mySubTitle = resources ? (resources.data.form.subTitle || undefined) : undefined
    if (serverResponse) {
        var serverResponseComponent = renderAlertComponent(serverResponse)
    }

    const onUpdateData = (data: IChangeEvent<any, any, any>) => {
        const newData = data?.formData
        if (!dataChanged && !deepEquals(newData, resources.data?.form?.formData)) {
            setDataChanged(true)
        }
    }

    return (
        <Box>
            <PreventUnloadPageJS hasUnsavedChanges={dataChanged} />
            <FormPage
                showButton={yearId ? false : true}
                formKey={formKey}
                tosComponent={getTosComponent(resources)}
                headerComponent={headerComponent}
                // onChange={this.onChange} // FIXME per il momento commentata
                buttonText='Avanti'
                onFormSubmit={onFormSubmit}
                resources={resources}
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                serverResponseComponent={serverResponseComponent}
                title={formTitle}
                subTitle={mySubTitle}
                showRequiredFieldsLegend
                versionManagement={true}
                onChange={onUpdateData}
            />
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrl: state.global.navigationLinks._links.enrollmentDeclarationsForm.href,
        requestUrlForTemplate: state.global.navigationLinks._links.enrollmentTemplate ? state.global.navigationLinks._links.enrollmentTemplate.href : undefined,
        structureInfo: state.global.structureInfo
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dichiarazioni)
