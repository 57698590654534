import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { GlobalStateInterface } from "redux/store"
import { appendQueryParams, logThis } from "@insoft/lib-react-universal/dist/utils"
import { GridSortItem } from "@mui/x-data-grid"
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services"
import ResponsiveMaterialUITableWithPagination, { TableColumnDefinition, TableSortModel } from "@insoft/lib-react-web/dist/components/datatable-mui/table-mui-with-pagination"
import BoxedContent from "@insoft/lib-react-web/dist/components/misc/boxed-content"
import { Box, Button } from "@mui/material"
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces/component-list-item"
import { ReactLabelComponent } from "@insoft/lib-react-universal"
import { EntityModel, defaultReactDataRenderMUITable } from "@insoft/lib-react-web"

interface Props {
    invoicesList: string
}

interface TableRow {
    number?: number;
    type?: string;
    emittingDate: string;
    submissionDate: string;
    expirationDate: string;
    visulize: ReactLabelComponent;
}

interface Document extends EntityModel {
    name: string;
    notes?: string;
    // visulize: ReactLabelComponent;
}


function DocumentTableComponent(props: Props) {

    const pageSizes = [5, 10, 25];

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[1]);
    const [sortModel, setSortModel] = useState<TableSortModel>();
    const [resources, setResources] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [])

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    const onDownload = (href: string) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = href || '#';
        link.click();
    };

    const fetchData = () => {
        setLoading(true)
        var sortingArray: string[] = [];
        var requestUrl = appendQueryParams(props.invoicesList, {
            page: pageNumber.toString(),
            size: pageSize.toString(),
            sort: sortingArray
        });
        executeRequest<any>({ url: requestUrl, method: "GET" })
            .then((data) => {
                setResources(data)
                setLoading(false)
            }).catch((error) => {
                logThis("Cannot fetch the values", error)
                setLoading(false)
            })
    }

    function getTableColumns() {
        let tableColumns: TableColumnDefinition[] = [];
        tableColumns.push({
            headerName: 'N° Doc',
            field: 'number'
        });
        tableColumns.push({
            headerName: 'Nome',
            field: 'name'
        });
        tableColumns.push({
            headerName: 'Note',
            field: 'notes'
        });
        tableColumns.push({
            headerName: 'Visualizza',
            field: 'visualize',
            renderCell: defaultReactDataRenderMUITable
        });
        return tableColumns;
    }

    const renderRow = (
        index: number,
        document: Document & ComponentListItem
    ) => {
        let link = <Button onClick={() => onDownload(document._links?.self?.href)} variant="text" color="primary">Visualizza</Button>
        const counter = index + 1
        const obj = {
            number: counter.toString(),
            name: document.name,
            notes: document.notes,
            visualize: { label: "", component: link }
        }
        return obj;
    };

    const tableElements = () => {
        let toReturn: any[] = new Array()
        if (resources && resources._embedded && resources._embedded.documentDtoes) {
            for (let index = 0; index < resources._embedded.documentDtoes.length; index++) {
                toReturn.push(renderRow(index, resources._embedded.documentDtoes[index]))
            }
        }
        return toReturn
    }

    return (
        <div>
            <BoxedContent xl={12}>
                <Box p={2}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <ResponsiveMaterialUITableWithPagination
                                    dense={true}
                                    data={tableElements()}
                                    columns={getTableColumns()}
                                    sortingMode="server"
                                    sortModel={sortModel}
                                    onSortModelChange={(sortModel) => handleModule(sortModel)}
                                    pagination={true}
                                    paginationMode="server"
                                    rowsPerPage={pageSize}
                                    page={pageNumber}
                                    rowCount={resources != undefined ?
                                        (resources.page != undefined ? resources.page.totalElements : 0)
                                        : 0}
                                    onPageChange={setNumberOfPage}
                                    onPageSizeChange={setNumberElementsOnPage}
                                    rowsPerPageOptions={pageSizes}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </BoxedContent>
        </div>
    )

}
const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        invoicesList: state.global.navigationLinks._links.documentsList.href
    }
}

export default connect(mapStateToProps)(DocumentTableComponent)