import { FormPage } from '@insoft/lib-react-web';
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AdminFilters, saveAdminFilters, startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { getSearchAdminFormObject } from './search-admin-form-objects';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    saveAdminFilters: (adminFilters: AdminFilters) => any
}

export interface Props {
    loading?: boolean
    requestUrlHostNames?: string
    adminFilters: AdminFilters
}

interface AllProps extends ActionCreatorsProps, Props {
    title?: string
    searchCallBack?: () => void
}

class AdminSearchForm extends React.Component<AllProps, null> {

    onFormSubmit = (formObject: { formData: AdminFilters }) => {
        let formData = formObject.formData
        this.props.saveAdminFilters(formData)
        if (this.props.searchCallBack) {
            this.props.searchCallBack()
        }
    }

    render() {
        if (this.props.loading) {
            return getLoadingComponent(true)
        }
        const formObject = getSearchAdminFormObject(this.props)
        return (
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={this.onFormSubmit}
                resources={formObject}
                title={"Ricerca"}
                buttonText="Cerca"
                showRequiredFieldsLegend
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrlHostNames: state.global.navigationLinks._links.getHostNames ? state.global.navigationLinks._links.getHostNames.href : undefined,
        adminFilters: state.global.adminFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        saveAdminFilters: bindActionCreators(saveAdminFilters, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchForm)