import { Snackbar, SnackbarOrigin, Typography, Alert as MuiAlert } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home'; import { GridOverlay } from '@mui/x-data-grid';
import FormHeaderComponent from 'commons/components/forms/form-header-component';
import TosArea from 'commons/components/forms/tos-area';
import { Child } from 'commons/interfaces/child';
import { StructureInfo } from 'commons/interfaces/structure-info';
import STYLE from 'commons/styles/style';
import Alert, { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import LoadingComponent from '@insoft/lib-react-web/dist/components/misc/loading-componentMaterial';
import MessageComponent from '@insoft/lib-react-web/dist/components/misc/message-component';
import { FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { createDangerousInnerHtml, getHeight } from '@insoft/lib-react-web/dist/utils/utils';
import React from 'react';
import {
    AndroidView, BrowserView, deviceType, IOSView, isAndroid, isBrowser, isChrome,
    isFirefox, isIOS, isMobile, isMobileSafari, isTablet, mobileModel, MobileView, osName, osVersion, TabletView
} from "react-device-detect";
import { DIARY_ICONS_MAPPING, ENROLLMENT_STATUS, REGISTRATION_TYPE, TOTEM_CONSTANTS } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import { ZonedDate } from '@progress/kendo-date-math';
import '@progress/kendo-date-math/tz/Europe/Rome';
import { ModalResponse } from 'commons/interfaces/utils';
import { clone, generateUUID, logThis, RetriedServerResponse, ServerResponse } from '@insoft/lib-react-universal/dist';
import { BreadCrumb } from '@insoft/lib-react-mui-web';
import { NavigateFunction } from 'react-router';


export function getLoadingComponent(fullPage?: boolean) {
    return (
        <LoadingComponent
            fullPage={fullPage}
            semiTransparentBackground={false}
            backgroundColor={'transparent'}
        />
    )
}

/** From: https://stackoverflow.com/a/43467144 */
export function isValidURL(url: string) {
    try {
        new URL(url)
    } catch (error) {
        return false
    }
    return true
}

export function getMessageComponent(options: { message: string, type: 'error' | 'success' }) {
    const { message, type } = options
    return (
        <MessageComponent
            message={message}
            type={type}
        />
    )
}

export function getFormHeaderComponent(helpSection?: any) {
    return (
        <FormHeaderComponent helpSection={helpSection} />
    )
}

export function getTosComponent(resources: FormObject): JSX.Element {
    try {
        var tosComponent = (
            <TosArea resources={resources.data.form.tos} />
        )
    } catch (error) { }
    return tosComponent
}

export function parsePositiveResponse(opt: { autoclose?: boolean, closable?: boolean, title?: string } = null): AlertObject {
    return {
        id: generateUUID(),
        type: "success",
        title: opt && opt.title ? opt.title : 'Richiesta eseguita con successo',
        closable: opt && opt.closable,
        autoClose: opt && opt.autoclose
    }
}

export function getVersionFromError(response: any): number | undefined {
    return getPropertyFromError(response, 'version')
}

export function getIdFromError(response: any): number | undefined {
    return getPropertyFromError(response, 'id')
}

export function getPropertyFromError(response: any, property: string): number | undefined {
    let serverResponse: any = response.response || response
    return serverResponse?.message?.data?.[property] || serverResponse?.[property]
}

export function parseServerErrorResponse(response: any): AlertObject {
    let serverResponse: ServerResponse = response.response || response
    if (response.failure) {
        // Failure response
        const responseWrapper: RetriedServerResponse = clone(response)
        serverResponse = JSON.parse(responseWrapper.failure.failure)
    } else if (response.name === "StopError") {
        // Retry stopped
        serverResponse = response.message
    }
    const id = generateUUID()
    const type = serverResponse.status === 200 ? "success" : "error"
    const title = serverResponse.status === 200 ? 'Richiesta eseguita con successo' : 'Errore nella richiesta'
    const data: any = serverResponse.data || serverResponse
    if (data) {
        var message = data.message ? data.message : ''
        var errors: JSX.Element[] = []
        try {
            data.errors.forEach((item: any) => {
                errors.push(<span dangerouslySetInnerHTML={createDangerousInnerHtml(item.message)} />)
            })
        } catch (e) {
            logThis("Error parsing the error response", e)
        }
    }
    /*var message = */
    return { id, type, title, errors, message }
}

export function renderAlertComponent(props: AlertObject) {
    if (!props) {
        return null
    }
    return (
        <Alert
            {...props}
        />
    )
}

export function renderModal(modalObject: ModalResponse, onClose: () => any) {
    if (modalObject == undefined || modalObject == null) {
        return undefined
    } else {
        const severty = modalObject.status === "OK" ? "success" : "error"
        const autoHideDuration = modalObject.status === "OK" ? 6000 : null
        const anchor: SnackbarOrigin = {
            horizontal: 'center',
            vertical: 'top'
        }
        return (
            <Snackbar open={modalObject != undefined} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={anchor} >
                <MuiAlert onClose={onClose} severity={severty} variant="filled" elevation={6}>
                    {modalObject.message}
                </MuiAlert>
            </Snackbar>

        )
    }
}

export function parseEnrollmentIdFromResponse(responseObject: { id: string }) {
    if (responseObject.id) {
        return responseObject.id
    }
    throw new Error('Id not set')
}

export function isEnrollmentInFillingStatus(status: string) {
    return status === ENROLLMENT_STATUS.FILLING
}

/**
 * 
 * @param opt 
 * @returns
 */
export function getTitleViewForPage(opt: { page: string, id?: string, structureInfo: StructureInfo }): {
    title: string,
    breadcrumbs: BreadCrumb[]
} {
    let titleView = null
    const { page, id, structureInfo } = opt

    let homeBreadcrumb = {
        title: 'Home',
        url: PAGES.dashboard,
        icon: HomeIcon
    }

    let frequenzaBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, structureInfo) + ' (1/2)',
        url: id ? PAGES.nuovaIscrizioneFrequenza + '/' + id : PAGES.nuovaIscrizioneFrequenza
    }

    let dichiarazioniBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, structureInfo) + ' (2/2)',
        url: id ? PAGES.nuovaIscrizioneDichiarazioni + '/' + id : PAGES.nuovaIscrizioneDichiarazioni
    }

    let cambioDatiBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, structureInfo) + ' (1/2)',
        url: id ? PAGES.cambioDati + '/' + id : PAGES.cambioDati
    }

    let cambioDatiDichiarazioniBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, structureInfo) + ' (2/2)',
        url: id ? PAGES.cambioDatiDichiarazioni + '/' + id : PAGES.cambioDatiDichiarazioni
    }

    let riepilogoBreadcrumb = {
        title: 'Riepilogo',
        url: id ? PAGES.nuovaIscrizioneRiepilogo + '/' + id : PAGES.nuovaIscrizioneRiepilogo
    }

    let serviziAggiuntiviBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, structureInfo),
        url: id ? PAGES.serviziAggiuntivi + '/' + id : PAGES.serviziAggiuntivi
    }

    let riepilogoServiziAggiuntiviBreadcrumb = {
        title: 'Riepilogo richiesta ' + getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, structureInfo),
        url: id ? PAGES.serviziAggiuntiviRiepilogo + '/' + id : PAGES.serviziAggiuntiviRiepilogo
    }

    let modificaBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, structureInfo),
        url: id ? PAGES.modificaIscrizione + '/' + id : PAGES.modificaIscrizione
    }

    let riepilogoModificaBreadcrumb = {
        title: 'Riepilogo richiesta ' + getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, structureInfo),
        url: id ? PAGES.modificaIscrizioneRiepilogo + '/' + id : PAGES.modificaIscrizioneRiepilogo
    }

    let modificaInizioFineFrequenzaBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.MODIFICA, structureInfo),
        url: id ? PAGES.modificaInizioFineFrequenza + '/' + id : PAGES.modificaInizioFineFrequenza
    }

    let riepilogoModificaInizioFineFrequenzaBreadcrumb = {
        title: 'Riepilogo richiesta ' + getFunctionName(REGISTRATION_TYPE.MODIFICA, structureInfo),
        url: id ? PAGES.modificaInizioFineFrequenzaRiepilogo + '/' + id : PAGES.modificaInizioFineFrequenzaRiepilogo
    }

    let cambioIseeBreadcrumb = {
        title: getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, structureInfo),
        url: id ? PAGES.cambioIsee + '/' + id : PAGES.cambioIsee
    }

    let riepilogoCambioIseeBreadcrumb = {
        title: 'Riepilogo ' + getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, structureInfo),
        url: id ? PAGES.cambioIseeRiepilogo + '/' + id : PAGES.cambioIseeRiepilogo
    }

    let frequenzaBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, structureInfo) + ' (1/2)',
        url: id ? PAGES.templateNuovaIscrizioneFrequenza + '/' + id : PAGES.templateNuovaIscrizioneFrequenza
    }

    let dichiarazioniBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT, structureInfo) + ' (2/2)',
        url: id ? PAGES.templateNuovaIscrizioneDichiarazioni + '/' + id : PAGES.templateNuovaIscrizioneDichiarazioni
    }

    let cambioDatiBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, structureInfo) + ' (1/2)',
        url: id ? PAGES.templateCambioDati + '/' + id : PAGES.templateCambioDati
    }

    let cambioDatiDichiarazioniBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.DATA_CHANGE, structureInfo) + ' (2/2)',
        url: id ? PAGES.templateCambioDatiDichiarazioni + '/' + id : PAGES.templateCambioDatiDichiarazioni
    }

    let serviziAggiuntiviBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI, structureInfo),
        url: id ? PAGES.templateServiziAggiuntivi + '/' + id : PAGES.templateServiziAggiuntivi
    }

    let modificaBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.ENROLLMENT_CHANGE, structureInfo),
        url: id ? PAGES.templateModificaIscrizione + '/' + id : PAGES.templateModificaIscrizione
    }

    let modificaInizioFineFrequenzaBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.MODIFICA, structureInfo),
        url: id ? PAGES.templateModificaInizioFineFrequenza + '/' + id : PAGES.templateModificaInizioFineFrequenza
    }

    let cambioIseeBreadcrumbTemplate = {
        title: getFunctionName(REGISTRATION_TYPE.ISEE_CHANGE, structureInfo),
        url: id ? PAGES.templateCambioIsee + '/' + id : PAGES.templateCambioIsee
    }

    switch (page) {
        case PAGES.nuovaIscrizioneFrequenza:
            frequenzaBreadcrumb.url = null
            titleView = {
                title: frequenzaBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, frequenzaBreadcrumb]
            }
            break
        case PAGES.nuovaIscrizioneDichiarazioni:
            dichiarazioniBreadcrumb.url = null
            titleView = {
                title: dichiarazioniBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, frequenzaBreadcrumb, dichiarazioniBreadcrumb]
            }
            break
        case PAGES.nuovaIscrizioneRiepilogo: {
            riepilogoBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, frequenzaBreadcrumb, dichiarazioniBreadcrumb, riepilogoBreadcrumb] : [homeBreadcrumb, riepilogoBreadcrumb]
            titleView = {
                title: riepilogoBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        } case PAGES.cambioDati: {
            riepilogoBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, cambioDatiBreadcrumb, cambioDatiDichiarazioniBreadcrumb] : [homeBreadcrumb, cambioDatiBreadcrumb]
            titleView = {
                title: cambioDatiBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        } case PAGES.cambioDatiDichiarazioni: {
            riepilogoBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, cambioDatiBreadcrumb, cambioDatiDichiarazioniBreadcrumb] : [homeBreadcrumb, cambioDatiBreadcrumb]
            titleView = {
                title: cambioDatiDichiarazioniBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        } case PAGES.serviziAggiuntivi:
            serviziAggiuntiviBreadcrumb.url = null
            titleView = {
                title: serviziAggiuntiviBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, serviziAggiuntiviBreadcrumb]
            }
            break
        case PAGES.serviziAggiuntiviRiepilogo: {
            riepilogoServiziAggiuntiviBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, serviziAggiuntiviBreadcrumb, riepilogoServiziAggiuntiviBreadcrumb] : [homeBreadcrumb, riepilogoServiziAggiuntiviBreadcrumb]
            titleView = {
                title: riepilogoServiziAggiuntiviBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        }
        case PAGES.modificaIscrizione:
            modificaBreadcrumb.url = null
            titleView = {
                title: modificaBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, modificaBreadcrumb]
            }
            break
        case PAGES.modificaIscrizioneRiepilogo: {
            riepilogoModificaBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, modificaBreadcrumb, riepilogoModificaBreadcrumb] : [homeBreadcrumb, riepilogoModificaBreadcrumb]
            titleView = {
                title: riepilogoModificaBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        }
        case PAGES.modificaInizioFineFrequenza:
            modificaInizioFineFrequenzaBreadcrumb.url = null
            titleView = {
                title: modificaInizioFineFrequenzaBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, modificaInizioFineFrequenzaBreadcrumb]
            }
            break
        case PAGES.modificaInizioFineFrequenzaRiepilogo: {
            riepilogoModificaInizioFineFrequenzaBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, modificaInizioFineFrequenzaBreadcrumb, riepilogoModificaInizioFineFrequenzaBreadcrumb] : [homeBreadcrumb, riepilogoModificaInizioFineFrequenzaBreadcrumb]
            titleView = {
                title: riepilogoModificaInizioFineFrequenzaBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        }
        case PAGES.cambioIsee:
            cambioIseeBreadcrumb.url = null
            titleView = {
                title: cambioIseeBreadcrumb.title,
                breadcrumbs: [homeBreadcrumb, cambioIseeBreadcrumb]
            }
            break
        case PAGES.cambioIseeRiepilogo: {
            riepilogoCambioIseeBreadcrumb.url = null
            const breadcrumbs = id ? [homeBreadcrumb, cambioIseeBreadcrumb, riepilogoCambioIseeBreadcrumb] : [homeBreadcrumb, riepilogoCambioIseeBreadcrumb]
            titleView = {
                title: riepilogoCambioIseeBreadcrumb.title,
                breadcrumbs: breadcrumbs
            }
            break
        }
        case PAGES.riepilogo:
            titleView = {
                title: 'Riepilogo',
                breadcrumbs: [homeBreadcrumb]
            }
            break
        case PAGES.templateNuovaIscrizioneFrequenza:
            frequenzaBreadcrumbTemplate.url = null
            titleView = {
                title: frequenzaBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, frequenzaBreadcrumbTemplate]
            }
            break
        case PAGES.templateNuovaIscrizioneDichiarazioni:
            dichiarazioniBreadcrumbTemplate.url = null
            titleView = {
                title: dichiarazioniBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, frequenzaBreadcrumbTemplate, dichiarazioniBreadcrumbTemplate]
            }
            break
        case PAGES.templateCambioDati: {
            const breadcrumbs = id ? [homeBreadcrumb, cambioDatiBreadcrumbTemplate, cambioDatiDichiarazioniBreadcrumbTemplate] : [homeBreadcrumb, cambioDatiBreadcrumbTemplate]
            titleView = {
                title: cambioDatiBreadcrumbTemplate.title,
                breadcrumbs: breadcrumbs
            }
            break
        } case PAGES.templateCambioDatiDichiarazioni: {
            const breadcrumbs = id ? [homeBreadcrumb, cambioDatiBreadcrumbTemplate, cambioDatiDichiarazioniBreadcrumbTemplate] : [homeBreadcrumb, cambioDatiBreadcrumbTemplate]
            titleView = {
                title: cambioDatiDichiarazioniBreadcrumbTemplate.title,
                breadcrumbs: breadcrumbs
            }
            break
        } case PAGES.templateServiziAggiuntivi:
            serviziAggiuntiviBreadcrumbTemplate.url = null
            titleView = {
                title: serviziAggiuntiviBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, serviziAggiuntiviBreadcrumbTemplate]
            }
            break
        case PAGES.templateModificaIscrizione:
            modificaBreadcrumbTemplate.url = null
            titleView = {
                title: modificaBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, modificaBreadcrumbTemplate]
            }
            break
        case PAGES.templateModificaInizioFineFrequenza:
            modificaInizioFineFrequenzaBreadcrumbTemplate.url = null
            titleView = {
                title: modificaInizioFineFrequenzaBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, modificaInizioFineFrequenzaBreadcrumbTemplate]
            }
            break
        case PAGES.templateCambioIsee:
            cambioIseeBreadcrumbTemplate.url = null
            titleView = {
                title: cambioIseeBreadcrumbTemplate.title,
                breadcrumbs: [homeBreadcrumb, cambioIseeBreadcrumbTemplate]
            }
            break
    }

    return titleView
}

export function getFunctionName(requestType: string, structureInfo: StructureInfo) {
    let labels = structureInfo?.registrationLabels;
    if (labels) {
        return labels[requestType] || (STYLE.types[requestType] ? STYLE.types[requestType].label : requestType);
    }
    return STYLE.types[requestType] ? STYLE.types[requestType].label : requestType;
}

export function getDiaryIcon(iconName: string) {
    const mappingObject = clone(DIARY_ICONS_MAPPING)
    if (mappingObject[iconName]) {
        return mappingObject[iconName]
    }
}

export function isNewsTabSelected(selectedTotemTab: string) {
    return selectedTotemTab === TOTEM_CONSTANTS.NEWS_TAB
}

export function isTabSelected(selectedTotemTab: string, currentTabId: string) {
    return selectedTotemTab === currentTabId
}

export function isTabSelectedNum(selectedTotemTab: number, currentTabId: number) {
    return selectedTotemTab === currentTabId
}


export function getTotemContentHeight() {
    const tabsContainerHeight = $('.tabs-container').height()
    return getHeight() - tabsContainerHeight - 1
}

export function getChildWithId(childrenList: Child[], childId: string) {
    for (const child of childrenList) {
        if (child.ana === childId) {
            return child
        }
    }
}

// Metodo per recupero elemento da visualizzare con tabella priva di dati
export function getCustomNoRowsOverlayForDataGrid() {
    return (
        <GridOverlay style={{ backgroundColor: "rgba(235, 235, 235, .7)" }}>
            <Typography variant="body1">
                Non ci sono dati da visualizzare
            </Typography>
        </GridOverlay>
    );
}

// Metodo utile per il recupero di immagini da URI a Base64
export function toDataURL(url: string, callback: (dataUrl: any) => any) {
    var xhr = new XMLHttpRequest()
    xhr.onload = () => {
        var reader = new FileReader()
        reader.onloadend = () => {
            callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
}

export function formatDate(date: Date): string {
    if (date == undefined) {
        return "N.D."
    } else {
        const zonedDate = ZonedDate.fromLocalDate(date, 'Europe/Rome')
        return (
            [
                zonedDate.getDate().toString().padStart(2, '0'),
                (zonedDate.getMonth() + 1).toString().padStart(2, '0'),
                zonedDate.getFullYear()
            ].join('-') +
            ' ' +
            [
                zonedDate.getHours().toString().padStart(2, '0'),
                zonedDate.getMinutes().toString().padStart(2, '0'),
                zonedDate.getSeconds().toString().padStart(2, '0')
            ].join(':')
        );
    }
}

export function formatStringDate(stringDate: string): string {
    if (stringDate == undefined) {
        return "N.D."
    } else {
        const date = new Date(stringDate)
        return formatOnlyDate(date)
    }
}

export function formatOnlyDate(date: Date): string {
    if (date == undefined) {
        return "N.D."
    } else {
        const zonedDate = ZonedDate.fromLocalDate(date, 'Europe/Rome')
        return (
            [
                zonedDate.getDate().toString().padStart(2, '0'),
                (zonedDate.getMonth() + 1).toString().padStart(2, '0'),
                zonedDate.getFullYear()
            ].join('-')
        );
    }
}

// Componente che va a testare la libreria per il render di alcuni oggetti solo su determinati dispositivi (https://www.npmjs.com/package/react-device-detect)
export class DifferentDevicesLibraryTest extends React.Component {
    render() {
        return (
            <div>
                <BrowserView>
                    <div className="box box-solid box-danger text-center">
                        <div className="box-header">
                            <h3 className="box-title">Prova Libreria Visualizzazione solo Desktop</h3>
                        </div>
                        <div className="box-body">
                            <p style={{ fontSize: '150%' }}><b>isMobile: </b> {isMobile ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isTablet: </b> {isTablet ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isBrowser: </b> {isBrowser ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isAndroid: </b> {isAndroid ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isIOS: </b> {isIOS ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isChrome: </b> {isChrome ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isFirefox: </b> {isFirefox ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isMobileSafari: </b> {isMobileSafari ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osVersion: </b> {osVersion}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osName: </b> {osName}</p><br />
                            <p style={{ fontSize: '150%' }}><b>deviceType: </b> {deviceType}</p><br />
                            <p style={{ fontSize: '150%' }}><b>mobileModel: </b> {mobileModel}</p><br />
                        </div>
                    </div>
                </BrowserView>
                <TabletView>
                    <div className="box box-solid box-danger text-center">
                        <div className="box-header">
                            <h3 className="box-title">Prova Libreria Visualizzazione solo Tablet</h3>
                        </div>
                        <div className="box-body">
                            <p style={{ fontSize: '150%' }}><b>isMobile: </b> {isMobile ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isTablet: </b> {isTablet ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isBrowser: </b> {isBrowser ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isAndroid: </b> {isAndroid ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isIOS: </b> {isIOS ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isChrome: </b> {isChrome ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isFirefox: </b> {isFirefox ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isMobileSafari: </b> {isMobileSafari ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osVersion: </b> {osVersion}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osName: </b> {osName}</p><br />
                            <p style={{ fontSize: '150%' }}><b>deviceType: </b> {deviceType}</p><br />
                            <p style={{ fontSize: '150%' }}><b>mobileModel: </b> {mobileModel}</p><br />
                        </div>
                    </div>
                </TabletView>
                <MobileView>
                    <div className="box box-solid box-danger text-center">
                        <div className="box-header">
                            <h3 className="box-title">Prova Libreria Visualizzazione solo Smartphone</h3>
                        </div>
                        <div className="box-body">
                            <p style={{ fontSize: '150%' }}><b>isMobile: </b> {isMobile ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isTablet: </b> {isTablet ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isBrowser: </b> {isBrowser ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isAndroid: </b> {isAndroid ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isIOS: </b> {isIOS ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isChrome: </b> {isChrome ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isFirefox: </b> {isFirefox ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isMobileSafari: </b> {isMobileSafari ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osVersion: </b> {osVersion}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osName: </b> {osName}</p><br />
                            <p style={{ fontSize: '150%' }}><b>deviceType: </b> {deviceType}</p><br />
                            <p style={{ fontSize: '150%' }}><b>mobileModel: </b> {mobileModel}</p><br />
                        </div>
                    </div>
                </MobileView>
                <AndroidView>
                    <div className="box box-solid box-danger text-center">
                        <div className="box-header">
                            <h3 className="box-title">Prova Libreria Visualizzazione solo Android</h3>
                        </div>
                        <div className="box-body">
                            <p style={{ fontSize: '150%' }}><b>isMobile: </b> {isMobile ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isTablet: </b> {isTablet ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isBrowser: </b> {isBrowser ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isAndroid: </b> {isAndroid ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isIOS: </b> {isIOS ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isChrome: </b> {isChrome ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isFirefox: </b> {isFirefox ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isMobileSafari: </b> {isMobileSafari ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osVersion: </b> {osVersion}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osName: </b> {osName}</p><br />
                            <p style={{ fontSize: '150%' }}><b>deviceType: </b> {deviceType}</p><br />
                            <p style={{ fontSize: '150%' }}><b>mobileModel: </b> {mobileModel}</p><br />
                        </div>
                    </div>
                </AndroidView>
                <IOSView>
                    <div className="box box-solid box-danger text-center">
                        <div className="box-header">
                            <h3 className="box-title">Prova Libreria Visualizzazione solo iOS</h3>
                        </div>
                        <div className="box-body">
                            <p style={{ fontSize: '150%' }}><b>isMobile: </b> {isMobile ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isTablet: </b> {isTablet ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isBrowser: </b> {isBrowser ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isAndroid: </b> {isAndroid ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isIOS: </b> {isIOS ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isChrome: </b> {isChrome ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isFirefox: </b> {isFirefox ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>isMobileSafari: </b> {isMobileSafari ? 'true' : 'false'}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osVersion: </b> {osVersion}</p><br />
                            <p style={{ fontSize: '150%' }}><b>osName: </b> {osName}</p><br />
                            <p style={{ fontSize: '150%' }}><b>deviceType: </b> {deviceType}</p><br />
                            <p style={{ fontSize: '150%' }}><b>mobileModel: </b> {mobileModel}</p><br />
                        </div>
                    </div>
                </IOSView>
            </div>
        );
    }
}
