import { Box, Typography } from '@mui/material';
import { Icon } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { isEmptyArray, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';

interface Props {
    partnerLogos?: Icon[]
    name: string
}

export default function LogosSection(props: Props) {

    const renderLogo = (icon: Icon & ComponentListItem) => {
        return (
            <img key={icon._key} className="logo-image" src={icon.imgPath} />
        )
    }


    const logosToShow = []
    for (const logo of props.partnerLogos) {
        if (logo.showInHome) {
            logosToShow.push(logo)
        }
    }
    if (!isEmptyArray(logosToShow)) {
        var logos = renderComponentsList(logosToShow, renderLogo)
    }
    return (<Box display="flex" justifyContent="flex-end" p={2} ><Box flexGrow={1}><Typography variant="h6">{props.name}</Typography></Box><Box>{logos}</Box></Box>)
}