import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { SliderData } from 'commons/interfaces/slider-data';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces//component-list-item';
import { expandUri, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import { SocialNetwork } from '@insoft/lib-react-web/dist/interfaces/social-network';
import { Icon } from '@insoft/lib-react-web/dist/interfaces/structure-info';
import { BoxStyles, ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import { createDangerousInnerHtml } from '@insoft/lib-react-web/dist/utils/utils';
import React from 'react';
import { WEB_ENDPOINTS } from 'utils/rest-routes';
import Slider from './slider';
import { getIconStyle, getLinkStyle } from './style/landing-page-style';

interface Props {
    structureInfo: StructureInfo
    sliderData: SliderData[]
    structureId?: string
}

export default function Organization(props: Props) {
    const classesBox = BoxStyles();
    const classesButton = ButtonsStyles();

    const renderIcon = (item: Icon & ComponentListItem) => {
        if (!item) {
            return null
        }
        // Se ci saranno solo 2 immagini allora il Leschiu ha detto che si fa un'altra componente e che te l'asciughi con 1 riga di codice. :D
        return (
            <Box textAlign="center" className='col-xs-4' key={item._key}>
                <Link href={item.link} target='_blank'>
                    <img height='60' src={item.imgPath} />
                </Link>
            </Box>
        )
    }

    const renderSocialIcons = (item: SocialNetwork & ComponentListItem) => {
        if (!item) {
            return null
        }
        let cssClassLink = getLinkStyle(item.name, true)
        let cssClassIcon = getIconStyle(item.name)
        return (
            <a href={item.link} className={cssClassLink} target='_blank' key={item._key}>
                <i className={cssClassIcon} />
            </a>
        )
    }

    const { structureInfo } = props
    if (structureInfo) {
        let iconsArray = []
        iconsArray = structureInfo.partnersLogos
        var iconsViews = renderComponentsList(iconsArray, renderIcon, 'icon')

        var mainDescription = structureInfo.mainDescription
        var mainDescriptionText = createDangerousInnerHtml(mainDescription.text)
        var mainDescriptionTitle = structureInfo.mainDescription.title
        var secondDescription = structureInfo.secondDescription
        var secondDescriptionText = createDangerousInnerHtml(secondDescription.text)
        var secondDescriptionTitle = structureInfo.secondDescription.title

        var socialNotworks = structureInfo.socialNetworks
        var socialViews = renderComponentsList(socialNotworks, renderSocialIcons)
    }

    const novaeUri = expandUri(WEB_ENDPOINTS.novae, { structureId: props.structureId })

    return (
        <Box className={clsx(classesBox.box, classesBox.box_info)}>
            <Box className={clsx(classesBox.box_header, classesBox.box_header_with_border)}>
                {iconsViews}
            </Box>
            <Box className={classesBox.box_body}>
                {socialViews}
                <Typography variant="h2">
                    Benvenuto
                </Typography>
                <Typography variant="h3">
                    {mainDescriptionTitle}
                </Typography>
                <div dangerouslySetInnerHTML={mainDescriptionText} />
                <Box >
                    <ErrorBoundary>
                        <Slider sliderData={props.sliderData} />
                    </ErrorBoundary>
                </Box>
                <Box >
                    <Typography variant="h3">{secondDescriptionTitle}</Typography>
                    <div dangerouslySetInnerHTML={secondDescriptionText} />
                    <Box className={clsx(classesBox.box_footer, "clearfix")} textAlign="center">
                        <Button href={novaeUri} variant="contained" color="primary">Iscrizioni online</Button>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}
