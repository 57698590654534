import FormPage from '@insoft/lib-react-web/dist/components/form-components/form-page/index';
import { expandUri, logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import { baseFormStyleRules, getBaseFields, getBaseWidgets } from '@insoft/lib-react-web/dist/utils/base-form-style-rules';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { HistoryFilters, saveHistoryFilters } from 'redux/actions/global-actions';
import { getSearchStructureFormObject } from './search-multi-structure-form-object';

interface ActionCreatorsProps {
    saveHistoryFilters?: (historyFilters: HistoryFilters) => any
}

interface Props {
    requestUrlDashBoard?: string
    requestUrlYear?: string
    requestUrlState?: string
    requestUrlType?: string
    historyFilters?: HistoryFilters
    startLoading: () => any
    stopLoading: () => any
}


export interface AllProps extends ActionCreatorsProps, Props {
    title?: string
    structureData?: any
    searchCallBack?: () => void
    linkStructure: string
    onDataGrid: any
}

export class StructureSearchForm extends React.Component<AllProps> {

    constructor(props: AllProps | Readonly<AllProps>) {
        super(props)
    }

    onFormSubmit = (formObject: { formData: { ae: string, structure: any } }) => {
        let formData = formObject.formData
        this.props.saveHistoryFilters(formData)
        if (this.props.searchCallBack) {
            this.props.searchCallBack()
        }

        this.getStructuresObjects(formData)
    }

    getStructuresObjects = async (formData: { ae: string, structure: any }) => {
        this.props.startLoading()
        executeRequest({ url: expandUri(this.props.linkStructure, { organizations: formData.structure, year: formData.ae }), method: 'GET' })
            .then((data) => {
                this.props.stopLoading()
                this.props.onDataGrid(data)
            }).catch((data) => {
                this.props.stopLoading()
                logThis(data)
            })
    }

    render() {
        const formObject = getSearchStructureFormObject(this.props)
        return (
            <div>
                <FormPage
                    formStyleRules={baseFormStyleRules}
                    fields={getBaseFields()}
                    widgets={getBaseWidgets()}
                    onFormSubmit={this.onFormSubmit}
                    resources={formObject}
                    title={"Ricerca"}
                    buttonText="Cerca"
                    showRequiredFieldsLegend
                />
            </div>
        )
    }
}



const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        saveHistoryFilters: bindActionCreators(saveHistoryFilters, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(StructureSearchForm)