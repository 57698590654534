import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { GlobalStateInterface } from "redux/store"
import { appendQueryParams, logThis } from "@insoft/lib-react-universal/dist/utils"
import { GridSortItem } from "@mui/x-data-grid"
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services"
import ResponsiveMaterialUITableWithPagination, { TableColumnDefinition, TableSortModel } from "@insoft/lib-react-web/dist/components/datatable-mui/table-mui-with-pagination"
import BoxedContent from "@insoft/lib-react-web/dist/components/misc/boxed-content"
import { Box, Button } from "@mui/material"
import { formatDateObject, FORMAT_UNI_EN28601 } from "@insoft/lib-react-universal/dist/utils/date-utils"
import { Page, _Links } from "@insoft/lib-react-universal/dist/interfaces/rest-interfaces"
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces/component-list-item"
import LinkAdapter from "@insoft/lib-react-web/dist/adapters/link-adapter"
import { defaultReactDataRenderMUITable } from "@insoft/lib-react-web"

interface Props {
    invoicesList: string
}

// export interface Enrollments {
//     enrollments: Enrollment[]
// }

export interface Requests {
    _embedded?: Embedded,
    _links?: _Links,
    page?: Page
}

export interface Embedded {
    invoices?: Invoice[]
}

interface Invoice {
    number?: number;
    emittingDate: Date;
    submissionDate: Date;
    expirationDate: Date;
    // visulize: ReactLabelComponent;
    links: LinkAdapter[]
}

function InvoicesTableComponent(props: Props) {

    const pageSizes = [5, 10, 25];

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[1]);
    const [sortModel, setSortModel] = useState<TableSortModel>({ field: "number", sort: "desc" });
    const [resources, setResources] = useState<Requests>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
    }, [props.invoicesList, pageNumber, pageSize, sortModel]);

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    const onDownload = (href: string) => {
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = href;
        link.click();
    };

    const fetchData = () => {
        setLoading(true)
        var sortingArray: string[] = [];
        if (sortModel) {
            if (sortModel.field == "number") {
                sortingArray.push(createParam("nroN", sortModel))
            } else {
                sortingArray.push(createParam(sortModel.field, sortModel))
            }
        }
        var requestUrl = appendQueryParams(props.invoicesList, {
            page: pageNumber.toString(),
            size: pageSize.toString(),
            sort: sortingArray,
        });
        executeRequest<any>({ url: requestUrl, method: "GET" })
            .then((data) => {
                setResources(data)
                setLoading(false)
            }).catch((error) => {
                logThis("Cannot fetch the values", error)
            })
    }

    function getTableColumns() {
        let tableColumns: TableColumnDefinition[] = [];
        tableColumns.push({
            headerName: 'N° Doc',
            field: 'number'
        });
        tableColumns.push({
            headerName: 'Data emissione',
            field: 'emittingDate'
        });
        tableColumns.push({
            headerName: 'Data scadenza',
            field: 'expirationDate'
        });
        tableColumns.push({
            headerName: 'Visualizza',
            field: 'visualize',
            renderCell: defaultReactDataRenderMUITable
        });
        return tableColumns;
    }

    const renderRow = (
        invoice: Invoice & ComponentListItem
    ) => {
        let link = <Button onClick={() => onDownload(invoice.links[0].href)} variant="text" color="primary">Visualizza</Button>
        const obj = {
            number: invoice.number,
            emittingDate: formatDateObject(invoice.emittingDate, FORMAT_UNI_EN28601),
            expirationDate: formatDateObject(invoice.expirationDate, FORMAT_UNI_EN28601),
            visualize: { label: "", component: link },
        }
        return obj;
    };

    return (
        <div>
            <BoxedContent xl={12}>
                <Box p={2}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <ResponsiveMaterialUITableWithPagination
                                    dense={true}
                                    // data={getInvoiceDataForTable()}
                                    data={resources && resources._embedded && resources._embedded.invoices.map(renderRow)}
                                    columns={getTableColumns()}
                                    sortingMode="server"
                                    sortModel={sortModel}
                                    onSortModelChange={(sortModel) => handleModule(sortModel)}
                                    pagination={true}
                                    paginationMode="server"
                                    rowsPerPage={pageSize}
                                    page={pageNumber}
                                    rowCount={resources != undefined ?
                                        (resources.page != undefined ? resources.page.totalElements : 0)
                                        : 0}
                                    onPageChange={setNumberOfPage}
                                    onPageSizeChange={setNumberElementsOnPage}
                                    rowsPerPageOptions={pageSizes}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </BoxedContent>
        </div>
    )

}
const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        invoicesList: state.global.navigationLinks._links.invoicesList.href
    }
}

export default connect(mapStateToProps)(InvoicesTableComponent)
