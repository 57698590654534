import { BreadCrumb } from "@insoft/lib-react-mui-web";
import { MessageInterface } from "@insoft/lib-react-universal/dist/interfaces/message-interface";
import { clone, expandUri, generateUUID, isEmptyArray, logThis } from "@insoft/lib-react-universal/dist/utils";
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services";
import StructureInfoAdapter from "commons/adapters/structure-info-adapter";
import { Child } from "commons/interfaces/child";
import { Navigation } from "commons/interfaces/navigation";
import { News, NewsItem } from "commons/interfaces/news";
import { SliderData } from "commons/interfaces/slider-data";
import { StructureInfo } from "commons/interfaces/structure-info";
import { UserInfo } from "commons/interfaces/user-info";
import { } from "react-redux";
import { batchActions } from "redux-batched-actions";
import { toDataURL } from "utils";
import { SINGLETON_ERRORS } from "utils/constants";
import { setStructureIdInCookie } from "utils/novae-cookies";
import { NovaeModule, REST_ENDPOINTS } from "utils/rest-routes";
import { Dispatch } from "../../../node_modules/redux";

export const GLOBAL_START_LOADING = "GLOBAL_START_LOADING"
export const GLOBAL_STOP_LOADING = "GLOBAL_STOP_LOADING"
export const GLOBAL_ADD_MESSAGE = "GLOBAL_ADD_MESSAGE"
export const GLOBAL_REMOVE_MESSAGE = "GLOBAL_REMOVE_MESSAGE"
export const GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS = "GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS"
export const GLOBAL_RETRIEVE_USER_INFO_SUCCESS = "GLOBAL_RETRIEVE_USER_INFO_SUCCESS"
export const GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS = "GLOBAL_RETRIEVE_STRUCTURE_INFO"
export const GLOBAL_RETRIEVE_NEWS_SUCCESS = "GLOBAL_RETRIEVE_NEWS_SUCCESS"
export const GLOBAL_RETRIEVE_NEWS_ITEM_SUCCESS = "GLOBAL_RETRIEVE_NEWS_ITEM_SUCCESS"
export const GLOBAL_RETRIEVE_SLIDER_DATA_SUCCESS = "GLOBAL_RETRIEVE_SLIDER_DATA_SUCCESS"
export const GLOBAL_RETRIEVE_CHILDREN_SUCCESS = "GLOBAL_RETRIEVE_CHILDREN_SUCCESS"
export const GLOBAL_RETRIEVE_NEWS_RECIPIENTS_SUCCESS = "GLOBAL_RETRIEVE_NEWS_RECIPIENTS_SUCCESS"
export const GLOBAL_SELECT_CHILD = "GLOBAL_SELECT_CHILD"
export const GLOBAL_SELECT_TAB = "GLOBAL_SELECT_TAB"
export const GLOBAL_SELECT_NEWS = "GLOBAL_SELECT_NEWS"
export const GLOBAL_SAVE_STRUCTURE_ID = "GLOBAL_SAVE_STRUCTURE_ID"
export const GLOBAL_SAVE_HISTORY_FILTERS = "GLOBAL_SAVE_HISTORY_FILTERS"
export const GLOBAL_SAVE_ADMIN_FILTERS = "GLOBAL_SAVE_ADMIN_FILTERS"
export const GLOBAL_SAVE_STAFF_FILTERS = "GLOBAL_SAVE_STAFF_FILTERS"
export const GLOBAL_SAVE_COOKIE_STRUCTUREID = "GLOBAL_SAVE_COOKIE_STRUCTUREID"
export const GLOBAL_SAVE_PAGE_HEADER_INFO = "GLOBAL_SAVE_PAGE_HEADER_INFO"



export interface GlobalAction {
    type: string
    message?: MessageInterface
    navigationLinks?: Navigation
    userInfo?: UserInfo
    structureInfo?: StructureInfo
    structureId?: string
    news?: News
    newsItem?: NewsItem
    newsRecipients?: Child[]
    sliderData?: SliderData[]
    childrenList?: Child[]
    selectedChild?: Child
    selectedTab?: string
    selectedNews?: NewsItem
    historyFilters?: HistoryFilters
    adminFilters?: AdminFilters
    staffFilters?: StaffFilters
    pageInfo?: { title?: string, breadcrumbs?: BreadCrumb[] }
}

export interface HistoryFilters {
    [searchKey: string]: string
}

export interface AdminFilters {
    [searchKey: string]: string
}

export interface StaffFilters {
    [searchKey: string]: string | string[]
}

export function saveHistoryFilters(historyFilters: HistoryFilters): GlobalAction {
    return {
        type: GLOBAL_SAVE_HISTORY_FILTERS,
        historyFilters
    }
}

export function saveAdminFilters(adminFilters: AdminFilters): GlobalAction {
    return {
        type: GLOBAL_SAVE_ADMIN_FILTERS,
        adminFilters
    }
}

export function saveStaffFilters(staffFilters: StaffFilters): GlobalAction {
    return {
        type: GLOBAL_SAVE_STAFF_FILTERS,
        staffFilters
    }
}

export function startLoading(): GlobalAction {
    return {
        type: GLOBAL_START_LOADING
    }
}

export function stopLoading(): GlobalAction {
    return {
        type: GLOBAL_STOP_LOADING
    }
}

export function addMessage(message: MessageInterface): GlobalAction {
    return {
        type: GLOBAL_ADD_MESSAGE,
        message
    }
}

export function removeMessage(message: MessageInterface): GlobalAction {
    return {
        type: GLOBAL_REMOVE_MESSAGE,
        message
    }
}

export function retrieveInitializationInfo(structureId: string, module?: NovaeModule) {
    return (dispatch: Dispatch<any>) => {
        dispatch(startLoading())
        const navigationUri = expandUri(REST_ENDPOINTS.navigation, { structureId: structureId, module: module })
        executeRequest<Navigation>({ url: navigationUri })
            .then((response) => {
                // Get the user info
                if (response._links && response._links.userInfo) {
                    const userInfoUri = response._links.userInfo.href
                    dispatch(retrieveUserInfo(userInfoUri))
                }

                // Get the structure info
                if (response._links && response._links.organization) {
                    const structureInfoUri = response._links.organization.href
                    dispatch(retrieveStructureInfo(structureInfoUri))
                }

                return dispatch(batchActions([
                    retrieveNavigationLinksSuccess(response),
                ]))
            })
            .catch((error) => {
                errorOnNavigationFetch(error, dispatch)
            })
    }
}

function retrieveNavigationLinksSuccess(navigationLinks: Navigation): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS,
        navigationLinks: navigationLinks
    }
}

export function retrieveUserInfo(userInfoUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<UserInfo>({ url: userInfoUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveUserInfoSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign user info fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveUserInfoSuccess(userInfo: UserInfo): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_USER_INFO_SUCCESS,
        userInfo
    }
}

export function retrieveStructureInfo(structureInfoUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<StructureInfo>({ url: structureInfoUri, Adapter: StructureInfoAdapter })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveStrucutureInfoSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign structure info fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveStrucutureInfoSuccess(structureInfo: StructureInfo): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS,
        structureInfo
    }
}

export function retrievePublicInitializationInfo(structureId: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(startLoading())
        const navigationUri = expandUri(REST_ENDPOINTS.navigation, { structureId: structureId })
        executeRequest<Navigation>({ url: navigationUri })
            .then((response) => {
                if (response.toChoiceOrganization) {
                    dispatch(retrieveNavigationLinksSuccess(response))
                }

                // Get the structure info
                if (response._links && response._links.organization) {
                    const uri = response._links.organization.href
                    dispatch(retrieveStructureInfo(uri))
                }

                // Get the news data
                if (response._links && response._links.news) {
                    const uri = response._links.news.href
                    dispatch(retrieveNews(uri))
                }

                // Get the slider data
                if (response._links && response._links.slider) {
                    const uri = response._links.slider.href
                    dispatch(retrieveSliderData(uri))
                }

                return dispatch(batchActions([
                    retrieveNavigationLinksSuccess(response),
                ]))
            })
            .catch((error) => {
                errorOnNavigationFetch(error, dispatch)
            })
    }
}

export function errorOnNavigationFetch(error: any, dispatch: Dispatch<any>) {
    logThis("Error durign navigation fetch", error)
    let status = error.message.status || 0
    let messageId = status === 404 ? SINGLETON_ERRORS.NAVIGATION_404 : SINGLETON_ERRORS.INIT_ERROR
    return dispatch(batchActions([
        addMessage(error.message.data.enabledOrganizations ?
            { id: messageId, message: error.message.data.message, enabledOrganizations: error.message.data.enabledOrganizations } :
            { id: messageId, message: error.message.data.message }),
        stopLoading()
    ]))
}

export function retrieveNews(newsUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<News>({ url: newsUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveNewsSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign news fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveNewsSuccess(news: News): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_NEWS_SUCCESS,
        news
    }
}

export function retrieveNewsBase64Images(news: News) {
    return (dispatch: Dispatch) => {
        if (news && news.news && !isEmptyArray(news.news)) {
            news.news.forEach((newsItem) => {
                if (!newsItem.base64Image) {
                    if (newsItem.urlImg) {
                        toDataURL(newsItem.urlImg, (dataUrl: any) => {
                            const clonedItem = clone(newsItem)
                            clonedItem.base64Image = dataUrl
                            dispatch(retrieveNewsBase64ImageSuccess(clonedItem))
                        })
                    }
                }
            })
        }
    }
}

function retrieveNewsBase64ImageSuccess(newsItem: NewsItem): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_NEWS_ITEM_SUCCESS,
        newsItem
    }
}

export function retrieveSliderData(sliderDataUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<SliderData[]>({ url: sliderDataUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveSliderDataSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign slider data fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: SINGLETON_ERRORS.INIT_ERROR }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveSliderDataSuccess(sliderData: SliderData[]): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_SLIDER_DATA_SUCCESS,
        sliderData
    }
}

export function retrieveNewsRecipients(newsRecipientsUri: string) {
    return (dispatch: Dispatch) => {
        executeRequest<Child[]>({ url: newsRecipientsUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveNewsRecipientsSuccess(response),
                ]))
            })
            .catch((error) => {
                logThis("Error durign news recipients fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), message: "Errore nel recupero della lista destinatari delle news" }),
                ]))
            })
    }
}

function retrieveNewsRecipientsSuccess(newsRecipients: Child[]): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_NEWS_RECIPIENTS_SUCCESS,
        newsRecipients
    }
}

export function retrieveChildrenList(childrenListUri: string) {
    return (dispatch: Dispatch) => {
        dispatch(startLoading())
        executeRequest<Child[]>({ url: childrenListUri })
            .then((response) => {
                return dispatch(batchActions([
                    retrieveChildrenListSuccess(response),
                    stopLoading()
                ]))
            })
            .catch((error) => {
                logThis("Error durign children list fetch", error)
                return dispatch(batchActions([
                    addMessage({ id: generateUUID(), message: "Errore nel recupero della lista dei bambini" }),
                    stopLoading()
                ]))
            })
    }
}

function retrieveChildrenListSuccess(childrenList: Child[]): GlobalAction {
    return {
        type: GLOBAL_RETRIEVE_CHILDREN_SUCCESS,
        childrenList
    }
}

export function selectChild(selectedChild: Child): GlobalAction {
    return {
        type: GLOBAL_SELECT_CHILD,
        selectedChild
    }
}

export function selectTotemTab(selectedTab: string): GlobalAction {
    return {
        type: GLOBAL_SELECT_TAB,
        selectedTab
    }
}

export function selectNews(selectedNews: NewsItem): GlobalAction {
    return {
        type: GLOBAL_SELECT_NEWS,
        selectedNews
    }
}

export function saveStructureId(structureId: string): GlobalAction {
    setStructureIdInCookie(structureId)
    return {
        type: GLOBAL_SAVE_STRUCTURE_ID,
        structureId
    }
}

export function savePageInfo(structureId: string): GlobalAction {
    setStructureIdInCookie(structureId)
    return {
        type: GLOBAL_SAVE_STRUCTURE_ID,
        structureId
    }
}

export function saveStructureIdForCookie(structureId: string): GlobalAction {
    setStructureIdInCookie(structureId)
    return {
        type: GLOBAL_SAVE_COOKIE_STRUCTUREID,
        structureId
    }
}

export function savePageHeaderInfo(pageInfo: { title: string, breadcrumbs: BreadCrumb[] }) {
    return {
        type: GLOBAL_SAVE_PAGE_HEADER_INFO,
        pageInfo
    }
}
