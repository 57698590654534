import { Image } from "commons/interfaces/child"
import { ComponentListItem } from "@insoft/lib-react-universal/dist/interfaces/component-list-item";
import { renderComponentsList } from "@insoft/lib-react-universal/dist/utils";
import Lightbox from 'react-images';
import React from 'react';
import { Grid } from "@mui/material";

interface Props {
    dailyImages: Image[]
    showTitle?: boolean
}


interface State {
    currentImage: number
    lightboxIsOpen: boolean
}

export default class ChildDiaryImages extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            currentImage: 0,
            lightboxIsOpen: false
        }
    }

    openLightbox = (index: number, event: any) => {
        event.preventDefault()
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        })
    }

    closeLightbox = () => {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        })
    }

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        })
    }

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        })
    }

    gotoImage = (index: number) => {
        this.setState({
            currentImage: index
        })
    }

    renderDailyImageView = (item: { src: string, kind: string } & ComponentListItem) => {
        if (!item.src) {
            return
        }
        if (item.kind && item.kind.startsWith('video')) {
            var preview = (<video controls style={{ maxWidth: "100%" }}><source src={item.src} type={item.kind} />Il tuo browser non supporta il video.</video>)
        } else {
            preview = (<a
                href="javascript:void(0)"
                onClick={(e) => this.openLightbox(item._itemIndex, e)}
            ><img src={item.src} style={{ maxWidth: "100%" }} /></a>)

        }
        return (
            <Grid item xs={12} sm={6} lg={4} key={item._key}>
                {preview}
            </Grid>
        )
    }

    renderTitle = () => {
        if (this.props.showTitle === undefined || this.props.showTitle) {
            return (<h3>Immagini del giorno</h3>)
        }
    }

    render() {
        if (this.props.dailyImages) {
            const objectsArray = this.props.dailyImages.map((x) => {
                return { src: x.link && x.link.href ? x.link.href : x.source, kind: x.kind }
            })
            const dailyImagesViews = renderComponentsList(objectsArray, this.renderDailyImageView)
            const title = this.renderTitle()
            return (
                <div>
                    {title}
                    <Grid container spacing={3}>
                        {dailyImagesViews}
                    </Grid>
                    <Lightbox
                        currentImage={this.state.currentImage}
                        images={objectsArray}
                        isOpen={this.state.lightboxIsOpen}
                        onClose={this.closeLightbox}
                        onClickNext={this.gotoNext}
                        onClickPrev={this.gotoPrevious}
                        showThumbnails={true}
                        onClickThumbnail={this.gotoImage}
                    />
                </div>
            )
        }
    }
}