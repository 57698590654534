import { Button } from '@mui/material';
import { TableBody } from '@mui/material';
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Invoice } from 'commons/interfaces/invoices';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { formatDateObject, FORMAT_UNI_EN28601 } from '@insoft/lib-react-universal/dist/utils/date-utils';
import React from 'react';


interface Props {
    invoices: Invoice[]
}


export class InvoicesTable extends React.Component<Props, null> {

    renderItem = (item: Invoice & ComponentListItem) => {
        const onDownload = () => {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = item.links[0].href;
            link.click();
        };

        return (
            <TableRow key={item._key}>
                <TableCell>{item.number}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{formatDateObject(item.emittingDate, FORMAT_UNI_EN28601)}</TableCell>
                <TableCell>{formatDateObject(item.expirationDate, FORMAT_UNI_EN28601)}</TableCell>
                <TableCell><Button onClick={onDownload} variant="contained" color="primary">Visualizza</Button></TableCell>
            </TableRow>
        )
    }

    render() {
        if (this.props.invoices) {
            const tableItems = renderComponentsList(this.props.invoices, this.renderItem)
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>N. Doc</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Data emissione</TableCell>
                                <TableCell>Data scadenza</TableCell>
                                <TableCell>Link</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableItems}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

}