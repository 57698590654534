import { ChildDiary } from "commons/interfaces/child";
import ic_attiv from '../../public/dist/img/ic_diary/ic_attiv.png';
import ic_face_dissatisfied from '../../public/dist/img/ic_diary/ic_face_dissatisfied.png';
import ic_face_neutral from '../../public/dist/img/ic_diary/ic_face_neutral.png';
import ic_face_satisfied from '../../public/dist/img/ic_diary/ic_face_satisfied.png';
import ic_moon from '../../public/dist/img/ic_diary/ic_moon.png';
import ic_restaurant from '../../public/dist/img/ic_diary/ic_restaurant.png';
import ic_toiletpaper from '../../public/dist/img/ic_diary/ic_toiletpaper.png';
import ic_tshirt from '../../public/dist/img/ic_diary/ic_tshirt.png';

export const ENROLLMENT_STATUS = {
    PRE_ACCEPTED: 'PRE_ACCEPTED',
    ACCEPTED: 'ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    REJECTED: 'REJECTED',
    FILLING: 'FILLING',
    WAITING_LIST: 'WAITING_LIST',
    RETIRED: 'RETIRED',
    EXPIRED: 'EXPIRED'
}

export const PAYMENT_STATUS = {
    PAID: 'PAID',
    UNPAID: 'UNPAID'
}

export const REGISTRATION_TYPE = {
    ENROLLMENT: 'ENROLLMENT',
    MODIFICA: 'FREQUENCY_CHANGE',
    SERVIZI_AGGIUNTIVI: 'ADDITIONAL_SERVICE',
    ENROLLMENT_CHANGE: 'ENROLLMENT_CHANGE',
    ISEE_CHANGE: 'ISEE_CHANGE',
    DATA_CHANGE: 'DATA_CHANGE'
}

export const CONTENT_TYPE = {
    NEWS: 'NEWS',
    ORGANIZATION_DETAILS: 'ORGANIZATION_DETAILS'
}

export const SINGLETON_ERRORS = {
    INIT_ERROR: 'INIT_ERROR',
    NAVIGATION_404: 'NAVIGATION_NOT_FOUND'
}

export const REPRESENTS = {
    PRIVACY: 'privacy',
    PARENT_LIST: 'parent-list',
    DISABLE_SECTION: 'disable-section',
    RECIPIENTS_PICKER: 'recipients-picker'
}

export const FORM_INPUT_KEYS = {
    NEW_PASSWORD: "newPassword1",
    RETYPE_NEW_PASSWORD: "newPassword2"
}

export const DIARY_ICONS_MAPPING = {
    "alim": ic_restaurant,
    "bis_corp": ic_toiletpaper,
    "sonno": ic_moon,
    "meterial": ic_tshirt,
    "attiv": ic_attiv,
    "riepilogo": ic_attiv,
    "servizi": ic_attiv,
    "neutral": ic_face_neutral,
    "satisfied": ic_face_satisfied,
    "dissatisfied": ic_face_dissatisfied
}

export const TOTEM_CONSTANTS = {
    NEWS_TAB: "news",
    DIARIES_TAB: "diaries",
    DOCUMENTS_TAB: "documents"
}


export const PARENT_APP_CONSTANTS = {
    DIARY_TAB: 0,
    MEDIA_TAB: 1,
    NEWS_TAB: 2,
    DOCUMENTS_TAB: 3,
    INVOICES_TAB: 4
}

export const HEIGHTS = {
    TOTEM_TOP_SECTION_TITLE: 30
}

export const STRINGS = {
    ERROR_TOTEM_USER_NOT_AUTHORIZED: `Siamo spiacenti, ma questa sezione non è disponibile per l'operatore loggato.`,
    ERROR_NAVIGATION_NOT_FOUND: `Siamo spiacenti, ma il link utilizzato è scaduto. Ritenta usando quello fornito dall'asilo.`
}

// TODO: Temporaneamente qui, va rimosso una volta testato il totem
export const DIARY_BUNDLE1: ChildDiary = {
    diaryId: "DIAR_1",
    childId: "42",
    dayResultImage: "https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg?auto=compress&cs=tinysrgb&h=350",
    dayResultDescription: "descrizione del diary",
    sections: [
        {
            icon: "alim",
            title: "Pappa",
            items: [
                {
                    label: "Primo",
                    value: "metà"
                }, {
                    label: "Secondo",
                    value: "tutto"
                }, {
                    label: "Contorno",
                    value: "quasi tutto"
                }, {
                    label: "Merenda mattino",
                    value: "metà"
                }, {
                    label: "Merenda pomeriggio",
                    value: "tutto"
                }, {
                    label: "Note",
                    value: "Ha mangiato più del solito"
                }
            ]
        },
        {
            icon: "bis_corp",
            title: "Pipì e Popò",
            items: [
                {
                    label: "N. volte",
                    value: "2"
                }
            ]
        }, {
            icon: "sonno",
            title: "Nanna",
            items: [
                {
                    label: "Mattina",
                    value: "1 ora"
                }, {
                    label: "Pomeriggio",
                    value: "2 ore"
                }
            ]
        }, {
            icon: "meterial",
            title: "Mi serve",
            items: [
                {
                    label: "Magliette maniche lunghe",
                    value: "2"
                }, {
                    label: "Magliette maniche corte",
                    value: "3"
                }, {
                    label: "Body",
                    value: "2"
                }
            ]
        }, {
            icon: "attiv",
            title: "Attività",
            items: [
                {
                    label: "Attività del giorno",
                    value: "Giochi con l'acqua"
                }
            ]
        }, {
            icon: "riepilogo",
            title: "Riepilogo giornata",
            items: [
                {
                    label: "La mia giornata",
                    value: "Buona"
                }, {
                    label: "Note",
                    value: "Giocato con l'acqua"
                }
            ]
        }, {
            icon: "servizi",
            title: "Servizi",
            items: [
                {
                    label: "Pre accoglienza",
                    value: "Sì"
                }, {
                    label: "Pasto",
                    value: "Sì"
                }
            ]
        }
    ],
    dailyImages: [
        { link: {href: "https://www.elastic.co/assets/bltada7771f270d08f6/enhanced-buzz-1492-1379411828-15.jpg", rel: "self" }},
        { link: {href: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFPfEiNqWYes09QWju_60SjyegWeIQEFuiDR_KUa1CtZuYH0RE",rel: "self" }},
        { link: {href: "https://3.bp.blogspot.com/--wJKcRs0bKc/Wntv6WfrlVI/AAAAAAAAASg/TP_zwxM0XF8AOFqOuOjVrHEvYUiisI6QwCLcBGAs/s1600/3.jpeg",rel: "self" }},
        { link: {href: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRckCQc1uoVLV0HVhfa_dBpE8QS3UQrta_hF0WI6zXAIql0T2TC" ,rel: "self" }}
    ]
}

export const MESSAGE_PROMPT :string = "Le modifiche apportate potrebbero non essere salvate. Vuoi cambiare pagina?"