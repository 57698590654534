import { castTo, isObjectEmpty } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography'
import { NOVAE_PALETTE } from 'commons/styles/material-ui-style';

interface Props {
    firstName: string
    lastName: string
    open: Boolean
}

// TODO questa potrebbe essere generica???
export default function UserArea(props: Props) {

    let { firstName, lastName, open } = props

    if (isObjectEmpty(props)) {
        return null
    }

    if (open) {
        var completeName = '' + (firstName || '') + ' ' + (lastName || '')
    }

    return (
        <Box display="flex" padding={1.5} alignItems="center">
            <Box >
                <Avatar alt={completeName} src='/dist/img/ic_person.png' />
            </Box>
            <Box flexGrow={1} >
                <Typography align="center">{completeName}</Typography>
            </Box>
        </Box>
    )
}