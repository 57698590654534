
export const REST_ENDPOINTS = {
    navigation: '/resource/navigation{?structureId,module}',
    CHECK_AUTHENTICATION: 'resource/check-authentication'
}

export enum NovaeModule {
    MULTIPLE_STRUCTURE_MANAGMENT = "MULTIPLE_STRUCTURE_MANAGMENT",
    STRUCTURE_MANAGMENT = "STRUCTURE_MANAGMENT",
    PARENTS_ENROLLMENTS = "PARENTS_ENROLLMENTS"
}

export const APP_STORE_URLS = {
    GOOGLE: 'https://play.google.com/store/apps/details?id=it.insoft.novae.genitori',
    APPLE: 'https://apps.apple.com/it/app/novae-genitori/id1434413250?l=en'
}

export const BUNDLE_REST_ENDPOINTS = {
    root: 'http://localhost:8080/bundle/landing-page.json',
    navigation: 'http://localhost:8080/bundle/navigation.json',

    // Required, because it can be called with OPTIONS or GET methods
    userInformation: 'http://localhost:8080/bundle/user-information.json',

    // Required, because endopoint not reached by navigation
    summary: 'http://localhost:8080/bundle/summary.json'
}

export const WEB_ENDPOINTS = {
    novae: '/novae{?structureId}',
    dashboard: '/dashboard{?structureId}',
    root: '/{?structureId}'
}

export const PAGES = {

    logout: '/logout',
    notFound: '/not-found',
    dashboard: '/',
    changePassword: '/cambia-password',
    riepilogo: '/riepilogo',
    //TODO controllare se nuova-iscrizione serve ancora
    nuovaIscrizione: '/nuova-iscrizione',
    nuovaIscrizioneFrequenza: '/nuova-iscrizione/frequenza',
    templateNuovaIscrizioneFrequenza: '/template/nuova-iscrizione/frequenza',
    cambioDati: '/cambio-dati',
    templateCambioDati: '/template/cambio-dati',
    cambioDatiDichiarazioni: '/cambio-dati/dichiarazioni',
    templateCambioDatiDichiarazioni: '/template/cambio-dati/dichiarazioni',
    cambioDatiRiepilogo: '/cambio-dati/riepilogo',
    nuovaIscrizioneDichiarazioni: '/nuova-iscrizione/dichiarazioni',
    templateNuovaIscrizioneDichiarazioni: '/template/nuova-iscrizione/dichiarazioni',
    nuovaIscrizioneRiepilogo: '/nuova-iscrizione/riepilogo',
    modificaIscrizione: '/modifica-iscrizione',
    templateModificaIscrizione: '/template/modifica-iscrizione',
    modificaIscrizioneRiepilogo: '/modifica-iscrizione/riepilogo',
    serviziAggiuntivi: '/servizi-aggiuntivi',
    templateServiziAggiuntivi: '/template/servizi-aggiuntivi',
    serviziAggiuntiviRiepilogo: '/servizi-aggiuntivi/riepilogo',
    modificaInizioFineFrequenza: '/modifica-inizio-fine-frequenza',
    modificaInizioFineFrequenzaRiepilogo: '/modifica-inizio-fine-frequenza/riepilogo',
    templateModificaInizioFineFrequenza: '/template/modifica-inizio-fine-frequenza',
    cambioIsee: '/cambio-isee',
    templateCambioIsee: '/template/cambio-isee',
    cambioIseeRiepilogo: '/cambio-isee/riepilogo',
    contentManager: '/content-manager', // TODO forse non serve?

    contentManagerDescriptions: '/content-manager/descriptions',
    comune: '/comune',

    richieste: '/richieste',
    storicoRichieste: '/richieste/storico',
    listaAttesa: '/richieste/lista-attesa',
    requestBin: '/richieste/cestino',
    richiesteInCompilazione: '/richieste/in-compilazione',

    changeParameters: '/parameters',

    totem: '/totem',
    admin: '/admin',

    news: '/news',
    tableNews: '/news/all',
    newsModify: '/news/news-modify',
    readNews: '/news/read-users',
    newsDetails: '/news/read-users/news-details',

    organizationChange: '/organization-change',
    appSimulator: '/app-simulator',
    listInvoices: '/lista-fatture',
    listDocuments: '/lista-documenti',
    getStaff: '/personale',
    newStaff: '/nuovo/personale',
    modifyStaff: '/modifica/personale',

    calendar: '/calendar',
    yearTemplate: '/template',

    reportPresenze: '/report/organization',
    reportPreview: '/report/preview/:id?',

    accountHolders: '/account-holders'
}

export const MODULE_ROOTS = {
    multiStructureModule: '/multi-structure-dashboard',
    enrollmentsModule: '/enrollments'
}

export const MULTI_STRUCTURE_MODULE_PAGES = {
    report: '/report',
    presences: '/report/presences',
    cobertura: '/report/cobertura'
}