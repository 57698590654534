import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { GlobalStateInterface } from "redux/store";
import { addMessage, removeMessage, startLoading, stopLoading } from "@insoft/lib-react-universal/dist/redux/base-actions";
import { generateUUID, logThis, renderComponentsList } from "@insoft/lib-react-universal/dist/utils";
import { STRINGS } from "utils/constants";
import { PAGES } from "utils/rest-routes";
import { MessageInterface } from "@insoft/lib-react-universal/dist/interfaces/message-interface";
import { HistoryFilters, saveHistoryFilters } from "redux/actions/global-actions";
import { getApplicationSimulatorFormObject } from "./parent-app-search-form-object";
import { FormPage } from "@insoft/lib-react-web/dist/components";
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from "commons/styles/common-form-style-rules";

interface ActionCreatorsProps {
    addMessage: (message: MessageInterface) => any
    removeMessage: (message: MessageInterface) => any
    startLoading: () => any
    stopLoading: () => any
}

export interface Props {
    loading?: boolean
    historyFilters: HistoryFilters
    yearsLink: string
    parentsByYear: string
}

interface Year {
    id: number
    descr: string
    from: string
    to: string
}

interface State {
    years: Year[]
    yearSelected: number
}

interface FormData {
    [searchKey: string]: string
}

interface AllProps extends ActionCreatorsProps, Props {
    searchCallBack?: (data: FormData) => void
}

function ParentsAppSearchForm(props: AllProps) {


    // const [years, setYears] = useState<Year[]>(null)
    // const [yearSelected, setYearSelected] = useState<number>(null)

    useEffect(() => {
        if (!props.yearsLink) {
            props.addMessage({ id: generateUUID(), message: STRINGS.ERROR_TOTEM_USER_NOT_AUTHORIZED, target: PAGES.appSimulator })
        }
    }, []);


    /*    selectDefaultYear = (data: Year[] ): number|undefined => {
            if (data) {
                let element: Year = data.find((year: Year) => {
                    let now = new Date().getTime()
                    return getDateFromString(year.from, 'yyyy-MM-dd').getTime() <= now && getDateFromString(year.to, 'yyyy-MM-dd').getTime() >= now
                }) 
                return element ? element.id : undefined
            }
            return undefined
        }*/



    const onFormSubmit = (formObject: { formData: FormData }) => {
        props.startLoading()
        if (props.searchCallBack) {
            props.searchCallBack(formObject.formData)
        }
    }


    const formObject = getApplicationSimulatorFormObject(props)
    return (
        <FormPage
            formStyleRules={commonFormStyleRules}
            fields={getCommonFields()}
            widgets={getCommonWidgets()}
            onFormSubmit={onFormSubmit}
            resources={formObject}
            title={"Ricerca Genitore"}
            buttonText="Cerca"
            showRequiredFieldsLegend
        />
    )

}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        yearsLink: state.global.navigationLinks._links.parentYears.href,
        parentsByYear: state.global.navigationLinks._links.parentsByYear.href,
        historyFilters: state.global.historyFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        addMessage: bindActionCreators(addMessage, dispatch),
        removeMessage: bindActionCreators(removeMessage, dispatch),
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ParentsAppSearchForm)