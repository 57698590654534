import { logThis } from "@insoft/lib-react-universal/dist";
import { AlertObject, composeUriPath, FormPage, getFormData, getRedirectComponent, parsePositiveResponse, parseServerErrorResponse, sendForm } from "@insoft/lib-react-web";
import { Box } from "@mui/material";
import { commonFormStyleRules, getCommonFields, getCommonWidgets } from "commons/styles/common-form-style-rules";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { startLoading, stopLoading } from "redux/actions/global-actions";
import { GlobalStateInterface } from "redux/store";
import { getLoadingComponent, renderAlertComponent } from "utils";
import { PAGES } from "utils/rest-routes";

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading?: boolean
    url?: string
    structureId?: string
    nextPage: string
}

interface AllProps
    extends ActionCreatorsProps, Props { }

function NewStaff(props: AllProps) {

    const [resources, setResources] = useState<any>(null);
    const [ana, setAna] = useState<string>(undefined)
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
    const [serverResponse, setServerResponse] = useState<AlertObject>(undefined);

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        props.startLoading()
        getFormData({ url: props.url })
            .then((data) => {
                setResources(
                    data
                )
                props.stopLoading()
            }).catch((data) => {
                logThis(data)
                props.stopLoading()
            })
    }

    const onFormSubmit = (formObject: { formData: { [key: string]: any } }) => {
        let formData = formObject.formData
        formData.cst = props.structureId
        props.startLoading()
        sendForm({ method: 'POST', url: resources._links.self.href, data: formData })
            .then((message) => {
                setServerResponse(parsePositiveResponse({ autoclose: true }))
                setAna(message)
                setFormSubmitted(true)
            }).catch((error) => {
                setServerResponse(parsePositiveResponse(parseServerErrorResponse(error)))
                props.stopLoading()
            })
    }

    let serverResponseComponent = renderAlertComponent(serverResponse)

    if (formSubmitted) {
        return getRedirectComponent(composeUriPath(props.nextPage, [ana]))
    }

    if (props.loading) {
        return getLoadingComponent(true)
    }

    return (
        <Box>
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={onFormSubmit}
                resources={resources}
                buttonText="Crea"
                showRequiredFieldsLegend
            />
            {serverResponseComponent}
        </Box >
    )


}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        url: state.global.navigationLinks._links.newStaff.href,
        structureId: state.global.structureId,
        nextPage: PAGES.newStaff
    }
};

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewStaff)