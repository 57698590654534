
import { Button } from "@mui/material";
import ChildDiaryImages from "commons/components/child-diary-images";
import { Image } from "commons/interfaces/child";
import { MessageInterface } from "@insoft/lib-react-universal/dist/interfaces/message-interface";
import { appendQueryParams, expandUri, generateUUID, logThis } from "@insoft/lib-react-universal/dist/utils";
import LoadingComponent from "@insoft/lib-react-web/dist/components/misc/loading-componentMaterial";
import { HateoasResponse, Page } from "@insoft/lib-react-web/dist/interfaces/hateoas";
import { executeRequest } from "@insoft/lib-react-web/dist/services/ajax-services";
import React, { useEffect, useState } from "react";
import { PAGES, REST_ENDPOINTS } from "utils/rest-routes";

interface Props {
    childId: number
    childrenImagesUrl: string
    childrenChoice: JSX.Element
    renderNoData: () => JSX.Element
    addMessage: (message: MessageInterface) => any
    removeMessage: (message: MessageInterface) => any
}

interface State {
    images: Image[]
}

function SliderImages(props: Props) {
    const [loading, setLoading] = useState<boolean>(false)
    const [images, setImages] = useState<Image[]>(undefined)
    const [fetchUrl, setFetchUrl] = useState<string>(undefined)
    const [nextUrl, setNextUrl] = useState<string>(undefined)

    useEffect(() => {
        setImages(undefined)
        setFetchUrl(appendQueryParams(expandUri(props.childrenImagesUrl, { childId: props.childId.toString() }), { page: "0", size: "5", sort: "imageDate,desc" }))
    }, [props.childId])


    useEffect(() => {
        console.log('images use effect')
        console.log(props)
        fetchImages()
    }, [fetchUrl])


    const showMore = () => {
        setFetchUrl(nextUrl)
    }

    const fetchImages = () => {
        if (fetchUrl) {
            setLoading(true)
            executeRequest<HateoasResponse<Image>>({ url: fetchUrl, sessionCheckUri: REST_ENDPOINTS.CHECK_AUTHENTICATION })
                .then((data) => {
                    var newImages = images ? images : []
                    if (data && data._embedded && data._embedded["images"]) {
                        for (var i = 0; i < data._embedded["images"].length; i++) {
                            newImages.push(data._embedded["images"][i])
                        }
                    }
                    setImages(newImages)
                    setNextUrl(data._links.next ? data._links.next.href : undefined)
                    setLoading(false)
                    return null
                }).catch((data) => {
                    props.addMessage({ id: generateUUID(), message: "Impossibile recuperare le foto", target: PAGES.appSimulator })
                    setLoading(false)
                    logThis(data)
                })
        }
    }

    const renderButton = () => {
        if (nextUrl) {
            return (
                <div className="center">
                    <Button variant="contained" color="primary" onClick={() => showMore()}>
                        Prossimi elementi
                    </Button>
                </div>
            )
        }
    }

    if (loading && !images) {
        return (<LoadingComponent fullPage={false} />)
    }

    let imagesComp
    if (images && images.length > 0) {
        imagesComp = (
            <ChildDiaryImages dailyImages={images} showTitle={false} />
        )
        var buttonArea
        if (loading) {
            buttonArea = (<LoadingComponent fullPage={false} />)
        } else {
            buttonArea = renderButton()
        }
    } else {
        imagesComp = props.renderNoData();
    }
    return (
        <div>
            {props.childrenChoice}
            {imagesComp}
            {buttonArea}
        </div>
    )
}

export default SliderImages