import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { generateUUID, expandUri } from '@insoft/lib-react-universal/dist/utils';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import { FormDataObject, FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { sendForm } from '@insoft/lib-react-web/dist/services/ajax-services';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from '@insoft/lib-react-web/dist/utils/schema-utils';
import { SEARCH_FILEDS } from '../multi-structure-presences-report/search-multi-structure-form-object';
import { Method } from 'axios';
import SnackBarAlert from '@insoft/lib-react-web/dist/components/misc/snackbar-alert';
import { getLoadingComponent } from 'utils';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean,
    urlRequest: string,
    urlModify: string
}

interface AllProps extends ActionCreatorsProps, Props { }

function OrganizationChange(props: AllProps) {
    const navigate = useNavigate();
    const params = useParams()
    const defaultOption = params.yearId
    const width: string = 'col-lg-12 col-md-10 '
    const [id, setId] = useState<string>(params.id)
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
    const [snackBarError, setSnackBarError] = useState<any>(null)
    const [formKey, setFormKey] = useState<string>(generateUUID())

    // useEffect(() => {
    //     executeRequest<Array<Obj>>({url: expandUri(props.urlRequest, { requestId: id }), method: 'GET'})
    //         .then((data) =>{
    //             console.log(data[0].value)
    //             setDefaultOption(data[0].value)
    //         })
    // }, [])

    const onFormSubmit = (formObject: { formData: object }) => {
        const formData = formObject.formData
        const method: Method = 'PUT'
        const url: string = expandUri(props.urlModify, { requestId: id })
        props.startLoading()
        sendForm({ method: method, url: url, data: formData })
            .then((response) => {
                if (response.id) {
                    setId(response.id)
                }
                props.stopLoading()
                let opt = {
                    closable: true
                }
                goBack()
                // props.history.push(PAGES.richiesteInCompilazione)

            }).catch((error) => {
                props.stopLoading()
                setSnackBarOpen(true)
                setSnackBarError(error)
                setFormKey(generateUUID())
            });
    }

    if (props.loading) {
        return getLoadingComponent(true)
    }

    const goBack = () => {
        navigate(-1)
    }

    let properties: { [key: string]: any } = {}

    let formDataDefault: { [key: string]: any } = {}

    properties[SEARCH_FILEDS.STRUCTURE] = {
        type: "string",
        title: "Struttura",
        represents: [FORM_INPUT_DEFAULT_WIDGETS.LIST, FORM_LIST_TYPE.DROPDOWN_LIST],
        autocomplete: {
            href: expandUri(props.urlRequest, { requestId: id })
        }
    }
    formDataDefault[SEARCH_FILEDS.STRUCTURE] = defaultOption
    let formDataObject: FormDataObject = {
        form: {
            formSchema: {
                type: "object",
                properties: properties,
                required: [SEARCH_FILEDS.STRUCTURE],
            },
            formData: formDataDefault
        }
    }
    let resource: FormObject
    resource = {
        data: formDataObject,
        method: null,
        url: null
    }

    //mettere i campi required per fare ricerche con filtri selezionati?
    // const title : any = props.history
    // console.log(props.location.state)
    if (defaultOption) {
        return (
            <Box>
                <SnackBarAlert
                    error={snackBarError}
                    open={snackBarOpen}
                />
                <Box>
                    <div className={width}>
                        <FormPage
                            formKey={formKey}
                            formStyleRules={commonFormStyleRules}
                            fields={getCommonFields()}
                            widgets={getCommonWidgets()}
                            onFormSubmit={onFormSubmit}
                            resources={resource}
                            boxStyle='warning'
                            buttonText="Salva"
                        />
                    </div>
                </Box>
            </Box>
        )
    } else {
        return getLoadingComponent()
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        urlRequest: state.global.navigationLinks._links.getOrganizations.href,
        urlModify: state.global.navigationLinks._links.changeOrganization.href
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationChange)