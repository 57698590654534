import { Enrollment, Requests } from 'commons/interfaces//enrollments';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import STYLE from 'commons/styles/style';
import { composeUriPath } from '@insoft/lib-react-web/dist/utils/utils';
import { REGISTRATION_TYPE } from 'utils/constants';
import { PAGES } from 'utils/rest-routes';
import { Link } from 'react-router-dom';
import HistorySearchForm from './search-history';
import { Box, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { GridSortItem } from '@mui/x-data-grid';
import { HistoryFilters } from 'redux/actions/global-actions';
import { formatDate, formatStringDate, getFunctionName, getLoadingComponent, isEnrollmentInFillingStatus } from 'utils';
import { defaultReactDataRenderMUITable, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel } from '@insoft/lib-react-web';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { appendQueryParams, ComponentListItem, expandUri, generateUUID, logThis, ReactLabelComponent, renderComponentsList } from '@insoft/lib-react-universal/dist';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface Props {
    structureInfo: StructureInfo;
    requestUrl: string
    historySearchFilters: HistoryFilters
}

interface TableRow {
    collapsibleArrow?: ReactLabelComponent
    id: number
    lastName: string
    firstName: string
    birthDate: string
    submissionDate: string
    ae: string
    startDate: string
    order?: number | string
    type?: string
    state: ReactLabelComponent
    action: ReactLabelComponent
}

function getTableColumns(isScreenXS?: boolean) {
    let tableColumns: TableColumnDefinition[] = []
    if (isScreenXS) {
        tableColumns.push({
            headerName: "",
            field: "collapsibleArrow",
            sortable: false,
            showMobile: true,
            mobileCollapser: true,
            renderCell: defaultReactDataRenderMUITable
        });
    }
    tableColumns.push({ headerName: 'Cognome', field: 'lastName', showMobile: true, })
    tableColumns.push({ headerName: 'Nome', field: 'firstName', showMobile: true })
    tableColumns.push({ headerName: 'Data di nascita', field: 'birthDate' })
    tableColumns.push({ headerName: 'Data', field: 'submissionDate' })
    tableColumns.push({ headerName: 'A.E.', field: 'ae' })
    tableColumns.push({ headerName: 'Data di inizio', field: 'startDate' })
    tableColumns.push({ headerName: 'Ord.', field: 'order' })
    tableColumns.push({ headerName: 'Tipo', field: 'type' })
    tableColumns.push({
        headerName: 'Stato',
        field: 'state',
        renderCell: defaultReactDataRenderMUITable,
        showMobile: true
    })
    tableColumns.push({
        headerName: '',
        field: 'action',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    return tableColumns
}

function OrganizationRequestsHistory(props: Props) {

    const pageSizes = [10, 20, 50];

    const [resources, setResources] = useState<Requests>(null);
    const [datatableKey, setDatatableKey] = useState<string>(generateUUID());
    const [sortModel, setSortModel] = useState<TableSortModel>({ field: "submissionDate", sort: "desc" });
    const [loading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
    const theme = useTheme();
    const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));
    const [collapsedElements, setCollapsedElements] = useState<number[]>([])


    useEffect(() => {
        fetchData()
    }, [props.requestUrl, pageNumber, pageSize, sortModel, datatableKey]);

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const fetchData = () => {
        if (props.requestUrl) {
            setLoading(true)
            var sortingArray: string[] = [];
            if (sortModel) {
                sortingArray.push(createParam(sortModel.field, sortModel));
            }

            let requestUrl = expandUri(props.requestUrl, { projection: "enrollments" })
            if (props.historySearchFilters) {
                let queryParams: { [key: string]: string[] } = {}
                for (const keySearch in props.historySearchFilters) {
                    if (!props.historySearchFilters[keySearch]) {
                        continue;
                    }
                    setPageNumber(0)    //se ci sono filtri di ricerca allora setto la pagina a zero (se no  potrei avere una visualizzazione errata della tabella)
                    let columnKey = keySearch.lastIndexOf('_') === -1 ? keySearch : keySearch.substring(0, keySearch.lastIndexOf('_'))
                    var column
                    for (var i = 0; i < getTableColumns().length; i++) {
                        const colTable = getTableColumns()[i];
                        if (colTable.field === columnKey) {
                            if(colTable.field === "lastName") {
                                column = "child.lastName"
                            } else if(colTable.field === "firstName") {
                                column = "child.firstName"
                            } else if(colTable.field === "state") {
                                column = "status"
                            } else if(colTable.field === "ae") {
                                column = "yearId"
                            }
                            else {
                                column = colTable.field
                            }
                            break;
                        }
                    }
                    let searchParamsName: string[] = column ? column.split(',') : undefined
                    if (searchParamsName) {
                        for (i = 0; i < searchParamsName.length; i++) {
                            if (!queryParams[searchParamsName[i]]) {
                                queryParams[searchParamsName[i]] = [props.historySearchFilters[keySearch]];
                            } else {
                                queryParams[searchParamsName[i]].push(props.historySearchFilters[keySearch])
                            }
                        }
                    }
                }

                requestUrl = appendQueryParams(requestUrl, queryParams)
            }
            requestUrl = appendQueryParams(
                requestUrl,
                { page: pageNumber.toString(), size: pageSize.toString(), sort: sortingArray }
            );

            executeRequest<Requests>({ url: requestUrl, method: "GET" })
                .then((data) => {
                    setResources(data)
                    setLoading(false)
                    return null;
                }).catch((data) => {
                    setLoading(false)
                    logThis(data)
                })
        }
    }

    const renderRow = (
        enrollment: Enrollment & ComponentListItem
    ) => {
        const ND = "N.D.";
        let enrollmentId = enrollment.id;
        let child = enrollment.child;
        let status = enrollment.status;
        let statusClassName = STYLE.tags[status] ? STYLE.tags[status].class : ND;
        let statusLabel = STYLE.tags[status] ? STYLE.tags[status].label : ND;
        let type = enrollment.type;
        let typeLabel = getFunctionName(type, props.structureInfo) || ND;
        let lastPage = enrollment.lastPage;
        let order = enrollment.order !== undefined ? enrollment.order : ND;
        let submissionDate =
            enrollment.submissionDateTime !== undefined
                ? new Date(enrollment.submissionDateTime)
                : undefined;

        switch (type) {
            case REGISTRATION_TYPE.ENROLLMENT:
                var targetPage = isEnrollmentInFillingStatus(status)
                    ? (lastPage == 1 ? PAGES.nuovaIscrizioneDichiarazioni : PAGES.nuovaIscrizioneFrequenza)
                    : PAGES.nuovaIscrizioneRiepilogo;
                break;
            case REGISTRATION_TYPE.DATA_CHANGE:
                targetPage = isEnrollmentInFillingStatus(status)
                    ? (lastPage == 1 ? PAGES.cambioDatiDichiarazioni : PAGES.cambioDati)
                    : PAGES.cambioDatiRiepilogo;
                break;
            case REGISTRATION_TYPE.MODIFICA:
                targetPage = isEnrollmentInFillingStatus(status)
                    ? PAGES.modificaIscrizione
                    : PAGES.modificaIscrizioneRiepilogo;
                break;
            case REGISTRATION_TYPE.SERVIZI_AGGIUNTIVI:
                targetPage = isEnrollmentInFillingStatus(status)
                    ? PAGES.serviziAggiuntivi
                    : PAGES.serviziAggiuntiviRiepilogo;
                break;
            case REGISTRATION_TYPE.ENROLLMENT_CHANGE:
                targetPage = isEnrollmentInFillingStatus(status)
                    ? PAGES.modificaInizioFineFrequenza
                    : PAGES.modificaInizioFineFrequenzaRiepilogo;
                break;
            case REGISTRATION_TYPE.ISEE_CHANGE:
                targetPage = isEnrollmentInFillingStatus(status)
                    ? PAGES.cambioIsee
                    : PAGES.cambioIseeRiepilogo;
                break;
        }

        const stateComponent = (
            <span>
                <span className={statusClassName}>{statusLabel}</span>
            </span>
        );

        var actionComponent
        if (targetPage) {
            var linkUrl = composeUriPath(targetPage, [enrollmentId]);
            var linkLabel = isEnrollmentInFillingStatus(status)
                ? "Continua compilazione"
                : "Consulta";
            actionComponent = <Link to={linkUrl}>{linkLabel}</Link>
        } else {
            actionComponent = <span></span>
        }

        let collapsibleArrow: any
        if (collapsedElements.indexOf(enrollment._itemIndex + 1, 0) != -1) {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(enrollment._itemIndex + 1)}><ExpandLessIcon /></IconButton>
        } else {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(enrollment._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
        }

        let enrollmentObject: TableRow = {
            collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
            id: enrollment._itemIndex + 1,
            lastName: child.lastName,
            firstName: child.firstName,
            birthDate: formatStringDate(child.birthDate),
            submissionDate: formatDate(submissionDate),
            type: typeLabel,
            order: order,
            state: { label: statusLabel, component: stateComponent },
            action: { label: linkLabel, component: actionComponent },
            ae: enrollment.year,
            startDate: enrollment.effectiveStartDate
        };
        return enrollmentObject;
    };
    const manageCollapsedElements = (element: number) => {
        let index = collapsedElements.indexOf(element, 0)
        let newCollapsedElements: number[] = []
        collapsedElements.forEach((element) => {
            newCollapsedElements.push(element)
        })
        if (index != -1) {
            newCollapsedElements.splice(index, 1)
        } else {
            newCollapsedElements.push(element)
        }
        setCollapsedElements(newCollapsedElements)
    }


    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    if (loading) {
        return getLoadingComponent(true)
    }

    if (!props.requestUrl) {
        return null
    }
    return (
        <Grid container spacing={3}>
            <HistorySearchForm
                searchCallBack={() => setDatatableKey(generateUUID())}
            />
            <BoxedContent>
                <Box p={2}>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", height: "100%" }}>
                            <div style={{ flexGrow: 1 }}>
                                <ResponsiveMaterialUITableWithPagination
                                    collapsedElements={collapsedElements}
                                    data={resources && resources._embedded && renderComponentsList(resources._embedded.requestSummaries, renderRow)}
                                    columns={getTableColumns(isScreenXS)}
                                    pagination
                                    paginationMode="server"
                                    page={pageNumber}
                                    rowsPerPage={pageSize}
                                    rowCount={resources ? (resources.page ? resources.page.totalElements : 0) : 0}
                                    onPageChange={setNumberOfPage}
                                    onPageSizeChange={setNumberElementsOnPage}
                                    rowsPerPageOptions={pageSizes}
                                    sortingMode="server"
                                    sortModel={sortModel}
                                    onSortModelChange={(model) => handleModule(model)}
                                    loading={loading}
                                    isScreenXS={isScreenXS}
                                />
                            </div>
                        </div>
                    </div>
                </Box>
            </BoxedContent>
        </Grid>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationRequestsHistory) {
        var requestUrl = state.global.navigationLinks._links.organizationRequestsHistory.href
    }
    return {
        structureInfo: state.global.structureInfo,
        requestUrl: requestUrl,
        historySearchFilters: state.global.historyFilters
    }
};
export default connect(mapStateToProps)(OrganizationRequestsHistory)
