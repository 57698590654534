import React from 'react';
import { getTotemContentHeight } from 'utils';
import ChildDiary from './child-diary';
import ChildrenList from './children-list';

export default class DailyReport extends React.Component {
    render() {
        return (
            <div style={{ height: getTotemContentHeight() }}>
                <div className="left-container">
                    <ChildrenList />
                </div>
                <div className="right-container">
                    <ChildDiary />
                </div>
            </div>
        )
    }
}