import EnrollmentsComponent from 'commons/components/enrollments-component';
import { Enrollments } from 'commons/interfaces//enrollments';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import { getFunctionName } from 'utils';
import { REGISTRATION_TYPE } from 'utils/constants';

interface GlobalProps {
    requestUrl: string,
    excelUrl?: string,
    structureInfo: StructureInfo,
    showChangeOrganization: boolean
}



class OrganizationEnrollments extends React.Component<GlobalProps> {
    constructor(props: GlobalProps) {
        super(props)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (this.props.requestUrl) {
            executeRequest<Enrollments>({ url: this.props.requestUrl, method: "GET" })
                .then((data) => {
                    this.setState({
                        resources: data
                    })
                    return null
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    render() {
        if (!this.props.requestUrl) {
            return null
        }
        let title = 'Richieste di ' + getFunctionName(REGISTRATION_TYPE.ENROLLMENT, this.props.structureInfo) + ' in attesa'
        return (
            <EnrollmentsComponent
                requestUrl={this.props.requestUrl}
                excelUrl={this.props.excelUrl}
                title={title}
                printEmpty
                hideType
                operator={true}
                showChangeOrganization={this.props.showChangeOrganization}
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): GlobalProps => {
    if (state.global.navigationLinks._links.organizationNewEnrollments) {
        var requestUrl = state.global.navigationLinks._links.organizationNewEnrollments.href
    }
    if (state.global.navigationLinks._links.newEnrollmentExcelTable) {
        var excelUrl = state.global.navigationLinks._links.newEnrollmentExcelTable.href
    }
    return {
        requestUrl: requestUrl,
        excelUrl: excelUrl,
        structureInfo: state.global.structureInfo,
        showChangeOrganization: state.global.navigationLinks._links.changeOrganization != undefined ? true : false
    }
}

export default connect(mapStateToProps)(OrganizationEnrollments)