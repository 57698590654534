import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Year } from 'commons/interfaces//year-selection';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces//component-list-item';
import { isArray, isEmptyArray, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme';
import React from 'react';

interface Props {
    onYearSelected: (item: Year) => any
    buttons: Year[]
    changeDataType?: boolean
}

export default function YearSelectionComponent(props: Props) {
    const buttonStyle = ButtonsStyles()
    const renderButton = (item: Year & ComponentListItem) => {
        let { label } = item
        let functionToBeCalled = props.onYearSelected
        function generateCallbackWithItem() {
            function call() {
                functionToBeCalled(item)
            }
            return call
        }
        let callback = generateCallbackWithItem()
        //let disableCss = item.enable ? '' : 'disabled'
        //let cssClass = 'btn btn-primary button-margin' + disableCss
        return (
            <Button disabled={!item.enable} onClick={callback} key={item._key} color="primary" variant="contained" className={buttonStyle.buttonWithMargin}>
                {label}
            </Button>
        )
    }

    let { buttons } = props
    let title
    if (!isArray(buttons) || isEmptyArray(buttons)) {
        if(props.changeDataType) {
            title = "Nessun bambino disponibile"
        } else {
            title = "Nessun periodo disponibile"
        }
    } else {
        if (props.changeDataType) {
            title = "Selezionare il bambino:"
        } else {
            title = "Selezionare il periodo:"
        }
        var views = renderComponentsList(buttons, renderButton)
    }
    return (
        <Box className="text-center">
            <Typography variant="h3" gutterBottom={true}>{title}</Typography>
            {views}
        </Box>
    )
}