



(function () {
    // Aggiungi l'inizializzazione a livello globale (window)
    const globalNonce = document.head.querySelector("[property=csp-nonce]").getAttribute('content');

    const methodsToOverride = ['append', 'appendChild', 'insertBefore', 'replaceChild'] as const;

    methodsToOverride.forEach(method => {
        const original = document.head[method];
        // @ts-ignore
        document.head[method] = function (...args: any[]): any {
            const node = args[0];
            if (node instanceof HTMLElement && node.tagName == 'STYLE' && node.getAttribute('data-cke')) {
                node.setAttribute('nonce', globalNonce);
            }
            return original.apply(this, args);
        } as typeof document.head[typeof method];
    });
})();