import EnrollmentsComponent from 'commons/components/enrollments-component';
import { Enrollments } from 'commons/interfaces//enrollments';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';

interface Props {
    requestUrl: string
    excelUrl?: string
}


class OrganizationEnrollmentsTodo extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        if (this.props.requestUrl) {
            executeRequest<Enrollments>({ url: this.props.requestUrl, method: "GET" })
                .then((data) => {
                    this.setState({
                        resources: data
                    })
                    return null
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    render() {
        if (!this.props.requestUrl) {
            return null
        }
        let title = 'Richieste di modifica in attesa'
        return (
            <EnrollmentsComponent
                requestUrl={this.props.requestUrl}
                excelUrl={this.props.excelUrl}
                title={title}
                hideOrder
                operator={true}
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.organizationNewRequests) {
        var requestUrl = state.global.navigationLinks._links.organizationNewRequests.href
    }
    if (state.global.navigationLinks._links.newRequestExcelTable) {
        var excelUrl = state.global.navigationLinks._links.newRequestExcelTable.href
    }
    return {
        requestUrl: requestUrl,
        excelUrl: excelUrl,
    }
}

export default connect(mapStateToProps)(OrganizationEnrollmentsTodo)