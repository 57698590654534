import { ExcelIcon, MuiAlert, SpringRestTable, SpringRestTableAndSearch } from "@insoft/lib-react-mui-web";
import { Navigation } from "../../interfaces/navigation";
import { GlobalStateInterface } from "../../../redux/store";
import { useSelector } from "react-redux";
import { CollectionModel, CustomFieldProps, EntityModel, JsonSchema, LoadingComponent, MaskedInputField, PagedModel, UiSchemaValue, executeRequest } from "@insoft/lib-react-web";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { URITemplateInput, expandUri, logThis } from "@insoft/lib-react-universal";
import { useEffect, useState } from "react";
import { fecthOrganizations } from "../../services/organization-data-service";
import { EducationYearField } from "../forms/education-year-field";


export default function AccountHoldersTable(props: {
    isMultiStructure?: boolean
}) {
    const [organizations, setOrganizations] = useState<any[]>(undefined);
    const navigationLinks: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks);
    const enrollmentTypes = navigationLinks.enrollmentTypes
    const uri = navigationLinks._links?.accountHolders?.href
    if (!uri) {
        return <MuiAlert message="Impossibile visualizzare la tabella degli intestatari" />
    }

    // TODO refattorizzare!
    useEffect(() => {
        if (!organizations && props.isMultiStructure) {
            fecthOrganizations(navigationLinks)
                .then(org => setOrganizations(org))
                .catch(e => {
                    logThis(e)
                    setOrganizations([])
                })

        }
    }, [props.isMultiStructure])

    const buttonArea = (resources: PagedModel<any>) => {
        if (resources?._links?.download?.href) {
            return <Box m={1}><Button key="download" variant="contained" title="Scarica selezionati"
                href={expandUri(resources?._links?.download?.href, {})} target="_blank" endIcon={<ExcelIcon />}>Scarica</Button></Box>;
        }
        return undefined;
    };

    const columns = [{
        field: 'childName',
        headerName: 'Bambino'
    }, {
        field: 'accountHolderName',
        headerName: 'Pagatore'
    }];


    const emptyDataBox = (): JSX.Element => {
        return <Box p={2}><Typography>Non ci sono dati da visualizzare</Typography></Box>;
    }

    if (props.isMultiStructure) {
        if (!organizations) {
            return <LoadingComponent fullPage />
        }
        columns.unshift({
            field: 'organizationName',
            headerName: 'Struttura'
        })

        const jsonSchema: JsonSchema = {
            type: 'object',
            properties: {
                organizations: {
                    type: 'array',
                    title: 'strutture',
                    uniqueItems: true,
                    xs: 12,
                    lg: 9,
                    items: {
                        "$ref": "#/definitions/organizations"
                    }
                },
                year: {
                    type: 'string',
                    title: 'anno educativo',
                    xs: 12,
                    lg: 3
                },
                name: {
                    type: 'string',
                    title: 'nome',
                    xs: 12,
                    lg: 9
                }
            },
            definitions: {
                "organizations": {
                    type: 'number',
                    anyOf: organizations?.map(i => {
                        return {
                            const: i.id,
                            title: i.name
                        };
                    })
                }
            }
        };
        if (enrollmentTypes) {
            const enumValues: { type: string; enum: string[]; title: string; }[] = [];
            for (var code in enrollmentTypes) {
                enumValues.push({
                    type: 'string',
                    enum: [code],
                    title: enrollmentTypes[code]
                })
            }
            jsonSchema.properties['enrollmentType'] = {
                title: "Tipo iscrizione",
                type: "string",
                xs: 12,
                lg: 3,
                anyOf: enumValues
            }
        }

        return (
            <SpringRestTableAndSearch
                requestUrl={uri}
                embeddedKey={"accountHolderDatas"}
                columnDefinitions={columns}
                renderRow={function (item: EntityModel) {
                    return item;
                }}
                buttonArea={buttonArea}
                jsonSchemaSearch={jsonSchema}
                searchData={{}}
                searchDataToParams={function (data: any): URITemplateInput {
                    const yearString = (data.year as string)
                    if (yearString && yearString.length == 9 && yearString.charAt(4) == '/') {
                        var yearToSearch = yearString.substring(0, 4)
                    }
                    return {
                        'organizations': data.organizations?.map((i: number) => i.toString()),
                        'enrollmentType': data.enrollmentType,
                        'year': yearToSearch || yearString,
                        'name': data.name
                    }
                }}
                searchFormFields={{
                    'yearField': EducationYearField
                }}
                searchFormStyleRules={(key: string, object: any): any => {
                    let uiObject: any = {};
                    if (key == 'year') {
                        uiObject["ui:field"] = 'yearField'
                    }
                    for (var objKey in object) {
                        if (objKey.startsWith("ui:")) {
                            uiObject[objKey] = object[objKey]
                        }
                    }
                    return { key: key, value: uiObject };
                }}
                helpEmptyData={emptyDataBox}
                showLoading
            />)
    } else {

        return (

            <SpringRestTable
                requestUrl={expandUri(uri, { organizations: undefined })}
                embeddedKey={"accountHolderDatas"}
                columnDefinitions={columns}
                renderRow={function (item: EntityModel) {
                    return item
                }}
                buttonArea={buttonArea}
                helpEmptyData={emptyDataBox}
                showLoading
                searched />)
    }



}