import { useLocation, Navigate } from "react-router";
import { logThis } from "@insoft/lib-react-universal";
import { useEffect, useState } from "react";
import { ErrorBoundary, LoadingComponent, PagedModel, ResponseError, SpringErrorResponse, TableColumnDefinition, executeRequest } from "@insoft/lib-react-web";
import { ExcelIcon } from "@insoft/lib-react-mui-web";
import { SpringRestTable } from "@insoft/lib-react-mui-web";
import { Alert, AlertTitle, Box, Button, Snackbar, Stack } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { PAGES } from "../../../utils/rest-routes";

interface ColumnDefinition {
    field: string;
    title: string;
    dateFormat: string;
    tableOptions?: {
        hide?: boolean,
        hideOnSameContent?: boolean,
        lockOnScroll?: boolean,
        alignment?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
    }
}

interface ColumnsDefinition {
    columns: ColumnDefinition[]
}
export default function ReportDataPreview() {
    let { state } = useLocation();


    const [reload, setReload] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [columns, setColumns] = useState<TableColumnDefinition[]>([])

    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);
    const [errorTitle, setErrorTitle] = useState<string>(undefined);

    if (!state?.uri) {
        return <Navigate to={PAGES.reportPresenze} replace={true} />
    }

    const lockChoice = (url: string) => (event: any) => {
        setLoading(true)
        executeRequest({ url: url, method: 'PUT', retryPolicy: { retries: 1 } })
            .then(() => {
                setReload(true)
                setLoading(false)
            })
            .catch((data: ResponseError) => {
                const errorResponse: SpringErrorResponse = data.response
                logThis(data);
                setError(true)
                setErrorTitle(errorTitle)
                setErrorMessage(errorResponse.message)
                setLoading(false)
            });
    }

    // options
    useEffect(() => {
        if (state.uri && reload) {
            setLoading(true)
            executeRequest<ColumnsDefinition>({ url: state.uri, method: 'OPTIONS' })
                .then(data => {
                    const columns: TableColumnDefinition[] = data.columns.filter(item => !(item.tableOptions?.hide || false))
                        .map((item: ColumnDefinition) => {
                            return {
                                field: item.field,
                                headerName: item.title == 'Presenze' ? "P" : (item.title == 'Assenza' ? 'A' : item.title),
                                date: item.dateFormat != undefined,
                                dateFormat: 'yyyy-MM-dd' == item.dateFormat ? 'DD-MM-YYYY' : undefined,
                                disablePadding: true,
                                align: item.tableOptions?.alignment,
                                hideOnSameContent: item.tableOptions?.hideOnSameContent,
                                pinnedColumn: item.tableOptions?.lockOnScroll
                            }
                        })
                    setColumns(columns)
                    setReload(false)
                    setLoading(false)
                }).catch(error => {
                    logThis(error)
                    setReload(false)
                    setLoading(false)
                })
        }
    }, [reload])

    if (loading) {
        return (<LoadingComponent fullPage />)
    }

    if (error) {
        var snackbar =
            (<Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={error}
                autoHideDuration={6000}
                onClose={() => { setError(false) }}>
                <Alert onClose={() => { setError(false) }} severity="error" sx={{ width: '100%' }} variant="filled">
                    <AlertTitle>{errorTitle}</AlertTitle>
                    {errorMessage}
                </Alert></Snackbar>)
    }


    const renderButtons = (resources: PagedModel<any>) => {
        const buttons = []
        if (resources._links?.lock) {
            buttons.push(<Button key="lock" variant="contained" endIcon={<LockIcon />} onClick={lockChoice(resources._links?.lock.href)}>Valida</Button>)
        }
        if (resources._links?.excel) {
            buttons.push(<Button key="excel" variant="contained" href={resources._links?.excel.href} endIcon={<ExcelIcon />} target="_blank">Scarica</Button>)
        }
        if (buttons.length > 0) {
            return <Box m={2}><Stack direction="row" spacing={2}>{buttons}</Stack></Box>
        }

        return null;
    }
    // , width: '100%', backgroundColor: 'orange'
    return (
        <Box p={2} mt={3} style={{ fontSize: '16px' }}>
            <ErrorBoundary>
                {snackbar}
                <SpringRestTable
                    dense
                    searched
                    requestUrl={state.uri}
                    embeddedKey={"presenceRows"}
                    columnDefinitions={columns}
                    pagination={false}
                    renderRow={(i: any) => i}
                    maxTimeout={20 * 1000}
                    buttonArea={renderButtons}
                    showLoading
                    isRawDataOnFields={false}
                    style={{
                        MuiTableContainer: {
                            maxHeight: '60vh',
                            overflowY: 'auto'
                        }
                    }}
                    pinColumnHeader
                    helpEmptyData={() => <Alert severity="warning">Non ci sono dati da visualizzare</Alert>}
                />
            </ErrorBoundary>
        </Box>
    )
}