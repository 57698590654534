import SummaryContentRows from '@insoft/lib-react-web/dist/components/form-components/summary-content-rows';
import { AlertObject } from '@insoft/lib-react-web/dist/components/misc/alert';
import { renderAlertComponent } from 'utils';
import { ButtonsStyles } from '@insoft/lib-react-web/dist/themes/material-ui/styles/material-theme'
import Box from '@mui/material/Box'
import { Button, Divider, Typography } from '@mui/material';
import List from '@mui/icons-material/List';
import Print from '@mui/icons-material/Print';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { renderComponentsList, ComponentListItem } from '@insoft/lib-react-universal';
import { ENROLLMENT_STATUS } from 'utils/constants';
import { List as MuiList } from '@mui/material';
import { formatDateObject, FORMAT_UNI_EN28601_DATETIME_STROKES } from '@insoft/lib-react-universal';
import { CheckedData, NotesForParentsData, StatusChange, Summary } from 'commons/interfaces/summary';


interface Props {
    showPrintButton?: boolean
    printButtonText?: string,
    otherButtons?: JSX.Element[],
    resources: Summary
    title: string,
    subtitleView: any,
    serverResponse: AlertObject
    headerComponent: any,
    footerArea: any,
    helpSection: any
}

export default function SummaryComponent(props: Props) {

    const classesButton = ButtonsStyles();

    const renderPrintButton = () => {
        if (props.showPrintButton) {
            return (
                <Button className={classesButton.buttonWithMargin} color="primary" variant="contained" onClick={printClick} type='button' startIcon={<Print />}>
                    {props.printButtonText}
                </Button>
            )
        }
    }

    const printClick = () => {
        window.print()
    }

    const renderRequestConfirmData = (data: NotesForParentsData) => {
        let itemRender = (label: string, value: string) => {
            return (
                <span><strong>{label}:</strong> {value}<br /></span>)
        }
        if (data.notesForParents != undefined) {
            var notesForParents = itemRender('Note', data.notesForParents)
        }

        return (
            <Box p={2} >
                <Typography variant="h6">Note dal nido per il genitore</Typography>
                <Typography variant="body1">
                    {notesForParents}
                </Typography>
            </Box>
        )
    }

    const renderSingleStatusChange = (statusChange: StatusChange & ComponentListItem) => {
        let status
        switch (statusChange.requestStatus) {
            case ENROLLMENT_STATUS.FILLING:
                status = 'IN COMPILAZIONE'
                break;
            case ENROLLMENT_STATUS.IN_PROGRESS:
                status = 'IN LAVORAZIONE'
                break;
            case ENROLLMENT_STATUS.PRE_ACCEPTED:
                status = 'PRE-ACCETTATA'
                break;
            case ENROLLMENT_STATUS.REJECTED:
                status = 'RIFIUTATA'
                break;
            case ENROLLMENT_STATUS.ACCEPTED:
                status = 'ACCETTATA'
                break;
            case ENROLLMENT_STATUS.RETIRED:
                status = 'RITIRATA'
                break;
            case ENROLLMENT_STATUS.WAITING_LIST:
                status = 'LISTA D\'ATTESA'
                break;
        }
        return (
            <li key={'status_' + statusChange._itemIndex}>{"In "} <strong>{status}</strong> {" dal " + formatDateObject(statusChange.changeStateDate, FORMAT_UNI_EN28601_DATETIME_STROKES)}</li>
        )
    }

    const renderCheckedDataSection = (data: CheckedData) => {
        let itemRender = (label: string, value: string) => {
            return (
                <span><strong>{label}:</strong> {value}<br /></span>)
        }
        if (data.useIsee != undefined) {
            var useIsee = itemRender('Utilizzo ISEE', data.useIsee ? 'sì' : 'no')
        }
        if (data.iseeValue) {
            var iseeValue = itemRender('Importo ISEE', data.iseeValue + ' €')
        }
        if (data.iseeNumber) {
            var iseeNumber = itemRender('Attestazione ISEE', data.iseeNumber)
        }
        if (data.socialOrganizationChild != undefined) {
            var socialOrganizationChild = itemRender('Bambino a carico del comune', data.socialOrganizationChild ? 'sì' : 'no')
        }
        if (data.checkedByOrganizationData.checkedOn != undefined) {
            var checkedOn = itemRender('Controllato in data', data.checkedByOrganizationData.checkedOn)
        }
        if (data.checkedByOrganizationData.checkedBy != undefined) {
            var checkedBy = itemRender('Controllato da', data.checkedByOrganizationData.checkedBy)
        }

        return (
            <Box p={2} >
                <Typography variant="h6">Dati validati dal comune</Typography>
                <Typography variant="body1">
                    {useIsee}
                    {iseeValue}
                    {iseeNumber}
                    {socialOrganizationChild}
                    {checkedOn}
                    {checkedBy}
                </Typography>
            </Box>
        )
    }

    if (!props.resources) {
        return null
    }
    var serverResponseComponent = renderAlertComponent(props.serverResponse)
    if (props.resources.effectiveStartDate) {
        var effectiveStartDate = 'Inizio effettivo: ' + props.resources.effectiveStartDate
    }
    if (props.resources.submissionDate) {
        var submissioneDate = 'Sottomissione: ' + props.resources.submissionDate
    }
    if (props.resources.economicBand != undefined) {
        var economicBand = 'Fascia economica: ' + props.resources.economicBand
    }
    if (props.resources.checkedData) {
        var checkedDataSection = renderCheckedDataSection(props.resources.checkedData);
    }
    if (props.resources.notesForParentsData) {
        var notesForParents = renderRequestConfirmData(props.resources.notesForParentsData)
    }
    var printButton = renderPrintButton()
    var footerArea = props.footerArea
    if (props.resources.statusChanges != null && props.resources.statusChanges.length > 0) {
        return (
            <div>
                <BoxedContent title={props.title} boxCustomHeader={props.headerComponent}>
                    <Box p={1} display="flex" justifyContent="flex-end">
                        {printButton}
                        {props.otherButtons}
                    </Box>
                    <Box p={1} display="flex">
                        <Box p={1} flexGrow={1}>
                            <Typography variant="h3"><List fontSize="large" />{props.subtitleView}</Typography>
                        </Box>
                        <Box p={1}>
                            <Box fontWeight="fontWeightBold">{submissioneDate}<br />{effectiveStartDate}<br />{economicBand}</Box>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <SummaryContentRows resources={props.resources} />
                    </Box>
                    {checkedDataSection}
                    {notesForParents}
                    <Divider />
                    <Box p={1} flexGrow={1}>
                        <Typography variant="h5">Report cambi stato</Typography>
                    </Box>
                    <Box p={1}>
                        <MuiList>
                            {/* <li> */}
                            {renderComponentsList(props.resources.statusChanges, renderSingleStatusChange)}
                            {/* </li> */}
                        </MuiList>
                    </Box>
                    <Divider />
                    <Box>
                        {footerArea}
                    </Box>
                    {serverResponseComponent}
                </BoxedContent>
            </div>
        )
    } else {
        return (
            <div>
                <BoxedContent title={props.title} boxCustomHeader={props.headerComponent}>
                    <Box p={1} display="flex" justifyContent="flex-end">
                        {printButton}
                        {props.otherButtons}
                    </Box>
                    <Box p={1} display="flex">
                        <Box p={1} flexGrow={1}>
                            <Typography variant="h3"><List fontSize="large" />{props.subtitleView}</Typography>
                        </Box>
                        <Box p={1}>
                            <Typography variant="body1" align="right"><Box fontWeight="fontWeightBold">{submissioneDate}<br />{effectiveStartDate}<br />{economicBand}</Box></Typography>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <SummaryContentRows resources={props.resources} />
                    </Box>
                    {checkedDataSection}
                    {notesForParents}
                    {props.helpSection}
                    <Divider />
                    <Box>
                        {footerArea}
                    </Box>
                    {serverResponseComponent}
                </BoxedContent>
            </div>
        )
    }
}

SummaryComponent.defaultProps = {
    showPrintButton: true,
    printButtonText: 'Stampa',
    otherButtons: {}
}