import { Box, Grid } from '@mui/material';
import { startLoading, stopLoading } from '@insoft/lib-react-universal/dist/redux/base-actions';
import { logThis } from '@insoft/lib-react-universal/dist/utils';
import ErrorBoundary from '@insoft/lib-react-web/dist/components/misc/error-boundary';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { HistoryFilters } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import MultiStructureGrid from './multi-structure-grid';
import StructureSearchForm from './search-structure';
interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    loading: boolean
    requestUrlDashBoard: string
    requestUrlYear?: string
    requestUrlState?: string
    requestUrlType?: string
    historyFilters: HistoryFilters
}

interface Result {
    _links: { next: { href: string } }
}

interface AllProps extends ActionCreatorsProps, Props { }


function MultiStructurePresenceReport(props: AllProps) {

    const [linkStructure, setLinkStructure] = useState<string>(null);
    const [dataGrid, setDataGrid] = useState<any>(null);


    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        props.startLoading()
        executeRequest<Result>({ url: props.requestUrlDashBoard, method: 'GET' })
            .then((data) => {
                props.stopLoading()
                setLinkStructure(
                    data._links.next.href
                )
                return null
            }).catch((data) => {
                logThis(data)
            })
    }

    const showDataGrid = (data: any) => {
        setDataGrid(data)
    }

    if (props.loading) {
        let style: CSSProperties = {
            position: "relative",
            display: "inline-block",
            marginLeft: "49%"
        }
        var loading = (<div style={style}>{getLoadingComponent()}</div>)
    } else if (dataGrid != null) {
        var multiStructureGrid = (
            <ErrorBoundary>
                <MultiStructureGrid key={dataGrid} data={dataGrid} />
            </ErrorBoundary>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box  >
                    <ErrorBoundary>
                        <StructureSearchForm
                            linkStructure={linkStructure}
                            requestUrlDashBoard={props.requestUrlDashBoard}
                            onDataGrid={showDataGrid}
                            startLoading={props.startLoading}
                            stopLoading={props.stopLoading}
                        />
                    </ErrorBoundary>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box >
                    {loading}
                    {multiStructureGrid}
                </Box>
            </Grid>
        </Grid>
    )

}


const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrlYear: state.global.navigationLinks._links.organizationRequestsHistoryYear ? state.global.navigationLinks._links.organizationRequestsHistoryYear.href : undefined,
        requestUrlState: state.global.navigationLinks._links.organizationRequestsHistoryState ? state.global.navigationLinks._links.organizationRequestsHistoryState.href : undefined,
        requestUrlType: state.global.navigationLinks._links.organizationRequestsHistoryType ? state.global.navigationLinks._links.organizationRequestsHistoryType.href : undefined,
        requestUrlDashBoard: state.global.navigationLinks._links.coberturaReport ? state.global.navigationLinks._links.coberturaReport.href : undefined,
        historyFilters: state.global.historyFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiStructurePresenceReport)
