import classNames from 'classnames';
import { checkSession } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { selectTotemTab } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { isTabSelected } from 'utils';
import { TOTEM_CONSTANTS } from 'utils/constants';
import { REST_ENDPOINTS } from 'utils/rest-routes';

interface ActionCreatorsProps {
    selectTotemTab: (tab: string) => any
}

interface Props {
    selectedTotemTab: string,
    todayLabel: string
}

interface AllProps extends ActionCreatorsProps, Props { }

class TabsContainer extends React.Component<AllProps, null> {

    onTabSelected = (tab: string) => {
        checkSession(REST_ENDPOINTS.CHECK_AUTHENTICATION)
        this.props.selectTotemTab(tab)
    }

    renderTab = (tabId: string, label: string, selectedTotemTab: string) => {
        let className = "tab"
        if (isTabSelected(selectedTotemTab, tabId)) {
            className = classNames(className, "active")
        }
        return (
            <a href="javascript:void(0)" onClick={null}>
                <div className={className} onClick={() => { this.onTabSelected(tabId) }}>{label}</div>
            </a>
        )
    }


    render() {
        const { selectedTotemTab, todayLabel } = this.props
        let newsTab = this.renderTab(TOTEM_CONSTANTS.NEWS_TAB, 'NEWS', selectedTotemTab)
        let diaryTab = this.renderTab(TOTEM_CONSTANTS.DIARIES_TAB, todayLabel.toUpperCase(), selectedTotemTab)
        let documentsTab = this.renderTab(TOTEM_CONSTANTS.DOCUMENTS_TAB, 'DOCUMENTI', selectedTotemTab)

        return (
            <div className='tabs-container' >
                {newsTab}
                {diaryTab}
                {documentsTab}
            </div>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        selectedTotemTab: state.global.selectedTotemTab,
        todayLabel: state.global.structureInfo.todayLabel
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        selectTotemTab: bindActionCreators(selectTotemTab, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer)