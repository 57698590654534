import { Box, Button, Grid, IconButton, TableRow, useMediaQuery, useTheme } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { StructureInfo } from "commons/interfaces/structure-info";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import StaffSearchForm from "./search-staff"
import { Link } from "react-router-dom";
import { GlobalStateInterface } from "redux/store";
import { StaffFilters } from "redux/actions/global-actions";
import { getLoadingComponent } from "utils";
import Add from "@mui/icons-material/Add";
import { PAGES } from "utils/rest-routes";
import { appendQueryParams, ComponentListItem, expandUri, generateUUID, getQueryParams, logThis, ReactLabelComponent, renderComponentsList } from "@insoft/lib-react-universal/dist";
import { BoxedContent, defaultReactDataRenderMUITable, executeRequest, Page, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel, _Links, composeUriPath } from "@insoft/lib-react-web";

interface GlobalProps {
    structureInfo: StructureInfo;
    requestUrl: string;
    staffSearchFilters: StaffFilters;
    staffExcelUrl: string;
}

export interface Staff {
    _embedded?: Embedded,
    _links?: _Links,
    page?: Page
}

export interface Entity {
    pk: string
    firstName: string
    lastName: string
    birthDate?: string
    validFrom?: string
    validTo?: string
    roleDescr?: string
    structure: string
    additionalNotes: string
}

export interface Embedded {
    staffEntities?: Entity[]
}

interface TableRow {
    collapsibleArrow?: ReactLabelComponent;
    id: number;
    name: string;
    lastName: string;
    birthDate: string;
    validFrom: string;
    validTo: string;
    roleDescr: string;
    additionalNotes: ReactLabelComponent;
    structureId: string;
    modifyLink: ReactLabelComponent;
}


function getTableColumns(isScreenXS?: boolean) {
    let tableColumns: TableColumnDefinition[] = [];
    if (isScreenXS) {
        tableColumns.push({
            headerName: "",
            field: "collapsibleArrow",
            sortable: false,
            showMobile: true,
            mobileCollapser: true,
            renderCell: defaultReactDataRenderMUITable
        });
    }
    tableColumns.push({ headerName: "Cognome", field: "lastName", showMobile: true });
    tableColumns.push({ headerName: "Nome", field: "name" });
    tableColumns.push({ headerName: "Data di nascita", field: "birthDate" });
    tableColumns.push({ headerName: "Valido da", field: "validFrom" });
    tableColumns.push({ headerName: "Valido a", field: "validTo" });
    tableColumns.push({ headerName: "Descrizione", field: "roleDescr" });
    tableColumns.push({
        headerName: "Note",
        field: "additionalNotes",
        renderCell: defaultReactDataRenderMUITable
    })
    tableColumns.push({ headerName: "Struttura", field: "structureId" });
    tableColumns.push({
        headerName: "",
        field: "modifyLink",
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    });
    return tableColumns;
}

function Staff(props: GlobalProps) {

    const pageSizes = [10, 20, 50];

    const [sortModel, setSortModel] = useState<TableSortModel>({ field: "lastName", sort: "asc" });
    const [datatableKey, setDatatableKey] = useState<string>(generateUUID());
    const [pageSize, setPageSize] = useState<number>(pageSizes[0]);
    const [loading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [resources, setResources] = useState<Staff>(null);
    const [collapsedElements, setCollapsedElements] = useState<number[]>([])
    const [staffExcelUrl, setStaffExcelUrl] = useState<string>(props.staffExcelUrl);
    const theme = useTheme();
    const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        fetchData();
    }, [props.requestUrl, pageNumber, pageSize, sortModel, datatableKey])

    const createParam = (paramName: string, sortItem: TableSortModel) =>
        paramName + (sortItem ? "," + sortItem.sort : "");
    const fetchData = () => {
        setLoading(true)
        if (props.requestUrl) {
            var sortingArray: string[] = [];
            if (sortModel) {
                if (sortModel.field === "lastName") {
                    sortingArray.push(createParam("ana.cognome", sortModel));
                } else if (sortModel.field === "name") {
                    sortingArray.push(createParam("ana.nome", sortModel));
                } else {
                    sortingArray.push(createParam(sortModel.field, sortModel));
                }
            }

            let requestUrl = props.requestUrl
            if (props.staffSearchFilters) {
                let queryParams: { [key: string]: string[] } = {}
                const list = getQueryParams(requestUrl)

                //TO DO: Rimuovere questi workaround
                requestUrl = expandUri(requestUrl, { firstName: undefined, lastName: undefined, csts: undefined })
                let newStaffExcelUrl = expandUri(staffExcelUrl, { firstName: undefined, lastName: undefined, csts: undefined })

                for (const keySearch in props.staffSearchFilters) {
                    if (!props.staffSearchFilters[keySearch]) {
                        continue;
                    }
                    // setPageNumber(0)    //se ci sono filtri di ricerca allora setto la pagina a zero (se no  potrei avere una visualizzazione errata della tabella)
                    let columnKey = keySearch.lastIndexOf('_') === -1 ? keySearch : keySearch.substring(0, keySearch.lastIndexOf('_'))
                    var column
                    for (var i = 0; i < getTableColumns().length; i++) {
                        const colTable = getTableColumns()[i];
                        if (colTable.field === columnKey) {
                            if (colTable.field === "name") {
                                column = "firstName"
                            } else if (colTable.field === "structureId") {
                                column = "csts"
                            } else {
                                column = colTable.field
                            }
                            break;
                        }
                    }
                    let searchParamsName: string[] = column ? column.split(',') : undefined
                    if (searchParamsName) {
                        for (i = 0; i < searchParamsName.length; i++) {
                            if (!queryParams[searchParamsName[i]]) {
                                queryParams[searchParamsName[i]] = (keySearch == "structureId" && props.staffSearchFilters[keySearch] == "all" ? undefined : props.staffSearchFilters[keySearch] as string[]);
                            } else {
                                if (keySearch == "structureId" && props.staffSearchFilters[keySearch] == "all") {
                                    (props.staffSearchFilters[keySearch] as string[]).forEach(element => {
                                        queryParams[searchParamsName[i]].push((element))
                                    });
                                }
                            }
                        }
                    }
                }

                requestUrl = appendQueryParams(requestUrl, queryParams)
                setStaffExcelUrl(appendQueryParams(newStaffExcelUrl, queryParams))
            }
            requestUrl = appendQueryParams(
                requestUrl,
                { page: pageNumber.toString(), size: pageSize.toString(), sort: sortingArray }
            );

            executeRequest<Staff>({ url: requestUrl, method: 'GET' })
                .then((data) => {
                    setResources(data)
                    setLoading(false)
                })
                .catch((data) => {
                    logThis(data);
                    setLoading(false)
                });
        }
    }

    const getSortingArray = () => {
        var sortingArray: string[] = [];
        if (sortModel) {
            if (sortModel.field === "lastName") {
                sortingArray.push(createParam("ana.cognome", sortModel));
            } else if (sortModel.field === "name") {
                sortingArray.push(createParam("ana.nome", sortModel));
            } else {
                sortingArray.push(createParam(sortModel.field, sortModel));
            }
        }
        return sortingArray;
    }

    const manageCollapsedElements = (element: number) => {
        let index = collapsedElements.indexOf(element, 0)
        let newCollapsedElements: number[] = []
        collapsedElements.forEach((element) => {
            newCollapsedElements.push(element)
        })
        if (index != -1) {
            newCollapsedElements.splice(index, 1)
        } else {
            newCollapsedElements.push(element)
        }
        setCollapsedElements(newCollapsedElements)
    }

    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    const renderStaffRow = (
        staff: Entity & ComponentListItem
    ) => {
        const ND = "N.D.";

        let collapsibleArrow: any
        if (collapsedElements.indexOf(staff._itemIndex + 1, 0) != -1) {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(staff._itemIndex + 1)}><ExpandLessIcon /></IconButton>
        } else {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(staff._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
        }

        const modifyElement = <Link to={composeUriPath(PAGES.modifyStaff, [staff.pk])}>{"Visualizza"}</Link>

        let entityObject: TableRow = {
            collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
            id: staff._itemIndex + 1,
            name: staff.firstName,
            lastName: staff.lastName,
            birthDate: staff.birthDate ? staff.birthDate : ND,
            validFrom: staff.validFrom ? staff.validFrom : ND,
            validTo: staff.validTo ? staff.validTo : ND,
            roleDescr: staff.roleDescr ? staff.roleDescr : ND,
            additionalNotes: { component: <b>{staff.additionalNotes}</b> },
            structureId: staff.structure,
            modifyLink: { component: modifyElement }
        };
        return entityObject;
    };

    if (loading) {
        return getLoadingComponent(true)
    }

    return <Grid container spacing={3}>
        <StaffSearchForm
            searchCallBack={() => setDatatableKey(generateUUID())}
        />
        <BoxedContent xl={12}>
            <Box p={2}>
                <div style={{ width: "100%" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Button variant={"contained"} color={"primary"} startIcon={<Add />} href={"#" + PAGES.newStaff}>Nuovo</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                startIcon={<GetAppIcon />}
                                href={staffExcelUrl}>Scarico personale</Button>
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <Box p={2}>
                <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <ResponsiveMaterialUITableWithPagination
                                dense={true}
                                collapsedElements={collapsedElements}
                                data={resources && resources._embedded && renderComponentsList(resources._embedded.staffEntities, renderStaffRow)}
                                columns={getTableColumns(isScreenXS)}
                                sortingMode="server"
                                sortModel={sortModel}
                                onSortModelChange={(sortModel) => handleModule(sortModel)}
                                pagination={true}
                                paginationMode="server"
                                rowsPerPage={pageSize}
                                page={pageNumber}
                                rowCount={resources != undefined ?
                                    (resources.page != undefined ? resources.page.totalElements : 0)
                                    : 0}
                                onPageChange={setNumberOfPage}
                                onPageSizeChange={setNumberElementsOnPage}
                                rowsPerPageOptions={pageSizes}
                                loading={loading}
                                isScreenXS={isScreenXS}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </BoxedContent>
    </Grid>
}


const mapStateToProps = (state: GlobalStateInterface): GlobalProps => {
    return {
        structureInfo: state.global.structureInfo,
        requestUrl: state.global.navigationLinks._links.getStaff.href,
        staffSearchFilters: state.global.staffFilters,
        staffExcelUrl: state.global.navigationLinks._links.getStaffExcel.href
    }
};

export default connect(mapStateToProps)(Staff)