import { clone } from "@insoft/lib-react-universal";
import { Dialog, DialogProps } from "@progress/kendo-react-dialogs";
import { SchedulerForm, SchedulerFormEditorProps, SchedulerFormProps } from "@progress/kendo-react-scheduler";
import { CustomFormEditor } from "./custom-form-editor";
import { CustomEditorProps } from "./interfaces";
import { Section } from "../../commons/interfaces/organization-data";
import { useCallback } from "react";

export const CustomEventForm = (props: SchedulerFormProps & CustomEditorProps) => {

    const requiredValidator = useCallback(
        (value: any) =>
            value === undefined || value === null || value === ""
                ? "Il campo è obbligatorio."
                : undefined,
        []
    );
    const formValidator = (_dataItem: any, formValueGetter: any) => {
        let result: any = {};
        result.section = [requiredValidator(formValueGetter("section"))]
            .filter(Boolean)
            .reduce((current, acc) => current || acc, "");
        return result;
    };

    const customProps: CustomEditorProps = {
        children: props.children,
        sections: props.sections,
        currentView: props.currentView,
        educators: props.educators,
        eventDefaultDurationMinutes: props.eventDefaultDurationMinutes,
        section: props.section
    }

    let dataItem: {
        isAllDay?: boolean
        end?: Date
        start?: Date
        section?: number
        day?: Date
    };
    const isNewItem = props.dataItem.id == undefined;
    if (props.currentView == 'month') {
        dataItem = clone(props.dataItem)
        dataItem.isAllDay = false
        dataItem.day = props.dataItem.start
        if (isNewItem) {
            dataItem.start = undefined
            dataItem.end = undefined
        }
    } else {
        dataItem = props.dataItem
    }
    dataItem.section = props.dataItem?.section || props.sections?.find(i => i.id == props.section)



    return < SchedulerForm {...props} dataItem={dataItem}
        validator={formValidator}
        editor={(editorProps: SchedulerFormEditorProps) => <CustomFormEditor {...editorProps} {...customProps} />}
        dialog={(dialogProps: DialogProps) =>
            <Dialog {...dialogProps}
                title={isNewItem ? "Inserisci appuntamento" : "Modifica appuntamento"}

            />}
    />;
};