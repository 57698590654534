import { FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from '@insoft/lib-react-web';
import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import { FormObject, ServerFormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { Props } from './search-admin';

export const SEARCH_FILEDS = {
    NAME: 'name',
    HOSTNAME: 'hostName'
}

export function getSearchAdminFormObject(props: Props): FormObject {
    let serverFormObject: ServerFormObject
    let properties: { [key: string]: any } = {

    }

    properties[SEARCH_FILEDS.NAME] = {
        type: "string",
        title: "Nome"
    }
    properties[SEARCH_FILEDS.HOSTNAME] = {
        type: "string",
        title: "Hostname",
        represents: [
            FORM_INPUT_DEFAULT_WIDGETS.LIST,
            FORM_LIST_TYPE.DROPDOWN_LIST
        ],
        autocomplete: {
            href: props.requestUrlHostNames
        }
    }

    serverFormObject = {
        PUT: {
            form: {
                formSchema: {
                    type: "object",
                    properties: properties
                },
                formData: props.adminFilters
            }
        },
        _links: {
            self: {
                href: ''
            }
        }
    }
    return new FormObjectAdapter(serverFormObject)
}