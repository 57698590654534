import { commonFormStyleRules, getCommonFields, getCommonWidgets } from 'commons/styles/common-form-style-rules';
import { stopLoading } from '@insoft/lib-react-universal/dist/redux/base-actions';
import { FormPage } from '@insoft/lib-react-web/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { HistoryFilters, saveHistoryFilters, startLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent } from 'utils';
import { getSearchHistoryFormObject } from './search-history-form-objects';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
    saveHistoryFilters: (historyFilters: HistoryFilters) => any
}

export interface Props {
    requestUrlYear?: string
    requestUrlState?: string
    requestUrlType?: string
    loading?: boolean
    historyFilters: HistoryFilters
}

interface AllProps extends ActionCreatorsProps, Props {
    title?: string
    searchCallBack?: () => void
}

class HistorySearchForm extends React.Component<AllProps, null> {

    onFormSubmit = (formObject: { formData: HistoryFilters }) => {
        let formData = formObject.formData
        this.props.saveHistoryFilters(formData)
        if (this.props.searchCallBack) {
            this.props.searchCallBack()
        }
    }

    render() {
        if (this.props.loading) {
            return getLoadingComponent(true)
        }
        const formObject = getSearchHistoryFormObject(this.props)
        return (
            <FormPage
                formStyleRules={commonFormStyleRules}
                fields={getCommonFields()}
                widgets={getCommonWidgets()}
                onFormSubmit={this.onFormSubmit}
                resources={formObject}
                title={"Ricerca"}
                buttonText="Cerca"
                showRequiredFieldsLegend
            />
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        loading: state.global.isLoading,
        requestUrlYear: state.global.navigationLinks._links.organizationRequestsHistoryYear ? state.global.navigationLinks._links.organizationRequestsHistoryYear.href : undefined,
        requestUrlState: state.global.navigationLinks._links.organizationRequestsHistoryState ? state.global.navigationLinks._links.organizationRequestsHistoryState.href : undefined,
        requestUrlType: state.global.navigationLinks._links.organizationRequestsHistoryType ? state.global.navigationLinks._links.organizationRequestsHistoryType.href : undefined,
        historyFilters: state.global.historyFilters
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch),
        saveHistoryFilters: bindActionCreators(saveHistoryFilters, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistorySearchForm)