import { MenuLink } from "@insoft/lib-react-mui-web";
import { Navigation } from "../../commons/interfaces/navigation";
import { StructureInfo } from "../../commons/interfaces/structure-info";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { MULTI_STRUCTURE_MODULE_PAGES, PAGES } from "../../utils/rest-routes";
import ContactPageIcon from '@mui/icons-material/ContactPage';


export function evaluateLinks(navigation: Navigation): MenuLink[] {
    if (!navigation || !navigation.isOperator) {
        return []
    }

    const links: MenuLink[] = []
    const reportSection = getReportLinks(navigation);
    if (reportSection) {
        links.push(reportSection)
    }
    if (navigation._links?.accountHolders)
        links.push({
            id: 'account-holder',
            name: 'Intestatari Fatture',
            href: PAGES.accountHolders,
            icon: ContactPageIcon
        })
    return links
}

function getReportLinks(navigation: Navigation): MenuLink {
    const reportLinks: MenuLink = {
        id: 'report',
        name: 'Reports',
        icon: AssessmentIcon,
        pathToCheck: MULTI_STRUCTURE_MODULE_PAGES.report,
        subItems: []
    }
    if (navigation._links.organizationPresencesReportTable) {
        reportLinks.subItems.push({
            id: 'rep#presence',
            name: 'Presenze',
            href: MULTI_STRUCTURE_MODULE_PAGES.presences,
            pathToCheck: MULTI_STRUCTURE_MODULE_PAGES.presences
        })
    }
    if (navigation._links.coberturaReport) {
        reportLinks.subItems.push({
            id: 'rep#cobertura',
            name: 'Copertura',
            href: MULTI_STRUCTURE_MODULE_PAGES.cobertura,
            pathToCheck: MULTI_STRUCTURE_MODULE_PAGES.cobertura
        })
    }
    return reportLinks.subItems.length == 0 ? undefined : reportLinks;
}