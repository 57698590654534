import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import { FormObject, ServerFormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { AllProps } from './search-structure';
import { FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from "@insoft/lib-react-web/dist/utils/schema-utils"


export const SEARCH_FILEDS = {
    NAME: 'firstName',
    SURNAME: 'lastName',
    ORDER: 'order',
    SUBMISSION_DATE: 'date',
    START_DATE: 'startDate',
    STATE: 'state',
    YEAR: 'ae',
    TYPE: 'type',
    STRUCTURE: 'structure'
}

const date = new Date().getFullYear();

var getlast5Years = () => {
    var arr: number[] = [];

    for (let i = 1; i < 6; i++) {
        arr.push(date - i)
    }

    return arr;

}

var getArrayYears = () => {
    var arr: string[] = []
    var last6Years = getlast5Years()
    last6Years.unshift(date)

    for (let i = 0; i < 5; i++) {
        arr.push((last6Years[i + 1] + "/" + last6Years[i]))
    }

    return arr
}


export function getSearchStructureFormObject(props: AllProps): FormObject {
    let serverFormObject: ServerFormObject
    let properties: { [key: string]: any } = {

    }

    properties[SEARCH_FILEDS.YEAR] = {
        type: "number",
        title: "Anno Educativo",
        classNames: "col-xs-12 col-sm-6 col-lg-6",
        enum: getlast5Years(),
        enumNames: getArrayYears()
    }

    properties[SEARCH_FILEDS.STRUCTURE] = {
        type: "array",
        title: "Struttura",
        represents: [FORM_LIST_TYPE.MULTI_SELECT_LIST],
        classNames: "col-xs-12 col-sm-6 col-lg-6",
        autocomplete: {
            href: props.requestUrlDashBoard
        },
        items: {
            type: 'string'
        },
        minItems: 1
    }

    //mettere i campi required per fare ricerche con filtri selezionati?
    serverFormObject = {
        PUT: {
            form: {
                formSchema: {
                    type: "object",
                    properties: properties,
                    required: [SEARCH_FILEDS.YEAR, SEARCH_FILEDS.STRUCTURE]
                },
                formData: props.historyFilters
            }
        },
        _links: {
            self: {
                href: ''
            }
        }
    }
    return new FormObjectAdapter(serverFormObject)
}