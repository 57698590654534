export const NEWS_FROM = {
    "type": "object",
    "properties": {
        "id": {
            "type": "string",
            "hidden": true
        },
        "organizationId": {
            "type": "string",
            "hidden": true
        },
        "title": {
            "type": "string",
            "title": "Titolo"
        },
        "order": {
            "type": "integer",
            "minimum": 1,
            "title": "Ordine importanza"
        },
        "urlImg": {
            "type": "string",
            "title": "URL immagine",
            "hidden": true
        },
        "base64Image": {
            "type": "string",
            "title": " ",
            "format": "data-url"
        },
        "body": {
            "type": "string",
            "title": "Testo (i link esterni devono essere di questo tipo: https://example.it)",
            "represents": "editable-text-long"
        },
        "link": {
            "type": "object",
            "title": " ",
            "properties": {
                "url": {
                    "type": "string",
                    "title": "Link collegamento (se esterno deve essere di questo tipo: https://example.it)"
                },
                "text": {
                    "type": "string",
                    "title": "Nome collegamento"
                }
            },
            "$id": "urn:jsonschema:it:insoft:resource:novae:infrastructure:nosql:models:Link"
        },
        "recipients": {
            "type": "array",
            "title": "Destinatari",
            "items": {
                "type": "string"
            },
            "represents": "recipients-picker"
        },
        "expireDate": {
            "type": "string",
            "title": "Scadenza",
            "format": "date"
        },
        "published": {
            "type": "boolean",
            "title": "Pubblicato",
            "oneOf": [
                { "const": true, "title": "sì" },
                { "const": false, "title": "no" }
            ]
        }
    },
    "required": ["title", "body", "expireDate"],
    "$id": "urn:jsonschema:it:insoft:resource:novae:infrastructure:data:NewsData"
};

export const EDIT_ORGANIZATION_FORM = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string",
            "title": "Nome struttura"
        },
        "hostName": {
            "type": "string",
            "title": "Nome host"
        },
        "invoiceDisclaimer": {
            "type": "string",
            "title": "Testo sezione fatture"
        },
        "acceptedRequestNotifyMails": {
            "type": "string",
            "title": "Mail notificata all'accettazione della domanda"
        },
        "logoutRedirectUrl": {
            "type": "string",
            "title": "Url di reindirizzamento dopo il logout"
        },
        "helpSectionsDuringFilling": {
            "type": "boolean",
            "title": "Abilitare sezione di aiuto durante la compilazione"
        },
        "processVersion": {
            "type": "string",
            "title": "Verione progetto Bonita"
        },
        "processName": {
            "type": "string",
            "title": "Nome progetto Bonita"
        },
        "feeManagement": {
            "type": "boolean",
            "title": "Gestione retta"
        },
        "doubleAcceptance": {
            "type": "boolean",
            "title": "Richiesta acettazione doppia"
        },
        "pec": {
            "type": "string",
            "title": "Pec"
        },
        "mainDescription": {
            "type": "object",
            "title": "Descrizione primaria",
            "properties": {
                "title": {
                    "type": "string",
                    "title": "Titolo"
                },
                "htmlText": {
                    "type": "string",
                    "title": "Testo HTML"
                }
            }
        },
        "secondDescription": {
            "type": "object",
            "title": "Descrizione secondaria",
            "properties": {
                "title": {
                    "type": "string",
                    "title": "Titolo"
                },
                "htmlText": {
                    "type": "string",
                    "title": "Testo HTML"
                }
            }
        },
        "cstPk": {
            "type": "string",
            "title": "PK struttura",
            "disabled": true
        },
        "contacts": {
            "type": "object",
            "title": "Contatti",
            "properties": {
                "address": {
                    "type": "string",
                    "title": "Indirizzo"
                },
                "mail": {
                    "type": "string",
                    "title": "Mail"
                },
                "phone": {
                    "type": "string",
                    "title": "Telefono"
                }
            }
        }
    }
};