
import { clone, isEmptyArray } from '@insoft/lib-react-universal/dist';
import { NewsItem } from 'commons/interfaces/news';
import { GLOBAL_ADD_MESSAGE, GLOBAL_REMOVE_MESSAGE, GLOBAL_RETRIEVE_CHILDREN_SUCCESS, GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS, GLOBAL_RETRIEVE_NEWS_ITEM_SUCCESS, GLOBAL_RETRIEVE_NEWS_RECIPIENTS_SUCCESS, GLOBAL_RETRIEVE_NEWS_SUCCESS, GLOBAL_RETRIEVE_SLIDER_DATA_SUCCESS, GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS, GLOBAL_RETRIEVE_USER_INFO_SUCCESS, GLOBAL_SAVE_ADMIN_FILTERS, GLOBAL_SAVE_HISTORY_FILTERS, GLOBAL_SAVE_PAGE_HEADER_INFO, GLOBAL_SAVE_STAFF_FILTERS, GLOBAL_SAVE_STRUCTURE_ID, GLOBAL_SELECT_CHILD, GLOBAL_SELECT_NEWS, GLOBAL_SELECT_TAB, GLOBAL_START_LOADING, GLOBAL_STOP_LOADING, GlobalAction } from '../actions/global-actions';
import { GlobalStateInterface, INITIAL_STATE } from '../store';

export default function (state = INITIAL_STATE.global, action: GlobalAction): GlobalStateInterface["global"] {
    switch (action.type) {
        case GLOBAL_START_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case GLOBAL_STOP_LOADING:
            return {
                ...state,
                isLoading: false
            }
        case GLOBAL_ADD_MESSAGE:
            let clonedAddMessage = clone(state.messages)
            clonedAddMessage[action.message.id] = action.message
            return {
                ...state,
                messages: clonedAddMessage
            }
        case GLOBAL_REMOVE_MESSAGE:
            let clonedRemoveMessage = clone(state.messages)
            delete clonedRemoveMessage[action.message.id]
            return {
                ...state,
                messages: clonedRemoveMessage
            }
        case GLOBAL_RETRIEVE_NAV_LINKS_SUCCESS:
            return {
                ...state,
                navigationLinks: action.navigationLinks
            }
        case GLOBAL_RETRIEVE_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case GLOBAL_RETRIEVE_STRUCTURE_INFO_SUCCESS:
            return {
                ...state,
                structureInfo: action.structureInfo
            }
        case GLOBAL_RETRIEVE_NEWS_SUCCESS:
            return {
                ...state,
                news: action.news
            }
        case GLOBAL_RETRIEVE_NEWS_ITEM_SUCCESS:
            const newItem = clone(action.newsItem)
            let newObject = clone(state.news)
            if (state.news && state.news.news && !isEmptyArray(state.news.news)) {
                // recreate the array
                let newArray: NewsItem[] = []
                state.news.news.forEach((item) => {
                    if (item.urlImg === newItem.urlImg) {
                        newArray.push(newItem)
                    } else {
                        newArray.push(item)
                    }
                })
                newObject = {
                    news: newArray
                }
            }
            return {
                ...state,
                news: newObject
            }
        case GLOBAL_RETRIEVE_SLIDER_DATA_SUCCESS:
            return {
                ...state,
                sliderData: action.sliderData
            }
        case GLOBAL_RETRIEVE_CHILDREN_SUCCESS:
            return {
                ...state,
                childrenList: action.childrenList
            }
        case GLOBAL_RETRIEVE_NEWS_RECIPIENTS_SUCCESS:
            return {
                ...state,
                newsRecipients: action.newsRecipients
            }
        case GLOBAL_SELECT_CHILD:
            return {
                ...state,
                selectedChild: action.selectedChild
            }
        case GLOBAL_SELECT_TAB:
            return {
                ...state,
                selectedTotemTab: action.selectedTab
            }
        case GLOBAL_SELECT_NEWS:
            return {
                ...state,
                selectedNews: action.selectedNews
            }
        case GLOBAL_SAVE_STRUCTURE_ID:
            return {
                ...state,
                structureId: action.structureId
            }
        case GLOBAL_SAVE_HISTORY_FILTERS:
            return {
                ...state,
                historyFilters: action.historyFilters
            }
        case GLOBAL_SAVE_ADMIN_FILTERS:
            return {
                ...state,
                adminFilters: action.adminFilters
            }
        case GLOBAL_SAVE_STAFF_FILTERS:
            return {
                ...state,
                staffFilters: action.staffFilters
            }
        case GLOBAL_SAVE_PAGE_HEADER_INFO:
            return {
                ...state,
                pageInfo: action.pageInfo
            }
        default:
            return state
    }
}