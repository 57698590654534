import { NurseryData } from 'commons/interfaces/nursery-data';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { appendQueryParams, expandUri, generateUUID, logThis, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import { EmbeddedResource, HateoasResponse } from '@insoft/lib-react-web/dist/interfaces/hateoas';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';
import { WEB_ENDPOINTS } from 'utils/rest-routes';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { createStyles, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import CreateIcon from '@mui/icons-material/Create';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { Grid, IconButton, Link, TextField, useMediaQuery } from '@mui/material';
import { ReactLabelComponent } from '@insoft/lib-react-universal';
import { AdminFilters } from 'redux/actions/global-actions';
import { defaultReactDataRenderMUITable, ResponsiveMaterialUITableWithPagination, TableColumnDefinition, TableSortModel } from '@insoft/lib-react-web';
import { FormDataObject, FormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { EDIT_ORGANIZATION_FORM } from 'utils/form';
import AlertDialog from '@insoft/lib-react-web/dist/components/misc/alert-dialog';
import { GridSortItem } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AdminSearchForm from './search-admin';


interface Props {
    requestUrl: string
    adminFilters: AdminFilters
}

interface TableRow {
    collapsibleArrow?: ReactLabelComponent;
    number: number,
    id: string,
    name: string,
    address: string,
    hostName?: string,
    cstPk: number,
    structureLink: ReactLabelComponent,
    modifyLink: ReactLabelComponent,
    copyLink: ReactLabelComponent
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function getTableColumns(isScreenXS?: boolean) {
    let tableColumns: TableColumnDefinition[] = []
    if (isScreenXS) {
        tableColumns.push({
            headerName: "",
            field: "collapsibleArrow",
            sortable: false,
            showMobile: true,
            mobileCollapser: true,
            renderCell: defaultReactDataRenderMUITable
        });
    }
    tableColumns.push({ headerName: 'Nome', field: 'name', showMobile: true })
    tableColumns.push({ headerName: 'Indirizzo', field: 'address' })
    tableColumns.push({ headerName: 'Hostname', field: 'hostName' })
    tableColumns.push({
        headerName: '',
        field: 'structureLink',
        sortable: false,
        showMobile: true,
        renderCell: defaultReactDataRenderMUITable
    })
    tableColumns.push({
        headerName: '',
        field: 'modifyLink',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    tableColumns.push({
        headerName: '',
        field: 'copyLink',
        sortable: false,
        renderCell: defaultReactDataRenderMUITable
    })
    return tableColumns
}

//TODO trovare modo per fare l'import dal file di stili ritornando un hook richiamabile
export const ButtonsStyles = makeStyles(() =>
    createStyles({
        button: {
            color: "#fff",
            backgroundColor: "#337ab7",
            borderColor: "#2e6da4",
            '&:hover': {
                background: '#337ab7',
            },
        },
        box: {
            position: "relative",
            borderRadius: "3px",
            background: "#ffffff",
            marginBottom: "20px",
            width: "100%",
            borderColor: "#d2d6de",
            boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column"
        },
    })
)



function AdminDashBoard(props: Props) {
    const pageSizes = [10, 20, 50]
    // const pageSizes = [1, 2, 5]

    // const classesButton = ButtonsStyles();

    const [openDialog, setOpenDialog] = React.useState(true);
    const [sortModel, setSortModel] = useState<TableSortModel>(undefined)
    const [pageSize, setPageSize] = useState<number>(pageSizes[0])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [resources, setResources] = useState<HateoasResponse<NurseryData>>(undefined)
    const [selectedResourceUrl, setSelectedResourceUrl] = useState<string>(undefined)
    const [copyResourceUrl, setCopyResourceUrl] = useState<string>(undefined)
    const [datatableKey, setDatatableKey] = useState<string>(generateUUID())
    const theme = useTheme();
    const isScreenXS = useMediaQuery(theme.breakpoints.down('sm'))
    const [collapsedElements, setCollapsedElements] = useState<number[]>([])
    const [width, height] = useWindowSize();
    const [formData, setFormData] = useState<any>(undefined);
    const [error, setError] = useState<string>(undefined);

    useEffect(() => {
        fetchData()
    }, [props.requestUrl, datatableKey, pageNumber, pageSize, sortModel, width, height]);

    useEffect(() => { }), [selectedResourceUrl, copyResourceUrl]

    const createParam = (paramName: string, sortItem: GridSortItem) =>
        paramName + (sortItem ? "," + sortItem.sort : "");

    const onModify = (url: string, getUrl: string) => {
        executeRequest<HateoasResponse<object>>({ url: getUrl, method: "GET" })
            .then((data) => {
                setFormData(data)
            }).catch((data) => {
                logThis(data)
            })
        setOpenDialog(true)
        setSelectedResourceUrl(url)
    }

    const onCopyNurseryData = (url: string) => {
        setError(undefined)
        setOpenDialog(true)
        setCopyResourceUrl(url)
    }

    const onModalViewClose = () => {
        setSelectedResourceUrl(undefined);
        setCopyResourceUrl(undefined);
        setDatatableKey(generateUUID());
    }

    const manageCollapsedElements = (element: number) => {
        let index = collapsedElements.indexOf(element, 0)
        let newCollapsedElements: number[] = []
        collapsedElements.forEach((element) => {
            newCollapsedElements.push(element)
        })
        if (index != -1) {
            newCollapsedElements.splice(index, 1)
        } else {
            newCollapsedElements.push(element)
        }
        setCollapsedElements(newCollapsedElements)
    }

    const renderRow = (nursery: NurseryData & ComponentListItem & EmbeddedResource): TableRow => {
        const organizationUri = expandUri(WEB_ENDPOINTS.novae, { structureId: nursery.cstPk.toString() }) + '#/'
        const structureLinkComponent = <Link href={organizationUri} target="_blank">Apri</Link>
        const modifyButton = <Button color="primary" variant="contained" size="small" onClick={() => onModify(nursery.organizationLink.href, nursery.getLink.href)} startIcon={<CreateIcon />}>Modifica</Button>
        const copyButton = <Button color="primary" variant="contained" size="small" onClick={() => onCopyNurseryData(nursery.copyLink.href)} startIcon={<FileCopyIcon />}>Copia</Button>
        let collapsibleArrow: any
        if (collapsedElements.indexOf(nursery._itemIndex + 1, 0) != -1) {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(nursery._itemIndex + 1)}><ExpandLessIcon /></IconButton>
        } else {
            collapsibleArrow = <IconButton onClick={() => manageCollapsedElements(nursery._itemIndex + 1)}><ExpandMoreIcon /></IconButton>
        }
        if (!nursery.hostName) {
            nursery.hostName = null
        }
        // let multiplicator;
        // if (length === 10) {
        //     multiplicator = 10;
        // } else if (length === 25) {
        //     multiplicator = 25;
        // } else if (length === 50) {
        //     multiplicator = 50;
        // } else {
        //     multiplicator = 100;
        // }
        return {
            collapsibleArrow: (isScreenXS ? { component: collapsibleArrow } : { component: <p></p> }),
            number: nursery._itemIndex + 1,
            id: nursery.id,
            name: nursery.name,
            address: nursery.address,
            hostName: nursery.hostName,
            cstPk: nursery.cstPk,
            structureLink: { label: 'Vai', component: structureLinkComponent },
            modifyLink: { component: modifyButton },
            copyLink: { component: copyButton }
        }
    }

    const fetchData = () => {
        if (props.requestUrl) {
            var sortingArray: string[] = [];
            if (sortModel) {
                sortingArray.push(createParam(sortModel.field, sortModel));
            }

            let requestUrl = props.requestUrl
            if (props.adminFilters) {
                let queryParams: { [key: string]: string[] } = {}
                if (Object.keys(props.adminFilters).length == 0) {
                    requestUrl = expandUri(requestUrl, { name: undefined })
                }
                for (const keySearch in props.adminFilters) {
                    if (!props.adminFilters[keySearch]) {
                        continue;
                    }
                    setPageNumber(0)
                    let columnKey = keySearch.lastIndexOf('_') === -1 ? keySearch : keySearch.substring(0, keySearch.lastIndexOf('_'))
                    var column
                    for (var i = 0; i < getTableColumns().length; i++) {
                        const colTable = getTableColumns()[i];
                        if (colTable.field === columnKey) {
                            column = colTable.field
                        }
                    }
                    let searchParamsName: string[] = column ? column.split(',') : undefined
                    if (searchParamsName) {
                        for (i = 0; i < searchParamsName.length; i++) {
                            if (!queryParams[searchParamsName[i]]) {
                                queryParams[searchParamsName[i]] = [props.adminFilters[keySearch]];
                            } else {
                                queryParams[searchParamsName[i]].push(props.adminFilters[keySearch])
                            }
                        }
                    }
                }
                requestUrl = expandUri(requestUrl, queryParams)
            }
            requestUrl = appendQueryParams(
                requestUrl,
                { page: pageNumber.toString(), size: pageSize.toString(), sort: sortingArray }
            );

            executeRequest<HateoasResponse<NurseryData>>({ url: requestUrl, method: "GET" })
                .then((data) => {
                    setResources(data)
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    const copyStructure = (url: string) => {
        const input = document.getElementsByName("cstPk") as unknown as HTMLInputElement[] | null;
        if (input && input[0] && input[0].value) {
            executeRequest<HateoasResponse<object>>({ url: url, method: 'POST', data: { cstCrossing: input[0].value } })
                .catch((data) => {
                    setError(data.message.data.message)
                })
        }
    }


    if (!props.requestUrl) {
        return null
    }
    if (selectedResourceUrl && openDialog) {
        let formDataObject: FormDataObject = {
            form: {
                formSchema: EDIT_ORGANIZATION_FORM,
                formData: formData,
                title: "Modifica news",
            }
        }
        let formObject: FormObject = {
            data: formDataObject,
            method: 'PUT',
            url: selectedResourceUrl
        }
        var modifyView = (
            <AlertDialog
                title='Modifica struttura'
                key={datatableKey}
                message={"Cambia i seguenti dati, salvando poi col bottone apposito a fondo popup:"}
                open={openDialog}
                abortButtonText="Annulla"
                acceptButtonText="Salva"
                onAbortCallback={() => setOpenDialog(false)}
                onAcceptCallback={() => setOpenDialog(true)}
                functionOnClose={() => onModalViewClose()}
                form={formObject}
            ></AlertDialog>
        )
    }
    if (copyResourceUrl && openDialog) {
        const element = error ? (<TextField error id="outlined-basic" variant="outlined" name="cstPk" helperText={error} />) :
            (<TextField id="outlined-basic" variant="outlined" name="cstPk" />)
        var copyView = (
            <AlertDialog
                title='Copia struttura'
                key={datatableKey}
                message={"Inserisci la nuova chiave di struttura:"}
                open={openDialog}
                abortButtonText="Annulla"
                acceptButtonText="Crea"
                elements={[{ element }]}
                onAbortCallback={() => setOpenDialog(false)}
                onAcceptCallback={() => copyStructure(copyResourceUrl)}
                functionOnClose={() => onModalViewClose()}
            ></AlertDialog>
        )
    }

    const setNumberOfPage = (page: number) => {
        setPageNumber(page);
    };

    const setNumberElementsOnPage = (size: number) => {
        setPageSize(size);
    };

    const handleModule = (model: TableSortModel) => {
        setSortModel(model)
    };

    return (
        <Box>
            {modifyView}
            {copyView}
            <Grid container spacing={3}>
                <AdminSearchForm
                    searchCallBack={() => setDatatableKey(generateUUID())}
                />
                <BoxedContent>
                    <Box p={2}>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex", height: "100%" }}>
                                <div style={{ flexGrow: 1 }}>
                                    <ResponsiveMaterialUITableWithPagination
                                        columns={getTableColumns(isScreenXS)}
                                        dense={true}
                                        collapsedElements={collapsedElements}
                                        data={resources && resources._embedded && renderComponentsList(resources._embedded.organizationSummaries, renderRow)}
                                        isScreenXS={isScreenXS}
                                        sortingMode="server"
                                        sortModel={sortModel}
                                        pagination={true}
                                        paginationMode="server"
                                        rowsPerPage={pageSize}
                                        rowCount={resources != undefined ?
                                            (resources.page != undefined ? resources.page.totalElements : 0)
                                            : 0}
                                        page={pageNumber}
                                        onPageChange={setNumberOfPage}
                                        onPageSizeChange={setNumberElementsOnPage}
                                        rowsPerPageOptions={pageSizes}
                                        onSortModelChange={(sortModel) => handleModule(sortModel)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </BoxedContent>
            </Grid>
        </Box>
    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.adminOrganizations) {
        var requestUrl = state.global.navigationLinks._links.adminOrganizations.href
    }
    return {
        requestUrl: requestUrl,
        adminFilters: state.global.adminFilters
    }
}

export default connect(mapStateToProps)(AdminDashBoard)