import { UiSchema } from '@insoft/lib-react-web/dist/interfaces/form-interfaces';
import { baseFormStyleRules } from '@insoft/lib-react-web/dist/utils/base-form-style-rules';

export function styleRules(key: string, object: any) {
    let uiObject: UiSchema = baseFormStyleRules(key, object).value

    uiObject['ui:classNames'] = 'col-xs-12'

    if (object.represents === 'password') {
        uiObject['ui:widget'] = 'password'
    }
    return {
        key: key,
        value: uiObject
    }
}