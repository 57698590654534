import { GridSize } from '@mui/material';
import Box from '@mui/material/Box';
import EuroIcon from '@mui/icons-material/Euro';
import { InvoiceItem, Invoices } from 'commons/interfaces//invoices';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { generateUUID, logThis, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import BoxedContent from '@insoft/lib-react-web/dist/components/misc/boxed-content';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalStateInterface } from 'redux/store';

interface Props {
    requestUrl: string
}

interface AllProps extends Props {
    width?: GridSize
}

interface State {
    resources: Invoices
}

function InvoicesArea(props: AllProps) {

    const [resources, setResources] = useState<Invoices>(null)

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        if (props.requestUrl) {
            executeRequest<Invoices>({ url: props.requestUrl, method: "GET" })
                .then((data) => {
                    setResources(data)
                    return null
                }).catch((data) => {
                    logThis(data)
                })
        }
    }

    const renderInvoiceItem = (item: InvoiceItem & ComponentListItem) => {
        let tooltipArray: any[] = []
        item.details.forEach((element) => {
            let service = element.voice ? ' (' + element.voice + ')' : ''
            tooltipArray.push(
                (
                    <div key={generateUUID()}>
                        Iscrizione <i>{element.child}</i>
                        {service}: {element.cost}&euro;
                    </div>
                )
            )
        })

        return (
            <tr key={item._key}>
                <td>{tooltipArray}</td>
                <td>{item.from}</td>
                <td>{item.totalAmount}&euro;</td>
            </tr>
        )
    }

    const renderDisclaimer = (disclaimer: string) => {
        if (!disclaimer) {
            return null;
        } else {
            return (
                <p className="text-muted text-center">{disclaimer}</p>
            )
        }
    }

    if (!resources || !props.requestUrl) {
        return null
    } else {

        let invoiceItems = resources.invoices
        if (invoiceItems !== undefined) {
            var viewsList = renderComponentsList(invoiceItems, renderInvoiceItem)
        }
        var bodyContent = (
            <Box>
                <table className='table table-bordered'>
                    <tbody>
                        <tr>
                            <th>Voce</th>
                            <th>Dal</th>
                            <th>Totale Previsto</th>
                        </tr>
                        {viewsList}
                    </tbody>
                </table>
            </Box>
        )
    }

    let subTitle = ["Dettaglio delle spese"]
    if (resources && resources.disclaimer) {
        subTitle.push(resources.disclaimer)
    }

    return (
        <BoxedContent title="Saldo" titleIcon={(<EuroIcon />)} description={subTitle} xs={12} md={12} lg={3} titleBorder={true}>
            {bodyContent}
        </BoxedContent>


    )
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    if (state.global.navigationLinks._links.invoices) {
        var requestUrl = state.global.navigationLinks._links.invoices.href
    }
    return {
        requestUrl
    }
}

InvoicesArea.defaultProps = {
    width: 3 as GridSize
}

export default connect(mapStateToProps)(InvoicesArea)