import classNames from 'classnames';
import { Child } from 'commons/interfaces/child';
import { Navigation } from 'commons/interfaces/navigation';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces/component-list-item';
import { expandUri, isEmptyArray, renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveChildrenList, selectChild } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getTotemContentHeight } from 'utils';

interface ActionCreatorsProps {
    retrieveChildrenList: (uri: string) => any
    selectChild: (child: Child) => any
}

interface Props {
    structureId: string
    navigationLinks: Navigation
    loading: boolean
    childrenList: Child[]
    selectedChild: Child
}

interface AllProps extends ActionCreatorsProps, Props { }

class ChildrenList extends React.Component<AllProps, null> {

    componentDidMount() {
        if (isEmptyArray(this.props.childrenList) && this.props.navigationLinks._links.children) {
            const uri = this.props.navigationLinks._links.children.href
            const expandedUri = expandUri(uri, { structureId: this.props.structureId })
            this.props.retrieveChildrenList(expandedUri)
        }
    }

    renderItem = (item: Child & ComponentListItem) => {
        const label = `${item.firstName} ${item.lastName}`
        const selectedChildIndex = this.getSelectedChildIndex()
        let className = 'list-item'
        if (item._itemIndex === selectedChildIndex) {
            className = classNames(className, "active")
        }
        return (
            <li key={item._key} className={className} onClick={() => { this.onListItemSelected(item._itemIndex) }}>
                <a href="javascript:void(0)" onClick={null}>
                    <p>{label}</p>
                </a>
            </li>
        )
    }

    getSelectedChildIndex = () => {
        let index = -1
        if (this.props.selectedChild) {
            for (const children of this.props.childrenList) {
                if (children.ana === this.props.selectedChild.ana) {
                    index = this.props.childrenList.indexOf(children)
                }
            }
        }
        return index
    }

    onListItemSelected = (itemIndex: number) => {
        const selectedChild = this.props.childrenList[itemIndex]
        this.props.selectChild(selectedChild)
    }

    getContainerHeight = () => {
        return getTotemContentHeight()
    }

    render() {
        const listItems = renderComponentsList(this.props.childrenList, this.renderItem)
        return (
            <div style={{ height: this.getContainerHeight() }}>
                <div className='top-section-title'>
                    <h2 className='center'>Bimbi</h2>
                </div>
                <div>
                    <ul className='children-list' >
                        {listItems}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        structureId: state.global.structureId,
        navigationLinks: state.global.navigationLinks,
        loading: state.global.isLoading,
        childrenList: state.global.childrenList,
        selectedChild: state.global.selectedChild
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveChildrenList: bindActionCreators(retrieveChildrenList, dispatch),
        selectChild: bindActionCreators(selectChild, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildrenList)