import classnames from 'classnames';

const baseLinkRules = 'btn btn-social-icon'
const baseIconRules = 'fa'

export function getLinkStyle(key: string, pullRight: boolean) {
    let cssClass = ''
    return classnames(baseLinkRules, {
        'btn-facebook': key === 'facebook',
        'btn-twitter': key === 'twitter'
    },
        { 'pull-right': pullRight }
    )
}

export function getIconStyle(key: string) {
    let cssClass = ''
    return classnames(baseIconRules, {
        'fa-facebook': key === 'facebook',
        'fa-twitter': key === 'twitter'
    }
    )
}