import Box from '@mui/material/Box';
import GenericNovaeHeader from 'commons/components/navigation/generic-novae-header';
import MultiStructureRouter from 'commons/components/routers/multi-structure-router';
import { Navigation } from 'commons/interfaces/navigation';
import { StructureInfo } from 'commons/interfaces/structure-info';
import { UserInfo } from 'commons/interfaces/user-info';
import { getQueryParamsFromWindowSearch } from '@insoft/lib-react-web/dist/utils/utils';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveInitializationInfo, saveStructureId } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import OrganizationChange from 'src/pages/admin/organization-change';
import { getLoadingComponent } from 'utils';
import { SINGLETON_ERRORS, STRINGS } from 'utils/constants';
import { MODULE_ROOTS, NovaeModule, PAGES } from 'utils/rest-routes';
import AdminDashBoard from '../pages/admin';
import NovaeChangePassword from '../pages/change-pwd';
import OrganizationManager from '../pages/content-manager/description-manager';
import NovaeDashboard from '../pages/dashboard';
import Dichiarazioni from '../pages/dichiarazioni';
import Error404 from '../pages/error-pages/404';
import GenericError from '../pages/error-pages/generic-error';
import DataChange from '../pages/form-with-selection/data-change';
import DataChangeDichiarazioni from '../pages/dichiarazioni/data-change-declarations'
import EnrollmentChange from '../pages/form-with-selection/enrollment-change';
import IseeChange from '../pages/form-with-selection/isee-change';
import Modifica from '../pages/form-with-selection/modifica';
import NewEnrollment from '../pages/form-with-selection/new-enrollment';
import ServiziAggiuntivi from '../pages/form-with-selection/servizi-aggiuntivi';
import WaitingListPanel from '../pages/lista-attesa';
import NewsDetails from '../pages/news-read-users/news-details';
import ParametersPanel from '../pages/parameters';
import ParentsAppSimulator from '../pages/parents-app-simulator';
import SummaryComponent from '../pages/riepilogo/summary';
import Totem from '../pages/totem';
import RequestBin from '../pages/cestino-richieste';
import Staff from '../pages/staff';
import NewStaff from 'src/pages/staff/new-staff';
import NewStaffRia from 'src/pages/staff/new-staff-ria';
import ModifyStaff from 'src/pages/staff/modify-staff';
import { getMessageByParam, Messages } from '@insoft/lib-react-universal';
import { evaluateLinks } from 'commons/navigation-links';
import CalendarPage from '../pages/calendar';
import OrganizationPresenceReport from '../commons/components/organization-presence-report/metadata-table';
import ReportDataPreview from '../commons/components/organization-presence-report/preview';
import { ContextChoiceSection } from '@insoft/lib-react-mui-web'
import PageWrapper from '../commons/components/page/page-wrapper';
import DocumentTableComponent from '../pages/documents/document-table-component';
import InvoicesTableComponent from '../pages/invoices/invoices-table-component';
import AccountHoldersTable from '../commons/components/account-holders/account-holders-table';
import OrganizationFillingList from '../pages/in-compilazione/organization-filling-list';
import TopTableReadNews from '../pages/news-read-users/top-table-read-news';
import SingleNewsComponent from '../pages/news/single-news-component';
import NewsTableComponent from '../pages/news/news-table-component';
import OrganizationRequestsHistory from '../pages/storico-iscrizioni/organization-requests-history';
import TemplateTableComponent from '../pages/templates/template-table-component';
interface ActionCreatorsProps {
    retrieveInitializationInfo: (structureId: string) => any
    saveStructureId: (structureId: string) => any
}

interface Props {
    navigationLinks: Navigation
    userInfo: UserInfo
    structureInfo: StructureInfo
    messages: Messages
    structureId: string
}

interface AllProps extends ActionCreatorsProps, Props { }


function App(props: AllProps) {

    const location = useLocation()
    const navigationLinks: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks);
    useEffect(() => {
        const queryParams = getQueryParamsFromWindowSearch()
        if (queryParams.structureId) {
            props.saveStructureId(queryParams.structureId)
        }
        props.retrieveInitializationInfo(queryParams.structureId)
    }, []);

    const evaluateLinksFromState = (state: GlobalStateInterface) => {
        return evaluateLinks(state.global.navigationLinks, state.global.structureInfo)
    };

    if (props.navigationLinks?.toChoiceOrganization) {
        return <PageWrapper evaluateLinksFromState={evaluateLinksFromState} >
            <ContextChoiceSection
                options={props.navigationLinks.organizations}
                templatePath="/novae{?structureId}"
                showInstallationId={navigationLinks.isSuperUser}
                fallBackRedirectUrl={navigationLinks.mainApplications?.find(app => app.frontEndModule == NovaeModule.MULTIPLE_STRUCTURE_MANAGMENT) ? '/dashboard' : undefined}
            />
        </PageWrapper>
    }


    // Listen to history changes, to check if the user is authenticated or not.
    // const history = createHistory()
    //     const unlisten = props.history.listen((location, action) => {
    //         // Check the authentication
    // //        logThis("Check the user authentication.")
    // //        checkSession(REST_ENDPOINTS.checkAuthentication)
    //         setMsg({
    //             show: true, 
    //             type: 'error',
    //             msg: 'Oops, error'
    //         })
    //     })



    const notFoundRoute = () => (
        <Navigate to={PAGES.notFound} />
    )

    {/* <NovaeNavigation
                needNavbarArea
                loginRedirectUrl={props.structureInfo.logoutRedirectUrl}
                structureId={props.structureId}
                navigationLinks={props.navigationLinks}
                userInfo={props.userInfo}
                structureInfo={props.structureInfo}
            /> */}


    //display="flex" flexDirection="column" width="100vw" minHeight="100vh" 


    const novaeWebRoutes = () => {
        return (
            <Routes>
                <Route element={<NovaeDashboard />} path={PAGES.dashboard} />
                <Route element={<NovaeChangePassword />} path={PAGES.changePassword} />

                <Route element={<NewEnrollment />} path={PAGES.nuovaIscrizioneFrequenza + '/:id'} />
                <Route element={<NewEnrollment />} path={PAGES.templateNuovaIscrizioneFrequenza + '/:yearId'} />
                <Route element={<NewEnrollment />} path={PAGES.nuovaIscrizioneFrequenza} />
                <Route element={<SummaryComponent />} path={PAGES.nuovaIscrizioneRiepilogo + '/:id'} />

                <Route element={<Dichiarazioni />} path={PAGES.nuovaIscrizioneDichiarazioni + '/:id'} />
                <Route element={<Dichiarazioni />} path={PAGES.templateNuovaIscrizioneDichiarazioni + '/:yearId'} />

                <Route element={<DataChange />} path={PAGES.cambioDati + '/:id'} />
                <Route element={<DataChange />} path={PAGES.templateCambioDati + '/:yearId'} />
                <Route element={<DataChange />} path={PAGES.cambioDati} />
                <Route element={<DataChangeDichiarazioni />} path={PAGES.cambioDatiDichiarazioni + '/:id'} />
                <Route element={<DataChangeDichiarazioni />} path={PAGES.templateCambioDatiDichiarazioni + '/:yearId'} />
                <Route element={<SummaryComponent />} path={PAGES.cambioDatiRiepilogo + '/:id'} />

                <Route element={<Modifica />} path={PAGES.modificaIscrizione + '/:id'} />
                <Route element={<Modifica />} path={PAGES.templateModificaIscrizione + '/:yearId'} />
                <Route element={<Modifica />} path={PAGES.modificaIscrizione} />
                <Route element={<SummaryComponent />} path={PAGES.modificaIscrizioneRiepilogo + '/:id'} />

                <Route element={<ServiziAggiuntivi />} path={PAGES.serviziAggiuntivi + '/:id'} />
                <Route element={<ServiziAggiuntivi />} path={PAGES.templateServiziAggiuntivi + '/:yearId'} />
                <Route element={<ServiziAggiuntivi />} path={PAGES.serviziAggiuntivi} />
                <Route element={<SummaryComponent />} path={PAGES.serviziAggiuntiviRiepilogo + '/:id'} />

                <Route element={<EnrollmentChange />} path={PAGES.modificaInizioFineFrequenza + '/:id'} />
                <Route element={<EnrollmentChange />} path={PAGES.templateModificaInizioFineFrequenza + '/:yearId'} />
                <Route element={<EnrollmentChange />} path={PAGES.modificaInizioFineFrequenza} />
                <Route element={<SummaryComponent />} path={PAGES.modificaInizioFineFrequenzaRiepilogo + '/:id'} />

                <Route element={<IseeChange />} path={PAGES.cambioIsee + '/:id'} />
                <Route element={<IseeChange />} path={PAGES.templateCambioIsee + '/:yearId'} />
                <Route element={<IseeChange />} path={PAGES.cambioIsee} />
                <Route element={<SummaryComponent />} path={PAGES.cambioIseeRiepilogo + '/:id'} />

                <Route element={<OrganizationRequestsHistory />} path={PAGES.storicoRichieste} />
                <Route element={<WaitingListPanel />} path={PAGES.listaAttesa} />
                <Route element={<OrganizationFillingList />} path={PAGES.richiesteInCompilazione} />
                <Route element={<NewsTableComponent />} path={PAGES.tableNews} />
                <Route element={<OrganizationManager />} path={PAGES.contentManagerDescriptions} />
                <Route element={<ParametersPanel />} path={PAGES.changeParameters} />
                <Route element={<TopTableReadNews />} path={PAGES.readNews} />
                <Route element={<NewsDetails />} path={PAGES.newsDetails + '/:id'} />
                <Route element={<SingleNewsComponent />} path={PAGES.newsModify + '/:id'} />
                <Route element={<SingleNewsComponent />} path={PAGES.newsModify} />
                <Route element={<OrganizationChange />} path={PAGES.organizationChange + '/:yearId' + '/:id'} />

                <Route element={<OrganizationPresenceReport singleOrganization />} path={PAGES.reportPresenze} />
                <Route element={<ReportDataPreview />} path={PAGES.reportPreview} />
                <Route element={<AccountHoldersTable />} path={PAGES.accountHolders} />

                <Route element={<AdminDashBoard />} path={PAGES.admin} />

                <Route element={<ParentsAppSimulator />} path={PAGES.appSimulator/* + '/:structureId'*/} />

                <Route element={<InvoicesTableComponent />} path={PAGES.listInvoices} />
                <Route element={<DocumentTableComponent />} path={PAGES.listDocuments} />

                <Route element={<RequestBin />} path={PAGES.requestBin} />
                <Route element={<Staff />} path={PAGES.getStaff} />
                <Route element={<NewStaff />} path={PAGES.newStaff} />
                <Route element={<NewStaffRia />} path={PAGES.newStaff + '/:ana'} />
                <Route element={<ModifyStaff />} path={PAGES.modifyStaff + '/:ria'} />

                <Route element={<CalendarPage />} path={PAGES.calendar} />


                <Route element={<TemplateTableComponent />} path={PAGES.yearTemplate} />

                <Route element={notFoundRoute()} />
            </Routes>
        )
    }

    const isInitCompleted = () => {
        const { navigationLinks, userInfo, structureInfo } = props
        return navigationLinks && userInfo && structureInfo
    }


    const navigationNotFoundError = getMessageByParam(props.messages, "id", SINGLETON_ERRORS.NAVIGATION_404)
    if (navigationNotFoundError) {
        if (props.messages.NAVIGATION_NOT_FOUND && props.messages.NAVIGATION_NOT_FOUND.message) {
            return (
                <Error404 message={props.messages.NAVIGATION_NOT_FOUND.message} />
            )
        } else {
            return (
                <Error404 message={STRINGS.ERROR_NAVIGATION_NOT_FOUND} />
            )
        }
    }


    const genericError = getMessageByParam(props.messages, "id", SINGLETON_ERRORS.INIT_ERROR)
    if (genericError) {
        return (
            <Box>
                <GenericNovaeHeader logout={true} structureId={props.structureId} />
                <GenericError message={genericError.message} enabledOrganizations={genericError.enabledOrganizations} />
            </Box>
        )
    }

    if (!isInitCompleted()) {
        return (
            <PageWrapper evaluateLinksFromState={evaluateLinksFromState} navigationBars={false}>{getLoadingComponent(true)}</PageWrapper>
        )
    }
    console.log("LogoutRedirectUrl in router: " + props.structureInfo.logoutRedirectUrl)
    console.log("Current path " + location.pathname)
    return (
        <PageWrapper evaluateLinksFromState={evaluateLinksFromState}>
            <Routes>
                <Route element={<Error404 />} path={PAGES.notFound} />
                <Route element={<Totem />} path={PAGES.totem + '/:structureId'} />
                <Route element={<Totem />} path={PAGES.totem} />
                <Route element={<MultiStructureRouter />} path={MODULE_ROOTS.multiStructureModule} />
                <Route element={novaeWebRoutes()} path="/*" />
            </Routes>
        </PageWrapper>
    )
    // <CustomMessageProvider>
    // <CheckAuth history={props.history}/>
    // <ThemeProvider theme={NOVAE_PALETTE}>
    //     <Switch>
    //         <Route element={<Error404/>} path={PAGES.notFound} />
    //         <Route element={<Totem/>} path={PAGES.totem + '/:structureId'} />
    //         <Route element={<Totem/>} path={PAGES.totem} />
    //         <Route element={<multiStructureRouter/>} path={MODULE_ROOTS.multiStructureModule} />
    //         <Route render={novaeWebRoutes} />
    //     </Switch>
    // </ThemeProvider>
    // <CustomSnackBar/>
    // </CustomMessageProvider>           
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        userInfo: state.global.userInfo,
        structureInfo: state.global.structureInfo,
        messages: state.global.messages,
        structureId: state.global.structureId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveInitializationInfo: bindActionCreators(retrieveInitializationInfo, dispatch),
        saveStructureId: bindActionCreators(saveStructureId, dispatch)
    }
}

export const AppHoc = connect(mapStateToProps, mapDispatchToProps)(App)