import { News, NewsItem } from 'commons/interfaces//news';
import { Navigation } from 'commons/interfaces/navigation';
import { ComponentListItem } from '@insoft/lib-react-universal/dist/interfaces//component-list-item';
import { renderComponentsList } from '@insoft/lib-react-universal/dist/utils';
import Modal from '@insoft/lib-react-web/dist/components/misc/modal';
import { createDangerousInnerHtml, getHeight } from '@insoft/lib-react-web/dist/utils/utils';
import Carousel from 'nuka-carousel';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { retrieveNews, retrieveNewsBase64Images, selectNews } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getLoadingComponent, isValidURL } from 'utils';
import Typography from '@mui/material/Typography'
import { Box, Button, Container, Grid } from '@mui/material';

interface ActionCreatorsProps {
    retrieveNews: (uri: string) => any
    selectNews: (selectedNews: NewsItem) => any
    retrieveNewsBase64Images: (news: News) => any
}

interface Props {
    navigationLinks: Navigation
    loading: boolean
    news: News
    selectedNews: NewsItem
}

interface AllProps extends ActionCreatorsProps, Props {
    autoplay?: boolean
    newsPerPage?: number
}

function NewsComponent(props: AllProps) {

    useEffect(() => {
        if (!props.news) {
            const link = props.navigationLinks._links.news.href
            props.retrieveNews(link)
        }
    }, [])

    useEffect(() => {
        props.retrieveNewsBase64Images(props.news)
    })

    const renderNews = (item: NewsItem & ComponentListItem) => {
        if (item.urlImg || item.base64Image) {
            const imageSrc = item.base64Image ? item.base64Image : item.urlImg
            var imgView = (
                <Grid item md={12} xs={12} lg={12}>
                    <Container maxWidth="sm">
                        <Box display="flex" justifyContent="center" >
                            <img src={imageSrc} />
                        </Box>
                    </Container>
                </Grid>

            )
        }
        if (item.link && item.link.url && isValidURL(item.link.url) && item.link.text) {
            var linkButton = (
                <Button href={item.link.url} variant="contained" color="primary">{item.link.text}</Button>
            )
        }

        var descriptionView = (
            <Grid item xs={12} md={12}>
                <Box>
                    <Typography align="center" variant="h3" gutterBottom >{item.title}</Typography>
                    <Typography variant="body1" component="div" gutterBottom>
                        <div dangerouslySetInnerHTML={createDangerousInnerHtml(item.body)} />
                    </Typography>
                    {linkButton}
                </Box>
            </Grid>
        )
        const rowStyleObject = { height: getHeight() / 2 }
        return (
            <Grid container justifyContent="center" key={item._key} onClick={() => showNewsDetail(item)} style={rowStyleObject} spacing={1}>
                {imgView}
                {descriptionView}
            </Grid>
        )
    }

    const showNewsDetail = (selectedNews: NewsItem) => {
        props.selectNews(selectedNews)
    }

    const hideNewsDetail = () => {
        props.selectNews(null)
    }

    const renderNewsDetailBody = (item: NewsItem) => {
        if (item.urlImg || item.base64Image) {
            const imageSrc = item.base64Image ? item.base64Image : item.urlImg
            var img = (
                <Box display="flex" justifyContent="center" >
                    <img src={imageSrc} />
                </Box>
            )
        }
        const description = (
            <div dangerouslySetInnerHTML={createDangerousInnerHtml(item.body)} />
        )
        if (item.link && item.link.url && isValidURL(item.link.url) && item.link.text) {
            var linkButton = (
                <Button href={item.link.url} variant="contained" color="primary">{item.link.text}</Button>
            )
        }
        return (
            <Box justifyContent="center" p={2}>
                {img}
                {description}
                {linkButton}
            </Box>
        )
    }


    const { loading, news, selectedNews } = props
    if (loading) {
        return getLoadingComponent(false)
    }
    if (selectedNews) {
        const newsDetailBody = renderNewsDetailBody(selectedNews)
        const title = selectedNews.title ? selectedNews.title : "News"
        var newsDetailView = (
            <Modal
                title={title}
                body={newsDetailBody}
                onDismiss={hideNewsDetail}
                resizeBodyHeight
            />
        )
    }
    if (news && news.news) {
        const newsListView = renderComponentsList(news.news, renderNews)
        var newsView = (
            <Carousel
                autoplay={news.news.length != 1 ? true : false}
                wrapAround
                renderCenterLeftControls={null}
                renderCenterRightControls={null}
            >
                {newsListView}
            </Carousel>
        )
    } else {
        var newsView = (<Typography style={{ textAlign: 'center' }}>Non ci sono news recenti.</Typography>)
    }

    const rowStyleObject = { height: getHeight() / 2 }
    return (
        <div className='panel panel-default'>
            <div className='panel-heading'>
                <span className='glyphicon glyphicon-list-alt separate-to-text' />
                <b>News</b>
            </div>
            <div className='panel-body'>
                <div className='row'>
                    <div className='col-xs-12 news-wrapper' style={rowStyleObject}>
                        {newsDetailView}
                        {newsView}
                    </div>
                </div>
            </div>
            <div className='panel-footer' />
        </div >
    )
}

NewsComponent.defaultProps = {
    autoplay: false,
    newsPerPage: 4
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        navigationLinks: state.global.navigationLinks,
        loading: state.global.isLoading,
        news: state.global.news,
        selectedNews: state.global.selectedNews
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        retrieveNews: bindActionCreators(retrieveNews, dispatch),
        selectNews: bindActionCreators(selectNews, dispatch),
        retrieveNewsBase64Images: bindActionCreators(retrieveNewsBase64Images, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsComponent)