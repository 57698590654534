import { CustomMessageProvider, ErrorBoundary } from "@insoft/lib-react-web";
import { expandUri } from "@insoft/lib-react-universal";
import { CheckAuth } from "@insoft/lib-react-web";
import CustomSnackBar from "@insoft/lib-react-web/dist/components/misc/sessionExpired-component/CustomSnackBar";
import { CssBaseline, PaletteColor, ThemeProvider, darken, lighten, Box, Divider } from "@mui/material";
import { NovaeModule, PAGES, REST_ENDPOINTS, WEB_ENDPOINTS } from "../../../utils/rest-routes";
import { NOVAE_PALETTE } from "../../styles/material-ui-style";
import { ApplicationItem, BreadCrumb, ContextOption, MenuLink, PageWithTopBarAndLeftDrawer } from '@insoft/lib-react-mui-web';
import React from 'react';
import { useSelector } from "react-redux";
import { GlobalStateInterface } from "../../../redux/store";
import { Navigation } from "../../interfaces/navigation";
import { StructureInfo } from "../../interfaces/structure-info";
import { UserInfo } from "../../interfaces/user-info";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { getPageTitleInfo } from "../../navigation-links";
import Footer from "../footer";

export default function PageWrapper(props: {
    navigationBars?: boolean
    children?: React.ReactElement[] | React.ReactElement
    evaluateLinksFromState: (state: GlobalStateInterface) => MenuLink[]
}) {
    const globalState: GlobalStateInterface = useSelector((state: GlobalStateInterface) => state);
    const navigationLinks: Navigation = useSelector((state: GlobalStateInterface) => state.global.navigationLinks);
    const structureInfo: StructureInfo = useSelector((state: GlobalStateInterface) => state.global.structureInfo);
    const structureId: string = useSelector((state: GlobalStateInterface) => state.global.structureId);
    const userData: UserInfo = useSelector((state: GlobalStateInterface) => state.global.userInfo);
    const location = useLocation()
    const storedPageInfo: { title?: string, breadcrumbs?: BreadCrumb[] } = useSelector((state: GlobalStateInterface) => state.global.pageInfo);
    const navigate = useNavigate()

    const hideAppBar = location.pathname.startsWith(PAGES.totem)



    // TODO verificare come passare la funzione da fuori
    const menuLinks = props.evaluateLinksFromState(globalState)

    const MAIN_COLOR_DRAWER = '#222d32'
    const drawerPalette: PaletteColor = {
        light: lighten(MAIN_COLOR_DRAWER, 0.5),
        main: MAIN_COLOR_DRAWER,
        dark: darken(MAIN_COLOR_DRAWER, 0.5),
        contrastText: '#ffffff'
    }
    var children;
    if (!hideAppBar && (props.navigationBars == undefined || props.navigationBars)) {
        if (navigationLinks?.organizations) {
            var organizations: ContextOption[] = navigationLinks.organizations.map(
                organization => {
                    return {
                        id: organization.id,
                        name: organization.name,
                        href: expandUri(WEB_ENDPOINTS.novae, { structureId: organization.id }),
                        current: structureId == organization.id,
                        // icon: organization.adminDashboard ? CorporateFareIcon : undefined
                    }
                }
            )
            var map = new Map()
            navigationLinks.organizations.forEach(item => map.set(item.id, item))
        }
        // const pageInfo = getTitlePageInfo({
        //     page: location.pathname,
        //     structureInfo: structureInfo,
        //     navigate: navigate
        // })
        let pageInfo: { title?: string, breadcrumbs?: BreadCrumb[] } = storedPageInfo;
        if (!storedPageInfo) {
            pageInfo = getPageTitleInfo(location.pathname, menuLinks, navigate)
        }

        if (structureInfo) {
            var footer = <Box alignContent="flex-end" pt={2} pb={2}>
                <Divider />
                <Footer structureInfo={structureInfo} />
            </Box >
        }
        const logoutUrl = '/logout?logoutRedirectUrlParam=' + encodeURIComponent(structureInfo?.logoutRedirectUrl ||
            window.location.protocol + '//' + window.location.host + expandUri(WEB_ENDPOINTS.root, { structureId: structureId }));
        children = (<PageWithTopBarAndLeftDrawer
            iconAlt='Novae WEB'
            barTitle='WEB'
            icon='/dist/img/novae_logo.png'
            iconSmall='/dist/img/ic_launcher.png'
            homeLink='/'
            pageTitle={pageInfo?.title}
            breadCrumbs={pageInfo?.breadcrumbs}
            currentLink={location.pathname}
            links={menuLinks}
            palette={drawerPalette}
            userData={userData}
            useCookieCsrf={true}
            logoutUrl={logoutUrl}
            // logoutRedirectUrlParam={structureInfo?.logoutRedirectUrl || expandUri(WEB_ENDPOINTS.root, { structureId: structureId })}
            changePasswordUrl={PAGES.changePassword}
            changeContextOptions={navigationLinks?.isOperator ? organizations : undefined}
            changeContextOptionsBackgroundWhite
            changeContextCallback={navigationLinks?.isOperator && map ? (structureId: string) => {
                const item = map.get(structureId)
                if (item.adminDashboard) {
                    const url = expandUri(WEB_ENDPOINTS.dashboard, { structureId: structureId });
                    window.location.href = url
                } else {
                    const url = expandUri(WEB_ENDPOINTS.novae, { structureId: structureId });
                    window.location.href = url
                }
            } : undefined}
            changeApplicationOptions={navigationLinks?.isOperator && navigationLinks?.mainApplications ? navigationLinks?.mainApplications.map(
                (item: {
                    id: string;
                    name?: string;
                    url?: string;
                    frontEndModule?: NovaeModule
                }): ApplicationItem => {
                    return {
                        id: item.id,
                        url: item.id == 'novae' ? WEB_ENDPOINTS.novae.replace("{?structureId}", "")
                            : (item.url || (item.frontEndModule == NovaeModule.MULTIPLE_STRUCTURE_MANAGMENT ? WEB_ENDPOINTS.dashboard.replace("{?structureId}", "") : "#")),
                        name: item.name
                    }
                }
            ) : undefined}
        >
            <Box flexGrow={1}>
                <Box p={2}>
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </Box>
            </Box>
            {footer}
        </PageWithTopBarAndLeftDrawer>);
    } else {
        children = props.children
    }

    return (
        <CustomMessageProvider>
            <CheckAuth
                restEndpoint={REST_ENDPOINTS.CHECK_AUTHENTICATION} />
            <ThemeProvider theme={NOVAE_PALETTE}>
                <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    <CustomSnackBar />
                    {children}
                </SnackbarProvider>
            </ThemeProvider>
        </CustomMessageProvider>)
}
