import ChildDiaryImages from 'commons/components/child-diary-images';
import { Child, ChildDiary } from 'commons/interfaces/child';
import { Navigation } from 'commons/interfaces/navigation';
import { expandUri, logThis } from '@insoft/lib-react-universal/dist/utils';
import { executeRequest } from '@insoft/lib-react-web/dist/services/ajax-services';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { startLoading, stopLoading } from 'redux/actions/global-actions';
import { GlobalStateInterface } from 'redux/store';
import { getTotemContentHeight } from 'utils';
import { REST_ENDPOINTS } from 'utils/rest-routes';
import ChildDiaryText from '../../commons/components/child-diary-text';

interface ActionCreatorsProps {
    startLoading: () => any
    stopLoading: () => any
}

interface Props {
    structureId: string
    navigationLinks: Navigation
    loading: boolean
    selectedChild: Child
}

interface AllProps extends ActionCreatorsProps, Props { }

interface State {
    childDiary: ChildDiary
    currentImage: number
    lightboxIsOpen: boolean
}

class ChildDiaryComponent extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props)
        this.state = {
            childDiary: null,
            currentImage: 0,
            lightboxIsOpen: false
        }
    }

    componentDidMount() {
        if (this.props.selectedChild) {
            this.fetchData()
        }
    }

    componentDidUpdate(prevProps: AllProps) {
        if (this.childrenWasChanged(prevProps)) {
            this.fetchData()
        }
    }

    childrenWasChanged = (prevProps: AllProps) => {
        const childIsNowSelected = this.props.selectedChild
        const childWasPreviouslySelected = prevProps.selectedChild
        const childHasChanged = childIsNowSelected && (!childWasPreviouslySelected || childWasPreviouslySelected && prevProps.selectedChild.ana !== this.props.selectedChild.ana)
        return childHasChanged
    }

    fetchData = () => {
        this.setState({ childDiary: null })
        this.props.startLoading()
        const expandedUri = expandUri(this.props.navigationLinks._links.childDiary.href, { childId: this.props.selectedChild.ana, structureId: this.props.structureId })
        executeRequest<ChildDiary>({ url: expandedUri, sessionCheckUri: REST_ENDPOINTS.CHECK_AUTHENTICATION })
            .then((data) => {
                this.props.stopLoading()
                this.setState({ childDiary: data })
                return null
            }).catch((data) => {
                this.props.stopLoading()
                logThis(data)
            })
    }

    renderDailyImagesSection = () => {
        const dailyImages = this.state.childDiary.dailyImages
        if (dailyImages) {
            return (
                <ChildDiaryImages dailyImages={dailyImages} />
            )
        }
    }


    getContainerHeight = () => {
        return getTotemContentHeight()
    }

    render() {
        if (!this.props.selectedChild) {
            var errorMessage = "Nessun bambino selezionato"
        } else if (!this.state.childDiary || !this.state.childDiary.sections) {
            errorMessage = "Diario non disponibile"
        }
        if (errorMessage) {
            var content = (
                <section className='content' style={{ height: getTotemContentHeight() }}>
                    <h2 style={{ marginTop: getTotemContentHeight() * 1 / 3 }}>{errorMessage}</h2>
                </section>
            )
        } else {
            const childName = this.props.selectedChild.firstName
            const dailyImagesSection = this.renderDailyImagesSection()
            content = (
                <section className='content'>
                    <div className="child-diary" >
                        <ChildDiaryText childName={childName} childDiary={this.state.childDiary} diaryDate={new Date()} />
                        {dailyImagesSection}
                    </div>
                </section>
            )
        }
        return (
            <div style={{ height: this.getContainerHeight() }}>
                {content}
            </div>
        )
    }
}

const mapStateToProps = (state: GlobalStateInterface): Props => {
    return {
        structureId: state.global.structureId,
        navigationLinks: state.global.navigationLinks,
        loading: state.global.isLoading,
        selectedChild: state.global.selectedChild
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ActionCreatorsProps => {
    return {
        startLoading: bindActionCreators(startLoading, dispatch),
        stopLoading: bindActionCreators(stopLoading, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildDiaryComponent)