import FormObjectAdapter from '@insoft/lib-react-web/dist/adapters/form-object-adapter';
import { FormObject, ServerFormObject } from '@insoft/lib-react-web/dist/interfaces/form-object';
import { COMMONS_REPRESENTATION_KEYS, FORM_INPUT_DEFAULT_WIDGETS, FORM_LIST_TYPE } from '@insoft/lib-react-web/dist/utils/schema-utils';
import { Props } from './search-history';


export const SEARCH_FILEDS = {
    NAME: 'firstName',
    SURNAME: 'lastName',
    ORDER: 'order',
    SUBMISSION_DATE: 'date',
    START_DATE: 'startDate',
    STATE: 'state',
    YEAR: 'ae',
    TYPE: 'type'
}


export function getSearchHistoryFormObject(props: Props): FormObject {
    let serverFormObject: ServerFormObject
    let properties: { [key: string]: any } = {

    }

    properties[SEARCH_FILEDS.NAME] = {
        type: "string",
        title: "Nome"
    }
    properties[SEARCH_FILEDS.SURNAME] = {
        type: "string",
        title: "Cognome"
    }
    properties[SEARCH_FILEDS.ORDER] = {
        type: "string",
        title: "Ordine"
    }
    properties[SEARCH_FILEDS.SUBMISSION_DATE + '_From'] = {
        type: "string",
        title: "Data: Da",
        represents: COMMONS_REPRESENTATION_KEYS.DATE
    }
    properties[SEARCH_FILEDS.SUBMISSION_DATE + '_To'] = {
        type: "string",
        title: "Data: Fino a",
        represents: COMMONS_REPRESENTATION_KEYS.DATE
    }
    properties[SEARCH_FILEDS.START_DATE + '_From'] = {
        type: "string",
        title: "Data Inizio: Da",
        represents: COMMONS_REPRESENTATION_KEYS.DATE
    }
    properties[SEARCH_FILEDS.START_DATE + '_To'] = {
        type: "string",
        title: "Data Inizio: Fino a",
        represents: COMMONS_REPRESENTATION_KEYS.DATE
    }
    properties[SEARCH_FILEDS.STATE] = {
        type: "string",
        title: "Stato",
        represents: [
            FORM_INPUT_DEFAULT_WIDGETS.LIST,
            FORM_LIST_TYPE.DROPDOWN_LIST
        ],
        autocomplete: {
            href: props.requestUrlState
        }
    }
    properties[SEARCH_FILEDS.YEAR] = {
        type: "string",
        title: "Anno",
        represents: [
            FORM_INPUT_DEFAULT_WIDGETS.LIST,
            FORM_LIST_TYPE.DROPDOWN_LIST
        ],
        autocomplete: {
            href: props.requestUrlYear
        }
    }
    properties[SEARCH_FILEDS.TYPE] = {
        type: "string",
        title: "Tipo",
        represents: [
            FORM_INPUT_DEFAULT_WIDGETS.LIST,
            FORM_LIST_TYPE.DROPDOWN_LIST
        ],
        autocomplete: {
            href: props.requestUrlType
        }
    }

    serverFormObject = {
        PUT: {
            form: {
                formSchema: {
                    type: "object",
                    properties: properties
                },
                formData: props.historyFilters
            }
        },
        _links: {
            self: {
                href: ''
            }
        }
    }
    return new FormObjectAdapter(serverFormObject)
}